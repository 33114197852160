import styles from "./interview.module.scss";
import { config } from "../_helpers";

import icon from "assets/icons/success-tick.svg";

export const Complete = ({ setCurrentView, type, getInterviewFormList }) => {
  const handleClick = (e) => {
    e.preventDefault();
    getInterviewFormList();
    setCurrentView("listView");
  };

  return (
    <div className={styles.success_msg__wrapper}>
      <div className={styles.success_cntnt__wrapper}>
        <img src={icon} alt="" />
        <h3>{type === "draft" ? "Interview form was saved as draft." : "Interview form was submitted."}</h3>
        <div className={styles.input__wrapper}>
          <a href="/" className={styles.button} onClick={handleClick}>
            Go to Interview Forms
          </a>
        </div>
      </div>
    </div>
  );
};
