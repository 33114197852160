import React from "react";
import { connect } from "react-redux";
import { Bulletin } from "./Bulletin";
import { EmployeeStats } from "./EmployeeStats";
import { Inventory } from "./Inventory";
import { NVAMOverview } from "./NVAMOverview";
import { CrewAssignmentStatus } from "../CrewAssignmentStatus";
import { DocsWelcome } from "./DocsWelcome";
import { DocsWelcomeRehire } from "./DocsWelcomeRehire";
import { UserWelcome } from "./UserWelcome";
import { DocsThankYou } from "./DocsThankYou";
import { ApplicantStats } from "./ApplicantStats";
import { DocsStats } from "./DocsStats";
import { userActions, permissionActions } from "../_actions";
import { notificationActions } from "../_actions";
import "../assets/styles/Stats-progressbar.css";
import "../assets/styles/dashboard.scss";
import "../assets/styles/dashboard-dynamic.css";
import loader from "../assets/images/loader.gif";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TORWidget from "TORWidget";

class Widgets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widgets: [],
      resource_list: [],
      loader: "",
      user_type: "",
      permList: [],
      widgetsLoaded: false,
      widgetDraggable: false,
      is_admin: false,
      loading: true,
      grid: {
        col1: [],
        col2: [],
      },
    };

    !Array.prototype.reindex &&
      (Array.prototype.reindex = function () {
        var array,
          length = this.length;
        array = this.map((item, index) => {
          item["position"] = index + 1;
          return item;
        });
        return array;
      });
  }

  componentDidMount() {
    this.componentWillReceiveProps(this.props);
    this.props.dispatch(userActions.getWidgets());
    this.props.dispatch(userActions.getAll()).then(
      (res) => {
        this.setState({ loading: false });
      },
      (err) => {
        console.error(err);
      },
    );
    this.props.dispatch(notificationActions.getBulletin());
    this.props.dispatch(permissionActions.getPermission());
  }
  sort(array) {
    array.sort((a, b) => {
      return a.position - b.position;
    });
    return array;
  }
  buildDashboard() {
    var data = Object.assign({}, this.state.widgets),
      column1 = [],
      column2 = [];
    Object.keys(data).map((key) => {
      let item = data[key];
      (item.col === 1 ? column1 : column2).push(item);
    });

    column1 = this.sort(column1);
    column2 = this.sort(column2);
    this.setState({
      grid: {
        col1: column1,
        col2: column2,
      },
    });
  }

  componentWillReceiveProps(nextProps) {
    const { user, permission } = nextProps;

    if (user.profile) {
      let { docs_status } = user.profile.data;
      if (docs_status == 1) {
      }
    }

    if (user["widgets"]) {
      var data = user["widgets"].data || {};
      this.setState(
        {
          widgets: data,
        },
        () => {
          this.buildDashboard();
          this.setState({
            widgetsLoaded: true,
          });
        },
      );
    }

    if (user.items) {
      let is_admin = false;

      if (user.items.data.is_admin > 0) {
        is_admin = true;
      }

      localStorage.setItem("userType", user.items.data.user_type);
      localStorage.setItem("hash", user.items.data.hash);
      localStorage.setItem("avail", user.items.data.temp_unavailable);
      var user_type = user.items.data.user_type;
      this.setState({
        user_type,
        is_admin,
      });
    }

    var permList = [],
      inventory = false;

    if (permission.permissions) {
      permList = permission.permissions.data;
      if (permList.includes("Inventory")) {
        inventory = true;
      }

      this.setState({ permList, inventory_permission: inventory });
    }

    this.setState({ loader: "hide" });
  }

  isVisible() {}

  toggleView(event, widgetid) {
    var target = event.target,
      owns = target.getAttribute("aria-owns"),
      container = document.getElementById(owns),
      status = container.getAttribute("aria-expanded") === "true";
    if (!container) return;
    if (status) {
      container.setAttribute("aria-expanded", "false");
    } else {
      container.setAttribute("aria-expanded", "true");
    }

    this.updateWidget(widgetid, status ? 0 : 1);
  }
  updateWidget(id, visibility) {
    var data = Object.assign({}, this.state.grid),
      widgets = data.col1.concat(data.col2),
      i = 0,
      length = widgets.length,
      target = {};

    for (; i < length; i++) {
      let ele = widgets[i];
      if (ele.id == id) {
        target = ele;
        continue;
      }
    }

    typeof visibility !== "undefined" && (target["visibility"] = visibility);
    this.props.dispatch(userActions.updateWidgets([target]));
  }
  getData(changed) {
    if (!changed || !Object.keys(changed).length) return;
    var obj = Object.assign({}, this.state.grid),
      merged = obj.col1.concat(obj.col2);
    merged = merged.map((item) => {
      if (item.id == changed.id) {
        return Object.assign(item, changed);
      }
      return item;
    });

    this.setState(
      {
        widgets: merged,
      },
      () => {
        this.buildDashboard();
      },
    );
    return merged;
  }
  toggleDragAndDrop(event) {
    var target = event.target;
    this.setState({
      widgetDraggable: target.checked ? true : false,
    });
  }
  onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    var obj = Object.assign({}, this.state.grid),
      start = source.droppableId,
      end = destination.droppableId,
      startIndex = source.index,
      endIndex = destination.index;

    if (start === end) {
      //same colum but different order
      let array = obj[start],
        ele = array.splice(startIndex, 1);
      array.splice(endIndex, 0, ele[0]);
      obj[start] = array.reindex();
      this.setState(
        {
          grid: obj,
        },
        () => {
          var changed = ele[0];
          changed["position"] = endIndex + 1;
          var data = this.getData(changed);
          this.props.dispatch(userActions.updateWidgets(data));
        },
      );
      return;
    } else {
      //different colum sorting
      let startArray = obj[start],
        endArray = obj[end],
        spliced = startArray.splice(startIndex, 1);
      endArray.splice(endIndex, 0, spliced[0]);
      var col1 = "col1" === start ? startArray : endArray,
        col2 = "col1" === start ? endArray : startArray;
      this.setState(
        {
          grid: {
            col1: col1.reindex(),
            col2: col2.reindex(),
          },
        },
        () => {
          var changed = spliced[0];
          changed["col"] = end === "col1" ? 1 : 2;
          changed["position"] = endIndex + 1;
          var data = this.getData(changed);
          this.props.dispatch(userActions.updateWidgets(data));
        },
      );
    }
  };

  render() {
    const { permission } = this.props;
    const { bulletin } = this.props;
    let { path } = this.props.match;
    let permList = [];
    let user = this.props.user.items ? this.props.user.items.data : {};
    let docs_status = null;
    let rehire_docs_status = null;

    permission.permissions && (permList = permission.permissions.data);
    if (user.hash && !this.state.loading) {
      docs_status = user.docs_status;
      rehire_docs_status = user.rehire_docs_status;
      if (docs_status === 1) {
        this.props.history.push(`${path}/docs/candidate-information-page?rehire=false`);
        // return <Redirect to={{ pathname: `${path}/docs/candidate-information-page?rehire=false` }} />
      } else if (rehire_docs_status === 1) {
        this.props.history.push(`${path}/docs/employee-re-hire-form?rehire=true`);
        // return <Redirect to={{ pathname: `${path}/docs/employee-re-hire-form` }}/>
      }
    }
    return (
      <div className='dashboard_container'>
        {docs_status != null && (
          <h1 className='page_title'>
            Dashboard
            {(docs_status < 0 || docs_status > 2) && (
              <div className='drag-drop-toggle'>
                <label htmlFor='isDraggable' className='switch'>
                  <input
                    type='checkbox'
                    id='isDraggable'
                    onChange={($event) => {
                      this.toggleDragAndDrop($event);
                    }}
                  />
                  <span className='slider' />
                </label>
                <label htmlFor='isDraggable' className='label-inline'>
                  Toggle Drag/Drop
                </label>
              </div>
            )}
          </h1>
        )}
        {docs_status != null && (() => {
          // LOCKED
          if (user.status == 2 && !this.state.is_admin) {
            return <UserWelcome />;
          } // WIDGETS/DASHBOARD
          else if (
            (docs_status == 3 &&
              rehire_docs_status === -1 &&
              permList.includes("Dashboard Widgets")) ||
            (docs_status === -1 &&
              rehire_docs_status === -1 &&
              permList.includes("Dashboard Widgets")) ||
            (docs_status == 3 && rehire_docs_status == 3 && permList.includes("Dashboard Widgets"))
          ) {
            return (
              <div className='row'>
                <center id='feedLoader' className={this.state.loader ? "loader_hide" : ""}>
                  <img alt='Loader' src={loader} />
                </center>
                {this.state.widgetsLoaded === false && (
                  <div className='dashboard-loader'>
                    <img src={require("../assets/images/loader.gif").default} />
                  </div>
                )}

                <DragDropContext onDragEnd={this.onDragEnd}>
                  <div
                    className={
                      "dashboard-dynamic " + (this.state.widgetDraggable ? "" : "drag-disabled")
                    }>
                    <div className='col-sm-12 col-md-6 drop-container'>
                      <Droppable droppableId={"col1"}>
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={
                              "drop-block " + (snapshot.isDraggingOver ? "dragging-over" : "")
                            }>
                            {this.state.grid.col1.map((item, index) => {
                              return (
                                <Draggable
                                  key={"item" + item.id}
                                  draggableId={item.id + "draggableid-col1"}
                                  index={index}
                                  isDragDisabled={!this.state.widgetDraggable}>
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                      className='draggable-box'>
                                      {item.name === "Bulletin Board" &&
                                        this.state.permList &&
                                        this.state.permList.length && (
                                          <Bulletin
                                            toggle={this.toggleView.bind(this)}
                                            widgetid={item.id}
                                            visibility={item.visibility}
                                            permission={this.state.permList}
                                            {...bulletin}
                                          />
                                        )}
                                      {item.name === "Employee Stats" &&
                                        this.state.permList.includes("Employee Stats") &&
                                        this.state.permList.includes("Dashboard Widgets") && (
                                          <EmployeeStats
                                            toggle={this.toggleView.bind(this)}
                                            widgetid={item.id}
                                            visibility={item.visibility}
                                          />
                                        )}
                                      {item.name === "Assignment Stats" &&
                                        this.state.permList.includes("Assignment Stats") && (
                                          <CrewAssignmentStatus
                                            toggle={this.toggleView.bind(this)}
                                            widgetid={item.id}
                                            visibility={item.visibility}
                                          />
                                        )}
                                      {item.name === "Inventory Stats" &&
                                        this.state.is_admin &&
                                        this.state.inventory_permission && (
                                          <Inventory
                                            toggle={this.toggleView.bind(this)}
                                            widgetid={item.id}
                                            visibility={item.visibility}
                                          />
                                        )}

                                      {item.name === "NVAM Overview" &&
                                        this.state.permList.includes("NVAM Overview") && (
                                          <NVAMOverview
                                            toggle={this.toggleView.bind(this)}
                                            widgetid={item.id}
                                            visibility={item.visibility}
                                          />
                                        )}

                                      {item.name === "Applicant Statistics" &&
                                        this.state.permList.includes("Applicant Statistics") && (
                                          <ApplicantStats
                                            toggle={this.toggleView.bind(this)}
                                            widgetid={item.id}
                                            visibility={item.visibility}
                                          />
                                        )}

                                      {item.name === "Onboarding Documents" &&
                                        this.state.permList.includes("Docs Stats") && (
                                          <DocsStats
                                            toggle={this.toggleView.bind(this)}
                                            widgetid={item.id}
                                            visibility={item.visibility}
                                          />
                                        )}

                                      {item.name === "TOR Documents" &&
                                        this.state.permList.includes("Manage Docs") && (
                                          <TORWidget
                                            toggle={this.toggleView.bind(this)}
                                            widgetid={item.id}
                                            visibility={item.visibility}
                                          />
                                        )}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}

                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>

                    {/* Column2  */}

                    <div className='col-sm-12 col-md-6 drop-container'>
                      <Droppable droppableId={"col2"}>
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={
                              "drop-block " + (snapshot.isDraggingOver ? "dragging-over" : "")
                            }>
                            {this.state.grid.col2.map((item, index) => {
                              return (
                                <Draggable
                                  key={"item" + item.id}
                                  draggableId={item.id + "draggableid-col2"}
                                  index={index}
                                  isDragDisabled={!this.state.widgetDraggable}>
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                      className='draggable-box'>
                                      {item.name === "Bulletin Board" &&
                                        this.state.permList &&
                                        this.state.permList.length && (
                                          <Bulletin
                                            toggle={this.toggleView.bind(this)}
                                            widgetid={item.id}
                                            visibility={item.visibility}
                                            permission={this.state.permList}
                                            {...bulletin}
                                          />
                                        )}
                                      {item.name === "Employee Stats" &&
                                        this.state.permList.includes("Employee Stats") &&
                                        this.state.permList.includes("Dashboard Widgets") && (
                                          <EmployeeStats
                                            toggle={this.toggleView.bind(this)}
                                            widgetid={item.id}
                                            visibility={item.visibility}
                                          />
                                        )}
                                      {item.name === "Assignment Stats" &&
                                        this.state.permList.includes("Assignment Stats") && (
                                          <CrewAssignmentStatus
                                            toggle={this.toggleView.bind(this)}
                                            widgetid={item.id}
                                            visibility={item.visibility}
                                          />
                                        )}
                                      {item.name === "Inventory Stats" &&
                                        this.state.is_admin &&
                                        this.state.inventory_permission && (
                                          <Inventory
                                            toggle={this.toggleView.bind(this)}
                                            widgetid={item.id}
                                            visibility={item.visibility}
                                          />
                                        )}
                                      {item.name === "NVAM Overview" &&
                                        this.state.permList.includes("NVAM Overview") && (
                                          <NVAMOverview
                                            toggle={this.toggleView.bind(this)}
                                            widgetid={item.id}
                                            visibility={item.visibility}
                                          />
                                        )}

                                      {item.name === "Applicant Statistics" &&
                                        this.state.permList.includes("Applicant Statistics") && (
                                          <ApplicantStats
                                            toggle={this.toggleView.bind(this)}
                                            widgetid={item.id}
                                            visibility={item.visibility}
                                          />
                                        )}

                                      {item.name === "Onboarding Documents" &&
                                        this.state.permList.includes("Docs Stats") && (
                                          <DocsStats
                                            toggle={this.toggleView.bind(this)}
                                            widgetid={item.id}
                                            visibility={item.visibility}
                                          />
                                        )}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                </DragDropContext>
                <div className={this.state.loader ? "hide" : "hide"}>
                  <div className='col-md-7 '>
                    <Bulletin {...bulletin} />
                    {this.state.permList.includes("Assignment Stats") && <CrewAssignmentStatus />}
                  </div>
                  <div className='col-md-5'>
                    {this.state.permList.includes("Employee Stats") &&
                      this.state.permList.includes("Dashboard Widgets") && <EmployeeStats />}
                    {this.state.permList.includes("Applicant Statistics") && <ApplicantStats />}
                    {this.state.is_admin && this.state.inventory_permission ? <Inventory /> : ""}
                    {this.state.permList.includes("NVAM Overview") && <NVAMOverview />}
                  </div>
                </div>
              </div>
            );
          } // START ONBOARDING DOCS
          else if (docs_status == 0) {
            return <DocsWelcome />;
          } // COMPLETED DOCS
          else if (
            docs_status == 2 ||
            ((docs_status === 3 || docs_status === -1) && rehire_docs_status == 2)
          ) {
            return <DocsThankYou />;
          }
          // START REHIRE DOCS
          else if (rehire_docs_status == 0 && (docs_status === 3 || docs_status === -1)) {
            return <DocsWelcomeRehire />;
          } // WELCOME
          else {
            return <UserWelcome />;
          }
          // // WELCOME
          // if (user.status == 2 || (docs_status === -1 && rehire_docs_status === -1)) {
          //   return <UserWelcome />;
          // }
        })()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, bulletin, permission } = state;
  return {
    user,
    bulletin,
    permission,
  };
}

const connectedWidgets = connect(mapStateToProps)(Widgets);
export { connectedWidgets as Widgets };
