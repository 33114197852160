import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { authHeader, config } from '../_helpers';
import { resourceActions } from '../_actions';
import '../assets/styles/resource.css';
import Dropzone from 'react-dropzone';
import { AlertPopup } from '../_components/AlertPopup';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ReactModal from 'react-modal';
ReactModal.setAppElement('#app');



const NO_IMAGE_AVATAR = "no_avatar_profile.jpg"

class EditResource extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            imageError : false ,
            form: {
                resource_type: "",
                resource_name: "",
            },
            resourceTypes:[],
            file_name:"",
            resource_photo:"",
            resource_nameError: "",
            resource_typeError: "",
            imgPreview: "",
            crop: {   x: 20,
                      y: 10,  
                      width: 150,
                      height: 150
                    },
            pixelCrop: {   x: "",
                        y: "",  
                        width: "",
                        height: ""
                      },
            fileName: "",
            image: "",
            resoure_image: "",
            alertPop: false,
            saveClick: false

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.onCropChange = this.onCropChange.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
        this.goBack = this.goBack.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.apply = this.apply.bind(this);
        this.statusOpenModal = this.statusOpenModal.bind(this);  
        this.statusCloseModal = this.statusCloseModal.bind(this); 
    }

    componentDidMount() {
        this.props.dispatch(resourceActions.getResourceDetails(this.props.id));
        this.props.dispatch(resourceActions.getResourceTypes());
    }

    componentWillReceiveProps(nextProps) {
        const { singleresource, resourceTypes } = nextProps
        if(singleresource && singleresource.items && singleresource.items.data) {
            var img = "";
            if(singleresource.items.data.resource_photo !== ""){
                img = config.apiUrl+"/uploadresourcepic/"+singleresource.items.data.resource_photo
            } 
            this.setState({ 
                form:{ ...this.state.form, 
                    resource_type: singleresource.items.data.resource_type_id, 
                    resource_name: singleresource.items.data.resource_name
                } ,
                resoure_image: singleresource.items.data.resource_photo,
                imgPreview: config.apiUrl+"/uploadresourcepic/"+singleresource.items.data.resource_photo,
                cropResult: img
            });
            this.setState({ id: singleresource.items.data.id })
        }

        if(resourceTypes)
        {
            this.setState({
                resourceTypes:resourceTypes
            });
        }

    }

    apply(file, e) {
        this.setState({ 
            imgPreview: window.URL.createObjectURL(file), 
            resource_photo: file 
        });
        
    }

    handleChange(event) {
        this.setState({ 
            form:{ ...this.state.form, [event.target.name]: event.target.value },
            [`${event.target.name}Error`]:""
        });
    }

    statusOpenModal() {
        this.setState({ statusOpen: true });
    }
    
    statusCloseModal() {
        this.setState({ statusOpen: false });
    }

    cropImage() {

        this.cropper.getCroppedCanvas().toBlob(blob => {
            var imgData = this.cropper.getData();
        this.setState({
            cropResult: this.cropper.getCroppedCanvas().toDataURL(),
            statusOpen: false,
            imgData ,
            resource_photo: blob
        });

    },'image/jpeg',.45);
    }

    goBack() {
        // this.props.goBack({id:this.props.id, resource_name:this.state.form.resource_name})
        this.props.goBack(this.props.id)
    }

    closeAlert(){
        this.setState({
            alertPop: false
        })
    }

    onImageDrop(files) {

         /*
    Validation
    */
    var allowed = ["png", "jpg", "jpeg"],
    file = files[0] || null;
  if (!file) {
    return this.setState({
      alertPop: true,
      alertHead: "Error",
      alertMsg: "Please select image file",
      alertFunction: ""
    });
  }
  var name = file.name,
    extension = name.split(".").pop().toLowerCase();
  if (allowed.indexOf(extension) === -1) {
    // Invalid file format.
    this.setState({
      alertPop: true,
      alertHead: "Error",
      alertMsg: "Invalid file format!",
      alertFunction: ""
    });
    return;
  }


        this.setState({
            // resource_photo: files[0],
            imgPreview: files[0].preview ,
            fileName: files[0].name,
            image: files[0],
            resoure_image: files[0],
            statusOpen: true
            // pixelCrop: ""
        });
        
    }

    handleFile(e){
        e.preventDefault();
        let file = e.target.files[0];
        var name = e.target.name, value = e.target.value, file_format;
        file_format = value.split('.').pop().toLowerCase();
        // var targetValue = value.toString();
        // var fileName = targetValue.replace("C:\\fakepath\\", ""); 
        if(file_format === "jpg" || file_format === "jpeg" || file_format === "png"){
            this.setState({ 
                [name]: value,
                resource_photo: file
            }); 
        }
        else{
            alert("Invalid file types. Please select any valid file types. [eg: .png, .jpeg, .jpg]");
        }
        e.target.setAttribute('errors',"");
    }

    validate(e) {
        let isError = false;
        if(this.state.form.resource_name === "" || this.state.form.resource_name === undefined){
            isError = true;
            this.setState({
                resource_nameError: "error"
            })
        }
        if(this.state.form.resource_type == ""){
            isError = true;
            this.setState({
                resource_typeError: "error"
            })
        }
        
        return isError;
    }

    onCropComplete(crop, pixelCrop){
        this.setState({
            pixelCrop
        })
    }

    onCropChange(crop){
      this.setState({ crop });
    }
    _crop(){
        // image in dataUrl
        // console.log(this.refs.cropper.getCroppedCanvas().toDataURL());
    }
    getCroppedImg(image, pixelCrop, fileName) {
 
      const canvas = document.createElement('myCanvas');
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
      const ctx = canvas.getContext('2d');
     
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );
     
      // As Base64 string
      // const base64Image = canvas.toDataURL('image/jpeg');
     
      // As a blob
      return new Promise((resolve, reject) => {
        canvas.toBlob(file => {
          file.name = fileName;
          resolve(file);
        }, 'image/jpeg');
      });
    }

    handleSubmit(event) {

        event.preventDefault();

        const err = this.validate();

        if(!err){
            this.setState({ saveClick: true })
            let data = new FormData();
            data.append('resource_name', this.state.form.resource_name)
            data.append('resource_type', this.state.form.resource_type)
            data.append('id', this.state.id)
            if(this.state.resource_photo !== "") {
                data.append('resource_photo', this.state.resource_photo)
                // data.append('x', parseInt(this.state.imgData.x))
                // data.append('y', parseInt(this.state.imgData.y))
                // data.append('width', parseInt(this.state.imgData.width))
                // data.append('height', parseInt(this.state.imgData.height))
            }    
            else {
                const shouldDeleteImage = this.state.resoure_image && typeof this.state.resoure_image === "string" && this.state.cropResult === ""
                if (shouldDeleteImage) {
                    this.deleteImageById(this.state.id)
                }
            }       
            var self = this, errMessage;
            fetch(config.apiUrl + '/api/resource/resourcesubmit',
            {
                headers: authHeader(),
                method: "POST",
                body: data
            })
            .then(function(res){ return res.json(); })
            .then(function(data){ 
                // alert( JSON.stringify( data.message ) ) 
                // self.goBack()
                if(typeof data.message === "object"){
                    for(var key in data.message){
                        errMessage = JSON.parse(JSON.stringify( data.message[key][0] ))
                        // console.log(JSON.parse(JSON.stringify( inventory.inventory.message[key][0] )))
                    }
                } else{
                    errMessage = JSON.parse(JSON.stringify( data.message))
                }
                if(data.success){
                    self.setState({ 
                        alertPop: true, 
                        alertHead: "Success", 
                        alertMsg: errMessage,
                        alertFunction: self.goBack.bind(this),
                        saveClick: false
                    })
                    // alert( JSON.stringify(data.message))
                    // self.props.history.push('/resources')
                }
                else{
                    self.setState({ 
                        alertPop: true, 
                        alertHead: "Error", 
                        alertMsg: errMessage,
                        alertFunction: "",
                        saveClick: false
                    })
                    // alert( JSON.parse(JSON.stringify(data.message.resource_name)))
                }
            })
        }
    }



    deleteImageById(resourceId) {
        let self = this
        try {
            let data = new FormData();
            data.append('resource_id', resourceId)
            this.props.dispatch(resourceActions.deleteResourceImageById(data)).then(res => {
                if (!(res && res.success)) {
                    throw res
                }
            })
        } catch (error) {

            self.setState({
                alertPop: true,
                alertHead: "Error",
                alertMsg: error.message,
                alertFunction: "",
                saveClick: false
            })

        }
    }

    async handleDeleteImage() {
        this.setState({
            resource_photo: "",
            cropResult: "",
        })
    }



    checkIfImageIsEmpty() {

        const noImageAvailable = !this.state.cropResult || this.state.cropResult && this.state.cropResult.includes && this.state.cropResult.includes(NO_IMAGE_AVATAR)

        if (noImageAvailable) {
            return true
        }
        else {
            return false
        }

    }

    render() {
        const { singleresource } = this.props;
        const form = this.state.form;
        var img;
        return (
            <div className="resource_container edit_resource_page">
                <div className="breadcrumbs"><Link to={"/resources"} className="green">Resources</Link> / <span onClick={this.goBack.bind(this)}>Details</span> / Edit Resource</div>
				<div className="page_title float">
					<h1>Edit Resource</h1>
				</div>
                <div className="inner_head"></div>
				<div className="resource_list">
                {/*<div className="goback" onClick={this.goBack.bind(this)}><p>Back</p></div>*/}
                    <div className="form resourceForm" >
                        <div className="formField">
                            <label>Resource type *</label>                        
                            <select name="resource_type" id="resource_type" value={form.resource_type} onChange={this.handleChange} errors={this.state.resource_typeError} >
                            <option value="">Select Type</option>
                            {
                                this.state.resourceTypes.map((type,index)=>{
                                    return  <option key={index} value={type.id}>{type.value}</option>
                                })
                            }
                            </select>

                        </div>
                        <div className="formField">
                            <label>Resource Name *</label>
                            <div className="legal_name app_input">
                                <input type="text" name="resource_name" errors={this.state.resource_nameError} value={form.resource_name} className="small_input" onChange={this.handleChange}/>
                            </div>
                        </div>

                        <div className="file_div formField">
                        <label>Upload file *</label>
                            <span className="file-1">
                                {/*<input id="file_name" type="file" size="40" name="file_name" value={this.state.file_name} className="file-download fileTaskbook" onChange={this.handleFile} />*/}
                            </span>
                            <div className="upload_or_drag">
                                <Dropzone
                                  id="file_name"
                                  name= "file_name"
                                  multiple={false}
                                  accept="image/*"
                                  onDrop={this.onImageDrop.bind(this)}>
                                  <p>Upload or Drag/Drop Here</p>
                                </Dropzone>
                                 
                            </div>
                            {/*<Cropper
                                ref='cropper'
                                src={this.state.cropResult}
                                style={{height: 400, width: '100%'}}
                                // Cropper.js options
                                aspectRatio={16 / 9}
                                guides={false}
                                crop={this._crop.bind(this)} />*/}
                            <div className="editResource_delete_icon_container">
                                {this.checkIfImageIsEmpty() ? null : (
                                    <div
                                        title="Delete Image"
                                        onClick={() => this.handleDeleteImage()}
                                        className="editResource_delete_icon"
                                    />
                                )}
                                {this.state.cropResult && (
                                    <img
                                        style={{ width: "100%" }}
                                        onError={(evrnt) => {
                                            this.setState({
                                                imageError: true,
                                            });
                                        }}
                                        onLoad={() => {
                                            this.setState({
                                                imageError: false,
                                            });
                                        }}
                                        src={this.state.cropResult}
                                        className={this.state.imageError ? "hide-error-image" : ""}
                                        alt="cropped image"
                                    />
                                )}
                            </div>

                           
                           {/* { this.state.imgPreview ?
                                ( <div className="resource_upload_div" style={{background:'url("'+this.state.imgPreview+'") no-repeat',width:'100%',height:'300px'}}>
                                        <AvatarImageCropper apply={this.apply.bind(this)} />
                                </div>) : (
                                    <div className="resource_upload_div" style={{background:'url('+img+') no-repeat',width:'100%',height:'300px'}}>
                                            <AvatarImageCropper apply={this.apply.bind(this)} />
                                    </div>
                                )
                            }*/}
                            <div className="clearFix"></div> 
                            </div>
                        <div className="formField form-field-bottom-controls">
                            <button className="button create_manifest" onClick={this.goBack.bind(this)}>Cancel</button>
                            <div className="right">
                                { this.state.saveClick &&
                                    <img className="login_loader" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                                <input type="submit" name="submit" onClick={this.handleSubmit.bind(this)} value="Update Resource" className="submit_appln"/>
                            </div>
                        </div>
                        		
                   	</div>			
				</div>
                <ReactModal 
                   isOpen={this.state.statusOpen} onClose={this.statusCloseModal.bind(this)}
                   contentLabel="Minimal Modal Example" overlayClassName="imageCropPopup"
                >
                    <div className="modal-head">
                        <img className="closeModal" onClick={this.statusCloseModal.bind(this)} src={require("../assets/images/close_green.png").default}/>
                    </div>
                    <div id="remove_applnt" className="modal-body">
                        <Cropper
                                ref={cropper => { this.cropper = cropper; }}
                                src={this.state.imgPreview}
                                style={{height: 400, width: '100%'}}
                                // Cropper.js options
                                aspectRatio={16 / 16}
                                guides={false}
                                crop={this._crop.bind(this)} />
                            
                    </div>
                    <div className="button_list">
                        <button onClick={this.cropImage.bind(this)} className="button">
                            Crop Image
                        </button>
                    </div>
                </ReactModal>	
                { this.state.alertPop &&
                    <AlertPopup head={this.state.alertHead} message={this.state.alertMsg} viewOpen="true" alertFunction={this.state.alertFunction} close={this.closeAlert.bind(this)} />
                }
			</div>
        );
    }
}

function mapStateToProps(state) {
    const { singleresource, resource } = state;
    return {
        singleresource,
        resourceTypes:resource.resourceTypes
    };
}

const connectedResource = connect(mapStateToProps)(EditResource);
export { connectedResource as EditResource };
