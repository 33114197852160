import React from "react";
import PropTypes from "prop-types";
import styles from "./checkBox.module.css";



const CustomCheckbox = ({ label, checked, ...props }) => {
  return (
    <div className={styles.checkBoxWrap}>
      <input type="checkbox" className={styles.checkboxInput} {...props} defaultChecked={!!checked} checked={!!checked} />
      <label className={styles.checkboxlabel}>
        <span>{label}</span>
      </label>
    </div>
  );
};

export default CustomCheckbox;

CustomCheckbox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

