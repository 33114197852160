import React from "react";
import { default as NumberFormat } from "react-number-format";
import { connect } from "react-redux";
import { userActions } from "../_actions";
import { config } from "../_helpers";
import { createProfileActions, permissionActions } from "../_actions";
import DatePicker from "react-datepicker";
import moment from "moment";
import Dropzone from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ReactModal from "react-modal";
import { AlertPopup } from "../_components/AlertPopup";
import { withRouter } from 'react-router-dom';

ReactModal.setAppElement("#app");

const DATE_FORMAT = "YYYY-MM-DD"
const INVALID_DATE = "Invalid date";

const PERMISSION_FOR_EXPIRE_ASSIGNED_DATE="Manage Expiry/Assigned Dates"
const PERMISSION_FOR_QUAL_UPDATE = "Manage Qualifications";




class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        fname: "",
        mname: "",
        lname: "",
        phone: "",
        secphone: "",
        nvamid: "",
        // ICANumber: "", // Removed for NVAM clone
        crew_identifier_id: "",
        primary_crew_identifier_id: "",
        resourceId: "",
        email: "",
        reEmailId: "",
        password: "",
        mailingAddress: "",
        mailingCity: "",
        mailingState: "",
        mailingZip: "",
        chkSameAddress: false,
        physicalAddress: "",
        physicalCity: "",
        physicalState: "",
        physicalZip: "",
        sex: '',
        emgc1FName: "",
        emgc1LName: "",
        // emgc1PhoneCC: "",
        emgc1Phone: "",
        emgc1Relation: "",
        emgc2FName: "",
        emgc2LName: "",
        // emgc2PhoneCC: "",
        emgc2Phone: "",
        emgc2Relation: "",
        driversLicense: "",
        DLState: "",
        MSPA_ExpDate: moment(),
        pantSize: "",
        inseam_pant_size: "",
        shirtSize: "",
        certifiedEMT: "",
        generalQualification: "",
        //lsa: "", // Removed for NVAM clone
        weight: "",
        currentQualification: "",
        resourcetype: this.props.type,
        position: this.props.position,
        profile_photo: "",
        applying_company: "",
        commercialDriversLicense: "",
        commercialDlExpiry: ""
      },
      permList: [],
      resources: [],
      pantSize: [],
      Crews: [],
      shirtSize: [],
      inseamPantSize: [],
      qualification: [],
      generalQualificationList: [],
      //lsa: [], // Removed for NVAM clone
      checkboxesChecked: [],
      alertPop: false,
      saveClick: false,
      isAdmin: false,
      qualificationObjectArray: []
    };
    // this.goBack = this.goBack.bind(this);
    // this.ProfilePic = this.ProfilePic.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleAddress = this.handleAddress.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.apply = this.apply.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.goBack = this.goBack.bind(this);
    this.generateAttrCheckbox = this.generateAttrCheckbox.bind(this);
  }

  componentDidMount() {
    if (this.props.match.path === "/profile/:hash") {
      var hash = "?hash=" + this.props.match.params.hash;
      this.props.dispatch(userActions.getProfile(hash));
    } else {
      this.props.dispatch(userActions.loginProfile());
    }
    this.props.dispatch(permissionActions.getPermission());
    this.props.dispatch(createProfileActions.getAll());
    this.componentWillReceiveProps(this.props);

    let isAdmin;
    this.props.user &&
      this.props.user.profile &&
      (isAdmin = this.props.user.profile.data.is_admin);
    this.setState({isAdmin})
  }

  componentWillReceiveProps(nextProps) {
    const { user, permission } = nextProps;
    let userData;
    const resources = [],
      pantSize = [],
      inseamPantSize = [],
      shirtSize = [],
      qualification = [],
      generalQualificationList = [];
      //lsa = []; // Removed for NVAM clone
    let profile, resource = [], Crews = [], response = nextProps.listAll, attributes = {};

    if (permission.permissions) {
      let permList = permission.permissions.data;

      this.setState({
        permList
      });
    }

    if (user.saveProfile) {
      var errMessage;
      if (typeof user.saveProfile.message === "object") {
        for (var key in user.saveProfile.message) {
          errMessage = JSON.parse(
            JSON.stringify(user.saveProfile.message[key][0])
          );
          // console.log(JSON.parse(JSON.stringify( inventory.inventory.message[key][0] )))
        }
      } else {
        errMessage = JSON.parse(JSON.stringify(user.saveProfile.message));
      }
      if (this.state.saveClick && user.saveProfile.success) {
        this.setState({
          alertPop: true,
          alertHead: "Success",
          alertMsg: errMessage,
          alertFunction: this.cancel.bind(this)
        });
      } else if (this.state.saveClick) {
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: errMessage,
          alertFunction: ""
        });
      }
    }

    if (this.props.match.path === "/profile/:hash") {
      if (user.items) {
        userData = user.items;
      }
    } else {
      if (user.profile) {
        userData = user.profile;
      }
    }

    // condition to check the logged in user is viewing his own profile(user.items is not available here)
    if (this.props.match.path === "/settings") {
      userData = user.profile;
    }

    if (userData) {
      profile = userData.data;
      this.setState({ profile });
      if (profile.mspa_exp_date) {
        var dateObj = new Date(profile.mspa_exp_date),
          // mspa_exp_date_Obj = moment(dateObj)
          mspa_exp_date_Obj = moment(profile.mspa_exp_date);
      } else {
        mspa_exp_date_Obj = moment();
      }

      if (profile.profile_photo === "") {
        this.setState({
          user: {
            ...this.state.user,
            profile_photo: config.apiUrl + "/" + profile.profile_photo
          },
          cropResult: config.apiUrl + "/" + config.altImg
        });
      } else {
        this.setState({
          user: {
            ...this.state.user,
            profile_photo: config.apiUrl + "/" + config.altImg
          },
          cropResult: config.apiUrl + "/" + profile.profile_photo
        });
      }

      // setting user attributes to this.state.user
      if (profile && response) {
        //generating user attributes checkbox
        if (profile.userattribute) {
          for (let [key, value] of Object.entries(profile.userattribute)) {
            value === 1 ? attributes[key] = true : attributes[key] = false;
          }
        }

        Crews = [...response.AboutTrees, ...response.AdminStaff, ...response.NVTS]

        if (nextProps.type === "AboutTrees") {
          resource = response.AboutTrees;
        } else if (nextProps.type === "AdminStaff") {
          resource = response.AdminStaff;
        } else if (nextProps.type === "NVTS"){
          resource = response.NVTS;
        }

        if (resource) {
          resource.map((item, index) => {
            resources.push(
              <option className="position-option" value={item.id} key={index}>
                {item.resource_name}
              </option>
            );
            return resources;
          });
        }
        if (response.PantSize) {
          response.PantSize.map((item, index) => {
            pantSize.push(
              <option className="pant-size-option" value={item.id} key={index}>
                {item.value}
              </option>
            );
            return pantSize;
          });
        }

        if (response.ListInseamPantSize) {
          response.ListInseamPantSize.map((item, index) => {
            inseamPantSize.push(
              <option className="pant-size-option" value={item.id} key={index}>
                {item.value}
              </option>
            );
            return inseamPantSize;
          });
        }

        if (response.ShirtSize) {
          response.ShirtSize.map((item, index) => {
            shirtSize.push(
              <option className="shirt-size-option" value={item.id} key={index}>
                {item.value}
              </option>
            );
            return shirtSize;
          });
        }
        if (response.Qualification) {
          if (profile) {
            // var qual = profile.qualification_id.split(",");

            // response.Qualification.map((item, index) => {
            //   qualification.push(
            //     <div className="current_qualification_item" key={index}>
            //       <input
            //         type="checkbox"
            //         id={"chk_current_qualification" + item.id}
            //         defaultChecked={
            //           qual.indexOf(item.id.toString()) !== -1 ? "checked" : ""
            //         }
            //         name="currentQualification[]"
            //         defaultValue={item.id}
            //         onChange={this.handleCheckbox.bind(this)}
            //       />
            //       <label htmlFor={"chk_current_qualification" + item.id}>
            //         <span />
            //       </label>
            //       <span className="wpcf7-list-item-label">{item.value}</span>
            //     </div>
            //   );
            //   return qualification;
            // });
          }
        }
        if (response.GeneralQualification) {
          response.GeneralQualification.map((item, index) => {
            generalQualificationList.push(
              <option
                className="qualification-option"
                value={item.id}
                key={index}
              >
                {item.name}
              </option>
            );
            return generalQualificationList;
          });
        }
        // if (response.Lsa) { // Removed for NVAM clone
        //   response.Lsa.map((item, index) => {
        //     lsa.push(
        //       <option className="lsa-option" value={item.id} key={index}>
        //         {item.value}
        //       </option>
        //     );
        //     return lsa;
        //   });
        // }
        const checkboxesChecked = profile?.qualificationdetails?.map(obj => obj.qualification_id.toString());

        const qualificationObjectArray = profile?.qualificationdetails?.map(qual => ({
          id: qual.qualification_id,
          qualifying_date: qual.qualifying_date || "",
        }));

        

        this.setState({
          hash: profile.hash,
          user: {
            ...this.state.user,
            ...attributes,
            id: profile.id ?? "",
            fname: profile.first_name ?? "",
            mname: profile.middle_name ?? "",
            lname: profile.last_name ?? "",
            phone: profile.phone ?? "",
            secphone: profile.second_phone ?? "",
            nvamid: profile.nvam_id ?? "",
            crew_identifier_id: profile.crew_identifier_id ?? "",
            primary_crew_identifier_id: profile.primary_crew_identifier_id ?? "",
            email: profile.email ?? "",
            reEmailId: profile.email ?? "",
            mailingAddress: profile.mailing_address?.address ?? "",
            mailingCity: profile.mailing_address?.city ?? "",
            mailingState: profile.mailing_address?.state ?? "",
            mailingZip: profile.mailing_address?.zip ?? "",
            physicalAddress: profile.physical_address?.address ?? "",
            physicalCity: profile.physical_address?.city ?? "",
            physicalState: profile.physical_address?.state ?? "",
            physicalZip: profile.physical_address?.zip ?? "",
            sex: profile.gender ?? "",
            emgc1FName: profile.emgcontact1?.fname ?? "",
            emgc1LName: profile.emgcontact1?.lname ?? "",
            emgc1Phone: profile.emgcontact1?.phone ?? "",
            emgc1Relation: profile.emgcontact1?.relation ?? "",
            emgc2FName: profile.emgcontact2?.fname ?? "",
            emgc2LName: profile.emgcontact2?.lname ?? "",
            emgc2Phone: profile.emgcontact2?.phone ?? "",
            emgc2Relation: profile.emgcontact2?.relation ?? "",
            driversLicense: profile.drivers_license ?? "",
            DLState: profile.DLState ?? "",
            MSPA_ExpDate: mspa_exp_date_Obj ?? "",
            pantSize: profile.pant_size ?? "",
            shirtSize: profile.shirt_size ?? "",
            inseam_pant_size: profile.inseam_pant_size ?? "",
            certifiedEMT: profile.certified_emt ?? "",
            generalQualification: profile.general_qualification_id ?? "",
            weight: profile.weight ?? "",
            currentQualification: profile.qualification_id ?? "",
            applying_company: profile.applying_company ?? "",
            commercialDlExpiry: profile.commercial_dl_expiry ?? "",
            commercialDriversLicense: profile.commercial_drivers_license ?? "",
            qualificationDetails: qualificationObjectArray ?? "",
            fingerPrintExpiry: profile?.useritemdates?.finger_print_expiry ?? "",
            physicalExpiry: profile?.useritemdates?.physical_expiry ?? "",
            firstAidExpiry: profile?.useritemdates?.first_aid_expiry ?? "",
            sexualHarassmentExpiry: profile?.useritemdates?.sexual_harassment_expiry ?? "",
            fallerQuals: profile?.useritemdates?.faller_quals ?? "",
            dlExpiry: profile?.useritemdates?.dl_expiry ?? "",
        },
        
          checkboxesChecked,
          resources,
          pantSize,
          Crews,
          shirtSize,
          inseamPantSize,
          qualification,
          generalQualificationList,
          qualificationData: response?.Qualification,
          qualificationObjectArray,
          //lsa, // Removed for NVAM clone
          ...attributes
        });
      }
    }
  }

  generateAttrCheckbox(attrs) {
    if (attrs) {
      let userattributes = [];
      for (let [key, value] of Object.entries(attrs)) {
        let name = key.split('_').join(' ').toUpperCase();
        userattributes.push(
          <div className="current_qualification_item" key={key}>
            <input
              type="checkbox"
              id={key}
              name={key}
              checked={this.state.user[key]}
              onChange={(e) => this.handleAttributes(e.currentTarget)}
            />
            <label htmlFor={key}>
              <span />
            </label>
            <span className="wpcf7-list-item-label">{name}</span>
          </div>
        )
      }
      return userattributes;
    }
  }

  closeAlert() {
    this.setState({
      alertPop: false
    });
  }

  handleChange(e) {
    var name = e.target.name,
      value = e.target.value;
    const { user } = this.state;
    // if(value !== ""){

    this.setState({
      user: {
        ...this.state.user,
        [name]: value
      }
    }, () => {

      var address = ["mailingAddress", "mailingCity", "mailingState", "mailingZip"], ob = {};
      if (address.indexOf(name) > -1 && this.state.user.chkSameAddress) {
        ob = {
          physicalAddress: this.state.user.mailingAddress,
          physicalCity: this.state.user.mailingCity,
          physicalState: this.state.user.mailingState,
          physicalZip: this.state.user.mailingZip,
        }
      }
      this.setState({
        user: {
          ...this.state.user,
          ...ob
        }
      }, () => {
      })
    });
    e.target.setAttribute("errors", "");
    e.target.setAttribute("id", "");
    // }
  }

  apply(file) {
    this.setState({
      imgPreview: window.URL.createObjectURL(file),
      uploadedFile: file
    });

    // var pic = file.preview;
    // var pic_name = pic.split('/').pop();
    // this.setState({  })
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  handleCheckbox(e) {
    var checkboxesChecked = [], a;
    var value = e.target.value;
    if (e.target.checked) {
      this.createQualificationObject(value);
      this.state.checkboxesChecked.push(value);
      checkboxesChecked = this.state.qualificationObjectArray;
    } else {
      a = this.state.checkboxesChecked.indexOf(value);
      if (a !== -1) {
        this.state.checkboxesChecked.splice(a, 1);
      }
      checkboxesChecked = this.state.qualificationObjectArray.filter(object => {
        return object.id.toString() !==  value.toString();
      });
    }
    var chkString = this.state.checkboxesChecked.toString();

    this.setState({
      user: {
        ...this.state.user,
        currentQualification: chkString,
        qualificationDetails: checkboxesChecked
      },
      qualificationObjectArray: checkboxesChecked
    });
    debugger
  }

  createQualificationObject =(itemId) => {
    const qualificationObject = {
      id: itemId,
      qualifying_date: "",
    }
    this.state.qualificationObjectArray.push(qualificationObject);
  }

  generateQualificationTable = (qualificationData = [], qual = []) => {
    return qualificationData.map((item, index) => {
      const qualObj = this.state.qualificationObjectArray?.find(obj => obj.id.toString() === item.id.toString());
     return (
        <tr>
          <td>
            <div className="current_qualification_item" key={index}>
              <input
                type="checkbox"
                id={"chk_current_qualification" + item.id}
                defaultChecked={
                  qual.indexOf(item.id.toString()) !== -1 ? "checked" : ""
                }
                name="currentQualification[]"
                defaultValue={item.id}
                onChange={this.handleCheckbox.bind(this)}
                disabled={!this.state.permList.includes(PERMISSION_FOR_QUAL_UPDATE)}
              />
              <label htmlFor={"chk_current_qualification" + item.id}>
                <span />
              </label>
              <span className="wpcf7-list-item-label">{item.value}</span>
            </div>
          </td>
          <td>
            <DatePicker
              dateFormat={"MM/DD/YYYY"}
              name="qualifying_date"
              selected={qualObj?.qualifying_date ? moment(qualObj?.qualifying_date) : ""}
              onChange={(date) => this.handleQualDateChange("qualifying_date", moment(date).format(DATE_FORMAT), item.id)}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              disabled={qual.indexOf(item.id.toString()) === -1 || !this.state.permList.includes(PERMISSION_FOR_QUAL_UPDATE)}
            />
          </td>
        </tr>
      );
    });
  }
  
  generateMobQualificationTable = (qualificationData = [], qual = []) => {
    return qualificationData.map((item, index) => {
      const qualObj = this.state.qualificationObjectArray?.find(obj => obj.id.toString() === item.id.toString());
      return(
        <React.Fragment>
          <hr></hr>
          <div className="status_item">
            <div className="list_item">
              <strong>Qualification</strong>
            </div>
            <div className="mobile_qualification_item" key={index}>
              <input
                type="checkbox"
                id={"chk_current_qualification"+ index + item.id}
                defaultChecked={
                  qual.indexOf(item.id.toString()) !== -1 ? "checked" : ""
                }
                name="currentQualification[]"
                defaultValue={item.id}
                onChange={this.handleCheckbox.bind(this)}
                disabled={!this.state.permList.includes(PERMISSION_FOR_QUAL_UPDATE)}
              />
              <label htmlFor={"chk_current_qualification"+ index + item.id}>
                <span />
              </label>
              <span className="wpcf7-list-item-label">{item.key}</span>
            </div>
          </div>
          <div className="status_item">
            <div className="list_item">
              <strong>Qualifying Date</strong>
            </div>
            <div className="mobile_qualification_item">
              <DatePicker
                dateFormat={"MM/DD/YYYY"}
                name="qualifying_date"
                selected={qualObj?.qualifying_date ? moment(qualObj?.qualifying_date) : ""}
                onChange={(date) => this.handleQualDateChange("qualifying_date", moment(date).format(DATE_FORMAT), item.id)}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                disabled={qual.indexOf(item.id.toString()) === -1 || !this.state.permList.includes(PERMISSION_FOR_QUAL_UPDATE)}
              />
            </div>
          </div>
        </React.Fragment>
      );
    });
  }

  handleQualDateChange(param, value, itemId) {
    const qualificationObjectArray = this.state.qualificationObjectArray.map(obj => {
      if (obj.id.toString() === itemId.toString()) {
        obj[param] = value
        debugger
      }
      return obj;
    });
    
    this.setState({
      user: {
      ...this.state.user,
      qualificationDetails : qualificationObjectArray,
     },
      qualificationObjectArray
    });

    debugger
  }


  handleAttributes(el) {
    let key = el.name;
    let user = this.state.user;
    if (el.checked) {
      this.setState({
        user: { ...this.state.user, [key]: true }
      })
    } else {
      this.setState({
        user: { ...user, [key]: false }
      })
    }
  }

  handleAddress(e) {
    var name = e.target.name,
      value = e.target.value;
    if (e.target.checked) {
      this.setState({
        user: {
          ...this.state.user,
          physicalAddress: this.state.user.mailingAddress,
          physicalCity: this.state.user.mailingCity,
          physicalState: this.state.user.mailingState,
          physicalZip: this.state.user.mailingZip,
          chkSameAddress: true
        },
        paddress: true,
        pcity: true,
        pstate: true,
        pzip: true
      });
      document.getElementById("physicalAddress").setAttribute("errors", "");
      document.getElementById("physicalCity").setAttribute("errors", "");
      document.getElementById("physicalState").setAttribute("errors", "");
      document.getElementById("physicalZip").setAttribute("errors", "");
    } else {
      this.setState({
        paddress: false,
        pcity: false,
        pstate: false,
        pzip: false,
        fnameError: "",
        mnameError: "",
        user: {
          ...this.state.user,
          physicalAddress: "",
          physicalCity: "",
          physicalState: "",
          physicalZip: "",
          chkSameAddress: false
        }
      });
    }
  }

  onImageDrop(files) {
    /*
    Validation
    */
    var allowed = ["png", "jpg", "jpeg"],
      file = files[0] || null;
    if (!file) {
      return this.setState({
        alertPop: true,
        alertHead: "Error",
        alertMsg: "Please select image file",
        alertFunction: ""
      });
    }
    var name = file.name,
      extension = name.split(".").pop().toLowerCase();
    if (allowed.indexOf(extension) === -1) {
      // Invalid file format.
      this.setState({
        alertPop: true,
        alertHead: "Error",
        alertMsg: "Invalid file format!",
        alertFunction: ""
      });
      return;
    }

    this.setState({
      imgPreview: files[0].preview,
      statusOpen: true
    });

    this.handleImageUpload(files[0]);
  }

  statusCloseModal() {
    this.setState({ statusOpen: false });
  }

  cropImage() {
    this.cropper.getCroppedCanvas().toBlob(
      blob => {
        var imgData = this.cropper.getData();
        this.setState({
          cropResult: this.cropper.getCroppedCanvas().toDataURL(),
          statusOpen: false,
          imgData,
          uploadedFile: blob
        });
      },
      "image/jpeg",
      0.45
    );
  }
  _crop() {
    // image in dataUrl
    // console.log(this.refs.cropper.getCroppedCanvas().toDataURL());
  }

  imageLoad() {
    this.setState({
      statusOpen: true
    });
  }

  handleImageUpload(file) {
    // this.setState({ imgPreview : file.preview })

    this.setState({ imgPreview: file.preview });

    var pic = file.preview;
    var pic_name = pic.split("/").pop();
    this.setState({ picName: pic.split("/").pop(), uploadedFile: file });
  }

  handleDateChange(date) {
    this.setState({
      user: {
        ...this.state.user,
        MSPA_ExpDate: date
      }
    });
  }
  validate(e) {
    let isError = false;

    if (this.state.user.weight === "") {
      isError = true;
      this.setState({
        weightError: "error"
      });
      // this.weightInput.focus();
    }
    // if (this.state.user.generalQualification === "") {
    //   isError = true;
    //   this.setState({
    //     QualificationError: "error"
    //   });
    //   // this.QualificationInput.focus();
    // }
    if (this.state.user.emgc2Relation === "") {
      isError = true;
      this.setState({
        emgc2RelationError: "error"
      });
      // this.emgc2RelationInput.focus();
    }
    if (this.state.user.emgc2Phone === "") {
      isError = true;
      this.setState({
        emgc2PhoneError: "error"
      });
      // this.emgc2PhoneInput.focus();
    }
    if (this.state.user.emgc2LName === "") {
      isError = true;
      this.setState({
        emgc2LNameError: "error"
      });
      // this.emgc2LNameInput.focus();
    }
    if (this.state.user.emgc2FName === "") {
      isError = true;
      this.setState({
        emgc2FNameError: "error"
      });
      // this.emgc2FNameInput.focus();
    }
    if (this.state.user.emgc1Relation === "") {
      isError = true;
      this.setState({
        emgc1RelationError: "error"
      });
      // this.emgc1RelationInput.focus();
    }
    if (this.state.user.emgc1Phone === "") {
      isError = true;
      this.setState({
        emgc1PhoneError: "error"
      });
      // this.emgc1PhoneInput.focus();
    }
    if (this.state.user.emgc1LName === "") {
      isError = true;
      this.setState({
        emgc1LNameError: "error"
      });
      // this.emgc1LNameInput.focus();
    }
    if (this.state.user.emgc1FName === "") {
      isError = true;
      this.setState({
        emgc1FNameError: "error"
      });
      // this.emgc1FNameInput.focus();
    }
    if (this.state.user.sex === "") {
      isError = true;
      this.setState({
        sexError: "error"
      });
      // this.sexInput.focus();
    }
    if (this.state.user.physicalZip === "") {
      isError = true;
      this.setState({
        physicalZipError: "error"
      });
      // this.physicalZipInput.focus();
    }

    if (this.state.user.physicalState === "") {
      isError = true;
      this.setState({
        physicalStateError: "error"
      });
      // this.physicalStateInput.focus();
    }

    if (this.state.user.physicalCity === "") {
      isError = true;
      this.setState({
        physicalCityError: "error"
      });
      // this.physicalCityInput.focus();
    }
    if (this.state.user.physicalAddress === "") {
      isError = true;
      this.setState({
        physicalAddressError: "error"
      });
      // this.physicalAddressInput.focus();
    }
    if (this.state.user.mailingZip === "") {
      isError = true;
      this.setState({
        mailingZipError: "error"
      });
      // this.mailingZipInput.focus();
    }
    if (this.state.user.mailingState === "") {
      isError = true;
      this.setState({
        mailingStateError: "error"
      });
      // this.mailingStateInput.focus();
    }
    if (this.state.user.mailingCity === "") {
      isError = true;
      this.setState({
        mailingCityError: "error"
      });
      // this.mailingCityInput.focus();
    }
    if (this.state.user.mailingAddress === "") {
      isError = true;
      this.setState({
        mailingAddressError: "error"
      });
      this.mailingInput.focus();
    }
    if (this.state.user.password === "") {
      isError = true;
      this.setState({
        passwordError: "error"
      });
      // this.passwordInput.focus();
    }
    if (this.state.user.reEmailId === "") {
      isError = true;
      this.setState({
        reEmaiError: "error"
      });
      // this.reEmailInput.focus();
    }
    if (
      this.state.user.reEmailId !== "" &&
      this.state.reEmailId !== this.state.emailId
    ) {
      isError = true;
      this.setState({
        reEmaiError: "error"
      });
      // this.reEmailInput.focus();
    }
    // if(this.state.user.reEmailId !== "" && this.state.reEmailId === this.state.emailId ){
    //     isError = false;
    //     this.setState({
    //       reEmaiError: ""
    //     })
    // }
    if (this.state.user.email === "") {
      isError = true;
      this.setState({
        emailError: "error"
      });
      // this.emailInput.focus();
    }
    // elseif(!this.IsValidEmail(this.state.email)){
    //     isError = true;
    //     this.setState({
    //         emailError: "error"
    //     })
    //     console.log("emailError1")
    //     this.emailInput.focus();
    // }
    // else{
    //     isError = false;
    //     this.setState({
    //       emailError: ""
    //     })
    // }
    if (this.state.user.resourceId === "") {
      isError = true;
      this.setState({
        ResError: "error"
      });
      // this.resourceInput.focus();
    }
    if (this.state.user.phone === "") {
      isError = true;
      this.setState({
        phoneError: "error"
      });
      // this.phoneInput.focus();
    }
    if (this.state.user.lname === "") {
      isError = true;
      this.setState({
        lnameError: "error"
      });
      // this.lnameInput.focus();
    }
    if (this.state.user.fname === "") {
      isError = true;
      this.setState({
        fnameError: "error",
        fnameError1: "fnameError"
      });
      // this.fnameInput.focus();
    }

    return isError;
  }
  IsValidEmail(email) {
    // var expr = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    var expr = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return expr.test(email);
  }
  //


  handleOnChange(key, value) { 
    let user = { ...this.state.user }
    user[key] = value === INVALID_DATE ? "" : value
    this.setState({ user })
  }

  
  save(e) {
    e.preventDefault();
    const err = false// this.validate();
    this.setState({ saveClick: true });
    let data = new FormData();
    if (!err) {
      const { user } = this.state;
      for (var key in user) {
        if (key === "qualificationDetails") {
          data.append(key, JSON.stringify(user[key]));
        } else {
          data.append(key, user[key]);
        }
      }
      if (this.state.uploadedFile !== undefined) {
        data.append("profilePic", this.state.uploadedFile);
      }

      if (this.props.match.path === "/profile/:hash") {
        this.props.dispatch(
          userActions.editProfile(data, "?hash=" + this.state.hash)
        );
      } else {
        this.props.dispatch(userActions.editSelfProfile(data)).then(res =>{
          this.props.dispatch(userActions.getProfile("?hash=" + this.state.hash));
        })
      }
    }
  }
  cancel() {
    this.props.cancel();
  }

  goBack() {
    // this.props.goBack({id:this.props.id, resource_name:this.state.form.resource_name})
    this.props.goBack(this.props.id)
  }

  render() {
    const { user } = this.props;
    let userData, profile;
    if (userData) {
      userData = user.items;
    } else if (user.profile) {
      userData = user.profile;
    }
    if (userData) {
      profile = userData.data;
    }

    var img = "";
    if (this.state.profile && this.state.profile.profile_photo) {
      img = config.apiUrl + "/" + this.state.profile.profile_photo;
    } else {
      img = config.apiUrl + "/" + config.altImg;
    }
    var img_preview = "";
    if (this.state.imgPreview) {
      img_preview = this.state.imgPreview;
    } else if (this.state.profile && this.state.profile.profile_photo) {
      img_preview = config.apiUrl + "/" + this.state.profile.profile_photo;
    } else {
      img_preview = config.apiUrl + "/" + config.altImg;
    }



    const { qualificationData, checkboxesChecked } = this.state;


    return (
      // details

      <div className="content_div profile_edit">
        <div className="btn_list">
          {user.loading && this.state.saveClick && (
            <img
              className="login_loader"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
            />
          )}
          <button
            className="button save_profile"
            onClick={this.save.bind(this)}
          >
            Save Profile
          </button>
          <button className="button grey" onClick={this.cancel.bind(this)}>
            Cancel
          </button>
        </div>
        <div className="profile_edit_wrapper">
          <div className="upload_or_drag edit_profile_pic">
            <div className="upload_or_drag">
              <Dropzone
                id="file_name"
                name="file_name"
                multiple={false}
                accept="image/*"
                onDrop={this.onImageDrop.bind(this)}
              >
                <p>Upload or Drag/Drop Here</p>
                {this.state.cropResult && (
                  <img
                    style={{ width: "100%" }}
                    onError={this.onImageError.bind(this)}
                    className="imagePreview"
                    src={this.state.cropResult}
                    alt="cropped image"
                  />
                )}
              </Dropzone>
            </div>
          </div>

          <div className="label_bar general">
            <div className="formField">
              <label>First Name</label>
              <input
                type="text"
                ref={input => {
                  this.fnameInput = input;
                }}
                name="fname"
                value={this.state.user.fname}
                errors={this.state.fnameError}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>Middle Name</label>
              <input
                type="text"
                ref={(input) => {
                  this.mnameInput = input;
                }}
                name="mname"
                value={this.state.user.mname}
                errors={this.state.mnameError}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>Last Name</label>
              <input
                type="text"
                ref={input => {
                  this.lnameInput = input;
                }}
                name="lname"
                errors={this.state.lnameError}
                value={this.state.user.lname}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>Primary Phone (Visible to all Staff)</label>
              {/*<input type="text" ref={(input) => { this.phoneInput = input; }} name="phone" errors={this.state.phoneError} value={this.state.user.phone} maxLength="10" onChange={this.handleChange} /> */}
              <NumberFormat
                format="###-###-####"
                mask="_"
                onChange={this.handleChange}
                ref={input => {
                  this.phoneInput = input;
                }}
                name="phone"
                errors={this.state.phoneError}
                value={this.state.user.phone}
              />
            </div>
            <div className="formField">
              <label>Secondary Phone</label>
              {/*<input type="text" name="secphone" value={this.state.user.secphone !== "null" ? this.state.user.secphone : ""} maxLength="10" onChange={this.handleChange} /> */}
              <NumberFormat
                format="###-###-####"
                mask="_"
                onChange={this.handleChange}
                name="secphone"
                value={
                  this.state.user.secphone !== "null"
                    ? this.state.user.secphone
                    : ""
                }
              />
            </div>
            { this.state.isAdmin === 1 &&
              <div className="formField">
                <label>Company Name</label>
                <select
                    onChange={this.handleChange}
                    className=""
                    value={this.state.user.applying_company}
                    name="applying_company"
                  >
                  <option value="1">About Trees</option>
                  <option value="2">North Valley Tree Service</option>
                  <option value="3">NVAM</option>
                </select>
              </div>
            }
            <div className="formField">
              <label>Employee ID #</label>
              <input
                type="text"
                name="nvamid"
                value={
                  this.state.user.nvamid !== "null"
                    ? this.state.user.nvamid
                    : ""
                }
                onChange={this.handleChange}
              />
            </div>
            {
              (this.state.permList.some(permission => [
                'Assign Staff',
                'Assign Your Own Crew',
                'Assign Entire Company'
              ].includes(permission))) ? (<div className="formField">
                <label>Primary Crew</label>
                <select
                  name="primary_crew_identifier_id"
                  value={this.state.user.primary_crew_identifier_id || ""}
                  onChange={this.handleChange}
                >
                  <option key="0" value="">Select</option>
                  {this.state.Crews.map((item, index) => {
                    return <option key={index + 1} value={item.id}>{item.resource_name}</option>
                  })}
                </select>
              </div>) : ""
            }
            <div className="formField">
              <label>Email (Visible to all Staff)</label>
              <input
                type="text"
                name="email"
                value={this.state.user.email}
                errors={this.state.emailError}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>Confirm Email</label>
              <input
                type="text"
                name="reEmailId"
                errors={this.state.reEmaiError}
                value={this.state.user.reEmailId}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>Street Address</label>
              <input
                type="text"
                name="mailingAddress"
                value={this.state.user.mailingAddress}
                errors={this.state.mailingAddressError}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>State</label>
              <input
                type="text"
                name="mailingState"
                value={this.state.user.mailingState}
                errors={this.state.mailingStateError}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>City</label>
              <input
                type="text"
                name="mailingCity"
                value={this.state.user.mailingCity}
                errors={this.state.mailingCityError}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>Zip</label>
              <input
                type="text"
                name="mailingZip"
                value={this.state.user.mailingZip}
                errors={this.state.mailingZipError}
                onChange={this.handleChange}
              />
            </div>
          </div>
          {this.state.permList.includes(PERMISSION_FOR_EXPIRE_ASSIGNED_DATE) && (
            <React.Fragment>
              <div className="label_bar margin_exp_date">
                <h2>Expiration Dates</h2>
                <div className="formField">
                  <label>Drivers License</label>
                  <input
                    type="text"
                    name="driversLicense"
                    value={
                      this.state.user.driversLicense !== "null"
                        ? this.state.user.driversLicense
                        : ""
                    }
                    onChange={this.handleChange}
                  />
                </div>
                <div className="formField">
                  <label>Drivers License State issued</label>
                  <input
                    type="text"
                    name="DLState"
                    value={
                      this.state.user.DLState !== "null"
                        ? this.state.user.DLState
                        : ""
                    }
                    onChange={this.handleChange}
                  />
                </div>
                <div className="formField">
                  <label>Drivers License Expiration Date</label>
                  <DatePicker
                    minDate={moment()}
                    dateFormat={"MM/DD/YYYY"}
                    name="dlExpiry"
                    selected={this.state.user.dlExpiry ? moment(this.state.user.dlExpiry) : ""}
                    onChange={(date) => this.handleOnChange("dlExpiry", moment(date).format(DATE_FORMAT))}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
                <div className="formField">
                  <label>Commercial Drivers License</label>
                  <input
                    type="text"
                    name="commercialDriversLicense"
                    value={
                      this.state.user.commercialDriversLicense !== "null"
                        ? this.state.user.commercialDriversLicense
                        : ""
                    }
                    onChange={this.handleChange}
                  />
                </div>
                <div className="formField">
                  <label>Commercial Drivers License Expiration Date
                  </label>
                  <DatePicker
                    minDate={moment()}
                    dateFormat={"MM/DD/YYYY"}
                    name="commercialDlExpiry"
                    selected={this.state.user.commercialDlExpiry ? moment(this.state.user.commercialDlExpiry) : ""}
                    onChange={(date) => this.handleOnChange("commercialDlExpiry", moment(date).format(DATE_FORMAT))}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
                <div className="formField">
                  <label>DMV Physical
                  </label>
                  <DatePicker
                    minDate={moment()}
                    dateFormat={"MM/DD/YYYY"}
                    name="physicalExpiry"
                    selected={this.state.user.physicalExpiry ? moment(this.state.user.physicalExpiry) : ""}
                    onChange={(date) => this.handleOnChange("physicalExpiry", moment(date).format(DATE_FORMAT))}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
                <div className="formField">
                  <label>First Aid/CPR
                  </label>
                  <DatePicker
                    minDate={moment()}
                    dateFormat={"MM/DD/YYYY"}
                    name="firstAidExpiry"
                    selected={this.state.user.firstAidExpiry ? moment(this.state.user.firstAidExpiry) : ""}
                    onChange={(date) => this.handleOnChange("firstAidExpiry", moment(date).format(DATE_FORMAT))}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>

                <div className="formField">
                  <label>Sexual Harassment
                  </label>
                  <DatePicker
                    minDate={moment()}
                    dateFormat={"MM/DD/YYYY"}
                    name="sexualHarassmentExpiry"
                    selected={this.state.user.sexualHarassmentExpiry ? moment(this.state.user.sexualHarassmentExpiry) : ""}
                    onChange={(date) => this.handleOnChange("sexualHarassmentExpiry", moment(date).format(DATE_FORMAT))}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
                <div className="formField">
                  <label>Faller Qualification
                  </label>
                  <DatePicker
                    minDate={moment()}
                    dateFormat={"MM/DD/YYYY"}
                    name="fallerQuals"
                    selected={this.state.user.fallerQuals ? moment(this.state.user.fallerQuals) : ""}
                    onChange={(date) => this.handleOnChange("fallerQuals", moment(date).format(DATE_FORMAT))}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>
            </React.Fragment>
          )}
          <div className="label_bar physicalAddress">
            <h2>Physical Address</h2>
            <span className="sameAddress">
              <input
                id="chkSameAddress"
                type="checkbox"
                name="chkSameAddress"
                defaultValue={this.state.user.chkSameAddress}
                onClick={this.handleAddress}
              />
              &nbsp;
              <span className="wpcf7-list-item-label">
                <b>Check if Physical Address is same as Mailing Address</b>
              </span>
            </span>

            <div className="formField">
              <label>Physical Address</label>
              <input
                id="physicalAddress"
                type="text"
                ref={input => {
                  this.physicalAddressInput = input;
                }}
                name="physicalAddress"
                value={this.state.user.physicalAddress}
                errors={this.state.physicalAddressError}
                onChange={this.handleChange}
                readOnly={this.state.paddress}
              />
            </div>

            <div className="formField">
              <label>City</label>
              <input
                id="physicalCity"
                type="text"
                ref={input => {
                  this.physicalCityInput = input;
                }}
                name="physicalCity"
                value={this.state.user.physicalCity}
                errors={this.state.physicalCityError}
                onChange={this.handleChange}
                readOnly={this.state.pcity}
              />
            </div>

            <div className="formField">
              <label>State</label>
              <input
                id="physicalState"
                type="text"
                ref={input => {
                  this.physicalStateInput = input;
                }}
                name="physicalState"
                value={this.state.user.physicalState}
                errors={this.state.physicalStateError}
                onChange={this.handleChange}
                readOnly={this.state.pstate}
              />
            </div>

            <div className="formField">
              <label>Zip</label>
              <input
                id="physicalZip"
                type="text"
                ref={input => {
                  this.physicalZipInput = input;
                }}
                name="physicalZip"
                value={this.state.user.physicalZip}
                errors={this.state.physicalZipError}
                onChange={this.handleChange}
                readOnly={this.state.pzip}
              />
            </div>

            <div className="formField">
              <label>Sex</label>
              <select
                id="sex"
                ref={input => {
                  this.sexInput = input;
                }}
                name="sex"
                value={this.state.user.sex}
                errors={this.state.sexError}
                onChange={this.handleChange}
              >
                <option value="">Sex</option>
                <option value="Female">Female</option>
                <option value="Male">Male</option>
              </select>
            </div>
          </div>
          <div className="label_bar emergency_contact1">
            <h2>Emergency Contact #1</h2>
            <div className="formField">
              <label>First Name</label>
              <input
                type="text"
                ref={input => {
                  this.emgc1FNameInput = input;
                }}
                name="emgc1FName"
                value={this.state.user.emgc1FName}
                errors={this.state.emgc1FNameError}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>Last Name</label>
              <input
                type="text"
                ref={input => {
                  this.emgc1LNameInput = input;
                }}
                name="emgc1LName"
                errors={this.state.emgc1LNameError}
                value={this.state.user.emgc1LName}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>Primary Phone</label>
              {/*<input type="text" ref={(input) => { this.emgc1PhoneInput = input; }} name="emgc1Phone" errors={this.state.emgc1PhoneError} maxLength="10" value={this.state.user.emgc1Phone}  onChange={this.handleChange} />*/}
              <NumberFormat
                format="###-###-####"
                mask="_"
                onChange={this.handleChange}
                ref={input => {
                  this.emgc1PhoneInput = input;
                }}
                name="emgc1Phone"
                errors={this.state.emgc1PhoneError}
                value={this.state.user.emgc1Phone}
              />
            </div>
            <div className="formField">
              <label>Relationship</label>
              <input
                type="text"
                ref={input => {
                  this.emgc1RelationInput = input;
                }}
                name="emgc1Relation"
                errors={this.state.emgc1RelationError}
                value={this.state.user.emgc1Relation}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="label_bar emergency_contact2">
            <h2>Emergency Contact #2</h2>
            <div className="formField">
              <label>First Name</label>
              <input
                type="text"
                ref={input => {
                  this.emgc2FNameInput = input;
                }}
                name="emgc2FName"
                errors={this.state.emgc2FNameError}
                value={this.state.user.emgc2FName}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>Last Name</label>
              <input
                type="text"
                ref={input => {
                  this.emgc2LNameInput = input;
                }}
                name="emgc2LName"
                errors={this.state.emgc2LNameError}
                value={this.state.user.emgc2LName}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>Primary Phone</label>
              {/*<input type="text" ref={(input) => { this.emgc2PhoneInput = input; }} name="emgc2Phone" maxLength="10" errors={this.state.emgc2PhoneError} value={this.state.user.emgc2Phone}  onChange={this.handleChange} />*/}
              <NumberFormat
                format="###-###-####"
                mask="_"
                onChange={this.handleChange}
                ref={input => {
                  this.emgc2PhoneInput = input;
                }}
                name="emgc2Phone"
                errors={this.state.emgc2PhoneError}
                value={this.state.user.emgc2Phone}
              />
            </div>
            <div className="formField">
              <label>Relationship</label>
              <input
                type="text"
                ref={input => {
                  this.emgc2RelationInput = input;
                }}
                name="emgc2Relation"
                errors={this.state.emgc2RelationError}
                value={this.state.user.emgc2Relation}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="label_bar more">
            <h2>More Info</h2>
            <div className="formField">
              <label>Pant Size</label>
              <select
                name="pantSize"
                value={this.state.user.pantSize}
                onChange={this.handleChange}
              >
                <option value="">Pant Size</option>
                {this.state.pantSize}
              </select>
            </div>
            <div className="formField">
            <label>Inseam Pant Size</label>
                <select
                  name="inseam_pant_size"
                  value={this.state.user.inseam_pant_size}
                  onChange={this.handleChange}
                >
                  <option value="">Inseam Pant Size</option>
                  {this.state.inseamPantSize}
                </select>
              </div>
            <div className="formField">
              <label>Shirt Size</label>
              <select
                name="shirtSize"
                value={this.state.user.shirtSize}
                onChange={this.handleChange}
              >
                <option value="">Shirt Size</option>
                {this.state.shirtSize}
              </select>
            </div>
            <div className="formField">
              <label>Certified First Aid and CPR</label>
              <select
                name="certifiedEMT"
                value={this.state.user.certifiedEMT}
                onChange={this.handleChange}
              >
                <option value="">Certified First Aid and CPR</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="formField">
              <label>Qualification</label>
              <select
                ref={input => {
                  this.QualificationInput = input;
                }}
                name="generalQualification"
                value={this.state.user.generalQualification}
                errors={this.state.QualificationError}
                onChange={this.handleChange}
              >
                <option value="">Qualification</option>
                {this.state.generalQualificationList}
              </select>
            </div>
            {/* <div className="formField"> // Removed for NVAM clone
              <label>LSA</label>
              <select
                name="lsa"
                value={this.state.user.lsa}
                onChange={this.handleChange}
              >
                <option value="">LSA</option>
                {this.state.lsa}
              </select>
            </div> */}
            <div className="formField">
              <label>Weight (lbs)</label>
              <input
                type="text"
                ref={input => {
                  this.weightInput = input;
                }}
                name="weight"
                value={this.state.user.weight}
                onChange={this.handleChange}
              />
            </div>
          </div>
          {/* <div className="label_bar more current_qualification">
            <h2>Current Qualifications</h2>
            <div className="formField">{this.state.qualification}</div>
          </div> */}

          <div className="label_bar more current_qualification">
            <h2>Current Qualifications</h2>
            <div className="formField">
              <table className="tab_hide qualification_table">
                <thead>
                  <tr className="qualification_table_row">
                    <th>Qualification</th>
                    <th>Qualifying Date</th>
                  </tr>
                </thead>
                <tbody className="qualification_table_tbody">
                  {this.generateQualificationTable(qualificationData, checkboxesChecked)}
                </tbody>
              </table>

              <div className="tab_show">
                <div className="history_items">
                  {this.generateMobQualificationTable(qualificationData, checkboxesChecked)}
                </div>
              </div>
            </div>
          </div>

          {/* Verified Docs */}
          {
            profile && profile.userattribute && profile.docs_status === 3 &&
            <div className="label_bar more current_qualification">
              <h2>Check each box below to indicate it has been submitted and verified</h2>
              <div className="formField">
                {this.generateAttrCheckbox(profile.userattribute)}
              </div>
            </div>
          }
        </div>
        {this.state.alertPop === true && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
        <ReactModal
          isOpen={this.state.statusOpen}
          onClose={this.statusCloseModal.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="imageCropPopup"
        >
          <div className="modal-head">
            <img
              className="closeModal"
              onClick={this.statusCloseModal.bind(this)}
              src={require("../assets/images/close_green.png").default}
            />
          </div>
          <div id="edit_profile_pic_popup" className="modal-body">
            <Cropper
              ref={cropper => {
                this.cropper = cropper;
              }}
              src={img_preview}
              style={{ height: 400, width: "100%" }}
              // Cropper.js options
              aspectRatio={16 / 16}
              guides={false}
              crop={this._crop.bind(this)}
            />
            <div className="button_list">
              <Dropzone
                id="file_name"
                className="button popup_upload"
                name="file_name"
                multiple={false}
                accept="image/*"
                onDrop={this.onImageDrop.bind(this)}
              >
                <p>Upload Here</p>
              </Dropzone>
              <button onClick={this.cropImage.bind(this)} className="button">
                Crop Image
              </button>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { listAll } = state.createProfile;
  let { user } = state;
  return {
    listAll,
    user
  };
}

const connectedRegisterPage = withRouter(connect(mapStateToProps)(EditProfile));
export { connectedRegisterPage as EditProfile };


