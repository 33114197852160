export function redirectToPdf(data) {
  if (data?.success) {
    const pdfLink = data.data;
    
    // Check if it's an iOS device
    const isIOS = /iPad|iPhone/i.test(window.navigator.userAgent);

    // Open PDF in a new tab/window for iOS devices
    if (isIOS) {
      window.open(pdfLink, '_blank');
    } else {
      // For other devices, create a link and trigger a download
      var link = document.createElement("a");
      link.href = pdfLink;
      link.target = "_blank";
      link.download = "downloaded.pdf"; // Set a default filename for non-iOS devices
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(pdfLink);
      }, 100);
    }
  }
}