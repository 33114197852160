import { docsActions } from "_actions";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import moment from "moment";
import TimeOffRequest from "Docs/Forms/TimeOffRequest";
import { config } from "_helpers/config";
import "../assets/styles/docs.scss";
import DocStatusTag from "_components/utils/DocStatusTag";

const DESC_CLASS = "sort-arrow desc";
const ASC_CLASS = "sort-arrow asc";

const initialStyleState = {
  documentClass: ASC_CLASS,
  employeeClass: ASC_CLASS,
  createdClass: ASC_CLASS,
  updatedClass: ASC_CLASS,
};

const Sort = {
  Asc: "asc",
  Dsc: "desc",
};

const TORPendingDocuments = (props) => {
  const { setSortParams } = props;

  const dispatch = useDispatch();

  const [{ employeeClass, createdClass, updatedClass }, setStyleState] =
    useState(initialStyleState);
  const [order, setOrder] = useState(false); /** true === asc **/
  const [sortItem, setSortItem] = useState("created_at");
  const [page, setPage] = useState(0);
  const [data, setData] = useState("");
  const [filteredData, setFilteredData] = useState("");
  const [viewListing, setViewListing] = useState(true);
  const [viewDoc, setViewDoc] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState("");
  const [docTypeID, setDocTypeID] = useState("");

  const fetchPendingDocuments = async (newPage, sort, order) => {
    let filter = {
      page: newPage || 0,
      doc_types: docTypeID,
      sort: sort || "created_at",
      order: order || "desc",
      doc_category: 3,
    };
    const res = await dispatch(docsActions.getAllDocs(filter));
    if (res?.success) {
      setData(res?.data);
      setFilteredData(res?.data?.data);
    }
  };

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams(sort, nextOrder);
  };

  const viewDocument = (item) => {
    setViewListing(false);
    setViewDoc(true);
    setSelectedDoc(item);
  };

  const goBack = () => {
    setViewListing(true);
    setViewDoc(false);
    setSelectedDoc("");
  };

  const handlePageChange = (newPage) => {
    const sortOrder = order ? Sort.Asc : Sort.Dsc;
    fetchPendingDocuments(newPage?.selected + 1, sortItem, sortOrder);
    setPage(newPage?.selected + 1);
  };

  useEffect(() => {
    const sortOrder = order ? Sort.Asc : Sort.Dsc;
    if (sortItem) {
      fetchPendingDocuments(page, sortItem, sortOrder);
    }
  }, [sortItem, order, docTypeID]);

  const getDocTypes = async () => {
    const res = await dispatch(docsActions.getDocTypes());
    if (res.length > 0) {
      const timeOffRequestDocType = res.find(
        (res) => res.slug === "time-off-request"
      );
      setDocTypeID(timeOffRequestDocType?.id);
    }
  };

  useEffect(() => {
    getDocTypes();
  }, []);

  return (
    <>
      {viewListing && (
        <>
          <div className="history_lists outer_list table__scroll">
            <table className="mobile_hide report_list_width_full">
              <thead>
                <tr className="report_list_table_row">
                  <th
                    onClick={() =>
                      sortList("employeeClass", employeeClass, "user_name")
                    }
                  >
                    <span>Employee</span>
                    <span className={employeeClass} />
                  </th>
                  <th>
                    <span>Time Off Pay</span>
                  </th>
                  <th
                    onClick={() =>
                      sortList("createdClass", createdClass, "created_at")
                    }
                  >
                    <span>Date Submitted</span>
                    <span className={createdClass} />
                  </th>
                  <th>
                    <span>Requested Dates Off</span>
                  </th>
                  <th>
                    <span>Date Approved</span>
                  </th>
                  <th>
                    <span></span>
                  </th>
                </tr>
              </thead>
              <tbody className="report_list_table_tbody">
                {!filteredData ? (
                  <tr>
                    <td colSpan="4">
                      <div className="dashboard_expiration_date_no_data">
                        <img
                          className="loader"
                          alt="Loader"
                          src={require("../assets/images/loader.gif").default}
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  filteredData?.map((item, index) => {
                    const name = item?.first_name + " " + item?.last_name;
                    return (
                      <tr key={index}>
                        {/* <td onClick={() => viewDoc(item)}>
                      {item?.docs_category_name ?? "-"}
                    </td> */}
                        <td>
                          <b>{name ?? "-"}</b>
                        </td>
                        <td>
                          <b>
                            {item.request_for_time_off_check === "1" &&
                            item.with_pay_hours &&
                            item.status === 3
                              ? item.with_pay_hours
                              : "-"}
                          </b>
                        </td>
                        <td>
                          {moment(
                            item?.created_at,
                            "MM-DD-YYYY HH:mm:ss"
                          ).format("MM/DD/YYYY")}
                        </td>
                        <td>{item?.time_off_from ? item?.time_off_from + "-" + item?.time_off_to : "-"}</td>
                        <td>
                          {item?.status === 3 ? (
                            moment(
                              item?.updated_at,
                              "MM-DD-YYYY HH:mm:ss"
                            ).format("MM/DD/YYYY")
                          ) : (
                            <DocStatusTag
                              status="Pending"
                              bgColor="#c9c188"
                              fontColor=""
                            />
                          )}
                        </td>
                        <td>
                          <span>
                            <button
                              className="button plain"
                              onClick={() => viewDocument(item)}
                            >
                              <img
                                src={`${config.assetUrl}icons/doc-admin-view.svg`}
                                alt=""
                              />
                            </button>
                          </span>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>

            {/* Mobile */}
            <TORPendingDocumentsMobileView
              data={data}
              fetchPendingDocuments={fetchPendingDocuments}
              setSortParams={setSortParams}
              page={page}
            />
          </div>
          <div className="fs-pagination-wrapper-outer" key={7}>
            <div className="customised-pagination right-align">
              <ReactPaginate
                forcePage={page ? page - 1 : page}
                previousLabel={""}
                nextLabel={""}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={data?.last_page}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(data) => handlePageChange(data)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </>
      )}
      {viewDoc && (
        <section className="content onboarding-docs">
          <div className="widget_tor_container">
            <button
              id="back-button"
              className="button_back_tor"
              onClick={() => goBack()}
            >
              Back
            </button>
            <div className="container doc_info full-width_form">
              <React.Fragment>
                <TimeOffRequest
                  goBack={() => viewDocument()}
                  isSingleDoc={true}
                  adminMode={true}
                  docDetails={selectedDoc}
                  status={selectedDoc.status}
                />
              </React.Fragment>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default TORPendingDocuments;

const TORPendingDocumentsMobileView = ({
  setSortParams,
  data,
  fetchPendingDocuments,
}) => {
  const [{ employeeClass, createdClass, updatedClass }, setStyleState] =
    useState(initialStyleState);

  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("last_name");
  const [filteredData, setFilteredData] = useState("");
  const [viewListing, setViewListing] = useState(true);
  const [viewDoc, setViewDoc] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState("");

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams?.(sort, nextOrder);
  };
  const viewDocument = (item) => {
    setViewListing(false);
    setViewDoc(true);
    setSelectedDoc(item);
  };

  const goBack = () => {
    setViewListing(true);
    setViewDoc(false);
    setSelectedDoc("");
  };

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      fetchPendingDocuments(0, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  useEffect(() => {
    setFilteredData(data?.data?.filter((item) => item?.status !== 5));
  }, [data]);

  return (
    <>
      {viewListing &&
        (filteredData ? (
          filteredData?.map((item, index) => (
            <React.Fragment key={index}>
              <div className="for_mobile_history_list">
                <hr></hr>
                <div className="history_items">
                  <div className="status_item">
                    <div
                      className="list_item"
                      onClick={() =>
                        sortList("employeeClass", employeeClass, "user_name")
                      }
                    >
                      <strong>Employee</strong>
                      <span className={employeeClass} />
                    </div>
                    <div className="list_item">
                      {item.first_name + " " + item?.last_name ?? "-"}
                    </div>
                  </div>

                  <div className="status_item">
                    <div className="list_item">
                      <strong>Time Off Pay</strong>
                    </div>
                    <div className="list_item">
                      {item.request_for_time_off_check === "1" &&
                      item.with_pay_hours &&
                      item.status === 4
                        ? item.with_pay_hours
                        : "-"}
                    </div>
                  </div>

                  <div className="status_item">
                    <div
                      className="list_item"
                      onClick={() =>
                        sortList("createdClass", createdClass, "created_at")
                      }
                    >
                      <strong>Date Submitted</strong>
                      <span className={createdClass} />
                    </div>
                    <div className="list_item">
                      {moment(item?.created_at, "MM-DD-YYYY HH:mm:ss").format(
                        "MM/DD/YYYY"
                      )}
                    </div>
                  </div>

                  <div className="status_item">
                    <div className="list_item">
                      <strong>Requested Dates Off</strong>
                    </div>
                    <div className="list_item">
                      {item?.time_off_from + "-" + item?.time_off_to}
                    </div>
                  </div>

                  <div className="status_item">
                    <div
                      className="list_item"
                      onClick={() =>
                        sortList("updatedClass", updatedClass, "updated_at")
                      }
                    >
                      <strong>Date Approved</strong>
                      <span className={updatedClass} />
                    </div>
                    <div className="list_item">
                      {item?.status === 4 ? (
                        moment(item?.created_at, "MM-DD-YYYY HH:mm:ss").format(
                          "MM/DD/YYYY"
                        )
                      ) : (
                        <DocStatusTag
                          status="Pending"
                          bgColor="#c9c188"
                          fontColor=""
                        />
                      )}
                    </div>
                  </div>

                  <div className="status_item">
                    <div className="list_item">
                      <span>
                        <button
                          className="button plain"
                          onClick={() => viewDocument(item)}
                        >
                          <img
                            src={`${config.assetUrl}icons/doc-admin-view.svg`}
                            alt=""
                          />
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))
        ) : (
          <div className="dashboard_expiration_date_no_data">
            <img
              className="loader"
              alt="Loader"
              src={require("../assets/images/loader.gif").default}
            />
          </div>
        ))}
      {viewDoc && (
        <section className="content onboarding-docs">
          <div className="widget_tor_container">
            <button
              id="back-button"
              className="button_back_tor"
              onClick={() => goBack()}
            >
              Back
            </button>
            <div className="container doc_info full-width_form">
              <React.Fragment>
                <TimeOffRequest
                  goBack={() => viewDocument()}
                  isSingleDoc={true}
                  adminMode={true}
                  docDetails={selectedDoc}
                  status={selectedDoc.status}
                />
              </React.Fragment>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
