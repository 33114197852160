import { useDispatch, useSelector } from "react-redux";
import { useState, useMemo, useEffect } from "react";
import moment from "moment";
import cx from "classnames";

import "../assets/styles/crew-assignment-status.css";
import { reportsActions } from "_actions";
import { reportsService } from "_services";
import { getEmployeesBirthdays, isEmployeeBirthdayLoading, getCurrentUserInfo } from "_helpers/reduxSelector";
import styles from "./employeeBirthdays.module.css";
import { redirectToPdf } from "_helpers/redirectToPdf";

const EmployeeBirthdays = ({ isReportMenu }) => {
  const dispatch = useDispatch();
 
  const employeeBirthdays = useSelector((state) => getEmployeesBirthdays(state));

  const isLoading = useSelector((state) => isEmployeeBirthdayLoading(state));

  const user = useSelector((state) => getCurrentUserInfo(state));

  const dateFormat = user?.date_formats?.US_DATE_FORMAT;
  const [date, setDate] = useState(moment().format(dateFormat));
  const [isToday, setIsToday] = useState(false);

  useEffect(() => {
    getBirthdays();
  }, [date, isToday]);

  const getBirthdays = () => {
    let payload = new FormData();
    payload.append("date", date);
    payload.append("today", isToday ? 1 : 0);
    dispatch(reportsActions.getEmployeesBirthday(payload));
  };

  const onExportClick = () => {
    let data = new FormData();
    data.append("date", date);
    data.append("today", isToday ? 1 : 0);
    data.append(" report_type","csv")
    reportsService?.generateBdayReport(data);
  };

  const onPrintClick = () => {
    let data = new FormData();
    data.append("date", date);
    data.append("today", isToday ? 1 : 0);
    data.append(" report_type","pdf")
    reportsService.printEmployeeBirthdaysReportPdf(data).then(res=>redirectToPdf(res))
  }

  const handleOnMonthChange = (type) => {
    setIsToday(false);
    if (type === "NEXT") {
      setDate(moment(date).add(1, "months").format(dateFormat));
    } else {
      setDate(moment(date).subtract(1, "months").format(dateFormat));
    }
  };

  const handleOnTodayClick = () => {
    setIsToday(!isToday);
    setDate(moment().format(dateFormat));
  };

  const data = useMemo(() => {
    let mappedData = {};
    const currentMonth = Object.keys(employeeBirthdays ?? {})?.[0];
    const dates = Object.keys(employeeBirthdays?.[currentMonth] ?? {})?.sort((a, b) => a - b);
    dates?.forEach(
      (_date) => (mappedData[`${currentMonth}/${_date}/${moment(date).year()}`] = employeeBirthdays?.[currentMonth]?.[_date])
    );
    return { keys: Object.keys(mappedData ?? {}), values: mappedData };
  }, [employeeBirthdays]);

  const noDataAvailable = !isLoading && !data?.keys?.length;

  return (
    <div className="dashboard_expiration_date_outer">
      <div
        className="employee_stats_details assignment_stats-details"
        id="birthday-widget"
        style={{ boxShadow: isReportMenu ? "unset" : "inherit" }}
      >
        <div className={styles?.header}>
          <div className={styles?.header_first}>
            <div className={styles?.header_action}>
              <button
                disabled={isLoading}
                onClick={() => handleOnMonthChange("PREV")}
              >
                <i class="fas fa-chevron-left"></i>
              </button>
              <button
                disabled={isLoading}
                onClick={() => handleOnMonthChange("NEXT")}
                className={styles?.header_action_margin}
              >
                <i class="fas fa-chevron-right"></i>
              </button>
              <button
                onClick={() => handleOnTodayClick()}
                disabled={isLoading}
                className={
                  isToday
                    ? styles?.header_action_today_active
                    : styles?.header_action_today
                }
              >
                Today
              </button>
            </div>
            <div className={styles?.header_title}>
              <h5>{moment(date).format("MMMM YYYY")}</h5>
            </div>
          </div>
          {!noDataAvailable && (
            <div className={styles.print_btn_container}>
              <button
                href=""
                className={cx(styles?.print_btn, "print_button")}
                onClick={(event) => {
                  onExportClick();
                }}
              >
                Export
              </button>
              <button
                href=""
                className={cx(styles?.print_btn, "print_button")}
                style={{ marginLeft: "10px" }}
                onClick={(event) => {
                  onPrintClick();
                }}
              >
                Print
              </button>
            </div>
          )}
        </div>
        <div className={styles?.content}>
          {noDataAvailable ? (
            <div className={styles?.no_records}>
              <p>No birthdays this month.</p>
            </div>
          ) : (
            data?.keys?.map((item, index) => (
              <>
                <div key={index} className={styles?.item_head}>
                  <div className={styles?.item_head_name}>
                    <h5>{`${moment(item).format("MMMM DD,")} ${moment(
                      date
                    ).format("YYYY")}`}</h5>
                  </div>

                  <div className={styles?.item_head_company}>
                    <h5>Company</h5>
                  </div>

                  <div className={styles?.item_head_day}>
                    <h5>{`${moment(
                      `${moment(item).format("MM/DD")}/${moment(date).format(
                        "YY"
                      )}`
                    ).format("dddd")}`}</h5>
                  </div>
                </div>
                {data?.values?.[item]?.map((child, childIndex) => (
                  <div key={childIndex} className={styles?.item_child}>
                    <div className={styles?.item_head_name}>
                      <h6>{`${child?.last_name} ${child?.first_name}`}</h6>
                    </div>

                    <div className={styles?.item_head_company}>
                      {child?.applying_company === 1 ? (
                        <h6>About Trees</h6>
                      ) : (
                        <h6>NVTS</h6>
                      )}
                    </div>

                    <div className={styles?.item_head_day}>
                    </div>
                  </div>
                ))}
              </>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeBirthdays;
