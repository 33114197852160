export const getCurrentUserInfo = (state) => state?.user?.profile?.data ?? {};

/**Vehicle Report */
export const getVehicleReport = (state) => state?.reports?.vehicleReport ?? [];
export const isVehicleReportLoading = (state) =>
  state?.reports?.isVehicleReportLoading;

/**Equipment Sold Report */
export const getEquipmentSoldReport = (state) =>
  state?.reports?.equipmentSoldReport ?? [];
export const isEquipmentSoldReportLoading = (state) =>
  state?.reports?.isEquipmentSoldReportLoading;

/**All Inventory Report */
export const getAllInventoryReport = (state) =>
  state?.reports?.allInventoryReport;
export const isAllInventoryReportLoading = (state) =>
  state?.reports?.isAllInventoryReportLoading;
export const selectInventoryEquipments = (state) =>
  state?.inventory?.equipments;

export const isApplicantsReportLoading = state=> state?.reports?.applicantsReportLoading;
export const applicantsReport = state=> state?.reports?.applicantsReport; 

/**Expiration date report */
export const expirationDateReport = (state) =>
  state?.reports?.expDateListReport;
export const isExpirationDateReportLoading = (state) =>
  state?.reports?.isExpDateListLoading;

/** Interview Form */
export const getInterviewFormList = (state) =>
  state?.interview?.list?.interview_forms;
export const getInterviewForm = (state) => state?.interview?.form;
export const isInterviewFomLoading = (state) => state?.interview?.loading;

export const getSelectedUserInfo = (state) => state?.user?.items?.data;
export const getCurrentUserPermissions = (state) =>
  state?.permission?.permissions?.data ?? [];

/**Master Report */
export const selectDocStatusList = (state) =>
  state?.reports?.docStatusListReport;
export const isDocStatusListLoading = (state) => state?.reports?.loading;

export const selectJobApplicationHistory = (state) =>
  state?.applicants?.jobApplicationHistory;

export const selectEmployementHistory = (state) =>
  state?.resource?.employmentHistory;
export const selectEmployementHistoryLoading = (state) =>
  state?.resource?.isEmploymentHistoryLoading;

export const getEmployeesBirthdays = (state) => state?.reports?.employeeBirthdays
export const isEmployeeBirthdayLoading = (state) => state?.reports?.isEmployeeBirthdayLoading  

export const mileageHobbsReport = state=>state.reports.mileageHobbsReport
export const mileageHobbsReportLoading = state=>state.reports.mileageHobbsReportLoading

export const selectInventoryNotes = (state) => state?.inventory?.inventoryNotes?.payload?.data ?? []

export const isActivityLogLoading = state=>state?.dashboard?.activityLogLoading
export const activityLog = state=>state?.dashboard?.activityLog
export const activityLogError = state=>state?.dashboard?.activityLogError 
export const filteredEmployees = (state) => state?.employee?.list ?? null

export const getResourceList = (state) => state?.createProfile?.listAll?.FireFighter ?? []
export const selectUserDocs = (state) => state?.docs?.userDocs

