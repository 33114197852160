import React from 'react';
import { connect } from 'react-redux';
import { config } from '../_helpers';
import Modal from 'react-responsive-modal';
import { permissionActions } from '../_actions';
import ReactModal from 'react-modal';
ReactModal.setAppElement('#app');

class Permissions extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            permission_array: [],
            admin_list: [],
            user_list: [],
            unassignOpen: false,
            allAdminOpen: false,
            delete_hash: "",
            hideSearch: "hide",
            statusOpen: false,
            user_name: "",
            user_hash: "",
            permission_list: ""
        };   
        this.unassignOpenModal = this.unassignOpenModal.bind(this); 
        this.unassignCloseModal = this.unassignCloseModal.bind(this); 
        this.allAdminOpenModal = this.allAdminOpenModal.bind(this); 
        this.allAdminCloseModal = this.allAdminCloseModal.bind(this); 
        this.deleteAdmin = this.deleteAdmin.bind(this);
        this.keyUpFn = this.keyUpFn.bind(this);
        this.statusOpenModal = this.statusOpenModal.bind(this);  
        this.statusCloseModal = this.statusCloseModal.bind(this); 
        this.userClick = this.userClick.bind(this);
        this.assignAdmin = this.assignAdmin.bind(this);
        this.onImageError = this.onImageError.bind(this);
    }

    componentDidMount(){
        this.props.dispatch(permissionActions.getAll());
        this.props.dispatch(permissionActions.getAdminList()); 
        this.props.dispatch(permissionActions.getPermission());
    }

    onImageError(e){
        e.target.setAttribute('src', config.apiUrl + "/" + config.altImg);
    }

    componentWillReceiveProps(nextProps){

        const permission = nextProps.adminpermission; 
        const { adminList } = nextProps.permission;
        var permission_array = [], CrewLead = [], Climber =[], DivisionManager = [], OperationsManager = [], Arborist= [], Groundsperson = [], EquipmentOperator = [], Mechanic = [], Staff = [], Technician = [], admin_list = [], user_list = [];
        if(permission.permissions){
            var permission_list = permission.permissions.data.permission;
            CrewLead = permission.permissions.data.roles['Crew Lead'];
            Climber = permission.permissions.data.roles['Climber'];
            DivisionManager = permission.permissions.data.roles['Division Manager'];
            OperationsManager = permission.permissions.data.roles['Operations Manager'];
            Groundsperson = permission.permissions.data.roles['Groundsperson'];
            EquipmentOperator = permission.permissions.data.roles['Equipment Operator'];
            Mechanic = permission.permissions.data.roles['Mechanic'];
            Staff = permission.permissions.data.roles['Office Staff'];
            Arborist = permission.permissions.data.roles['Arborist/Estimator'] || [];

            permission_list.map((item, index) => {
                permission_array.push(
                    <tr key={"parent"+index+item.id} className={item.nav === "0" ? "parent" : "child" }>
                        <td className="title" colSpan="2">{item.name}</td>
                        <td data-header-title="Division Manager">
                            <input type="checkbox" id={"DivisionManager"+item.id} className="permission_ck manager_ck" defaultChecked={DivisionManager.indexOf(item.id)!== -1 ? 'checked' : ''} onClick={this.setPermission.bind(this, 'Division Manager', item.id)}/>
                            <label htmlFor={"DivisionManager"+item.id}><span></span></label>
                        </td>
                        <td data-header-title="Operations Manager">
                            <input type="checkbox" id={"OperationsManager"+item.id} className="permission_ck manager_ck" defaultChecked={OperationsManager.indexOf(item.id)!== -1 ? 'checked' : ''} onClick={this.setPermission.bind(this, 'Operations Manager', item.id)}/>
                            <label htmlFor={"OperationsManager"+item.id}><span></span></label>
                        </td>
                        <td data-header-title="Arborist/Estimator">
                            <input type="checkbox" id={"Arborist"+item.id} className="permission_ck manager_ck" defaultChecked={Arborist.indexOf(item.id)!== -1 ? 'checked' : ''} onClick={this.setPermission.bind(this, 'Arborist/Estimator', item.id)}/>
                            <label htmlFor={"Arborist"+item.id}><span></span></label>
                        </td>
                        <td data-header-title="Crew Lead">
                            <input type="checkbox" id={"CrewLead"+item.id} className="permission_ck superin_ck" defaultChecked={CrewLead.indexOf(item.id)!== -1 ? 'checked' : ''} onChange={this.setPermission.bind(this, 'Crew Lead', item.id)}/>
                            <label htmlFor={"CrewLead"+item.id}><span></span></label>
                        </td>
                        <td data-header-title="Climber">
                            <input type="checkbox" id={"Climber"+item.id} className="permission_ck superin_ck" defaultChecked={Climber.indexOf(item.id)!== -1 ? 'checked' : ''} onChange={this.setPermission.bind(this, 'Climber', item.id)}/>
                            <label htmlFor={"Climber"+item.id}><span></span></label>
                        </td>
                        <td data-header-title="Equipment Operator">
                            <input type="checkbox" id={"EquipmentOperator"+item.id} className="permission_ck captain_ck" defaultChecked={EquipmentOperator.indexOf(item.id)!== -1 ? 'checked' : ''} onChange={this.setPermission.bind(this, 'Equipment Operator', item.id)}/>
                            <label htmlFor={"EquipmentOperator"+item.id}><span></span></label>
                        </td>
                        <td data-header-title="Mechanic">
                            <input type="checkbox" id={"Mechanic"+item.id} className="permission_ck captain_ck" defaultChecked={Mechanic.indexOf(item.id)!== -1 ? 'checked' : ''} onChange={this.setPermission.bind(this, 'Mechanic', item.id)}/>
                            <label htmlFor={"Mechanic"+item.id}><span></span></label>
                        </td>
                        <td data-header-title="Groundsperson">
                            <input type="checkbox" id={"Groundsperson"+item.id} className="permission_ck fire_ck" defaultChecked={Groundsperson.indexOf(item.id)!== -1 ? 'checked' : ''} onChange={this.setPermission.bind(this, 'Groundsperson', item.id)}/>
                            <label htmlFor={"Groundsperson"+item.id}><span></span></label>
                        </td>
                        <td data-header-title="Office Staff">
                            <input type="checkbox" id={"Staff"+item.id} className="permission_ck staff_ck" defaultChecked={Staff.indexOf(item.id)!== -1 ? 'checked' : ''} onChange={this.setPermission.bind(this, 'Staff', item.id)}/>
                            <label htmlFor={"Staff"+item.id}><span></span></label>
                        </td>
                    </tr>
                )   
                item.child.map((child, i) => {
                    permission_array.push(
                    <tr key={"child"+i+item.id} className={child.nav === "0" ? "parent" : "child" }>
                        <td className="title" colSpan="2">{child.name}</td>
                        <td data-header-title="Division Manager">
                            <input type="checkbox" 
                                disabled={DivisionManager.indexOf(parseInt(child.nav))!== -1 ? "" : "disabled" }  id={"DivisionManager"+child.id} 
                                className={DivisionManager.indexOf(parseInt(child.nav))!== -1 ? "permission_ck manager_ck" : "permission_ck manager_ck disabled"} 
                                defaultChecked={DivisionManager.indexOf(child.id)!== -1 ? 'checked' : ''} 
                                onChange={this.setPermission.bind(this, 'Division Manager', child.id)}/>
                            <label htmlFor={"DivisionManager"+child.id}><span></span></label>
                        </td>
                        <td data-header-title="Operations Manager">
                            <input type="checkbox" 
                                disabled={OperationsManager.indexOf(parseInt(child.nav))!== -1 ? "" : "disabled" }  id={"OperationsManager"+child.id} 
                                className={OperationsManager.indexOf(parseInt(child.nav))!== -1 ? "permission_ck manager_ck" : "permission_ck manager_ck disabled"} 
                                defaultChecked={OperationsManager.indexOf(child.id)!== -1 ? 'checked' : ''} 
                                onChange={this.setPermission.bind(this, 'Operations Manager', child.id)}/>
                            <label htmlFor={"OperationsManager"+child.id}><span></span></label>
                        </td>
                        <td data-header-title="Arborist/Estimator">
                            <input type="checkbox" 
                                disabled={Arborist.indexOf(parseInt(child.nav))!== -1 ? "" : "disabled" }  id={"Arborist"+child.id} 
                                className={Arborist.indexOf(parseInt(child.nav))!== -1 ? "permission_ck manager_ck" : "permission_ck manager_ck disabled"} 
                                defaultChecked={Arborist.indexOf(child.id)!== -1 ? 'checked' : ''} 
                                onChange={this.setPermission.bind(this, 'Arborist/Estimator', child.id)}/>
                            <label htmlFor={"Arborist"+child.id}><span></span></label>
                        </td>
                        <td data-header-title="Crew Lead">
                            <input type="checkbox" id={"CrewLead"+child.id}
                                disabled = {CrewLead.indexOf(parseInt(child.nav))!== -1 ? "" : "disabled"}
                                className={CrewLead.indexOf(parseInt(child.nav))!== -1 ? "permission_ck manager_ck" : "permission_ck manager_ck disabled"} 
                                defaultChecked={CrewLead.indexOf(child.id)!== -1 ? 'checked' : ''} 
                                onChange={this.setPermission.bind(this, 'Crew Lead', child.id)}/>
                            <label htmlFor={"CrewLead"+child.id}><span></span></label>
                        </td>
                        <td data-header-title="Climber">
                            <input type="checkbox" id={"Climber"+child.id}
                                disabled = {Climber.indexOf(parseInt(child.nav))!== -1 ? "" : "disabled"}
                                className={Climber.indexOf(parseInt(child.nav))!== -1 ? "permission_ck manager_ck" : "permission_ck manager_ck disabled"} 
                                defaultChecked={Climber.indexOf(child.id)!== -1 ? 'checked' : ''} 
                                onChange={this.setPermission.bind(this, 'Climber', child.id)}/>
                            <label htmlFor={"Climber"+child.id}><span></span></label>
                        </td>
                        <td data-header-title="Equipment Operator">
                            <input type="checkbox" id={"EquipmentOperator"+child.id} 
                                disabled = {EquipmentOperator.indexOf(parseInt(child.nav))!== -1 ? "" : "disabled"}
                                className={EquipmentOperator.indexOf(parseInt(child.nav))!== -1 ? "permission_ck manager_ck" : "permission_ck manager_ck disabled"} 
                                defaultChecked={EquipmentOperator.indexOf(child.id)!== -1 ? 'checked' : ''} 
                                onChange={this.setPermission.bind(this, 'Equipment Operator', child.id)}/>
                            <label htmlFor={"EquipmentOperator"+child.id}><span></span></label>
                        </td>
                        <td data-header-title="Mechanic">
                            <input type="checkbox" id={"Mechanic"+child.id} 
                                disabled = {Mechanic.indexOf(parseInt(child.nav))!== -1 ? "" : "disabled"}
                                className={Mechanic.indexOf(parseInt(child.nav))!== -1 ? "permission_ck manager_ck" : "permission_ck manager_ck disabled"} 
                                defaultChecked={Mechanic.indexOf(child.id)!== -1 ? 'checked' : ''} 
                                onChange={this.setPermission.bind(this, 'Mechanic', child.id)}/>
                            <label htmlFor={"Mechanic"+child.id}><span></span></label>
                        </td>
                        <td data-header-title="Groundsperson">
                            <input type="checkbox" id={"Groundsperson"+child.id} 
                                disabled = {Groundsperson.indexOf(parseInt(child.nav))!== -1 ? "" : "disabled"}
                                className={Groundsperson.indexOf(parseInt(child.nav))!== -1 ? "permission_ck manager_ck" : "permission_ck manager_ck disabled"} 
                                defaultChecked={Groundsperson.indexOf(child.id)!== -1 ? 'checked' : ''} 
                                onChange={this.setPermission.bind(this, 'Groundsperson', child.id)}/>
                            <label htmlFor={"Groundsperson"+child.id}><span></span></label>
                        </td>
                        <td data-header-title="Office Staff">
                            <input type="checkbox" id={"Staff"+child.id} 
                                disabled = {Staff.indexOf(parseInt(child.nav))!== -1 ? "" : "disabled"}
                                className={Staff.indexOf(parseInt(child.nav))!== -1 ? "permission_ck manager_ck" : "permission_ck manager_ck disabled"} 
                                defaultChecked={Staff.indexOf(child.id)!== -1 ? 'checked' : ''} 
                                onChange={this.setPermission.bind(this, 'Staff', child.id)}/>
                            <label htmlFor={"Staff"+child.id}><span></span></label>
                        </td>
                    </tr>
                    )
                    return permission_array;
                }) 
                return permission_array;
            })
            
            this.setState({ permission_array, permission_list })
        }  

        /** Admin list**/
        const { allAdmin } = nextProps.adminpermission;
        if(adminList){
            if(adminList.data){
                adminList.data.map((item,index) =>{
                    admin_list.push(
                        <div key={index}>
                            <img alt={item.first_name+" "+item.last_name} src={item.profile_photo ? config.apiUrl+'/'+item.profile_photo : config.apiUrl+'/'+config.altImg} onError={this.onImageError.bind(this)}/>
                            <p><span className="user-name">{item.first_name+" "+item.last_name} - </span><span className="user-permission">{item.user_type !== "Admin" ? "Admin ("+item.user_type+")" : item.user_type }</span>

                            { item.is_admin !== 2 &&
                                <span className="remove-admin" onClick={this.unassignOpenModal.bind(this, item.hash)}></span>
                            }
                            </p>
                        </div>
                    )
                    return admin_list;
                })
                this.setState({ admin_list })
            }
        }
        //

        if(allAdmin){
            const userSearchList = allAdmin.data;
            if(userSearchList){
                userSearchList.data.map((item,index) =>{
                    var name = item.first_name + " "+ item.last_name;
                    user_list.push(    
                        <li className="user_click" onClick={this.userClick.bind(this, name, item.hash)} key={index}>
                            <span>{item.first_name + " "+ item.last_name}</span>
                            <img alt={item.first_name+" "+item.last_name} src={item.profile_photo ? config.apiUrl+'/'+item.profile_photo : config.apiUrl+'/'+config.altImg} onError={this.onImageError.bind(this)}/>
                        </li>
                    )
                    return user_list;
                })
                this.setState({ user_list })
            }

        }
        

    }

    keyUpFn(e){
        var textInput = document.getElementById('txtSearchUsersForAssign');
        clearTimeout(this.state.timeout);
        this.state.timeout = setTimeout(function () {
            this.headerSearch(textInput.value);
        }.bind(this),1000)
    }

    userClick(name, hash, event){
        this.setState({user_name: name, user_hash: hash});
        document.getElementById("txtSearchUsersForAssign").value = name;
        this.setState({ hideSearch: "hide"})
    }

    headerSearch(text){
        // var content = "";
        // var dropdown = document.getElementById('searchResultTop');
        // dropdown.innerHTML = '';
        let data = new FormData();
        data.append("keyword", text);
        
        if(text.length === 0){
            this.setState({ hideSearch: "hide"}) 
        }
        else if(text.length >= 1){
            // document.getElementById('searchResultTop').innerHTML = '';
            this.setState({ hideSearch: "show", search_close: "show"})
            // dropdown.innerHTML = '';
            this.props.dispatch(permissionActions.adminSearch(data)); 
        } else{
        //   dropdown.innerHTML = ''; 
          this.setState({ formShow: "show"})
        }
    }

    unassignCloseModal() {
        this.setState({ unassignOpen: false });
    }

    unassignOpenModal(hash) {
        
        this.setState({ unassignOpen: true, delete_hash: hash });
    }

    allAdminCloseModal() {
        this.setState({ allAdminOpen: false, user_list:[], hideSearch: "hide", user_name: "" });
    }

    allAdminOpenModal() {
        this.setState({ allAdminOpen: true });
    }

    statusOpenModal() {
        this.setState({ statusOpen: true });
    }
    
    statusCloseModal() {
        this.setState({ statusOpen: false });
    }

    deleteAdmin(hash){
        let data = new FormData();
        data.append('hash', hash)
        this.props.dispatch(permissionActions.changeUserType(data))
        this.setState({ unassignOpen: false });
    }

    assignAdmin(hash){
        let data = new FormData();
        data.append('hash', hash)
        this.props.dispatch(permissionActions.changeUserType(data))
        this.setState({ allAdminOpen: false, user_name: "" });
    }

    setPermission(usertype, id, e){
        var status;
        if(e.target.checked){
            status = 1;
        } else{
            status = 0;
        }
        let data = new FormData();
        data.append('usertype', usertype)
        data.append('permissionId', id)
        data.append('status', status)
        this.props.dispatch(permissionActions.setPermission(data));
    }

    render() {   
        // const { adminpermission } = this.props;

        if(this.state.permission_list){
            return (            
                <div className="content_div permissions">                    
                    <table id="permission-tbl">
                        <thead>
                            <tr>
                                <th className="title" colSpan="2">&nbsp;</th>
                                <th>Division Manager</th>
                                <th>Operations Manager</th>
                                <th>Arborist/ Estimator</th>
                                <th>Crew Lead</th>
                                <th>Climber</th>
                                <th>Equipment Operator</th>
                                <th>Mechanic</th>
                                <th>Groundsperson</th>
                                <th>Office Staff</th>
                            </tr>
                        </thead>
                        <tbody>                        
                            {this.state.permission_array}
                        </tbody>
                    </table>

                    {/* Admin Listing */}
                    <div className="assign_admin_outer">
                        <h3>Assign Administrator</h3>
                        <div className="assign-admin-container">
                            <div className="admin-list">
                                { this.state.admin_list }
                            </div>
                            <div className="clearFix"></div>
                            <button className="assign-admin button" 
                                onClick={this.allAdminOpenModal.bind(this)} 
                                // onClick={this.statusOpenModal.bind(this)}
                            >Assign admin</button>
                        </div>
                    </div>

                    <Modal id="unassignAdmin_popup" className="unassignAdmin_popup" open={this.state.unassignOpen} onClose={this.unassignCloseModal.bind(this)} little>
                        <div id="unassignPopup">                                         
                            <p className="">Are you sure you want to remove this admin? </p>
                            <button className="button grey" onClick={this.unassignCloseModal.bind(this)}>Cancel</button>
                            <button className="button" onClick={this.deleteAdmin.bind(this, this.state.delete_hash)}>Delete</button>
                                
                        </div>  
                    </Modal>

                    <Modal open={this.state.allAdminOpen} onClose={this.allAdminCloseModal.bind(this)} little>        
                        <div id="remove_applnt" className="modal-body">
                            <div id="allAdminPopup">                                        
                                <label>Search Employee:</label>
                                <input type="text" id="txtSearchUsersForAssign" name="txtSearchUsersForAssign" defaultValue={this.state.user_name} className="txtSearchUsersForAssign" onKeyUp={this.keyUpFn} autoComplete="off" />
                                <ul id="searchResultTop" className={"searchResultUl "+this.state.hideSearch}>
                                    { this.state.user_list }
                                </ul>
                                <p>Are you sure you want to add this user as admin?</p>
                                <button className="button grey" onClick={this.allAdminCloseModal.bind(this)}>Cancel</button>
                                <button className="button" onClick={this.assignAdmin.bind(this, this.state.user_hash)}>Assign</button>
                            </div>                              
                        </div>
                    </Modal> 
                    
                </div>            
            );
        }
        return(
            <div className="content_div permissions">
                <center id="feedLoader" className={this.state.loader ? this.state.loader : ''}>
                  <img alt="Loader" src={require('../assets/images/loader.gif').default} />
                </center>
            </div>
        )
    }
}

function mapStateToProps(state) { 
    const { adminpermission, permission } = state;
    return {
        adminpermission, permission
    };
}

const connectedSettingsPage = connect(mapStateToProps)(Permissions);
export { connectedSettingsPage as Permissions };
