import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { assignmentStatsActions } from "../_actions";
import CircularProgressbar from 'react-circular-progressbar';
import "../assets/styles/crew-assignment-status.css";
import DatePicker from "react-datepicker";
import moment from "moment";
class CrewAssignmentStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assigmentCategoryOptions: [],
            redirect: "",
            circleGrpah: { total: 0, stats: [] },
            assignmentStatusOptions: ['Available', 'Unavailable', 'Committed'],
            toDate: moment(),
            fromDate: moment(),
        }
    }
    componentDidMount() {
        this.props.dispatch(assignmentStatsActions.getAssignemntStatusTypes());
        this.props.dispatch(assignmentStatsActions.getAssignemntStatusProgress());

    }



    componentWillReceiveProps(nextProps) {
        var { assignmentStats } = nextProps;
        if (assignmentStats.types) {
            this.setState({ assigmentCategoryOptions: assignmentStats.types });
        }
        if (assignmentStats.assignemntStatusProgress) {
            this.setState({ circleGrpah: assignmentStats.assignemntStatusProgress.data });
        }
        // '1=available', '2=unavailable', '3=committed'

    }

    redirect = () => {
        if (this.refs.AssignmentType.value) {
            this.setState({
                redirect: `/report/${this.refs.AssignmentType.value}`,
            })
        }
    }

    redirectResource = (status) => {
        this.setState({
            redirect: `/resources`,
            assignmentStats: status
        })
    }

    render() {
        if (this.state.redirect != "") {
            return <Redirect to={{
                pathname: this.state.redirect,
                state: { fromDate: `${this.state.fromDate}`, toDate: `${this.state.toDate}`, assignmentStats: this.state.assignmentStats }
            }}
            />
        }
        return (
            <div className="employee_stats_outer">
                <div className="employee_stats inner_head">
                    <h2>Assignment Status<span className="drag-icon-controller"></span></h2>
                    <span className="toggle-controller" aria-owns="assig_details" onClick={(event)=>{
                        this.props.toggle( event , this.props.widgetid );
                    }}></span>
                </div>
                <div className="employee_stats_details assignment_stats-details" aria-expanded={this.props.visibility == 1 ? "true" : "false"} id="assig_details">
                    <div className="assignment_stats">
                        <div className="total_crew">
                            <div className="crew_no">{this.state.circleGrpah.total}</div>
                            <span onClick={() => {
                                this.redirectResource("")
                            }}>Total Resources <img src={require("../assets/images/arrow.svg").default} /></span>
                        </div>
                        {this.state.circleGrpah.stats.map(
                            (item, index) =>
                                <div className="inventory_progress assignment-progress" key={index}>
                                    <CircularProgressbar percentage={item.per} textForPercentage={(percentage) => item.count} strokeWidth="9" />
                                   <div className="section-wrapper-main">
                                   <span onClick={() => {
                                        this.redirectResource(item.status)
                                    }} className="section-wrapper"><span className="percentage-block">{`${item.per}%`}</span> <span className="status-icon-block">{this.state.assignmentStatusOptions[item.status - 1]} <img src={require("../assets/images/arrow.svg").default} /></span> </span>
                                   </div>
                                </div>
                        )}
                    </div>
                    <div className="assignment-status-outer-form">
                        <div className="col-sm-4">
                            <div className="assignment_form">
                                <div className="form-group">
                                    {/* <input type="text" className="form-control" placeholder="Incident Type"/> */}
                                    <select ref="AssignmentType" className="form-control" required>
                                        {this.state.assigmentCategoryOptions.map(
                                            (item, index) =>
                                                <option key={index} value={item.id}>{item.value}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="assignment_form">
                                <div className="form-group">
                                    <span onClick={() => { this.refs.dateoff.setFocus() }} className="datepicker-image"><img src={require("../assets/images/icon_calendar.svg").default} /></span>
                                    <DatePicker
                                        className="txt "
                                        ref="dateoff"
                                        id="Dateoff"
                                        name="assignDate"
                                        selected={this.state.fromDate}
                                        onChange={(date) => {
                                            this.setState({ fromDate: date });
                                        }}
                                        maxDate={this.state.toDate}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select" placeholderText="Select Date"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="assignment_form">
                                <div className="form-group">
                                    <span onClick={() => { this.refs.dateon.setFocus() }} className="datepicker-image"><img src={require("../assets/images/icon_calendar.svg").default} /></span>
                                    <DatePicker
                                        className="txt "
                                        ref="dateon"
                                        id="Dateon"
                                        name="Dateon"
                                        selected={this.state.toDate}
                                        onChange={(date) => {
                                            this.setState({ toDate: date });
                                        }}
                                        minDate={this.state.fromDate}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select" placeholderText="Select Date"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="run-report" onClick={this.redirect}>
                            Run Report
                            {/* <Link to={"/report" + (typeof (this.refs.AssignmentType) != "undefined") ? this.refs.AssignmentType.value : ""}>Run Report</Link> */}
                            {/* <a href="" className="run-report"></a> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    var { assignmentStats } = state;
    return {
        assignmentStats
    }
}

const assignmentStatus = connect(mapStateToProps)(CrewAssignmentStatus);
export { assignmentStatus as CrewAssignmentStatus }; 