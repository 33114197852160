import { reportsConstants } from "../_constants";

const INITIAL_STATE = {
  docStatusListReport: {},
  isDocStatusListLoading: true,
  masterListError: null,
  vehicleReport: {},
  isVehicleReportLoading: true,
  vehicleReportError: null,
  isExpDateListLoading: true,
  expDateListReport: {},
  expDateListError: null,
  employeeBirthdays: {},
  isEmployeeBirthdayLoading: false,
  employeeBirthdayError: null,
  
  mileageHobbsReport:[],
  mileageHobbsReportLoading:false,
  mileageHobbsReportError:null,

  applicantsReportLoading: true,
  applicantsReport: {},
  applicantsReportError: null,
};

export function reports(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Vehicle Report
    case reportsConstants?.VEHICLE_REPORT_REQUEST:
      return {
        ...state,
        isVehicleReportLoading: true,
      };
    case reportsConstants?.VEHICLE_REPORT_SUCCESS:
      return {
        ...state,
        vehicleReport: action?.data,
        isVehicleReportLoading: false,
      };
    case reportsConstants?.VEHICLE_REPORT_ERROR:
      return {
        ...state,
        isVehicleReportLoading: false,
        vehicleReportError: action?.error,
      };
    // Equipment Sold Report
    case reportsConstants?.EQU_SOLD_REPORT_REQUEST:
      return {
        ...state,
        isEquipmentSoldReportLoading: true,
      };
    case reportsConstants?.EQU_SOLD_REPORT_SUCCESS:
      return {
        ...state,
        equipmentSoldReport: action?.data,
        isEquipmentSoldReportLoading: false,
      };
    case reportsConstants?.EQU_SOLD_REPORT_ERROR:
      return {
        ...state,
        isEquipmentSoldReportLoading: false,
        equipmentSoldReportError: action?.error,
      };
    // All Inventory Report
    case reportsConstants?.ALL_INV_REPORT_REQUEST:
      return {
        ...state,
        isAllInventoryReportLoading: true,
      };
    case reportsConstants?.ALL_INV_REPORT_SUCCESS:
      return {
        ...state,
        allInventoryReport: action?.data,
        isAllInventoryReportLoading: false,
      };
    case reportsConstants?.ALL_INV_REPORT_ERROR:
      return {
        ...state,
        isAllInventoryReportLoading: false,
        allInventoryReportError: action?.error,
      };
    // Master List Report
    case reportsConstants?.GET_DOC_STATUS_LIST_REPORT_REQUEST:
      return {
        ...state,
        isDocStatusListLoading: true,
      };
    case reportsConstants?.GET_DOC_STATUS_LIST_REPORT_SUCCESS:
      return {
        ...state,
        isDocStatusListLoading: false,
        docStatusListReport: action?.data,
      };
    case reportsConstants?.GET_DOC_STATUS_LIST_REPORT_ERROR:
      return {
        ...state,
        isDocStatusListLoading: false,
        masterListError: action?.error,
      };
    // Expiration Date Report
    case reportsConstants?.EXP_DATE_REPORT_REQUEST:
      return {
        ...state,
        isExpDateListLoading: true,
      };
    case reportsConstants?.EXP_DATE_REPORT_SUCCESS:
      return {
        ...state,
        isExpDateListLoading: false,
        expDateListReport: action?.data,
      };
    case reportsConstants?.EXP_DATE_REPORT_ERROR:
      return {
        ...state,
        isExpDateListLoading: false,
        expDateListError: action?.error,
      };

     // Employee Birthday
     case reportsConstants?.EMPLOYEE_BIRTHDAY_REQUEST:
      return {
         ...state,
        isEmployeeBirthdayLoading: true,
      };
    case reportsConstants?.EMPLOYEE_BIRTHDAY_SUCCESS:
      return {
         ...state,
        employeeBirthdays: action?.data,
        isEmployeeBirthdayLoading: false,
      };
    case reportsConstants?.EMPLOYEE_BIRTHDAY_ERROR:
      return {
        ...state,
        isEmployeeBirthdayLoading: false,
        employeeBirthdayError: action?.error,
      };  
    //Mileage hobbs report
    case reportsConstants?.MILEAGE_HOBBS_REPORT_REQUEST:
      return {
         ...state,
        mileageHobbsReportLoading: true,
      };
    case reportsConstants?.MILEAGE_HOBBS_REPORT_SUCCESS:
      return {
         ...state,
        mileageHobbsReport: action?.data,
        mileageHobbsReportLoading: false,
      };
    case reportsConstants?.MILEAGE_HOBBS_REPORT_ERROR:
      return {
        ...state,
        mileageHobbsReportLoading: false,
        mileageHobbsReportError: action?.error,
      };
      
     //
    case reportsConstants.APPLICANTS_REPORT_REQUEST:
      return {
        ...state,
        applicantsReportLoading: true,
      };
    case reportsConstants?.APPLICANTS_REPORT_SUCCESS:
      return {
        ...state,
        applicantsReportLoading: false,
        applicantsReport: action?.data,
      };
    case reportsConstants?.APPLICANTS_REPORT_ERROR:
      return {
        ...state,
        applicantsReportLoading: false,
        applicantsReportError: action?.error,
      };
        
    default:
      return state;

   
  }
}
