import { permissionConstants } from '../_constants';

export function permission(state = {}, action) {
  switch (action.type) {
    case permissionConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case permissionConstants.GETALL_SUCCESS:
      return {
        ...state,
        permissions: action.permission
      };
    case permissionConstants.GETALL_FAILURE:
      return { 
        ...state,
        error: action.error
      };

    case permissionConstants.SETPERM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case permissionConstants.SETPERM_SUCCESS:
      return {
        ...state,
        value: action.value
      };
    case permissionConstants.SETPERM_FAILURE:
      return { 
        ...state,
        error: action.error
      };

    case permissionConstants.GETADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case permissionConstants.GETADMIN_SUCCESS:
      return {
        ...state,
        adminList: action.adminList
      };
    case permissionConstants.GETADMIN_FAILURE:
      return { 
        ...state,
        error: action.error
      }; 

    case permissionConstants.DELETE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case permissionConstants.DELETE_SUCCESS:
      return {
        ...state,
        unassign: action.unassign
      };
    case permissionConstants.DELETE_FAILURE:
      return { 
        ...state,
        error: action.error
      }; 
    default:
      return state
  }
}
