import { toast } from "react-toastify";

export function handleErrorResponse(response) {
    if (typeof response.message === "string") {
      // When response.message is a string
      toast.error(response.message);
    } else if (typeof response.message === "object") {
      // When response.message is an object
      const errorMessages = Object.values(response.message).flat();
      errorMessages.forEach((errorMessage) => {
        toast.error(errorMessage);
      });
    } else {
      // Unexpected response structure
      toast.error("Something went wrong! please contact support team!");
    }
}

export const preventNegativeValueInDocs = (e) => {
    if (
      (e.key === "ArrowDown") ||
      (e.key === "ArrowUp") ||
      e.key === "-" ||
      e.key === "e"
    ) {
      e.preventDefault();
    }
};