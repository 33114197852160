import { manifestConstants,resourceConstants } from '../_constants';

export function email(state = {}, action) {
	switch (action.type) {
	case manifestConstants.EMAILMANIFEST_REQUEST:
		return {
		loading: true
		};
	case manifestConstants.EMAILMANIFEST_SUCCESS:
		return {
		manifestEmail: action.manifestEmail
		};
	case manifestConstants.EMAILMANIFEST_FAILURE:
		return {
		error: action.error
		};
	case resourceConstants.GETEMAILEMPLOYEE_REQUEST:
		return {
		loading: true
		};
	case resourceConstants.GETEMAILEMPLOYEE_SUCCESS:
		return {
		employee: action.employee
		};
	case resourceConstants.GETEMAILEMPLOYEE_FAILURE:
		return {
		error: action.error
		};
	default:
		return state
  	}
}
