import React, { Component } from 'react';

export default class NotFound extends Component{
    render(){
        return (
            <div className="notFound">
                <h3>404 page not found</h3>
                <p>We are sorry but the page you are looking for does not exist.</p>
            </div>
        )
    }
}