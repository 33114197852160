import React from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import "../assets/styles/settings.css";
import "../assets/styles/docs.scss";
import "../assets/styles/dashboard.scss";
import { Profile } from "./Profile";
import { BulletinBoard } from "./BulletinBoard";
import { EditProfile } from "./EditProfile";
import { Permissions } from "./Permissions";
import { DocsList } from "./DocsList";
import { notificationActions, permissionActions } from "../_actions";
import { userActions } from "../_actions";
import DatePicker from "react-datepicker";
import moment from "moment";
import ChangePassword from "ChangePassword";
import ChangeStatusModal from "_components/ChangeStatusModal";
import EmploymentHistory from "Resources/EmploymentHistory";
import { userService } from "_services/user.service";
import { toast } from "react-toastify";
import { handleErrorResponse } from "_helpers/helpers";


class SettingsProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainHtml: "",
      step: window.location.hash && "#bulletin" === window.location.hash ? "Bulletin" : "Profile",
      statusOpen: false,
      status: "",
      available: "",
      statusStep: "1",
      returnDateField: moment(),
      returnDate: "",
      permission: [],
      h1: "",
      sendingDDForm: false,
      send_dd_form_confirm: false,
    };
    this.changeTab = this.changeTab.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.setStatusReturn = this.setStatusReturn.bind(this);
    this.setStep = this.setStep.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.cancel = this.cancel.bind(this);
    this.editProfile = this.editProfile.bind(this);
  }

  openDdformConfirmModal() {
    this.setState({ send_dd_form_confirm: true });
  }

  ddFormConfirmCloseModal() {
    this.setState({
      send_dd_form_confirm: false
    })
  }

  sendDDForm() {
    if (this.props.user.items.data.hash) {
      this.setState({
        ...this.state,
        sendingDDForm: true,
        send_dd_form_confirm: false,
      });
      let data = new FormData();
      data.append("user_hash", this.props.user.items.data.hash)
      userService.sendDDForm(data).then((res) => {
        if(res.success){
          toast.success(res.message)
        }
        else{
          handleErrorResponse(res);
        }
        this.setState({
          ...this.state,
          sendingDDForm: false
        });
      })
      .catch((error) => {
        toast.error("Failed to send email to user.");
        this.setState({
          ...this.state,
          sendingDDForm: false
        });
      });
    } else {
      toast.error("An unexpected error occurred.")
    }
  }

  statusOpenModal() {
    this.setState({ statusOpen: true, statusStep: "1" });
  }

  statusCloseModal() {
    this.setState({ statusOpen: false });
  }

  changeTab(tab) {
    if (tab === "Profile") {
      var hash = "";
      if (
        this.props.match.path === "/profile/:hash" ||
        this.props.match.path === "/profile/:hash/docs/:category/:doc/:id"
      ) {
        hash = "?hash=" + this.props.match.params.hash;
      }
      this.props.dispatch(userActions.getProfile(hash));
    }
    this.props.dispatch(permissionActions.getPermission());
    this.setState({ step: tab }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }
  setStatusReturn(status) {
    if (status) {
      var step = "2a";
    } else step = "2b";

    this.setState({ statusStep: step }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }
  setStep(step) {
    this.setState({ statusStep: step }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  handleDateChange(date) {
    this.setState({
      returnDateField: date,
      returnDate: date.format("MM/DD/YY"),
    });
  }

  changeStatus(status, statVal) {
    if (status) {
      var stat = statVal === "Available" ? "Unavailable" : "Available";
      this.setState({ status: stat }, function () {
        this.componentWillReceiveProps(this.props);
      });

      let data = new FormData();
      this.statusCloseModal();
      if (
        this.props.match.path === "/profile/:hash" ||
        this.props.match.path === "/profile/:hash/docs/:category/:doc/:id"
      ) {
        data.append("hash", this.props.match.params.hash);
      }
      data.append("end_date", this.state.returnDate);
      data.append("status", stat);
      if (
        this.props.match.path === "/profile/:hash" ||
        this.props.match.path === "/profile/:hash/docs/:category/:doc/:id"
      ) {
        var hash = "?hash=" + this.props.match.params.hash;
        if (this.props.match.params.hash === localStorage.getItem("hash")) {
          this.props.dispatch(userActions.changeSelfStatus(data));
        } else {
          this.props.dispatch(userActions.changeStatus(data, hash));
        }
      } else {
        this.props.dispatch(userActions.changeSelfStatus(data));
      }
      this.props.dispatch(notificationActions.getCount());
    }
  }

  editProfile() {
    this.setState({ step: "EditProfile" }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }
  cancel() {
    this.setState({ step: "Profile" }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  componentDidMount() {
    var hash = "";
    if (
      this.props.match.path === "/profile/:hash" ||
      this.props.match.path === "/profile/:hash/docs/:category/:doc/:id"
    ) {
      hash = "?hash=" + this.props.match.params.hash;
    }
    this.props.dispatch(userActions.getProfile(hash));
    this.componentWillReceiveProps(this.props);
    this.props.dispatch(permissionActions.getPermission());
    if (this.props.match.path === "/profile/:hash/docs/:category/:doc/:id") {
      this.changeTab("DocsList");
    }
  }

  componentWillReceiveProps(nextProps) {
    const { permission } = this.props;
    if (permission.permissions) {
      // permList = permission.permissions.data
      this.setState({ permission: permission.permissions.data });
    }

    if (nextProps.user.items) {
      var admin = nextProps.user.items.data.is_admin !== 0 ? true : false;
      if (
        this.props.match.path === "/profile/:hash" ||
        this.props.match.path === "/profile/:hash/docs/:category/:doc/:id"
      ) {
        this.setState({
          h1: nextProps.user.items.data.first_name + " " + (nextProps.user.items.data.middle_name ? nextProps.user.items.data.middle_name + " " : "") + nextProps.user.items.data.last_name,
          admin,
          namePrefix: nextProps.user.items.data.first_name,
        });
      } else {
        this.setState({ h1: "Profile & Settings", admin, namePrefix: "you" });
      }
      var available = nextProps.user.items.data.active === "Available" ? true : false;
      this.setState({ status: nextProps.user.items.data.active, available: available });
    }
    var options;
    if (this.state.step === "Profile") {
      options = (
        <div>
          <Profile {...nextProps} editProfile={this.editProfile.bind(this)} />
        </div>
      );
    }
    if (this.state.step === "DocsList") {
      options = (
        <div>
          <DocsList  userHash={this.props.match.params.hash}/>
        </div>
      );
    }
    if (this.state.step === "Employment History") {
      options = (
        <div style={{ backgroundColor: "white", minHeight: 300 }}>
          <EmploymentHistory hash={this.props.match.params.hash} />
        </div>
      );
    }
    if (this.state.step === "Bulletin") {
      options = (
        <div>
          <BulletinBoard />
        </div>
      );
    }
    if (this.state.step === "Permissions") {
      options = (
        <div>
          <Permissions />
        </div>
      );
    }
    if (this.state.step === "EditProfile") {
      options = (
        <div>
          <EditProfile {...nextProps} cancel={this.cancel.bind(this)} />
        </div>
      );
    }

    this.setState({ mainHtml: options });
  }
  //
  render() {
    const { permission, user } = this.props;
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }

    var admin = false,
      editPerm = true,
      userItem;

    if (
      this.props.user.profile &&
      (!this.props.match.path === "/profile/:hash" ||
        this.props.match.path === "/profile/:hash/docs/:category/:doc/:id")
    ) {
      userItem = this.props.user.profile;
    } else if (this.props.user.items) {
      userItem = this.props.user.items;
    }

    var namePrefix = "";
    if (userItem) {
      var status = userItem.data.active;
      var available = userItem.data.active === "Available" ? true : false;
      admin = userItem.data.is_admin !== 0 ? true : false;
      var h1 = "Settings";
      namePrefix = "you";
      if (
        this.props.match.path === "/profile/:hash" ||
        this.props.match.path === "/profile/:hash/docs/:category/:doc/:id"
      ) {
        h1 = userItem.data.first_name + " " + (userItem.data.middle_name ? userItem.data.middle_name + " " : "") + userItem.data.last_name;
        editPerm = permList.includes("Status Update") ? true : false;
        namePrefix = this.state.namePrefix;
      }
    }

    let userData = user.profile ? user.profile.data : {};

    // if(userItem){
    return (
      <div>
        <div>
          <div className="settings_container">
            <div className="page_title float">
              <h1 className="">{this.state.h1}</h1>

              <div className="btn_list">
                {editPerm && (
                  <button className="button edit_profile" onClick={this.statusOpenModal.bind(this)}>
                    Edit User's Status
                  </button>
                )}
                {permList.includes("Edit User Profile") && (
                  <button className="button edit_profile" onClick={this.editProfile}>
                    Edit Profile
                  </button>
                )}
                {permList.includes("Send DD Form") && (
                  <button
                    className="button edit_profile"
                    onClick={this.openDdformConfirmModal.bind(this)}
                    disabled={this.state.sendingDDForm}
                  >
                    Send DD Form
                  </button>
                )}
                {permList.includes("Manage Passwords") && (
                  <ChangePassword
                    userType="user"
                    user={userItem?.data}
                    isAdmin={this.props?.user?.profile?.data?.is_admin !== 0 ? true : false}
                    className={"edit_profile"}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="inner_head">
                  <h2
                    className={this.state.step === "Profile" ? "active" : ""}
                    onClick={this.changeTab.bind(this, "Profile")}
                  >
                    Profile
                  </h2>
                  {/* userData.docs_status >= 2 &&  */}
                  {
                    <h2
                      className={this.state.step === "DocsList" ? "active" : ""}
                      onClick={this.changeTab.bind(this, "DocsList")}
                    >
                      Documents
                    </h2>
                  }
                  {permList.includes("Manage Employment History") && (
                    <h2
                      className={this.state.step === "Employment History" ? "active" : ""}
                      onClick={this.changeTab.bind(this, "Employment History")}
                    >
                      Employment History
                    </h2>
                  )}
                  {admin &&
                    this.props.match.path !== "/profile/:hash" &&
                    this.props.match.path !== "/profile/:hash/docs/:category/:doc/:id" && (
                      <h2
                        className={this.state.step === "Permissions" ? "active" : ""}
                        onClick={this.changeTab.bind(this, "Permissions")}
                      >
                        Permissions
                      </h2>
                    )}
                  {this.state.permission.includes("Bulletin Board") &&
                    this.props.match.path !== "/profile/:hash" &&
                    this.props.match.path !== "/profile/:hash/docs/:category/:doc/:id" && (
                      <h2
                        className={this.state.step === "Bulletin" ? "active" : ""}
                        onClick={this.changeTab.bind(this, "Bulletin")}
                      >
                        Bulletin Board
                      </h2>
                    )}
                </div>
                <div className="tab-contents">{this.state.mainHtml}</div>
              </div>
            </div>
            {this.state.statusOpen ? (
            <ChangeStatusModal  open={this.state.statusOpen} onClose={this.statusCloseModal} />
          ) : null}
            <Modal
              id="remove_popup"
              className="status_popup"
              open={this.state.send_dd_form_confirm}
              onClose={this.ddFormConfirmCloseModal.bind(this)}
              little
            >
              <div id="removePopup">
                <div className="modal-head">
                  <h4 className="modal-title">
                    Direct Deposit Form
                  </h4>
                </div>
                <div className="modal-body">
                  <label>This will send an email to {this.state.h1 ? this.state.h1 : "this employee"}, with Direct Deposit form link.</label>
                  <br />
                  <button className="button grey" onClick={this.ddFormConfirmCloseModal.bind(this)}>
                    Cancel
                  </button>
                  <button className="button" onClick={this.sendDDForm.bind(this)}>
                    Send
                  </button>
                </div>
              </div>
            </Modal>
            {/* <Modal
              id="status_popup"
              className="status_popup"
              open={this.state.statusOpen}
              onClose={this.statusCloseModal.bind(this)}
              little
            >
              <div id="statusPopup">
                <div className="modal-head">
                  <h4 className="modal-title">Current Status</h4>
                </div>
                {this.state.statusStep === "1" && (
                  <div className="modal-body">
                    <div className="status_step step1">
                      <label>
                        <span className="capitalize">
                          {this.props.match.path === "/profile/:hash" ||
                          this.props.match.path === "/profile/:hash/docs/:category/:doc/:id"
                            ? namePrefix
                            : "You"}
                        </span>
                        {this.props.match.path === "/profile/:hash" ||
                        this.props.match.path === "/profile/:hash/docs/:category/:doc/:id"
                          ? " is: "
                          : " are: "}
                        <span className="green">{status}</span>
                      </label>
                      <br></br>
                      <button className="button" onClick={this.setStep.bind(this, "2")}>
                        Change Current Status
                      </button>
                      <p className="step_no">1/3</p>
                    </div>
                  </div>
                )}
                {this.state.statusStep === "2" && available && (
                  <div className="modal-body">
                    <div className="status_step step2">
                      <label>Do you know when {namePrefix} will return?</label>
                      <button className="button" onClick={this.setStatusReturn.bind(this, true)}>
                        Yes
                      </button>
                      <button className="button grey" onClick={this.setStatusReturn.bind(this, false)}>
                        No
                      </button>
                      <p className="step_no">2/3</p>
                    </div>
                  </div>
                )}
                {this.state.statusStep === "2" && !available && (
                  <div className="modal-body">
                    <div className="status_step step2">
                      <label>Make Available?</label>
                      <button className="button" onClick={this.changeStatus.bind(this, true, status)}>
                        Yes
                      </button>
                      <button className="button grey" onClick={this.statusCloseModal.bind(this)}>
                        No
                      </button>
                      <p className="step_no">2/3</p>
                    </div>
                  </div>
                )}
                {this.state.statusStep === "2a" && (
                  <div className="modal-body">
                    <div className="status_step step4">
                      <label>Choose date of return:</label>
                      <DatePicker
                        className="returnDateField"
                        name="returnDateField"
                        selected={this.state.returnDateField}
                        onChange={this.handleDateChange.bind(this)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      <button className="button" onClick={this.setStep.bind(this, "3")}>
                        Next
                      </button>
                      <p className="step_no">2/3</p>
                    </div>
                  </div>
                )}
                {this.state.statusStep === "2b" && (
                  <div className="modal-body">
                    <div className="status_step step2">
                      <label>Make Unavailable?</label>
                      <button className="button" onClick={this.changeStatus.bind(this, true, status)}>
                        Yes
                      </button>
                      <button className="button grey" onClick={this.statusCloseModal.bind(this)}>
                        No
                      </button>
                      <p className="step_no">2/3</p>
                    </div>
                  </div>
                )}
                {this.state.statusStep === "3" && (
                  <div className="modal-body">
                    <div className="status_step step5">
                      <label>Are you sure you want to switch {namePrefix} to Unavailable?</label>
                      <p>
                        (returning {this.state.returnDate === "" ? moment().format("MM/DD/YY") : this.state.returnDate})
                      </p>
                      <button className="button" onClick={this.changeStatus.bind(this, true, status)}>
                        Yes
                      </button>
                      <button className="button grey" onClick={this.statusCloseModal.bind(this)}>
                        No
                      </button>
                      <p>Time off must be approved by your supervisor.</p>
                      <p className="step_no">3/3</p>
                    </div>
                  </div>
                )}
              </div>
            </Modal> */}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { applicants, user, permission } = state;
  return {
    applicants,
    user,
    permission,
  };
}
const connectedHomePage = connect(mapStateToProps)(SettingsProfile);
export { connectedHomePage as SettingsProfile };
