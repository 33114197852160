import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { isEquipmentSoldReportLoading, getCurrentUserInfo } from "_helpers/reduxSelector";
import moment from "moment";

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
  inventoryClass: ASC_CLASS,
  mileageClass: ASC_CLASS,
  resourceClass: ASC_CLASS,
  dateClass:ASC_CLASS
};

const MileageHobbsReport = ({ getMileageHobbsReport, data, lastPage, fromDate, toDate, setSortParams }) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("unique_id");
  const [page, setPage] = useState(0);
  const isLoading = useSelector((state) => isEquipmentSoldReportLoading(state));
  const currentUserInfo = useSelector((state) => getCurrentUserInfo(state));
  const DATE_FORMAT = currentUserInfo?.date_formats?.ISO_DATE_FORMAT;

  const [{ inventoryClass, mileageClass, resourceClass, dateClass }, setStyleState] = useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getMileageHobbsReport(page, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const handlePageChange = (newPage) => {
    const sortOrder = order ? "asc" : "desc";
    getMileageHobbsReport(newPage?.selected + 1, sortItem, sortOrder);
    setPage(newPage?.selected);
  };

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams(sort, nextOrder);
  };

  React.useEffect(() => {
    setPage(0);
  }, [fromDate, toDate]);

  const noDataAvailable = !isLoading && !data?.length;
  return (
    <div className="history_lists outer_list report_table">
      <table className="mobile_hide report_list_width_full">
        <thead>
          <tr className="report_list_table_row">
            <th onClick={() => sortList("inventoryClass", inventoryClass, "equipment_name")}>
              <span>Inventory</span>
              <span className={inventoryClass} />
            </th>
            <th onClick={() => sortList("mileageClass", mileageClass, "mileage")}>
              <span>Mileage/Hobbs</span>
              <span className={mileageClass} />
            </th>
            <th onClick={() => sortList("resourceClass", resourceClass, "resource_name")}>
              <span>Resource</span>
              <span className={resourceClass} />
            </th>
            <th onClick={() => sortList("dateClass", dateClass, "mileage_date")}>
              <span>Date</span>
              <span className={dateClass} />
            </th>
          </tr>
        </thead>
        <tbody className="report_list_table_tbody">
          {noDataAvailable ? (
            <tr>
              <td colSpan="3">
                <div className="dashboard_expiration_date_no_data">No Data Found</div>
              </td>
            </tr>
          ) : (
            data?.map((item, index) => {
              const name =
                item.equipment_type_id === 1 ? item.name : item.unique_id;
              return (
                <tr key={index}>
                  <td>{name??"-"}</td>
                  <td>{item?.mileage || "-"}</td>
                  <td>{item?.resource_name || "-"}</td>
                  <td>{moment(item?.mileage_date).isValid()?moment(item.mileage_date).format("DD/MM/YY") : "-"}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {noDataAvailable ? (
        <div className="dashboard_expiration_date_no_data for_mobile_history_list">No Data Found</div>
      ) : (
        <MileageHobbsReportMobileView 
          data={data} 
          getMileageHobbsReport={getMileageHobbsReport} 
          setSortParams={setSortParams}
          dateFormat ={DATE_FORMAT} />
      )}

      {!isLoading && data?.length ? (
        <div className="fs-pagination-wrapper-outer" key={7}>
          <div className="customised-pagination right-align">
            <ReactPaginate
              forcePage={page}
              previousLabel={""}
              nextLabel={""}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={lastPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => handlePageChange(data)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MileageHobbsReport;

const MileageHobbsReportMobileView = ({ data, getMileageHobbsReport,setSortParams, dateFormat }) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("unique_id");
  const [{ inventoryClass, mileageClass, resourceClass, dateClass }, setStyleState] = useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getMileageHobbsReport(0, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams(sort, nextOrder);
  };
  return data.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div className="list_item" onClick={() => sortList("inventoryClass", inventoryClass, "equipment_name")}>
              <strong>Inventory</strong>
              <span className={inventoryClass} />
            </div>
            <div className="list_item">{item.equipment_type_id === 1 ? item.name : item.unique_id}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("mileageClass", mileageClass, "mileage")}>
              <strong>Mileage/Hobbs</strong>
              <span className={mileageClass} />
            </div>
            <div className="list_item">{item?.mileage ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("resourceClass", resourceClass, "resource_name")}>
              <strong>Resource</strong>
              <span className={resourceClass} />
            </div>
            <div className="list_item">{item?.resource_name ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item" onClick={() => sortList("dateClass", dateClass, "mileage_date")}>
              <strong>Date</strong>
              <span className={dateClass} />
            </div>
            <div className="list_item">{moment(item?.mileage_date).format(dateFormat) ?? "-"}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};
