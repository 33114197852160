import React, { Component } from 'react'
import { config } from '../_helpers/config';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux'
import { connect } from 'react-redux';

class UserWelcome extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        let user = this.props.user.profile ? this.props.user.profile.data : {};

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="onboarding-docs">
                        <div className="doc-home">
                            <div className="doc-home-info">
                                <img src={`${config.assetUrl}icons/doc-thank.svg`} className="doc-home-img" />
                                <h3 className="doc-username">Hi, {user.first_name} {user.last_name}.</h3>
                                <p>Welcome to NVAM EMS</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state;
    return { user };
}

let connectedUserWelcome = compose(withRouter, connect(mapStateToProps))(UserWelcome);
export { connectedUserWelcome as UserWelcome  };