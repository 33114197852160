import React from 'react';
import { connect } from 'react-redux';
import { EditProfile } from '../Resources/EditProfile';
import { Profile } from './Profile';
import '../assets/styles/resource.css';

class Employee extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            mainHtml: "",
            step: "Profile",
            hash: this.props.match.params.hash
        }
        this.profile = this.profile.bind(this);
        // this.goBack = this.goBack.bind(this);
        this.editProfile = this.editProfile.bind(this);
    }

    profile(hash){
        this.setState({ step:'Profile', hash:hash },function(){
            this.componentWillReceiveProps(this.props)
        })
    }

    editProfile(hash){
        this.setState({ step:'EditProfile', hash:hash },function(){
            this.componentWillReceiveProps(this.props)
        })
    }

    // goBack(){
    //     this.setState({ step:'YourCrewDetails' },function(){
    //         this.componentWillReceiveProps(this.props)
    //     })
    // }

    componentDidMount() {
        this.componentWillReceiveProps(this.props)
    }

    componentWillReceiveProps(nextProps){ 
        
        var options;    
        
        if(this.state.step === 'Profile') {
            options = <div>
                        <Profile 
                        // goBack={this.goBack} 
                        hash={this.state.hash} 
                        editProfile={this.editProfile} /> 			           
                    </div>
        }

        if(this.state.step === 'EditProfile') {
            options = <div >
                        <EditProfile 
                            goBack={this.profile.bind(this)} 
                            hash={this.state.hash}
                            resourceDetails={this.state.props}
                            id={this.state.id}
                            // cancel={this.profile.bind(this, this.state.hash)} 
                        />   		
                    </div>		
        }
        
        this.setState({mainHtml:options})
    }
       

    render() {
        
        return (
    
            <div>                
                {this.state.mainHtml}  		
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { vehicles } = state;
    return {
        vehicles,
    };
}

const connectedHomePage = connect(mapStateToProps)(Employee);
export { connectedHomePage as Employee };
