import React from 'react';
import { connect } from 'react-redux';
import CircularProgressbar from 'react-circular-progressbar';
import { inventoryActions, permissionActions } from '../_actions';

class Inventory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stats_array: []
        }
    }
    componentDidMount() {
        this.props.dispatch(permissionActions.getPermission());
        this.props.dispatch(inventoryActions.getInventoryStats());
    }
    componentWillReceiveProps(nextProps) {
        const { stats } = nextProps.inventory
        var stats_array = []
        if (stats) {
            stats.data.stats.map((item, index) => {
                stats_array.push(
                    <div className="inventory_item" key={index}>
                        <div className="inventory_progress">

                            <CircularProgressbar percentage={item.per} textForPercentage={(percentage) => `${item.per}%`} strokeWidth="9" />
                        </div>
                        <div className="inventory_data">
                            <p>{item.per}</p>
                            <span className="small_grey">{item.assigned + " / " + item.total + " " + item.value + " Available"}</span>
                        </div>
                    </div>
                )
                return stats_array;
            })
            this.setState({ stats_array })
        }
    }
    render() {
        return (
            <div className="inventory_outer">
                <div className="inner_head">
                    <h2>Inventory <span className="drag-icon-controller"></span></h2>
                    <span className="toggle-controller" aria-owns="in_v_details" onClick={(event)=>{
                        this.props.toggle( event , this.props.widgetid );
                    }}></span>
                </div>
                <div className="inventory_details" aria-expanded={this.props.visibility == 1 ? "true" : "false"} id="in_v_details" >
                    {this.state.stats_array}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { inventory } = state;
    return {
        inventory
    };
}

const connectedApp = connect(mapStateToProps)(Inventory);
export { connectedApp as Inventory };

// export default Navigation;