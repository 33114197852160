import React from "react";
import Dropzone from "react-dropzone";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { inventoryService } from "_services";
import styles from "./upload.module.css";
import CsvIcon from "assets/images/csv.png";
import { saveAs } from 'file-saver';
import { config } from "_helpers";

const customStyles = {
  content: {
    maxWidth: 460,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  }
};

const MAX_FILE_SIZE = 20; //MB


const SAMPLE_FILE_URL = `${config?.assetUrl}samples/downloads/Import_Inventory_Sample.csv`
const INVENTORY_DETAILS_SAMPLE = `${config?.assetUrl}samples/downloads/Import_Inventory_Details.xlsx`

const FileUpload = ({ className,onComplete, ...props }) => {
  const [isFileUploading, setIsFileUploading] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [uploadingStatus, setUploadingStatus] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);

  const fileDrop = (files) => {
    setSelectedFile(files?.[0]);
  };

  const handleUpload = () => {
    uploadFile(selectedFile);
  };

  const uploadFile = async (file) => {
    try {
      setUploadingStatus("Uploading...");
      setIsFileUploading(true);
      const data = new FormData();
      data.append("uploaded_file", file);
      const response = await inventoryService?.uploadExcelToServer(data);
      if (response?.success) {
        onComplete?.()
        toast.success(response.message);
        setOpenModal(false);
        setIsFileUploading(false);
      
      } else {
        throw response;
      }
    } catch (error) {
      setUploadingStatus("Error...");
      setIsFileUploading(false);
      toast?.error(error?.message?.uploaded_file?.join() ?? error?.message);
    }
  };

  const downloadFile = (fileURL,fileName) => {
    saveAs(fileURL,fileName)
  };

  React.useEffect(() => {
    if (selectedFile) {
      const fileSize = selectedFile?.size / 1024 / 1024; // in MiB
      const sizeLimitMessage = "Max file size exceeded.";
      if (fileSize > MAX_FILE_SIZE) {
        setUploadingStatus(sizeLimitMessage);
        toast?.error(sizeLimitMessage);
        setSelectedFile(null);
      }
    }
  }, [selectedFile]);

  React.useEffect(() => {
    if (!openModal && (selectedFile || uploadingStatus)) {
      setSelectedFile(null);
      setUploadingStatus("");
    }
  }, [openModal]);
  return (
    <React.Fragment>
      <React.Fragment>
        <div
          disabled={isFileUploading}
          onClick={() => setOpenModal(true)}
          className={className}
        >
          Upload Excel
        </div>
      </React.Fragment>
      <ReactModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        style={customStyles}
      >
        <div className={styles?.container}>
          <div className={styles?.dialog_title}>
            {/* Modal Header */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4 style={{ margin: 0 }}>Upload Excel</h4>
            </div>
            {/* Close Button */}
            <div onClick={() => (isFileUploading ? {} : setOpenModal(false))}>
              <img
                alt="close"
                className="closeModal"
                src={require("../../assets/images/close_green.png").default}
              />
            </div>
          </div>
          <div className={styles?.content}>
            <div
              style={{
                display: "flex",
                justifyContent: "stretch",
                alignItems: "center",
                flexDirection: "column",
                pointerEvents: isFileUploading ? "none" : "inherit",
              }}
            >
              <Dropzone
                className={styles?.file_upload_drop_zone}
                multiple={false}
                accept=".csv"
                onDrop={(file) => fileDrop(file)}
              >
                {selectedFile ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 10,
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={CsvIcon}
                      style={{ maxWidth: 40}}
                      alt=""
                    />
                    <h6
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      {selectedFile?.name}
                    </h6>
                  </div>
                ) : (
                  <React.Fragment>
                    <p>Upload or Drag/Drop Here</p>
                    <p style={{fontSize:10}}>File size 20mb maximum. type allowed: csv</p>
                  </React.Fragment>
                )}
                <h6>{uploadingStatus}</h6>
              </Dropzone>
              <div style={{ margin: "20px 0px" }}>
                <button
                  style={{ minWidth: 300 }}
                  onClick={() => downloadFile(SAMPLE_FILE_URL,"Import Inventory Sample")}
                  className="button"
                >
                  Download Sample File
                </button>
              </div>
              <a href="#" onClick={() => downloadFile(INVENTORY_DETAILS_SAMPLE, "Import Inventory Details")}>More Details</a>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 20,
              borderTop: "1px solid #eee",
            }}
          >
            <button
              className="button grey"
              disabled={isFileUploading}
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
            <button
              disabled={isFileUploading || !selectedFile}
              onClick={() => handleUpload()}
              className="button"
            >
              Upload
              {isFileUploading && (
                <img
                  className=""
                  alt="loader"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                />
              )}
            </button>
          </div>
        </div>
      </ReactModal>
    </React.Fragment>
  );
};

export default FileUpload;
