// import { dashboardConstants } from '../_constants';
// import { dashboardService } from '../_services';
// import { alertActions } from './';
// import { history } from '../_helpers';

import { dashboardConstants } from "_constants";
import { dashboardService } from "_services/dashboard.service";

export const dashboardActions = {
    // getAllBulletin,
    // viewDetails,
    fetchActivityLog
};

// function getAllBulletin() {
//     return dispatch => {
//         dispatch(request());

//         dashboardService.getAllBulletin()
//             .then(
//                 bulletin => dispatch(success(bulletin)),
//                 error => dispatch(failure(error))
//             );
//     };

//     function request() { return { type: dashboardConstants.GETBULLET_REQUEST } }
//     function success(bulletin) { return { type: dashboardConstants.GETBULLET_SUCCESS, bulletin } }
//     function failure(error) { return { type: dashboardConstants.GETBULLET_FAILURE, error } }
// }

// function viewDetails(hash) {
//     return dispatch => {
//         dispatch(request(hash));

//         dashboardService.viewDetails(hash)
//             .then(
//                 list => dispatch(success(list)),
//                 error => dispatch(failure(error))
//             );
//     };

//     function request() { return { type: dashboardConstants.VIEWAPPLN_REQUEST } }
//     function success(list) { return { type: dashboardConstants.VIEWAPPLN_SUCCESS, list } }
//     function failure(error) { return { type: dashboardConstants.VIEWAPPLN_FAILURE, error } }
// }

function fetchActivityLog(data) {
    return (dispatch) => {
      dispatch(request());
      dashboardService.fetchActivityLog(data).then(
        (list) => dispatch(success(list)),
        (error) => dispatch(failure(error))
        );
      };
      
      function request() {
        return { type: dashboardConstants.ACTIVITY_LOG_REQUEST };
      }
      function success(response) {
      return {
        type: dashboardConstants.ACTIVITY_LOG_SUCCESS,
        data: response.data,
      };
    }
    function failure(error) {
      return { type: dashboardConstants.ACTIVITY_LOG_ERROR,error };
    }
  }