import { authHeader, config } from "../_helpers";

export const manifestService = {
  manifestType,
  getUsers,
  getVehicles,
  getSuperintendent,
  manifestList,
  saveManifest,
  printManifest,
  emailManifest,
  manifestDetails,
  updateManifest,
  deleteManifest,
  dispatchMailList
};

function manifestType() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    config.apiUrl + "/api/getlist/manifesttype",
    requestOptions
  ).then(handleResponse, handleError);
}

function getUsers(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/manifest/getusers", requestOptions).then(
    handleResponse,
    handleError
  );
}

function getVehicles(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(
    config.apiUrl + "/api/manifest/getvehicles",
    requestOptions
  ).then(handleResponse, handleError);
}

function getSuperintendent(data) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    config.apiUrl + "/api/getsuperintenddetails?" + data,
    requestOptions
  ).then(handleResponse, handleError);
}

function manifestList(data, pageNum = 0) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };
  let url =
    pageNum > 0
      ? `/api/manifest/listmanifest?page=${pageNum}`
      : "/api/manifest/listmanifest";
  return fetch(config.apiUrl + url, requestOptions).then(
    handleResponse,
    handleError
  );
}

function saveManifest(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/manifest/create", requestOptions).then(
    handleResponse,
    handleError
  );
}

function printManifest(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader()
  };

  return fetch(
    config.apiUrl + "/api/manifest/pdf1" + data,
    requestOptions
  ).then(handleResponse, handleError);
}

function emailManifest(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/manifest/mail", requestOptions).then(
    handleResponse,
    handleError
  );
}

function manifestDetails(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(
    config.apiUrl + "/api/manifest/getmanifestinfo",
    requestOptions
  ).then(handleResponse, handleError);
}

function dispatchMailList() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    config.apiUrl + "/api/getdispatchlist",
    requestOptions
  ).then(handleResponse, handleError);
}

function updateManifest(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/manifest/update", requestOptions).then(
    handleResponse,
    handleError
  );
}

function deleteManifest(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/manifest/delete", requestOptions).then(
    handleResponse,
    handleError
  );
}

function handleResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      // return json if it was returned in the response
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then(json => resolve(json));
      } else {
        resolve();
      }
    } else {
      // return error message from response body
      response.text().then(text => reject(text));
    }
  });
}

function handleError(error) {
  return Promise.reject(error && error.message);
}
