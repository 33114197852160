import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { getApplicantStatus, getCompanyName } from "_utils/utils";
import { useSelector } from "react-redux";
import { selectEmployementHistoryLoading } from "_helpers/reduxSelector";
import Loader from "_components/Loader";



const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
    companyClass: ASC_CLASS,
    jobTitleClass: ASC_CLASS,
    employeeIdClass: ASC_CLASS,
};

const noDataFoundStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 150
}

export default function EmploymentHistoryList({ getEmploymentHistory, data, lastPage, hash }) {





    const [order, setOrder] = useState(true); /** true === asc **/
    const [sortItem, setSortItem] = useState("applying_company");
    const [page, setPage] = useState(0);
    const isLoading = useSelector(selectEmployementHistoryLoading)
    const [initialStyleStateTypes, setStyleState] = useState(initialStyleState);
    const { companyClass, jobTitleClass, employeeIdClass } = initialStyleStateTypes;

    useEffect(() => {
        getEmploymentHistory(getFormData())
    }, [order, sortItem, page])


    const sortList = (className, prevStyle, sort) => {
        const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
        const nextOrder = prevStyle !== DESC_CLASS ? false : true;
        setStyleState({ ...initialStyleState, [className]: newStyle });
        setOrder(nextOrder);
        setSortItem(sort);
    };


    const handlePageChange = (newPage) => {
        setPage(newPage?.selected + 1)
    };


    const getFormData = () => {
        let formData = new FormData();
        formData.append("hash", hash);
        formData.append("page", page);
        formData.append("sort", sortItem);
        formData.append("order", order ? "asc" : "desc");
        return formData
    }

    const noDataAvailable = !isLoading && !data?.length;

    return (
        <>
            {isLoading ? <Loader /> : (
                <div className="history_lists outer_list">
                    <table className="mobile_hide report_list_width_full">
                        <thead>
                            <tr className="report_list_table_row">
                                <th onClick={() => sortList("companyClass", companyClass, "applying_company")}>
                                    <span>Company</span>
                                    <span className={companyClass} />
                                </th>
                                <th onClick={() => sortList("jobTitleClass", jobTitleClass, "date")}>
                                    <span>Job Title</span>
                                    <span className={jobTitleClass} />
                                </th>
                                <th onClick={() => sortList("employeeIdClass", employeeIdClass, "job_status")}>
                                    <span>Employee ID</span>
                                    <span className={employeeIdClass} />
                                </th>
                                <th>
                                    <span>Notes</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="report_list_table_tbody">
                            {noDataAvailable ? (
                                <tr>
                                    <td colSpan="4">
                                        <div className="dashboard_expiration_date_no_data" style={noDataFoundStyle}>No Data Found</div>
                                    </td>
                                </tr>
                            ) : (
                                data?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{getCompanyName(item?.applying_company) ?? "-"}</td>
                                        <td>{item?.designation ?? "-"}</td>
                                        <td>{item?.nvam_id ?? "-"}</td>
                                        <td>{item?.notes ?? "-"}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    {noDataAvailable ? (
                        <div className="dashboard_expiration_date_no_data for_mobile_history_list">No Data Found</div>
                    ) : (
                        <EmploymentHistoryListMobileView data={data} sortList={sortList} initialStyleStateTypes={initialStyleStateTypes} />
                    )}

                    {!isLoading && data?.length ? (
                        <div className="fs-pagination-wrapper-outer" key={7}>
                            <div className="customised-pagination right-align">
                                <ReactPaginate
                                    forcePage={page}
                                    previousLabel={""}
                                    nextLabel={""}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={lastPage}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={(data) => handlePageChange(data)}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            )}

        </>

    )
}



function EmploymentHistoryListMobileView({ data, initialStyleStateTypes, sortList, setSortParams }) {

    const { companyClass, jobTitleClass, statusClasss } = initialStyleStateTypes


    return data?.map((item, index) => (
        <React.Fragment key={index}>
            <div className="for_mobile_history_list">
                <hr></hr>
                <div className="history_items">
                    <div className="status_item">
                        <div className="list_item" onClick={() => sortList("companyClass", companyClass, "applying_company")}>
                            <strong>Company</strong>
                            <span className={companyClass} />
                        </div>
                        <div className="list_item">{getCompanyName(item?.applying_company) ?? "-"}</div>
                    </div>

                    <div className="status_item">
                        <div className="list_item" onClick={() => sortList("jobTitleClass", jobTitleClass, "date")}>
                            <strong>Job Title</strong>
                            <span className={jobTitleClass} />
                        </div>
                        <div className="list_item">{item?.designation ?? "-"}</div>
                    </div>

                    <div className="status_item">
                        <div className="list_item" onClick={() => sortList("statusClasss", statusClasss, "job_status")}>
                            <strong>Employee ID</strong>
                            <span className={statusClasss} />
                        </div>
                        <div className="list_item">{item?.nvam_id ?? "-"}</div>
                    </div>
                    <div className="status_item">
                        <div className="list_item">
                            <strong>Notes</strong>
                        </div>
                        <div className="list_item">{item?.notes ?? "-"}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    ));
};
