/* eslint-disable eqeqeq */
import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config } from "_helpers/config";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { docsActions } from "_actions/docs.actions";
import { usPhone } from "_utils/formatter";
import { toast } from "react-toastify";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";
import { SignPad } from "Docs/Components";
import moment from "moment";

class AuthorizationForDirectDeposit extends Component {
  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.state = {
      rehire_doc: true,
      doc: {},
      user: {
        mailing_address: {},
        emgcontact1: {},
        emgcontact2: {},
        gender: "Male",
      },
      form: {
        account_1: {
          type: "savings",
          bank_name: "",
          bank_routing_number: "",
          bank_account_number: "",
        },
        account_2: {
          type: "checking",
          bank_name: "",
          bank_routing_number: "",
          bank_account_number: "",
        },
        amount: "full",
        specific_amount: "",
        delivery_options: "",
        printed_name: "",
        employee_signature: "",
        date: moment().format("MM/DD/YYYY"),
        disclaimer_check: false,
        form_edited: false,
      },
      errors: {},
    };

    // Handlers
    this.handleFormChange = this.handleFormChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.navigate = navigate.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.signContainer = React.createRef();
    this.form = React.createRef();
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date){
    _.set(nextState, field, date.format("MM/DD/YYYY"));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: "",
      },
    });
  }

  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState({
      ...nextState,
      errors: {
        ...this.state.errors,
        [field]: "",
      },
    });
  }

  handleFormChange(e) {
    // safety check for datepicker
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { value, checked, type } = e.target;

      if (type == "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();
    if (err) {
      return;
    }

    let { doc, user, form } = this.state;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
      user,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc ? (data.single_doc = true) : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        } else if (this.props.lastPage) {
            if (this.props.adminMode) {
              this.props.history.push(`/docs`);
            } else {
              this.props.history.push(`/dashboard`);
            }
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { user, form } = res.data;

        form = {
          ...this.state.form,
          ...form,
          amount: form.amount ? form.amount : this.state.amount,
          account_1: form.account_1 ? form.account_1 : this.state.form.account_1,
          account_2: form.account_2 ? form.account_2 : this.state.form.account_2,
        };
        // date needs to a moment object or Datepicker breaks.
        form.date = form.date || currentState.form.date;

        if (!form?.printed_name) {
          form.printed_name = user?.full_name ?? ""
        }
        // spread the data coming from API into state
        this.setState({
          user,
          form,
        });
      }
    });
  }

  goBack() {
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen("sidebar");
    }
  }

  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    // if (this.props.rehire) {
    //   doc = this.props.docs.userDocs.find(
    //     (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //   );
    // } else {
    //   doc = this.props.docs.userDocs.find(
    //     (doc) => doc.slug === docSlug && doc.docs_categories_id == 1,
    //   );
    // }
    
    if (this.props.rehire) {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
      );
    } else {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id == 1,
      );
    }
    
    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;
    this.setState({
      doc,
      sign: {
        height: height,
        width: width,
      },
    });
    this.getFormData(doc.id);
  }

  render() {
    // still linked to state after destructuring
    let { user, form, errors } = this.state;
    let { adminMode } = this.props;
    let { classifications, pantSize, shirtSize, race, crewsList, userDocs } = this.props.docs;

    return (
      <div className="doc_info-rh">
        <div className="doc-title">
          <img
            src={`${config.assetUrl}icons/doc-title-img.svg`}
            className="doc-title-img"
          />
          <span className="close-button" onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Authorization For Direct Deposit</h3>
        </div>
        <div className="doc_info-form">
          <form
            className="info-form"
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            name="candidate_info_page"
            noValidate
          >
            <div className="row form-row">
              <p>
                This authorizes North Valley Arbor Management to send credit
                entries and appropriate debit and adjustment entries
                electronically or by any other commercially accepted method to
                my account(s) indicated below. This authorizes the financial
                institution holding the account(s) to post all such entries.
              </p>
            </div>
            {/* Account 1*/}
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    ACCOUNT # 1<span className="required-star">&#x2A;</span> –
                    (Please attach voided check if available)
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">Type (check one)</div>
                  <div className="check_radio_group gender_radio">
                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name={`type_account_1`}
                        value="checking"
                        field={`form.account_1.type`}
                        id={`checking_account_1`}
                        checked={form.account_1.type == "checking"}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor={`checking_account_1`}>
                        <strong>Checking</strong>
                      </label>
                    </div>
                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name={`type_account_1`}
                        value="savings"
                        field={`form.account_1.type`}
                        id={`savings_account_1`}
                        checked={form.account_1.type == "savings"}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor={`savings_account_1`}>
                        <strong>Savings</strong>
                      </label>
                    </div>
                    {/* Same as last year */}
                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name={`type_account_1`}
                        value="same_as_last"
                        field={`form.account_1.type`}
                        id={`same_as_last_account_1`}
                        checked={form.account_1.type == "same_as_last"}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor={`same_as_last_account_1`}>
                        <strong>Same as last year</strong>
                      </label>
                    </div>
                    {errors[`form.account_1.type`] && (
                      <label htmlFor="address" className="error_label">
                        {errors[`form.account_1.type`]}
                      </label>
                    )}
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Bank Name<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${
                        errors[`form.account_1.bank_name`] ? "error" : ""
                      }`}
                      name="bank_name"
                      id="bank_name"
                      value={form.account_1.bank_name}
                      field={`form.account_1.bank_name`}
                      placeholder="Bank Name"
                      required
                    />
                    {errors[`form.account_1.bank_name`] && (
                      <label htmlFor="bank_name" className="error_label">
                        {errors[`form.account_1.bank_name`]}
                      </label>
                    )}
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Bank Routing Number
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={
                        (`${
                          errors[`form.account_1.bank_routing_number`]
                            ? "error"
                            : ""
                        }`,
                        "disabled")
                      }
                      name="bank_routing_number"
                      id="bank_routing_number"
                      value={form.account_1.bank_routing_number}
                      field={`form.account_1.bank_routing_number`}
                      placeholder="Bank Routing Number"
                      disabled
                    />
                    {errors[`form.account_1.bank_routing_number`] && (
                      <label
                        htmlFor="bank_routing_number"
                        className="error_label"
                      >
                        {errors[`form.account_1.bank_routing_number`]}
                      </label>
                    )}
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Bank Account Number
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={
                        (`${
                          errors[`form.account_1.bank_account_number`]
                            ? "error"
                            : ""
                        }`,
                        "disabled")
                      }
                      name="bank_account_number"
                      id="bank_account_number"
                      value={form.account_1.bank_account_number}
                      field={`form.account_1.bank_account_number`}
                      placeholder="Bank Account Number"
                      disabled
                    />
                    {errors[`form.account_1.bank_account_number`] && (
                      <label
                        htmlFor="bank_account_number"
                        className="error_label"
                      >
                        {errors[`form.account_1.bank_account_number`]}
                      </label>
                    )}
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">Amount (check one)</div>
                  <div className="check_radio_group gender_radio amount_check">
                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name="amount"
                        value="full"
                        field="form.amount"
                        id="amount_full"
                        checked={form.amount == "full"}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor="amount_full">
                        <strong>Full Paycheck</strong>
                      </label>
                    </div>
                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name="amount"
                        value="specific"
                        field="form.amount"
                        id="specific"
                        checked={form.amount == "specific"}
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor="specific">
                        <strong>Specified amount</strong>
                      </label>
                    </div>
                    {form.amount == "specific" && (
                      <div className="grouped">
                        <input
                          className={`${
                            errors["form.specific_amount"] ? "error" : ""
                          }`}
                          type="text"
                          name="specific_amount"
                          id="specific_amount"
                          value={form.specific_amount}
                          field="form.specific_amount"
                          placeholder="Amount"
                          required
                        />
                      </div>
                    )}
                    {errors[`form.amount`] && (
                      <label htmlFor="amount_full" className="error_label">
                        {errors[`form.amount`]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Account 2 */}
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    ACCOUNT # 2<span className="required-star">&#x2A;</span> –
                    for Remainder of Paycheck if applicable
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">Type (check one)</div>
                  <div className="check_radio_group gender_radio">
                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name={`type_account_2`}
                        value="checking"
                        field={`form.account_2.type`}
                        id={`checking_account_2`}
                        checked={form.account_2.type == "checking"}
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor={`checking_account_2`}>
                        <strong>Checking</strong>
                      </label>
                    </div>
                    <div className="check_radio_item agreement_radio">
                      <input
                        type="radio"
                        name={`type_account_2`}
                        value="savings"
                        field={`form.account_2.type`}
                        id={`savings_account_2`}
                        checked={form.account_2.type == "savings"}
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor={`savings_account_2`}>
                        <strong>Savings</strong>
                      </label>
                    </div>
                    {errors[`form.account_2.type`] && (
                      <label htmlFor="address" className="error_label">
                        {errors[`form.account_2.type`]}
                      </label>
                    )}
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Bank Name<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${
                        errors[`form.account_2.bank_name`] ? "error" : ""
                      }`}
                      name="bank_name"
                      id="bank_name"
                      value={form.account_2.bank_name}
                      field={`form.account_2.bank_name`}
                      placeholder="Bank Name"
                    />
                    {errors[`form.account_2.bank_name`] && (
                      <label htmlFor="bank_name" className="error_label">
                        {errors[`form.account_2.bank_name`]}
                      </label>
                    )}
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Bank Routing Number
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={
                        (`${
                          errors[`form.account_2.bank_routing_number`]
                            ? "error"
                            : ""
                        }`,
                        "disabled")
                      }
                      name="bank_routing_number"
                      id="bank_routing_number"
                      value={form.account_2.bank_routing_number}
                      field={`form.account_2.bank_routing_number`}
                      placeholder="Bank Routing Number"
                      disabled
                    />
                    {errors[`form.account_2.bank_routing_number`] && (
                      <label
                        htmlFor="bank_routing_number"
                        className="error_label"
                      >
                        {errors[`form.account_2.bank_routing_number`]}
                      </label>
                    )}
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Bank Account Number
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={
                        (`${
                          errors[`form.account_2.bank_account_number`]
                            ? "error"
                            : ""
                        }`,
                        "disabled")
                      }
                      name="bank_account_number"
                      id="bank_account_number"
                      value={form.account_2.bank_account_number}
                      field={`form.account_2.bank_account_number`}
                      placeholder="Bank Account Number"
                      disabled
                    />
                    {errors[`form.account_2.bank_account_number`] && (
                      <label
                        htmlFor="bank_account_number"
                        className="error_label"
                      >
                        {errors[`form.account_2.bank_account_number`]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Deliver Options */}
            {/* <div className='row form-row delivery_opt'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>Select Delivery Option</div>
                  <div className='check_radio_group gender_radio del_opt_radio'>
                    <div className='check_radio_item agreement_radio'>
                      <input
                        type='radio'
                        name='delivery_options'
                        value='online'
                        field='form.delivery_options'
                        id='online'
                        checked={form.delivery_options == "online"}
                        required
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='online'>
                        <strong>Online</strong>{" "}
                        <i>
                          View paystub details on ViewMyPaycheck at{" "}
                          <a href='http://paychecks.intuit.com'>http://paychecks.intuit.com</a>{" "}
                          (timesheets are not included)
                        </i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group gender_radio'>
                    <div className='check_radio_item agreement_radio'>
                      <input
                        type='radio'
                        name='delivery_options'
                        value='chico'
                        field='form.delivery_options'
                        id='chico'
                        checked={form.delivery_options == "chico"}
                        required
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='chico'>
                        <strong>Hold in Chico</strong> (available for pick up after 9 AM on payday)
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group gender_radio'>
                    <div className='check_radio_item agreement_radio'>
                      <input
                        type='radio'
                        name='delivery_options'
                        value='mail_to_address'
                        field='form.delivery_options'
                        id='mail_to_address'
                        checked={form.delivery_options == "mail_to_address"}
                        required
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='mail_to_address'>
                        <strong>Mail to address on file</strong> (Postage fee applies)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {errors["form.delivery_optionsr"] && (
                <label htmlFor='online' className='error_label'>
                  {errors["form.delivery_options"]}
                </label>
              )}
            </div> */}

            <div className="row form-row">
              <div className="col">
                <p className="mb-0">
                  View paystub details on ViewMyPaycheck at{" "}
                  <a href="http://paychecks.intuit.com" target="_blank">
                    http://paychecks.intuit.com
                  </a>
                </p>
              </div>

              <div className="col">
                <p className="mb-0">
                  <i>
                    <strong>
                      *Please attach direct deposit authorization form from the
                      bank or a voided check to verify account & routing
                      numbers.
                    </strong>
                    If banking verification is not attached to this form, the
                    numbers will be entered as they appear. If the numbers are
                    incorrect or read incorrectly and the direct deposit does
                    not go through, the paycheck cannot be re‐issued until the
                    bank refunds the money. This may take up to 2 weeks. It is
                    highly recommended that banking verification is attached
                  </i>
                </p>
              </div>

              <div className="col">
                <div className="inputs-col">
                  {/*single input */}
                  <div className="inputs-head">
                    Printed Name<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${
                        errors["form.printed_name"] ? "error" : ""
                      }`}
                      name="printed_name"
                      id="printed_name"
                      value={form.printed_name}
                      field="form.printed_name"
                      placeholder="- Enter here -"
                      required
                    />
                    {errors["form.printed_name"] && (
                      <label htmlFor="printed_name" className="error_label">
                        {errors["form.printed_name"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Date<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors["form.date"] ? "error" : ""} w-100`}
                      name="date"
                      id="date"
                      selected={moment(form.date)}
                      field="form.date"
                      placeholder="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(this, "form.date")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                      required
                    />
                    {errors["form.full_name"] && (
                      <label htmlFor="full_name" className="error_label">
                        {errors["form.full_name"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="inputs-col w-50">
                  <div className="inputs-head">
                    Employee Signature
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className="sign_here"
                    >
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              "form.employee_signature"
                            )}
                            signature={form.employee_signature}
                          />
                          <input
                            type="hidden"
                            value={form.employee_signature}
                            name="employee_signature"
                            field="form.employee_signature"
                            required
                          ></input>
                          {errors["form.employee_signature"] && (
                            <label
                              htmlFor="employee_signature"
                              className="error_label"
                            >
                              {errors["form.employee_signature"]}
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row form-row">
              <div className="col">
                <div className="disclaimers">
                  <strong className="diss">Disclaimer: </strong>You must read
                  and carefully understand the documents that you are filling
                  out and signing. These are legally binding documents and must
                  be accurate. By filling these forms out, you are agreeing to
                  our{" "}
                  <a href="">
                    Terms of Use, Privacy Policy and Employee Agreements.
                  </a>
                </div>
              </div>
            </div>
            <div className="row form-row last">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group check_disclaimer">
                    <div className="check_radio_item">
                      <input
                        type="checkbox"
                        name="disclaimer_check"
                        id="disclaimer_check"
                        field="form.disclaimer_check"
                        checked={form.disclaimer_check}
                        required
                      />
                      <span className="checkbox_checkbox"></span>

                      <label htmlFor="disclaimer_check">
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormNav
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.disclaimer_check}
              mobileMode={this.props.mobileMode}
            />
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

let connectedAuthorizationForDirectDeposit = compose(
  withRouter,
  connect(mapStateToProps),
)(AuthorizationForDirectDeposit);
export { connectedAuthorizationForDirectDeposit as AuthorizationForDirectDeposit };
