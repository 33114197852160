import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { authHeader, config } from "../_helpers";
import { applicantsActions, permissionActions } from "../_actions";
import "../assets/styles/applicants.css";
import ReactModal from "react-modal";
import ReactPaginate from "react-paginate";
import { getApplicantStatus, getCompanyName } from "_utils/utils";
import { toast } from "react-toastify";
import NoInterviewFormAlert from "_components/NoInterviewFormAlert/NoInterviewFormAlert";
import ApplicantHistory from "./ApplicantHistory";


ReactModal.setAppElement("#app");

// To format the phone number
function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
}

const getAppliedCompany={
  1:"About Trees Applicant",
  2:"NVTS Applicant"
}

const applicantStatuses = {
  "NOT_SELECTED_AND_ARCHIVED": "remove",
  "RECEIVED": "received",
  "WAS_HIRED": "hire",
  "IN_REVIEW": "inreview",
  "OFFERED_POSITION": "offered",
  "DO_NOT_SELECT_AND_ARCHIVE": "donthire",
}

class ApplicantList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusUpdating:false,
      statusOpen: false,
      flag: true,
      applicationItem: [],
      removeApplicant: "",
      removeAction: "",
      selected: "",
      _page: 0,
      searchStatus: false,
      clearFilter: false,
      applicant_info: [],
      resultData: [],
      filterResult: false,
      order: true,
      sort: "date",
      nameOrder: false,
      emailOrder: false,
      phoneOrder: false,
      dateOrder: true,
      areaOrder: false,
      certOrder: false,
      orderAll: "desc",
      loader: "",
      keySearch: "",
      validMsg: "",
      borderError: "",
      filterOption: "",
      companyType: "",
      currentStatusUpdate: null,
      paginationLoading: false,
      results_per_page: localStorage.getItem("results_per_page")
        ? parseInt(localStorage.getItem("results_per_page"))
        : 30
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.viewDetails = this.viewDetails.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.chooseAction = this.chooseAction.bind(this);
    this.validate = this.validate.bind(this);
    this.removeApplicant = this.removeApplicant.bind(this);
    this.archived = this.archived.bind(this);
    this.hired = this.hired.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.keyUpFn = this.keyUpFn.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.emailFilter = this.emailFilter.bind(this);
    this.filter = this.filter.bind(this);
    this.sorting = this.sorting.bind(this);
  }

  componentDidMount() {
    this.componentWillReceiveProps(this.props);
    // Applicants with filter
    if (this.props.param !== "" && this.props.from === "filter") {
      var startDate, endDate;
      if (this.props.param.start_date === undefined) {
        startDate = "";
      } else {
        startDate = this.props.param.start_date;
      }
      if (this.props.param.end_date === undefined) {
        endDate = "";
      } else {
        endDate = this.props.param.end_date;
      }
      this.setState({
        startDate,
        endDate,
        variable1: this.props.param.var1,
        variable2: this.props.param.var2
      });
      let data = new FormData();
      data.append("startDate", startDate);
      data.append("endDate", endDate);
      data.append("variable1", this.props.param.var1);
      data.append("variable2", this.props.param.var2);
      this.props.dispatch(applicantsActions.filterApplicants(data));
    }
    // All applicants
    else {
      this.props.dispatch(applicantsActions.getAll(this.state.results_per_page));
    }
    this.props.dispatch(permissionActions.getPermission());
  }

  componentWillReceiveProps(nextProps) {
    window.addEventListener("scroll", this.handleScroll);
    const { applicants } = nextProps;

    // Fetching Applicant list
    if (applicants.items) {
      let dataResult;
      var appInf = [];
      // for filter results
      if (
        this.props.param !== "" &&
        this.props.from === "filter" &&
        this.state.clearFilter === false
      ) {
        dataResult = applicants.items.data;
      } else {
        dataResult = applicants.items.data.data;
      }
      if (dataResult && dataResult.length) {
        if (dataResult.length > 0) {
          dataResult.map((item, index) => {
            appInf[item.hash] = item.applicant_info;
            this.setState(state => ({
              applicant_info: { ...state.applicant_info, ...appInf }
            }));
          });
        }
      } else {
        applicants.items.data = [];
      }
      this.setState({
        resultData: Array.isArray(applicants.items.data)
          ? { data: applicants.items.data }
          : applicants.items.data
      });
      if (
        Array.isArray(applicants.items.data) &&
        applicants.items.data.length
      ) {
        // Filters
        this.setState({
          filterResult: true
        });
      } else {
        this.setState({
          filterResult: false
        });
      }
    }
  }

  // Default image function
  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  // Updating applicant notes
  updateNotes(hash, e) {
    const { permission } = this.props;
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }
    if (permList.includes("Update Applicant Note")) {
      var appInf = [];
      appInf[hash] = e.target.value;
      this.setState(state => ({
        applicant_info: { ...state.applicant_info, ...appInf }
      }));
      let data = new FormData();
      data.append("hash", hash);
      data.append("note", e.target.value);
      clearTimeout(this.state.timeout);
      this.state.timeout = setTimeout(
        function() {
          this.props.dispatch(applicantsActions.updateNote(data));
        }.bind(this),
        1500
      );
    }
  }

  // Opens model for removing applicant
  statusOpenModal(hash, status, selectedApplicantNoInterviewForms) {
    this.setState({
      statusOpen: true,
      removeApplicant: hash,
      currentStatusUpdate: status > -1 ? status : "",
      selectedApplicantNoInterviewForms
    });
  }

  // Close the popup
  statusCloseModal() {
    this.setState({ statusOpen: false, statusUpdating: false, selectedApplicantNoInterviewForms: false });
  }

  // Selecting remove option
  chooseAction(action) {
    var status = {
      denied: 0,
      received: 1,
      hire: 2,
      inreview: 3,
      offered: 4,
      donthire: 10,
      remove: 0
    };
    this.setState({
      removeAction: action,
      selected: action,
      currentStatusUpdate: status[action],
      validMsg: "",
      borderError: ""
    });
  }

  // set active class for selected option
  isActive(value) {
    var status = {
      0: "remove",
      1: "received",
      2: "hire",
      3: "inreview",
      4: "offered",
      10: "donthire"
    };
    if (
      this.state.currentStatusUpdate > -1 &&
      status.hasOwnProperty(this.state.currentStatusUpdate)
    ) {
      if (status[this.state.currentStatusUpdate] === value) {
        return "active";
      }
    }
    return "";
    // return value === this.state.selected ? "active" : "default";
  }

  // To Archieved applicants page
  archived() {
    this.props.archived();
  }

  // To Hired applicants page
  hired() {
    this.props.hired();
  }

  // To detail page of applicant
  viewDetails(hash) {
    this.props.viewDetails(hash);
  }

  // To email applicant page
  emailFilter() {
    this.props.emailFilter();
  }

  // To filter page
  filter() {
    this.props.filter();
  }

  sorting(sort, e) {
    this.setState({
      order: !this.state.order,
      sort,
      loader: "",
      applicationItem: []
    });
    if (sort === "name") {
      this.setState({
        nameOrder: !this.state.nameOrder,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: false,
        certOrder: false,
        dateOrder: false,
        jobStatusOrder:false,
        companyOrder:false
      });
    }
    if(sort==="applying_company"){
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: false,
        certOrder: false,
        dateOrder: false,
        jobStatusOrder:false,
        companyOrder:!this.state.companyOrder
      });
    }
    if (sort === "email") {
      this.setState({
        nameOrder: false,
        emailOrder: !this.state.emailOrder,
        phoneOrder: false,
        areaOrder: false,
        certOrder: false,
        dateOrder: false,
        jobStatusOrder:false,
        companyOrder:false
      });
    }
    if (sort === "phone") {
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: !this.state.phoneOrder,
        areaOrder: false,
        certOrder: false,
        dateOrder: false,
        jobStatusOrder:false,
        companyOrder:false
      });
    }
    if (sort === "area") {
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: !this.state.areaOrder,
        certOrder: false,
        dateOrder: false,
        jobStatusOrder:false,
        companyOrder:false
      });
    }
    if (sort === "cert") {
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: false,
        certOrder: !this.state.certOrder,
        dateOrder: false,
        jobStatusOrder:false,
        companyOrder:false
      });
    }
    if (sort === "date") {
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: false,
        certOrder: false,
        companyOrder:false,
        jobStatusOrder:false,
        dateOrder: !this.state.dateOrder
      });
    }
    if (sort === "job_status") {
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: false,
        certOrder: false,
        companyOrder:false,
        dateOrder: false,
        jobStatusOrder: !this.state.jobStatusOrder
      });
    }
    var order;
    if (this.state.order) {
      order = "asc";
    } else {
      order = "desc";
    }
    this.setState({ orderAll: order });
    if (this.props.param !== "" && this.props.from === "filter") {
      let data = new FormData();
      data.append("startDate", this.state.startDate);
      data.append("endDate", this.state.endDate);
      data.append("variable1", this.state.variable1);
      data.append("variable2", this.state.variable2);
      this.props.dispatch(
        applicantsActions.sortFilterApplicant(data, sort, order)
      );
    } else {
      let data = new FormData();
      if (this.state.keySearch) {
        data.append("keyword", this.state.keySearch);
      }
      data.append("sort", sort);
      data.append("order", order);
      data.append("type", this?.state?.filterOption);
      data.append("company", this?.state?.companyType);
      data.append("results_per_page", this.state.results_per_page);
      this.props.dispatch(applicantsActions.searchApplicants(data));
    }
  }

  validate() {
    var err = false;
    if (this.state.removeAction === "") {
      return true;
    }
  }

  removeApplicant(interviewFormCount) {
    debugger;
    if((this.state.removeAction === applicantStatuses.OFFERED_POSITION ||
        this.state.removeAction === applicantStatuses.WAS_HIRED) &&
        interviewFormCount
    ){
      return toast.error(" The applicant hasn't filled out the interview form.")
    }

    var err = this.validate();
    if (!err) {
      this.setState({ statusUpdating: true })
      let data = new FormData();
      data.append("hash", this.state.removeApplicant);
      data.append("action", this.state.removeAction);
      this.props.dispatch(applicantsActions.removeApplicant(data))?.then(res => {
        if (res?.success) {
          this.statusCloseModal();
        }
        else {
          this.updateApplicantStatusErrorToast(res?.message)
        }
      })?.catch(err => {
        this.updateApplicantStatusErrorToast(err?.message)
      })
    } else {
      this.setState({
        borderError: "borderError",
        validMsg: "Please choose an option!"
      });
    }
  }

  updateApplicantStatusErrorToast(message) {
    toast?.error(message);
    this.setState({ statusUpdating: false })
  }




  // Input search box typing
  keyUpFn(event) {
    var value = event.target.value;
    clearTimeout(this.state.timeout);
    this.setState({ keySearch: value });
    this.state.timeout = setTimeout(
      function() {
        this.handleSearch(value);
      }.bind(this),
      1000
    );
  }

  handleSearch(text) {
    this.setState({
      searchStatus: true,
      applicationItem: []
    });
    let { filterOption } = this.state;
    let data = new FormData();
    data.append("type", filterOption);
    data.append("keyword", text || this?.state?.keySearch);
    data.append("company", this?.state?.companyType);
    data.append("results_per_page", this.state.results_per_page);
    // data.append("status", text);
    if (
      this.props.param !== "" &&
      this.props.from === "filter" &&
      text !== ""
    ) {
      data.append("startDate", this.props.param.start_date);
      data.append("endDate", this.props.param.end_date);
      data.append("variable1", this.props.param.var1);
      data.append("variable2", this.props.param.var2);
      this.props.dispatch(applicantsActions.filterApplicants(data));
    } else {
      data.append("sort", this.state.sort);
      data.append("order", this.state.orderAll);
      this.props.dispatch(applicantsActions.searchApplicants(data));
      this.setState({ clearFilter: true });
    }
  }

  sendMail(email) {
    this.props.sendMail(email);
  }

  handleScroll(event) {
    return;
    var method;
    var wrap = document.getElementById("right_sidebar"),
      contentHeight = wrap.offsetHeight,
      yOffset = window.pageYOffset,
      y = yOffset + window.innerHeight,
      applicationItem = [],
      sortVariable = "",
      order;
    // Sortable field
    if (this.state.sort !== "") {
      sortVariable =
        "&sort=" + this.state.sort + "&order=" + this.state.orderAll;
    }
    let { filterOption,companyType } = this.state;
    // data.append("type", filterOption);
    if (filterOption) {
      sortVariable = `${sortVariable}&type=${filterOption}`;
    }
    sortVariable = `${sortVariable}&company=${companyType}`;
    if (y >= contentHeight) {
      let data = this.state.resultData;

      const { permission } = this.props;
      var permList = [];
      if (permission.permissions) {
        permList = permission.permissions.data;
      }

      if (data.current_page < data.last_page && this.state.flag !== false) {
        var requestOptions = {};
        if (data.next_page_url.indexOf("search") !== -1) {
          method = "POST";
          let bdata = new FormData();
          bdata.append("keyword", this.state.keySearch);
          requestOptions = {
            method: method,
            headers: authHeader(),
            body: bdata
          };
        } else {
          method = "GET";
          requestOptions = {
            method: method,
            headers: authHeader()
          };
        }

        fetch(data.next_page_url + sortVariable, requestOptions)
          .then(res => {
            return res.json();
          })
          .then(data => {
            var appInf = [];
            data.data.data.map((item, index) => {
              appInf[item.hash] = item.applicant_info;
              this.setState(state => ({
                applicant_info: { ...state.applicant_info, ...appInf }
              }));
            });
            this.setState({ resultData: data.data, applicant_info: appInf });
            // this.setState({
            //   applicationItem: [
            //     ...this.state.applicationItem,
            //     ...applicationItem
            //   ],
            // });
          })
          .catch(err => console.log(err));
      }
    }
  }
  handlePageChange(page) {
    if (!page || this.state.filterResult) return;
    var current = page.selected + 1;
    var method,
      sortVariable = "";
    if (this.state.sort !== "") {
      sortVariable =
        "&sort=" + this.state.sort + "&order=" + this.state.orderAll;
    }
    let { filterOption, companyType } = this.state;
    if (filterOption) {
      sortVariable = `${sortVariable}&type=${filterOption}`;
    }
    sortVariable = `${sortVariable}&company=${companyType}`;
    let data = this.state.resultData;

    const { permission } = this.props;
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }

    var requestOptions = {};
    if (
      (data.next_page_url && data.next_page_url.indexOf("search") !== -1) ||
      (data.last_page_url && data.last_page_url.indexOf("search") !== -1)
    ) {
      method = "POST";
      let bdata = new FormData();
      bdata.append("keyword", this.state.keySearch);
      requestOptions = {
        method: method,
        headers: authHeader(),
        body: bdata
      };
    } else {
      method = "GET";
      requestOptions = {
        method: method,
        headers: authHeader()
      };
    }
    
    var _url = (data.next_page_url || data.last_page_url) && (data.next_page_url || data.last_page_url).replace(
      /(page=)[0-9]{1,}/,
      "page=" + current
    );
    this.setState({
      paginationLoading: true,
      _page: page.selected
    });
    if (_url) {
      fetch(
        _url + "&results_per_page=" + this.state.results_per_page + sortVariable,
        requestOptions)
        .then(res => {
          return res.json();
        })
        .then(data => {
          var appInf = {};
          if (data && data.data && data.data.data.length > 0) {
            var dataResult = data.data.data || [];
            dataResult.map((item, index) => {
              appInf[item.hash] =
                item.applicant_info && item.applicant_info !== "null"
                  ? item.applicant_info
                  : "";
              // this.setState(state => ({
              //   applicant_info: appInf
              // }));
            });
        }
          this.setState(
            {
              applicant_info: appInf
            },
            () => {
              this.setState({ resultData: data.data, paginationLoading: false });
            }
          );
        })
        .catch(err => {
          this.setState({
            paginationLoading: false
          });
        });
    }
  }
  render() {
    const { applicants, permission } = this.props;

    const reslts_per = [5, 10, 15, 20, 30, 50, 100];
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }
    var applicationItem = [];
    const style = {
      content: {
        width: "40%"
      }
    };

    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }
    if (applicants.items) {
      let dataResult = [];
      if (
        this.props.param !== "" &&
        this.props.from === "filter" &&
        this.state.clearFilter === false
      ) {
        dataResult = applicants.items.data;
      } else {
        if (applicants.items.data.length === 0) {
          dataResult = applicants.items.data;
        } else {
          dataResult = applicants.items.data.data;
        }
      }
      var nameClass, emailClass, phoneClass, dateClass,companyClass, areaClass, certClass,jobStatusClass;
      if (this.state.sort === "name") {
        if (this.state.nameOrder && this.state.sort === "name") {
          nameClass = "sort-arrow desc";
        } else {
          nameClass = "sort-arrow asc";
        }
        emailClass = phoneClass = dateClass = areaClass = certClass = companyClass = jobStatusClass =
          "sort-arrow";
      }
      if (this.state.sort === "applying_company") {
        if (this.state.companyOrder && this.state.sort === "applying_company") {
          companyClass = "sort-arrow desc";
        } else {
          companyClass = "sort-arrow asc";
        }
        emailClass = phoneClass = dateClass = areaClass = certClass = jobStatusClass =
          "sort-arrow";
      }
      if (this.state.sort === "email") {
        if (this.state.emailOrder && this.state.sort === "email") {
          emailClass = "sort-arrow desc";
        } else {
          emailClass = "sort-arrow asc";
        }
        nameClass = phoneClass = dateClass = areaClass = certClass = companyClass = jobStatusClass =
          "sort-arrow";
      }
      if (this.state.sort === "phone") {
        if (this.state.phoneOrder && this.state.sort === "phone") {
          phoneClass = "sort-arrow desc";
        } else {
          phoneClass = "sort-arrow asc";
        }
        nameClass = emailClass = dateClass = areaClass = certClass = companyClass = jobStatusClass =
          "sort-arrow";
      }
      if (this.state.sort === "date") {
        if (this.state.dateOrder && this.state.sort === "date") {
          dateClass = "sort-arrow desc";
        } else {
          dateClass = "sort-arrow asc";
        }
        nameClass = emailClass = areaClass = phoneClass = certClass = companyClass = jobStatusClass =
          "sort-arrow";
      }
      if (this.state.sort === "job_status") {
        if (this.state.jobStatusOrder && this.state.sort === "job_status") {
          jobStatusClass = "sort-arrow desc";
        } else {
          jobStatusClass = "sort-arrow asc";
        }
        nameClass = emailClass = areaClass = phoneClass = certClass = companyClass =
          "sort-arrow";
      }
      if (this.state.sort === "area") {
        if (this.state.areaOrder && this.state.sort === "area") {
          areaClass = "sort-arrow desc";
        } else {
          areaClass = "sort-arrow asc";
        }
        nameClass = emailClass = dateClass = phoneClass = certClass = companyClass = jobStatusClass =
          "sort-arrow";
      }
      if (this.state.sort === "cert") {
        if (this.state.certOrder && this.state.sort === "cert") {
          certClass = "sort-arrow desc";
        } else {
          certClass = "sort-arrow asc";
        }
        nameClass = emailClass = dateClass = phoneClass = areaClass = companyClass = jobStatusClass =
          "sort-arrow";
      }
      
      if (dataResult) {
        return (
          <div className="applicants_container applicant_listing">
            <div className="page_title">
              <h1>Applicants</h1>
              <div className="top_btn_group flex-group">
                {/* {permList.includes("Manage Applicants") &&
                  permList.includes("Add New Applicant") && (
                    <Link to={"/application"} className="new_applicants button">
                      Add New Applicant
                    </Link>
                  )} */}

                {permList.includes("Manage Applicants") &&
                  permList.includes("Email Applicants") && (
                    <button
                      className="button"
                      onClick={this.emailFilter.bind(this)}
                    >
                      Email Applicants
                    </button>
                  )}

                <button className="button" onClick={this.filter.bind(this)}>
                  Filter
                </button>

                {permList.includes("Manage Applicants") &&
                  permList.includes("View Hired Applicants") && (
                    <button
                      className="button"
                      onClick={this.archived.bind(this)}
                    >
                      Archived Applicants
                    </button>
                  )}
              </div>
            </div>

            <div
              className={
                "applicants_inner_head inner_head inner_head_mob " +
                (this.state.filterResult ? "hide-filters" : "")
              }
            >
              <div className="col-xs-6 col-sm-6 col-md-4">
                <select
                  name="filters"
                  className="applicant_filter"
                  value={this.state.filterOption}
                  onChange={event => {
                    this.setState(
                      {
                        filterOption: event.target.value
                      },
                      () => {
                        this.handleSearch("");
                      }
                    );
                  }}
                >
                  <option value="">All</option>
                  <option value="received">Received</option>
                  <option value="inreview">In Review</option>
                  <option value="offered">Offered Position</option>
                </select>
              </div>

              <div className="col-xs-6 col-sm-6 col-md-4">
                <select
                  name="companyType"
                  className="applicant_filter"
                  value={this.state.companyType}
                  onChange={event => {
                    this.setState(
                      {
                        companyType: event.target.value
                      },
                      () => {
                        this.handleSearch("");
                      }
                    );
                  }}
                >
                  <option value={""}>-- Choose Company --</option>
                  <option value={0}>All</option>
                  <option value={1}>{getCompanyName(1)}</option>
                  <option value={2}>{getCompanyName(2)}</option>
                </select>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-4">
                <div className="right_inputs_wrapper">
                  <div className="results_per_page_wrapper">
                    Results per page
                    <select
                      value={this.state.results_per_page}
                      onChange={$event => {
                        var value = $event.target.value;
                        this.setState(
                          {
                            results_per_page: value,
                            _page: 0
                          },
                          () => {
                            this.handlePageChange.call(this, {
                              selected: this.state._page
                            });
                          }
                        );

                        localStorage.setItem("results_per_page", value);
                      }}
                    >
                      {reslts_per &&
                        reslts_per.map((item, index) => {
                          return (
                            <option
                              //selected={item === this.state.results_per_page}
                              key={"results_per_page" + index}
                              value={item}
                            >
                              {item}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <div className="applicant_search_wrapper">
                    <input
                      type="text"
                      className="applicant_search"
                      value={this.state.keySearch}
                      name="keyword"
                      placeholder="Search..."
                      onChange={this.keyUpFn.bind(this)}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="inner_container col-lg-12 applicants_list_page"
              id="applicants_inner"
            >
              <div
                className="applicants_list"
                id="applicants_list"
                onScroll={this.handleScroll}
              >
                <div className="appln_list_header">
                  <div
                    className="list_item_head appln_name"
                    onClick={this.sorting.bind(this, "name")}
                  >
                    Name
                    <span className={nameClass} />
                  </div>
                  <div
                    className="list_item_head appln_company"
                    onClick={this.sorting.bind(this, "applying_company")}
                  >
                    Company
                    <span className={companyClass} />
                  </div>
                  <div
                    className="list_item_head appln_email"
                    onClick={this.sorting.bind(this, "email")}
                  >
                    Email
                    <span className={emailClass} />
                  </div>
                  <div
                    className="list_item_head appln_phone"
                    onClick={this.sorting.bind(this, "phone")}
                  >
                    Phone#
                    <span className={phoneClass} />
                  </div>
                  <div
                    className="list_item_head appln_date"
                    onClick={this.sorting.bind(this, "date")}
                  >
                    Date
                    <span className={dateClass} />
                  </div>
                  <div
                    className="list_item_head appln_status"
                    onClick={this.sorting.bind(this, "job_status")}
                  >
                    Status
                    <span className={jobStatusClass} />
                  </div>
                  {/* <div  //Removed for NVAM clone
                    className="list_item_head appln_area"
                    onClick={this.sorting.bind(this, "area")}
                  >
                    Area
                    <span className={areaClass} />
                  </div> */}
                  {/* <div
                    className="list_item_head"  
                    onClick={this.sorting.bind(this, "cert")}
                  >
                    Cert. Status
                    <span className={certClass} />
                  </div> */}
                </div>
                <div className="items-wrapper">
                  {this.state.paginationLoading && (
                    <div className="items-overlay" />
                  )}
                  {this.state.resultData &&
                    this.state.resultData.data &&
                    this.state.resultData.data.map((item, index) => {
                      return (
                        <div
                          className="applicant_item"
                          key={index + 25}
                          data-test="df"
                        >
                          {item.status === 3 && (
                            <span className="aplication-status-indicator">
                              <img
                                src={require("../assets/images/review.png").default}
                                className="in-review-ico"
                              />
                            </span>
                          )}
                          {item.status === 1 && (
                            <span className="aplication-status-indicator">
                              <img
                                src={require("../assets/images/tick_round.png").default}
                              />
                            </span>
                          )}
                          {item.status === 4 && (
                            <span className="offer-posted aplication-status-indicator">
                              <img
                                src={require("../assets/images/offeredposition.svg").default}
                                alt="Offered Position"
                              />
                            </span>
                          )}

                          <div className="basic_info">
                            <div className="appln_name">
                              {item.first_name + " " + item.last_name} 
                              {item.reapplied_status ==1 && item.status == 1 && <span className="badge">NEW</span>}
                            </div>
                            <div className="appln_company">
                              {getAppliedCompany[item.applying_company]}
                            </div>
                            <div className="appln_email">
                              {item.email_address ? (
                                <a
                                  onClick={event => {
                                    event.stopPropagation();
                                  }}
                                  className="phone_email_link"
                                  href={`mailto:${item.email_address}`}
                                  target="_top"
                                >
                                  {item.email_address}
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                       
                            <div className="appln_phone">
                              {item.phone_number ? (
                                <a
                                  onClick={event => {
                                    event.stopPropagation();
                                  }}
                                  className="phone_email_link"
                                  href={`tel:${item.phone_number}`}
                                  target="_top"
                                >
                                  {formatPhoneNumber(item.phone_number)}
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="appln_date">{item.date}</div>
                            <div className="appln_status">{getApplicantStatus[item.status]}</div>
                            {/* <div className="appln_area">{item.area}</div> */}
                            {/* <div className="appln_cert_status"> // Removed for NVAM clone
                              {item.cert_status}
                            </div> */}
                          </div>
                          <div className="actions">
                            {!item?.interview_form_count ? (
                              <div className="applicant_no_interview_forms_icon">
                                <img src={`${config.assetUrl}icons/interview_form_not_filled.svg`} alt="Interview form not filled" />
                                <span className="applicant_no_interview_forms_tooltip">
                                  No interview forms are available.
                                </span>
                              </div>
                            ) : null}
                            <textarea
                              className="alb-info left"
                              id="single_note"
                              contentEditable=""
                              placeholder="Notes..."
                              data-hash={item.hash}
                              autoComplete="off"
                              onChange={this.updateNotes.bind(this, item.hash)}
                              // defaultValue={
                              //   this.state.applicant_info[item.hash] || ''
                              // }
                              value={this.state.applicant_info[item.hash] || ''}
                            />
                            <div className="applicants_actions_wrapper">
                              {permList.includes("Manage Applicants") &&
                                permList.includes("Email Applicants") && (
                                  <img
                                    alt="mail"
                                    src={require("../assets/images/mail.png").default}
                                    className="email"
                                    onClick={this.sendMail.bind(
                                      this,
                                      item.email_address
                                    )}
                                  />
                                )}
                              {permList.includes("View User Profile") && (
                                <Link
                                  to={"/applicant-view/" + item.hash}
                                  target="_blank"
                                >
                                  <img
                                    alt="view"
                                    src={require("../assets/images/view.png").default}
                                    className="view"
                                  />
                                </Link>
                              )}
                              <img
                                alt="Update Applicant"
                                src={require("../assets/images/update_applicant.png").default}
                                className="delete"
                                onClick={this.statusOpenModal.bind(
                                  this,
                                  item.hash,
                                  item.status,
                                  !item?.interview_form_count
                                )}
                              />
                            </div>
                          </div>
                          {item?.other_application?.length ? (
                            item?.other_application?.map(otherApp => <ApplicantHistory hash={otherApp?.hash} date={otherApp?.date} companyId={otherApp?.applying_company} />)
                          ) : null}
                        </div>
                      );
                    })}

                  {!this.state.filterResult &&
                    this.state.resultData.data &&
                    this.state.resultData.data.length > 0 && (
                      <div className="customised-pagination">
                        <ReactPaginate
                          forcePage={this?.state?._page}
                          previousLabel={""}
                          nextLabel={""}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.resultData.last_page}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageChange.bind(this)}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    )}
                  {(!this.state.resultData.data ||
                    !this.state.resultData.data.length) && (
                    <div key="noResult" className="applicant_item light-box">
                      <div className="basic_info">
                        <p className="noResult">No search results were found</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <ReactModal
              isOpen={this.state.statusOpen}
              onClose={this.statusCloseModal.bind(this)}
              contentLabel="Minimal Modal Example"
              overlayClassName="RemoveApplntPopup"
            >
              <div className="modal-head">
                <h4>Update Applicant Status</h4>
                <img
                  alt="close"
                  className="closeModal"
                  onClick={() => this?.state?.statusUpdating ? {} : this.statusCloseModal()}
                  src={require("../assets/images/close_green.png").default}
                />
              </div>
              <div id="remove_applnt" className="modal-body">
              {this?.state?.selectedApplicantNoInterviewForms ? <NoInterviewFormAlert /> : null}

                <div className="rmOptionBox float">
                  <div
                    className={
                      this.isActive("received") +
                      " rmOptionWrap col-lg-333 " +
                      this.state.borderError
                    }
                    onClick={this.chooseAction.bind(this, "received")}
                  >
                    <div className="rmOption">Received</div>
                  </div>

                  <div
                    className={
                      this.isActive("inreview") +
                      " rmOptionWrap col-lg-333 " +
                      this.state.borderError
                    }
                    onClick={this.chooseAction.bind(this, "inreview")}
                  >
                    <div className="rmOption">In Review</div>
                  </div>

                  <div
                    className={
                      this.isActive("offered") +
                      " rmOptionWrap col-lg-333 " +
                      this.state.borderError
                    }
                    onClick={this.chooseAction.bind(this, "offered")}
                  >
                    <div className="rmOption">Offered Position</div>
                  </div>
                  
                  <div
                    className={
                      this.isActive("hire") +
                      " rmOptionWrap col-lg-333 " +
                      this.state.borderError
                    }
                    onClick={this.chooseAction.bind(this, "hire")}
                  >
                    <div className="rmOption">Was Hired</div>
                  </div>

                  <div
                    className={
                      this.isActive("remove") +
                      " rmOptionWrap col-lg-333 " +
                      this.state.borderError
                    }
                    onClick={this.chooseAction.bind(this, "remove")}
                  >
                    <div className="rmOption">Not Selected & Archive</div>
                  </div>
                  <div
                    className={
                      this.isActive("donthire") +
                      " rmOptionWrap col-lg-333 " +
                      this.state.borderError
                    }
                    onClick={this.chooseAction.bind(this, "donthire")}
                  >
                    <div className="rmOption">Do Not Hire & Archive</div>
                  </div>
                </div>
                {/* <p className="sig_message">
                  "Send Email" will automatically inform the applicant of
                  denial.
                </p> */}
                <button
                  disabled={this?.state?.statusUpdating}
                  className="button grey"
                  onClick={this.statusCloseModal.bind(this)}
                >
                  Cancel
                </button>
                <button
                  disabled={this?.state?.statusUpdating}
                  className="button"
                  onClick={this.removeApplicant.bind(this, this?.state?.selectedApplicantNoInterviewForms)}
                >
                  Update
                </button>
              </div>
            </ReactModal>
          </div>
        );
      }
    }

    // Showing loader
    return (
      <div className="applicants_container applicant_listing">
        <div className="page_title">
          <h1>Applicants</h1>
          <div className="top_btn_group flex-group">
            {/* {permList.includes("Manage Applicants") &&
              permList.includes("View Hired Applicants") && (
                <button className="button" onClick={this.hired.bind(this)}>
                  Hired/Saved Applicants
                </button>
              )} */}

            {/* {permList.includes("Manage Applicants") &&
              permList.includes("Add New Applicant") && (
                <Link to={"/application"} className="new_applicants button">
                  Add New Applicant
                </Link>
              )} */}

            {permList.includes("Manage Applicants") &&
              permList.includes("Email Applicants") && (
                <button
                  className="button"
                  onClick={this.emailFilter.bind(this)}
                >
                  Email Applicants
                </button>
              )}

            <button className="button" onClick={this.filter.bind(this)}>
              Filter
            </button>

            <button className="button" onClick={this.archived.bind(this)}>
              Archived Applicants
            </button>
          </div>
        </div>

        <div className="applicants_inner_head inner_head">
          <input
            type="text"
            className="applicant_search"
            name="keyword"
            placeholder="Search..."
            //value={this.state.keySearch}
            defaultValue={this.state.keySearch}
            onKeyUp={this.keyUpFn.bind(this)}
            autoComplete="off"
          />
        </div>
        <div className="inner_container col-lg-12" id="applicants_inner">
          <center
            id="feedLoader"
            className={this.state.loader ? this.state.loader : ""}
          >
            <img alt="Loader" src={require("../assets/images/loader.gif").default} />
          </center>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { applicants, permission } = state;
  return {
    applicants,
    permission
  };
}

const connectedHomePage = connect(mapStateToProps)(ApplicantList);
export { connectedHomePage as ApplicantList };
