import { assignmentStatsConstants } from '../_constants';

export function assignmentStats(state = {}, action) {

    switch (action.type) {
        case assignmentStatsConstants.GETASSIGNMENTSTATUSTYPE_REQUEST:
            return {
                loading: true
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSTYPE_SUCCESS:

            return {
                ...state,
                types: action.applicantStats
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSTYPE_FAILURE:
            return {
                error: action.error
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUS_REQUEST:
            return {
                loading: true
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUS_SUCCESS:

            return {
                ...state,
                applicantStats: action.applicantStats
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUS_FAILURE:
            return {
                error: action.error
            };
        case assignmentStatsConstants.GETASSIGNMENTHISTORY_REQUEST:
            return {
                loading: true
            };
        case assignmentStatsConstants.GETASSIGNMENTHISTORY_SUCCESS:
            return {
                ...state,
                assignmentHistory: action.assignmentHistory
            };
        case assignmentStatsConstants.GETASSIGNMENTHISTORY_FAILURE:
            return {
                error: action.error
            };
        case assignmentStatsConstants.POSTASSIGNMENTSTATUSSAVED_REQUEST:
            return {
                loading: true
            };
        case assignmentStatsConstants.POSTASSIGNMENTSTATUSSAVED_SUCCESS:
            return {
                ...state,
                assignmentStatusSaved: action.assignmentStatusSaved
            };
        case assignmentStatsConstants.POSTASSIGNMENTSTATUSSAVED_FAILURE:
            return {
                error: action.error
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSGRAPH_REQUEST:
            return {
                loading: true
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSGRAPH_SUCCESS:
            return {
                ...state,
                assignmentStatusGraph: action.assignmentStatusGraph
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSGRAPH_FAILURE:
            return {
                error: action.error
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSPROGRESS_REQUEST:
            return {
                loading: true
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSPROGRESS_SUCCESS:
            return {
                ...state,
                assignemntStatusProgress: action.assignemntStatusProgress
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSPROGRESS_FAILURE:
            return {
                error: action.error
            };
        case assignmentStatsConstants.DELETEHISTORY_SUCCESS:
            return {
                ...state,
                historyDelete: true
            };
        case assignmentStatsConstants.DELETEHISTORY_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
    //
}
