/* eslint-disable no-useless-constructor */
import React from "react";
import { connect } from "react-redux";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import SlidingPane from "react-sliding-pane";
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import NewModal from "react-responsive-modal";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { toast } from "react-toastify";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  notificationActions,
  permissionActions,
  chatActions,
  resourceActions,
  userActions,
  docsActions
} from "../../_actions";
import moment from "moment";
import { Link, NavLink } from "react-router-dom";
import { config } from "../../_helpers";
import { getCompanyName,  unAvailableReason} from '../../_utils/utils'
import "./navbar.css";
import ChangeStatusModal, { currentStatus } from "_components/ChangeStatusModal";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    // Method bindings
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
    this.setRead = this.setRead.bind(this);
    this.readMore = this.readMore.bind(this);
    this.markAsRead = this.markAsRead.bind(this);
    this.keyUpFn = this.keyUpFn.bind(this);
    this.headerSearch = this.headerSearch.bind(this);
    this.parseSearch = this.parseSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.closeProfile = this.closeProfile.bind(this);
    this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.setStep = this.setStep.bind(this);
    this.setStatusReturn = this.setStatusReturn.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    // this.setWrapperRef = this.setWrapperRef.bind(this);
    // this.manifestView = this.manifestView.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.logout = this.logout.bind(this);
    this.closePopups = this.closePopups.bind(this);
    this.closeOverlay = this.closeOverlay.bind(this);
    this.showOverlay = this.showOverlay.bind(this);
    this.toggleProfile = this.toggleProfile.bind(this);
    // Refs
    this.searchInput = React.createRef();
    this.searchDesktop = React.createRef();
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.callToast = this.callToast.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    // State Initializing
    this.state = {
      active: "",
      applicationItem: [],
      count: "",
      flag: true,
      generalQualificationList: [],
      hideSearch: "hide",
      isMenuOpen: false,
      isSearchOpen: false,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      PopoverOpen: false,
      PopoverOpenDesktop: false,
      manifest_list: [],
      navClass: "",
      new_list: [],
      noResultContent: "",
      no_result: "show",
      noti: false,
      notify_active: false,
      notify_link: "",
      notification_list: false,
      numPages: null,
      old_list: [],
      open: false,
      pageNumber: 1,
      permList: [],
      profile: [],
      resources_list: [],
      returnDateField: moment(),
      test_list: [],
      timeout: null,
      searchResult: [],
      status: "",
      statusFlag: false,
      statusOpen: false,
      statusStep: "1",
      user: {},
      users_list: [],
      mark: false,
      isGuest: false,
      timeOffFlag: false
    };
  }
  // Helper Functions
  // Format Phone numbers
  formatPhoneNumber(s) {
    var s2 = ("" + s).replace(/\D/g, "");
    var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
    return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
  }
  setNavExpanded(expanded) {
    this.setState({ navExpanded: expanded });
  }
  closeNav() {
    this.setState({ navExpanded: false });
    this.closePopups();
  }
  closePopups() {
    if (this.searchDesktop && this.searchDesktop.current) {
      this.searchDesktop.current.value = "";
    }

    if (this.state.PopoverOpen) {
      this.setState({ PopoverOpen: false });
    }
    if (this.state.isMenuOpen) {
      this.setState({
        isMenuOpen: false,
        navClass: "new_nav_hide",
      });
    }
    if (this.state.isPaneOpen) {
      this.setState({ isPaneOpen: false });
    }
    this.setState({ hideSearch: "hide" });
    this.props.overlay.current.classList.add("hidden");
  }
  // closing overlay backdrop
  closeOverlay() {
    this.props.overlay.current.classList.add("hidden");
  }
  //Calling Toast Message
  callToast = (message,flag) => {
		this.props.callToast(message,flag);
	}
  // showing overlay backdrop
  showOverlay() {
    this.props.overlay.current.classList.remove("hidden");
  }
  // toggle navbar menu list
  toggleMenu() {
    // close opened profile
    if (this.state.isPaneOpen) {
      this.setState({
        isPaneOpen: false,
      });
    }
    // close opened notification
    if (this.state.PopoverOpen) {
      this.setState({ PopoverOpen: false });
    }
    if (!this.state.isMenuOpen) {
      this.props.overlay.current.classList.remove("hidden");
      this.setState({
        isMenuOpen: true,
        navClass: "new_nav_show",
      });
    } else {
      this.props.overlay.current.classList.add("hidden");
      this.setState({
        isMenuOpen: false,
        navClass: "new_nav_hide",
      });
    }
  }
  // toggle search bar
  toggleSearch() {
    this.clearSearch();
    if (!this.state.isSearchOpen) {
      this.setState({ isSearchOpen: true });
    } else {
      this.setState({ isSearchOpen: false });
    }
  }
  // Toggle profile
  toggleProfile() {
    // close opened menu
    if (this.state.isMenuOpen) {
      this.setState({
        isMenuOpen: false,
        navClass: "new_nav_hide",
      });
    }
    // close opened notification
    if (this.state.PopoverOpen) {
      this.setState({ PopoverOpen: false });
    }
    if (!this.state.isPaneOpen) {
      this.setState({
        isPaneOpen: true,
      });
    } else {
      this.setState({
        isPaneOpen: false,
      });
    }
    this.closeOverlay();
  }
  // toggle Mobile Notification
  toggleNotification() {
    // close opened menu
    if (this.state.isMenuOpen) {
      this.setState({
        isMenuOpen: false,
        navClass: "new_nav_hide",
      });
    }
    // close opened profile
    if (this.state.isPaneOpen) {
      this.setState({
        isPaneOpen: false,
      });
    }
    if (!this.state.PopoverOpen) {
      this.props.overlay.current.classList.remove("hidden");
    } else {
      this.props.overlay.current.classList.add("hidden");
    }
    this.setState(
      {
        PopoverOpen: this.state.PopoverOpen ? false : true,
      },
      function () {
        if (this.state.PopoverOpen) {
          this.setState({ notify_active: true });
          this.props.dispatch(notificationActions.getAll());
        } else {
          this.setState({ notify_active: false });
        }
      },
    );
  }
  // toggle Desktop Notification
  toggle() {
    this.setState(
      {
        PopoverOpenDesktop: this.state.PopoverOpenDesktop ? false : true,
      },
      function () {
        if (this.state.PopoverOpenDesktop) {
          this.setState({ notify_active: true });
          this.props.dispatch(notificationActions.getAll());
        } else {
          this.setState({ notify_active: false });
        }
      },
    );
  }


  handleStatusChange = async() => {
    debugger;
    this.statusOpenModal()
    await this.props.dispatch(docsActions.getUserDocs(this.props.user?.items?.data?.hash));

    if (this.props?.docs) {
      const userDocs = this.props.docs.userDocs;
    
      if (userDocs && userDocs.length > 0) {
        const filteredUserDocs = userDocs.filter((item) => item.slug === "time-off-request");
    
        if (filteredUserDocs.length > 0) {
          filteredUserDocs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    
          const mostRecentDoc = filteredUserDocs[0];
          const currentDate = new Date();
    
          this.setState({
            timeOffFlag:
              mostRecentDoc &&
              mostRecentDoc.status === 3 &&
              new Date(mostRecentDoc.time_off_from) > currentDate
          });
        } else {
          this.setState({ timeOffFlag: false });
        }
      } else {
        this.setState({ timeOffFlag: false }); 
      }
    }    
  }

  // on error notifications
  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }
  // Notificiation Read More
  readMore() {
    this.closeOverlay();
    this.setState({
      PopoverOpen: false,
      PopoverOpenDesktop: false,
      notify_active: false,
    });
  }
  // Read Notification
  setRead(id, link, text) {
    this.closeOverlay();
    /*text = text.toLowerCase();
    let rehire = text.includes("rehire");
    let onboarding = text.includes("onboarding");
    rehire && (link = link.concat("/employee-re-hire-form?rehire=true"));
    onboarding && (link = link.concat("/candidate-information-page?rehire=false"));*/
    if (Number(id) === 16){
      let rehire = link.includes("rehire=true");
      !rehire && (link = link.concat("/employee-re-hire-form?rehire=true"));
    }
    if (Number(id) === 17){
      let onboarding = link.includes("rehire=false");
      !onboarding && (link = link.concat("/candidate-information-page?rehire=false"));
    }

    this.props.dispatch(notificationActions.setRead(id));
    this.setState({
      PopoverOpen: false,
      PopoverOpenDesktop: false,
      notify_link: link,
    });
    this.props.dispatch(notificationActions.getAll());
  }
  // Set as read
  markAsRead() {
    this.closeOverlay();
    this.setState({ new_list: [], old_list: [], count: 0 });
    this.props.dispatch(notificationActions.markAsRead()).then(mark =>{
      this.props.dispatch(notificationActions.getCount());
    });
  }
  // Getting the Notifications
  getNotifications(notification) {
    if (notification.list) {
      //
      let new_list = [],
        old_list = [];
      const data = notification.list.data.data;
      this.setState({ new_list: [], old_list: [] });

      if (data.new.length <= 0 && data.old.length <= 0) {
      } else {
        if (data.new.length > 0) {
          new_list.push(
            <h2 key='h2' className='new'>
              New
            </h2>,
          );
          var notification_link = "",
            temp;
          data.new.forEach((item, index) => {
            if (item.data.type === 6) {
              notification_link = config.baseUrl + "applicant-view/" + item.data.hash;
            } else if (item.data.type === 7 || item.data.type === 8 || item.data.type === 9) {
              temp = item.data.link.replace("/inventory-view", "/inventory/inventory-view");
              notification_link = temp;
            } else if (item.data.type === 15) {
              notification_link = item.data.link;
            } else {
              notification_link = item.data.link;
            }
            new_list.push(
              <div
                className={item.read_at === null ? "unread notify_item" : "notify_item"}
                key={index}
                onClick={this.setRead.bind(this, item.id, notification_link, item.data.body)}>
                <div className='img_left left'>
                  <img
                    alt={item.data.name}
                    src={
                      item.data.profile_photo
                        ? config.apiUrl + "/" + item.data.profile_photo
                        : config.apiUrl + "/" + config.altImg
                    }
                    onError={this.onImageError.bind(this)}
                  />
                </div>
                <div className='details'>
                  <p className='title'>
                    <span>{item.data.name}</span>
                    {" " + item.data.body}
                  </p>
                  <p className='date'>{item.at}</p>
                </div>
              </div>,
            );
          });
        } else {
          new_list.push(
            <div className='no_notification' key='no_new'>
              <h2 key='h2' className='new'>
                New
              </h2>
              <p>No new notifications</p>
            </div>,
          );
        }
        this.setState({ new_list, notification_list: true });

        if (data.old.length > 0) {
          old_list.push(<h2 key='h0'>Earlier</h2>);
          data.old.forEach((item, index) => {
            if (item.data.type === 6) {
              notification_link = config.baseUrl + "applicant-view/" + item.data.hash;
            } else if (item.data.type === 15) {
              notification_link = item.data.link;
            } else if (item.data.type === 7 || item.data.type === 8 || item.data.type === 9) {
              temp = item.data.link.replace("/inventory-view", "/inventory/inventory-view");
              notification_link = temp;
            } else {
              notification_link = item.data.link;
            }
            old_list.push(
              <div
                className={item.read_at === null ? "unread notify_item" : "notify_item"}
                key={index}
                onClick={this.setRead.bind(this, item.id, notification_link, item.data.body)}>
                <div className='img_left left'>
                  <img
                    alt={item.data.name}
                    src={
                      item.data.profile_photo
                        ? config.apiUrl + "/" + item.data.profile_photo
                        : config.apiUrl + "/" + config.altImg
                    }
                    onError={this.onImageError.bind(this)}
                  />
                </div>
                <div className='details'>
                  <p className='title'>
                    <span>{item.data.name}</span>
                    {" " + item.data.body}
                  </p>
                  <p className='date'>
                    {/* Setting time to PST pacific time */}
                    {moment.utc(item.created_at).subtract(8, "hours").format("lll")}
                  </p>
                </div>
              </div>,
            );
          });
        } else {
          old_list.push(
            <div className='no_notification'>
              <h2 key='h2' className='new'>
                Earlier
              </h2>
              <p>No new notifications</p>
            </div>,
          );
        }
        this.setState({ old_list, notification_list: true });
      }
    }
  }
  // Search input keyup
  keyUpFn(e) {
    this.setState({ searchInput: e.target.value });
    let textInput = e.target;
    clearTimeout(this.state.timeout);
    this.setState({
      timeout: setTimeout(
        function () {
          if (textInput.value.length > 1) {
            this.headerSearch(textInput.value);
          } else if (textInput.value === "") {
            this.setState({
              searchResult: [],
              hideSearch: "hide",
            });
          }
        }.bind(this), // binding to current class instance
        1000,
      ),
    });
  }
  // Search
  headerSearch(text) {
    let data = new FormData();
    data.append("keyword", text);
    this.props.dispatch(resourceActions.universalSearch(data));
    this.setState({ hideSearch: "show", search_close: "show" });
    // if (text.length === 0) {
    //   this.setState({ hideSearch: "hide" });
    // } else if (text.length >= 1) {
    //   let data = new FormData();
    //   data.append("keyword", text);
    //   this.props.dispatch(resourceActions.universalSearch(data));
    //   this.setState({ hideSearch: "show", search_close: "show" });
    // } else {
    //   this.setState({ formShow: "show" });
    // }
  }
  // parse search result
  parseSearch() {
    if (this.props.search.items) {
      const resultArray = this.props.search.items.data;
      let searchArray = [],
        a1 = [],
        noAvailable = false;
      Object.keys(resultArray).forEach((res) => {
        a1 = [];
        if (resultArray[res].length > 0) {
          // Getting Heading Names
          if (res === "users") {
            if (parseInt(localStorage.getItem("avail"), 10) === 1) {
              a1.push(<h3 key={res}>{res}</h3>);
            } else {
              noAvailable = true;
            }
          } else {
            a1.push(<h3 key={res}>{res}</h3>);
          }
          // Pushing rest of the user data to Array ( after heading H3)
          resultArray[res].forEach((item, index) => {
            if (res === "inventory") {
              a1.push(
                <div key={"inv-" + index}>
                  <a
                    href={config.baseUrl + "inventory/inventory-view/" + item.id}
                    onClick={this.closeSearchResult}>
                    <div className='user_click'>
                      <div className='left inv_pic pro_pic'>
                        <img
                          alt={item.unique_id}
                          src={
                            item.pic !== null
                              ? config.s3BucketUrl + item.pic
                              : config.apiUrl + "/" + config.altInvImg
                          }
                          onError={this.onImageError.bind(this)}
                        />
                      </div>
                      <div className='search_detail'>
                        <p>
                          <span className='name'>{item.unique_id}</span>&nbsp;-&nbsp;{item.type}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>,
              );
            }
            if (res === "manifest") {
              a1.push(
                <div className='resource_li' key={"m-" + index}>
                  <a
                    href={config.baseUrl + "manifest/" + item.mid + "/" + item.bid + "/" + item.rid}
                    className='manifest_show'
                    data-mid='1'
                    data-manifest-id='20'
                    data-resource-id='2'>
                    <div className='manifest_show'>
                      <div className='search_detail'>
                        <p className='name'>
                          {item.name} &nbsp;-&nbsp; {item.resource_name}&nbsp; - &nbsp;
                          {item.added_date}&nbsp;- &nbsp;{item.value}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>,
              );
            }
            if (res === "applicants") {
              a1.push(
                <div className="resource_li" key={"applicant-" + index}>
                  <a
                    href={config.baseUrl + "applicant-view/" + item?.hash}
                    target="_blank"
                    rel="noopener"
                    className="manifest_show"
                  // data-mid="1"
                  // data-manifest-id="20"
                  // data-resource-id="2"
                  >
                    <div className="manifest_show">
                      <div className="search_detail">
                        <p className="name">
                          {item.first_name} &nbsp;{item?.last_name}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              );
            }
            if (res === "users" && parseInt(localStorage.getItem("avail"), 10) === 1) {
              a1.push(
                <div key={"u-" + index}>
                  <a
                    href={config.baseUrl + "profile/" + item.hash}
                    onClick={this.closeSearchResult}>
                    <div className='user_click'>
                      <div className='left pro_pic'>
                        <img
                          alt={item.first_name + " " + item.last_name}
                          src={
                            item.profile_photo
                              ? config.apiUrl + "/" + item.profile_photo
                              : config.apiUrl + "/" + config.altImg
                          }
                          onError={this.onImageError.bind(this)}
                        />
                      </div>
                      <div className='search_detail'>
                        <p className='name'>{item.first_name + " " + item.last_name}</p>
                        <p>{item.user_type}</p>
                      </div>
                    </div>
                  </a>
                </div>,
              );
            }
            if (res === "resources") {
              a1.push(
                <a
                  href={config.baseUrl + "resources/" + item.id}
                  key={"r-" + index}
                  onClick={this.closeSearchResult}>
                  <div className='user_click' key={index}>
                    <div className='left pro_pic'>
                      <img
                        alt={item.resource_name}
                        src={
                          item.resource_photo
                            ? config.apiUrl + "/" + item.resource_photo
                            : config.apiUrl + "/" + config.altImg
                        }
                        onError={this.onImageError.bind(this)}
                      />
                    </div>
                    <div className='search_detail'>
                      <p>
                        <span className='name'>{item.resource_name}</span>&nbsp;-&nbsp;
                        {item.resource_type}
                      </p>
                    </div>
                  </div>
                </a>,
              );
            }
          });
          searchArray.push(a1);
        }
      });
      // If Empty
      if (searchArray.length === 0 || noAvailable) {
        searchArray = [];
        searchArray.push(
          <div className='user_click'>
            <div className='search_detail'>
              <p>
                <span className='name'>No Result Found</span>
              </p>
            </div>
          </div>,
        );
      }
      this.setState({ searchResult: searchArray });
      // return searchArray;
    }
  }
  // Clear Search
  clearSearch() {
    this.searchInput.current.value = "";
    this.searchDesktop.current.value = "";
    this.setState({
      searchResult: [],
    });
  }
  // Close Profile Sidepane
  closeProfile() {
    this.setState({ isPaneOpen: false });
  }
  // Close Status modal
  statusCloseModal() {
    this.setState({ statusOpen: false });
  }
  // Open Status Modal
  statusOpenModal() {
    this.setState({ statusOpen: true, statusStep: "1" });
  }
  // Change Modal Step
  setStep(step) {
    this.setState({
      statusStep: step,
    });
  }
  // Change User status
  changeStatus(status) {
    if (status) {
      var stat = this.state.status === "Available" ? "Unavailable" : "Available";
      this.setState(
        {
          status: stat,
        },
        function () {
          // this.componentWillReceiveProps(this.props);
        },
      );
      let data = new FormData();
      this.statusCloseModal();
      data.append("hash", this.state.hash);
      if (this.state.returnDate !== undefined) {
        data.append("end_date", this.state.returnDate);
      }

      data.append("status", stat);
      this.props.dispatch(userActions.changeSelfStatus(data));
    }
  }
  // Change status step
  setStatusReturn(status) {
    debugger
    if(!this.state.timeOffFlag && (Number(this.state.unavailableReason) === Number(1))){
      return toast.error("Please get approval on your Time Off Request Document")
    }
    if (Number(this.state.unavailableReason) === Number(0)) {
      this.setState({
        unavailableErr: "error",
      });
      return;
    }
    if ((Number(this.state.unavailableReason) === Number(1) ||
    Number(this.state.unavailableReason) === Number(2) ||
    Number(this.state.unavailableReason) === Number(3) ||
    Number(this.state.unavailableReason) === Number(4)) && !this.state.otherReason) {
      this.setState({
        unavailableErr: "error",
      });
      return;
    }
    if (status) {
      var step = "2a";
    } else step = "2b";

    this.setState({ statusStep: step }, function () {
      //   this.componentWillReceiveProps(this.props);
    });
  }
  // Change Date
  handleDateChange(date) {
    this.setState({
      returnDateField: date,
      returnDate: date.format("MM/DD/YY"),
    });
  }
  //set wrapper ref
  // setWrapperRef(node) {
  // 	this.wrapperRef = node;
  // }
  // Logout User
  logout() {
    this.props.dispatch(userActions.logout());
    localStorage.removeItem("user");
  }
  // handleDocumentClick
  handleClickOutside(event) {
    if (this.wrapperRef.current && !this.wrapperRef.current.childNodes.length !== 0) {
      this.setState({ hideSearch: "hide" });
    }
  }

  // Lifecycle Hooks
  componentWillReceiveProps(nextProp) {
    //destructuring values from nextProp
    const { permission, notification } = nextProp;
    let permList = [];
    // get notifications
    this.getNotifications(notification);
    // Set Notification Count
    if (nextProp.notification.count) {
      const data = nextProp.notification.count.data;
      this.setState({
        active: data.active,
        count: data.notification_count,
        statusFlag: false,
      });
    }
    // setting permissions
    if (permission.permissions) {
      permList = permission.permissions.data;
      this.setState({ permList });
    }
    // Set User Data
    if (nextProp.user.profile) {
      var available = nextProp.user.profile.data.active === "Available" ? true : false;
      this.setState({
        status: nextProp.user.profile.data.active,
        available: available,
        hash: nextProp.user.profile.data.hash,
        profile: nextProp.user.profile.data,
        isGuest: nextProp.user.profile.data.status === 3 ? true : false
      });
    }
    // parsing search from props
    if (this.searchInput.current.value.length > 1) {
      this.parseSearch();
    }

    // redirecting after reading notification
    if (notification.read) {
      if (notification.read.success) {
        window.location.replace(this.state.notify_link);
      }
    }
  }
  componentDidMount() {
    this.props.overlay.current.onclick = this.closePopups;
    Modal.setAppElement(this.el);
    this.props.dispatch(permissionActions.getPermission());
    this.props.dispatch(notificationActions.getCount());
    this.props.dispatch(chatActions.getAllCounts());
    this.props.dispatch(userActions.loginProfile());
    this.interval = setInterval(() => {
      if (this.props.user) {
      this.props.dispatch(notificationActions.getCount())
      .then(res => {
        if(res && res.data){
          const { data } = res;
          if (data && data.banner) {
            let banner_id = window.localStorage.getItem('banner_id');
            window.localStorage.setItem('app_verion', data.app_version);
            if(data.banner.length > 0 && Number(data.banner[0].id) !== Number(banner_id)){
              window.localStorage.setItem('banner_id', data.banner[0].id);
              this.callToast(data.banner[0], true);
              } /*else {
              this.callToast('',false)
              }*/
            }
          }
        });
      } else {
        clearInterval(this.interval);
      }
      // this.props.dispatch(chatActions.getConversations()); // temp disabled for resource profile notes fix
    }, 30000);
  }

  componentWillUnmount() {
		clearInterval(this.interval);
	}

  // Render Method


  render() {    
    const timeOffFlag = this.state.timeOffFlag;
    // 
    let banner_height = this.props.banner_height;
    var notify = "hide";
    if (this.state.permList) {
      if (this.state.permList.includes("Notifications")) {
        notify = "show";
      } else {
        notify = "hide";
      }
    }
    let count, pathname;
    if (this.props.chat) {
      count = this.props.chat.count;
    }
    if (this.props.location) {
      pathname = this.props.location;
    }
    var image;
    if (this.state.profile.profile_photo) {
      image = config.apiUrl + "/" + this.state.profile.profile_photo;
    } else {
      image = config.apiUrl + "/" + config.altImg;
    }

    if (this.props.search.items) {
      const resultArray = this.props.search.items.data;
      var searchArray = [];
      var a1 = [],
        noAvailable = false;
      Object.keys(resultArray).forEach((res) => {
        a1 = [];
        if (resultArray[res].length > 0) {
          if (res === "users") {
            if (parseInt(localStorage.getItem("avail")) === 1) {
              a1.push(<h3 key={res}>{res}</h3>);
            } else {
              noAvailable = true;
            }
          } else {
            a1.push(<h3 key={res}>{res}</h3>);
          }
          resultArray[res].forEach((item, index) => {
            if (res === "inventory") {
              a1.push(
                <div key={"inv-" + index}>
                  <a
                    href={config.baseUrl + "inventory/inventory-view/" + item.id}
                    onClick={this.closeSearchResult}>
                    <div className='user_click'>
                      <div className='left inv_pic pro_pic'>
                        <img
                          alt={item.unique_id}
                          src={
                            item.pic !== null
                              ? config.s3BucketUrl + item.pic
                              : config.apiUrl + "/" + config.altInvImg
                          }
                          onError={this.onImageError.bind(this)}
                        />
                      </div>
                      <div className='search_detail'>
                        <p>
                          <span className='name'>{item.unique_id}</span>
                          &nbsp;-&nbsp;{item.type}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>,
              );
            }
            if (res === "manifest") {
              a1.push(
                <div className='resource_li' key={"m-" + index}>
                  <a
                    href={config.baseUrl + "manifest/" + item.mid + "/" + item.bid + "/" + item.rid}
                    className='manifest_show'
                    data-mid='1'
                    data-manifest-id='20'
                    data-resource-id='2'>
                    <div className='manifest_show'>
                      <div className='search_detail'>
                        <p className='name'>
                          {item.name} &nbsp;-&nbsp; {item.resource_name}&nbsp; - &nbsp;
                          {item.added_date}&nbsp;- &nbsp;{item.value}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>,
              );
            }
            if (res === "applicants") {
              a1.push(
                <div className="resource_li" key={"applicant" + index}>
                  <a
                    href={config.baseUrl + "applicant-view/" + item?.hash}
                    target="_blank"
                    rel="noopener"
                    className="manifest_show"
                  // data-mid="1"
                  // data-manifest-id="20"
                  // data-resource-id="2"
                  >
                    <div className="manifest_show">
                      <div className="search_detail">
                        <p className="name">
                          {item.first_name} &nbsp;{item?.last_name}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              );
            }
            if (res === "users" && parseInt(localStorage.getItem("avail")) === 1) {
              a1.push(
                <div key={"u-" + index}>
                  <a
                    href={config.baseUrl + "profile/" + item.hash}
                    onClick={this.closeSearchResult}>
                    <div className='user_click'>
                      <div className='left pro_pic'>
                        <img
                          alt={item.first_name + " " + item.last_name}
                          src={
                            item.profile_photo
                              ? config.apiUrl + "/" + item.profile_photo
                              : config.apiUrl + "/" + config.altImg
                          }
                          onError={this.onImageError.bind(this)}
                        />
                      </div>
                      <div className='search_detail'>
                        <p className='name'>
                          {item.first_name + " " + item.last_name} -{" "}
                          <small>({item.resource_name})</small>
                        </p>
                        <i>{item.type}</i>
                      </div>
                    </div>
                  </a>
                </div>,
              );
            }
            if (res === "resources") {
              a1.push(
                <a
                  href={config.baseUrl + "resources/" + item.id}
                  key={"r-" + index}
                  onClick={this.closeSearchResult}>
                  <div className='user_click' key={index}>
                    <div className='left pro_pic'>
                      <img
                        alt={item.resource_name}
                        src={
                          item.resource_photo
                            ? config.apiUrl + "/" + item.resource_photo
                            : config.apiUrl + "/" + config.altImg
                        }
                        onError={this.onImageError.bind(this)}
                      />
                    </div>
                    <div className='search_detail'>
                      <p>
                        <span className='name'>{item.resource_name}</span>
                        &nbsp;-&nbsp;{item.resource_type}
                      </p>
                    </div>
                  </div>
                </a>,
              );
            }
          });
          searchArray.push(a1);
        }
      });
      if (searchArray.length === 0 || noAvailable) {
        searchArray = [];
        searchArray.push(
          <div className='user_click'>
            <div className='search_detail'>
              <p>
                <span className='name'>No Result Found</span>
              </p>
            </div>
          </div>,
        );
      }
    }
    return (
      <section className="push-notification-nav-setup">
        <main>
          {/* Mobile */}
          <div className='new_nav' style={{'top': banner_height}}>
            <div className='nav_container'>
              <div className='nav-col'>
                <div className='btn_toggler'>
                  <button type='button' className='navbar-toggle collapsed' onClick={this.toggleMenu}>
                    <span className='sr-only'>Toggle navigation</span>
                    <span className='icon-bar'></span>
                    <span className='icon-bar'></span>
                    <span className='icon-bar'></span>
                  </button>
                </div>
                <div className='logo'>
                  <Link to={"/dashboard"} className='logo'>
                    {/* <img src={`${config.assetUrl}icons/logo.svg`} alt='logo' /> */}

                    {/* change the existing logo with the following after adding the assets  */}

                   <img src={`${config.assetUrl}icons/logo.svg`} alt='logo' className="desktop-logo"/>
                    <img src={`${config.assetUrl}icons/logo-text.svg`} alt='logo'  className="mobile-logo"/> 
                    
                  </Link>
                </div>
                <div className='action_buttons'>
                  <ul className='actions'>
                    {/* checking for search permissions */}
                    {this.state.permList && this.state.permList.includes("System Search") && (
                      <li onClick={this.toggleSearch}>
                        <a>
                          <img
                            src={`${config.assetUrl}icons/search-icon-white.svg`}
                            alt='search-icon'
                          />
                        </a>
                      </li>
                    )}

                    {/* checking for permission notificatoin  */}
                    {this.state.permList && this.state.permList.includes("Notifications") && (
                      <li onClick={this.toggleNotification} id='Popover1'>
                        <a>
                          <img
                            src={`${config.assetUrl}icons/notify-icon.svg`}
                            alt='notification-icon'
                          />
                        </a>
                        {this.state.count !== 0 && (
                          <span className='notify-count'>
                            {this.state.count > 0 ? this.state.count : ""}
                          </span>
                        )}
                        {/* Notification Dropdown */}
                        {this.state.permList && this.state.permList.includes("Notifications") && (
                          <Popover
                            className='notify_dropdown'
                            placement='left'
                            isOpen={this.state.PopoverOpen}
                            target='Popover1'
                            toggle={this.toggleNotification}>
                            <div className='pop_header'>
                              <PopoverHeader>Notifications</PopoverHeader>
                              <span className='mark_as_read' onClick={this.markAsRead}>
                                Mark All as Read
                              </span>
                            </div>
                            <PopoverBody id='notification_outer'>
                              <div className='notfication_listing'>
                                {this.state.new_list}
                                {this.state.old_list.length > 1 && this.state.old_list}
                                {this.state.applicationItem}
                                <Link
                                  to='/notifications'
                                  onClick={this.readMore}
                                  className='btn-readmore'
                                  id='btn-readmore'>
                                  Read More 
                                </Link>
                              </div>
                            </PopoverBody>
                          </Popover>
                        )}
                      </li>
                    )}

                    <li>
                      <a onClick={this.toggleProfile}>
                        {this.state.profile.profile_photo ? (
                          <img
                            className={` ${
                              this.state.isGuest ? 'guest-code' : (this.state.status === "Available" ? "availability" : "")
                            } pro_pic `}
                            alt='profile'
                            src={config.apiUrl + "/" + this.state.profile.profile_photo}
                            onError={this.onImageError.bind(this)}
                          />
                        ) : (
                          <img
                            className={` ${
                              this.state.isGuest ? 'guest-code' : (this.state.status === "Available" ? "availability" : "")
                            } pro_pic `}
                            alt='profile'
                            src={config.apiUrl + "/" + config.altImg}
                            onError={this.onImageError.bind(this)}
                          />
                        )}
                      </a>
                      <SlidingPane
                        className='sliding_profile'
                        overlayClassName='sliding_profile_overlay'
                        isOpen={this.state.isPaneOpen}
                        onRequestClose={this.closeProfile}>
                        <div className='sliding_content'>
                          <div className='container-fluid'>
                            {/* <div className="row msg msg-header">
                              <div className="col-md-6">
                                <h1 className="msg-heading">Profile Info</h1>
                              </div>
                              <div className="col-md-6">
                                <a href="#" className="btn msg-cancel-btn pull-right">Logout</a>
                              </div>
                            </div> */}
                            <div className='row msg'>
                              <div className='msg-col msg-messages'>
                                <div className='container-fluid'>
                                  <div className='row msg-mob-messageheader'>
                                    <div className='col-xs-8'>
                                      <a className='mob-backbtn' onClick={this.closeProfile}>
                                        <img
                                          src={`${config.assetUrl}icons/back-btn.svg`}
                                          alt='back-button'
                                        />
                                      </a>
                                      <h3 className='title'>Profile Info</h3>
                                    </div>
                                    <div className='col-xs-4'>
                                      <ul className='admincontrols nav navbar-nav navbar-right'>
                                        <li>
                                          <i className='fas fa-share-square'></i>
                                          <Link
                                            className='msg-admincontrol'
                                            to='/login'
                                            onClick={this.logout}>
                                            Logout
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className='row profileInfo profile-pic-row'>
                                    <div className='col-md-12'>
                                      <div className='profile-name'>
                                        <span
                                          className={"color_code " + this.state.profile.colorcode}
                                        />
                                        {`${this.state.profile.first_name} ${this.state.profile.last_name}`}
                                      </div>
                                      <div className='profile-pic'>
                                        {this.state.profile.profile_photo ? (
                                          <img
                                            className={` ${
                                              this.state.isGuest ? 'guest-code' : (this.state.status === "Available" ? "availability" : "")
                                            } pro_pic `}
                                            alt='profile'
                                            src={
                                              config.apiUrl + "/" + this.state.profile.profile_photo
                                            }
                                            onError={this.onImageError.bind(this)}
                                          />
                                        ) : (
                                          <img
                                            className={` ${
                                              this.state.isGuest ? 'guest-code' : (this.state.status === "Available" ? "availability" : "")
                                            } pro_pic `}
                                            alt='profile'
                                            src={config.apiUrl + "/" + config.altImg}
                                            onError={this.onImageError.bind(this)}
                                          />
                                        )}
                                      </div>
                                      <p navClass='profile-features'>
                                        {this.state.profile.user_type}
                                      </p>
                                      {this.state.profile.crew_name && (
                                        <p navClass='profile-features'>
                                          {this.state.profile.crew_name}
                                        </p>
                                      )}
                                      <p navClass='profile-features'>{this.state.profile.nvam_id}</p>
                                      {/* <p navClass='profile-features'>
                                        {this.state.profile.ica_number}
                                      </p> */}
                                      {this.state.profile.primary_crew_name && (
                                        <p navClass='profile-features'>
                                          Primary Crew: {this.state.profile.primary_crew_name}
                                        </p>
                                      )}
                                 
                                      {this.state.profile.applying_company !== 0 && <p>Company: {getCompanyName(this.state.profile.applying_company)}</p>}

                                      {this.state.profile.active=== currentStatus.Unavailable&&(
                                    <p>Unavailable: {unAvailableReason(this.state.profile.unavailable_reason,
                                      
                                      this.state.profile.other_reason)}</p>
                                  )}
                                    </div>
                                  </div>
                                  <div className='row profileInfo'>
                                    <div className='col-md-12'>
                                      <div className='profile-details has-details'>
                                        <div className='detailsTab'>
                                          <Accordion allowZeroExpanded='true'>
                                            <AccordionItem>
                                              <AccordionItemHeading>
                                                <AccordionItemButton>
                                                  <p>Profile Info</p>
                                                  <a className='pull-right profile-tb-btn'>
                                                    <i className='fas fa-chevron-down'></i>
                                                  </a>
                                                </AccordionItemButton>
                                              </AccordionItemHeading>
                                              <AccordionItemPanel>
                                                <div className='sliding_content clearf'>
                                                  <div className='profile_name'>
                                                    <div className='status' />
                                                    {/* <p>
                                                <span className={"color_code "+this.state.profile.colorcode} />
                                                { `${this.state.profile.first_name} ${this.state.profile.last_name}` }
                                              </p> */}
                                                    {/* <div>
                                                <Link
                                                to={"/settings"}
                                                onClick={() => {
                                                  this.setState({ isPaneOpen: false });
                                                }}
                                                >
                                                <img
                                                  className={
                                                  this.state.profile.active + " profilePicture availability"
                                                  }
                                                  src={
                                                  this.state.profile.profile_photo
                                                    ? config.apiUrl + "/" + this.state.profile.profile_photo
                                                    : config.apiUrl + "/" + config.altImg
                                                  }
                                                  alt="Name"
                                                  onError={this.onImageError.bind(this)}
                                                />
                                                </Link>
                                              </div> */}
                                                  <div className='clearFix' />
                                                    <div className='cp-qual profile-sep'>
                                                      <h4 className='cp-title'>Qualifications</h4>
                                                      {this.state.generalQualificationList}
                                                    </div>
                                                    <div className='cp-cellno profile-sep'>
                                                      <h4 className='cp-title'>Cell Number</h4>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile &&
                                                        this.state.profile.phone ? (
                                                          <a
                                                            onClick={(event) => {
                                                              event.stopPropagation();
                                                            }}
                                                            className='phone_email_link'
                                                            href={`tel:${this.state.profile.phone}`}
                                                            target='_top'>
                                                            {this.formatPhoneNumber(
                                                              this.state.profile.phone,
                                                            )}
                                                          </a>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </p>
                                                    </div>
                                                    <div className='cp-sec profile-sep'>
                                                      <h4 className='cp-title'>Secondary Number</h4>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile &&
                                                        this.state.profile.second_phone !== "null" ? (
                                                          <a
                                                            onClick={(event) => {
                                                              event.stopPropagation();
                                                            }}
                                                            className='phone_email_link'
                                                            href={`tel:${this.state.profile.second_phone}`}
                                                            target='_top'>
                                                            {this.formatPhoneNumber(
                                                              this.state.profile.second_phone,
                                                            )}
                                                          </a>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </p>
                                                    </div>
                                                    <div className='cp-email profile-sep'>
                                                      <h4 className='cp-title'>Email</h4>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile &&
                                                        this.state.profile.email ? (
                                                          <a
                                                            onClick={(event) => {
                                                              event.stopPropagation();
                                                            }}
                                                            className='phone_email_link'
                                                            href={`mailto:${this.state.profile.email}`}
                                                            target='_top'>
                                                            {this.state.profile.email}
                                                          </a>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </p>
                                                    </div>
                                                    <div className='cp-madr profile-sep'>
                                                      <h4 className='cp-title'>Mailing Address</h4>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile.mailing_address
                                                          ? this.state.profile.mailing_address.address
                                                          : ""}
                                                      </p>
                                                      <p className='crew-profile-detail txtformat-capitalize'>
                                                        {this.state.profile.mailing_address
                                                          ? this.state.profile.mailing_address.city
                                                          : ""}
                                                      </p>
                                                      <p className='crew-profile-detail txtformat-capitalize'>
                                                        {this.state.profile.mailing_address
                                                          ? this.state.profile.mailing_address.state
                                                          : ""}
                                                      </p>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile.mailing_address
                                                          ? this.state.profile.mailing_address.zip
                                                          : ""}
                                                      </p>
                                                    </div>
                                                    <div className='cp-pa profile-sep'>
                                                      <h4 className='cp-title'>Physical Address</h4>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile.physical_address
                                                          ? this.state.profile.physical_address
                                                              .address
                                                          : ""}
                                                      </p>
                                                      <p className='crew-profile-detail txtformat-capitalize'>
                                                        {this.state.profile.physical_address
                                                          ? this.state.profile.physical_address.city
                                                          : ""}
                                                      </p>
                                                      <p className='crew-profile-detail txtformat-capitalize'>
                                                        {this.state.profile.physical_address
                                                          ? this.state.profile.physical_address.state
                                                          : ""}
                                                      </p>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile.physical_address
                                                          ? this.state.profile.physical_address.zip
                                                          : ""}
                                                      </p>
                                                    </div>
                                                    <div className='cp-sex profile-sep'>
                                                      <h4 className='cp-title'>Sex</h4>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile.gender}
                                                      </p>
                                                    </div>
                                                    <div className='cp-contact1 profile-sep'>
                                                      <h4 className='cp-title'>
                                                        Emergency Contact #1
                                                      </h4>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile.emgcontact1
                                                          ? this.state.profile.emgcontact1.fname +
                                                            " " +
                                                            this.state.profile.emgcontact1.lname
                                                          : ""}
                                                      </p>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile.emgcontact1 ? (
                                                          // this.formatPhoneNumber(this.state.profile.emgcontact1.phone)
                                                          <a
                                                            onClick={(event) => {
                                                              event.stopPropagation();
                                                            }}
                                                            className='phone_email_link'
                                                            href={`tel:${this.state.profile.emgcontact1.phone}`}
                                                            target='_top'>
                                                            {this.formatPhoneNumber(
                                                              this.state.profile.emgcontact1.phone,
                                                            )}
                                                          </a>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </p>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile.emgcontact1
                                                          ? this.state.profile.emgcontact1.relation
                                                          : ""}
                                                      </p>
                                                    </div>
                                                    <div className='cp-contact2 profile-sep'>
                                                      <h4 className='cp-title'>
                                                        Emergency Contact #2
                                                      </h4>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile.emgcontact2
                                                          ? this.state.profile.emgcontact2.fname +
                                                            " " +
                                                            this.state.profile.emgcontact2.lname
                                                          : ""}
                                                      </p>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile.emgcontact2 ? (
                                                          // this.formatPhoneNumber(this.state.profile.emgcontact2.phone)
                                                          <a
                                                            onClick={(event) => {
                                                              event.stopPropagation();
                                                            }}
                                                            className='phone_email_link'
                                                            href={`tel:${this.state.profile.emgcontact2.phone}`}
                                                            target='_top'>
                                                            {this.formatPhoneNumber(
                                                              this.state.profile.emgcontact2.phone,
                                                            )}
                                                          </a>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </p>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile.emgcontact2
                                                          ? this.state.profile.emgcontact2.relation
                                                          : ""}
                                                      </p>
                                                    </div>
                                                    <div className='cp-drivers-license profile-sep'>
                                                      <h4 className='cp-title'>Drivers License</h4>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile.drivers_license}
                                                      </p>
                                                    </div>
                                                    <div className='cp-weight profile-sep'>
                                                      <h4 className='cp-title'>Weight (lbs)</h4>
                                                      <p className='crew-profile-detail'>
                                                        {this.state.profile.weight}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </AccordionItemPanel>
                                            </AccordionItem>
                                          </Accordion>
                                        </div>
                                      </div>
                                      <div className='profile-details'>
                                        <p>Status</p>
                                        <a
                                          className='pull-right btn msg-btn'
                                          onClick={this.statusOpenModal}>
                                          {this.state.status}
                                        </a>
                                        {this.state.statusOpen ? (
            <ChangeStatusModal forceCurrentUser  open={this.state.statusOpen} onClose={this.statusCloseModal} timeOffFlag={false} />
          ) : null}

                                        {/* <NewModal
                                          id='status_popup'
                                          className='status_popup'
                                          open={this.state.statusOpen}
                                          onClose={this.statusCloseModal}
                                          little>
                                          <div id='statusPopup'>
                                            <div className='modal-head'>
                                              <h4 className='modal-title'>Current Status</h4>
                                            </div>
                                            {this.state.statusStep === "1" && (
                                              <div className='modal-body'>
                                                <div className='status_step step1'>
                                                  <label>
                                                    You Are:{" "}
                                                    <span className='green'>{this.state.status}</span>
                                                  </label>
                                                  <br />
                                                  <button
                                                    className='button'
                                                    onClick={this.setStep.bind(this, "2")}>
                                                    Change Current Status
                                                  </button>
                                                  <p className='step_no'>1/3</p>
                                                </div>
                                              </div>
                                            )}
                                            {this.state.statusStep === "2" &&
                                              this.state.status === "Available" && (
                                                <div className='modal-body'>
                                                  <div className='status_step step2'>
                                                    <label>Do you know when you will return?</label>
                                                    <button
                                                      className='button'
                                                      onClick={this.setStatusReturn.bind(this, true)}>
                                                      Yes
                                                    </button>
                                                    <button
                                                      className='button grey'
                                                      onClick={this.setStatusReturn.bind(
                                                        this,
                                                        false,
                                                      )}>
                                                      No
                                                    </button>
                                                    <p className='step_no'>2/3</p>
                                                  </div>
                                                </div>
                                              )}
                                            {this.state.statusStep === "2" &&
                                              this.state.status !== "Available" && (
                                                <div className='modal-body'>
                                                  <div className='status_step step2'>
                                                    <label>Make Available?</label>
                                                    <button
                                                      className='button'
                                                      onClick={this.changeStatus.bind(this, true)}>
                                                      Yes
                                                    </button>
                                                    <button
                                                      className='button grey'
                                                      onClick={this.statusCloseModal.bind(this)}>
                                                      No
                                                    </button>
                                                    <p className='step_no'>2/3</p>
                                                  </div>
                                                </div>
                                              )}
                                            {this.state.statusStep === "2a" && (
                                              <div className='modal-body'>
                                                <div className='status_step step4'>
                                                  <label>Choose date of return:</label>
                                                  <DatePicker
                                                    className='returnDateField'
                                                    name='returnDateField'
                                                    selected={this.state.returnDateField}
                                                    onChange={this.handleDateChange.bind(this)}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                  />
                                                  <button
                                                    className='button'
                                                    onClick={this.setStep.bind(this, "3")}>
                                                    Next
                                                  </button>
                                                  <p className='step_no'>2/3</p>
                                                </div>
                                              </div>
                                            )}
                                            {this.state.statusStep === "2b" && (
                                              <div className='modal-body'>
                                                <div className='status_step step2'>
                                                  <label>Make Unavailable?</label>
                                                  <button
                                                    className='button'
                                                    onClick={this.changeStatus.bind(this, true)}>
                                                    Yes
                                                  </button>
                                                  <button
                                                    className='button grey'
                                                    onClick={this.statusCloseModal.bind(this)}>
                                                    No
                                                  </button>
                                                  <p className='step_no'>2/3</p>
                                                </div>
                                              </div>
                                            )}
                                            {this.state.statusStep === "3" && (
                                              <div className='modal-body'>
                                                <div className='status_step step5'>
                                                  <label>
                                                    Are you sure you want to switch to Unavailable?
                                                  </label>
                                                  <p>
                                                    (returning{" "}
                                                    {this.state.returnDate === ""
                                                      ? moment().format("MM/DD/YY")
                                                      : this.state.returnDate}
                                                    )
                                                  </p>
                                                  <button
                                                    className='button'
                                                    onClick={this.changeStatus.bind(this, true)}>
                                                    Yes
                                                  </button>
                                                  <button
                                                    className='button grey'
                                                    onClick={this.statusCloseModal.bind(this)}>
                                                    No
                                                  </button>
                                                  <p>Time off must be approved by your supervisor.</p>
                                                  <p className='step_no'>3/3</p>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </NewModal> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SlidingPane>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Search Window */}
            <div className={`${this.state.isSearchOpen ? "d-flex" : ""} searchWrapper`}>
              <div className='searchbar'>
                <div className='searchbar_input'>
                  <input
                    ref={this.searchInput}
                    type='text'
                    className='searchbox'
                    placeholder='Search Here'
                    onKeyUp={this.keyUpFn}
                  />

                  <button className='clear_input' onClick={this.clearSearch}>
                    <img src={`${config.assetUrl}icons/clear-input.svg`} alt='clear-icon' />
                  </button>
                </div>
                <div className='cancel_btn'>
                  <button onClick={this.toggleSearch} className='search_cancel'>
                    Cancel
                  </button>
                </div>
              </div>
              <div className='search_results universalSearch_result'>{this.state.searchResult}</div>
            </div>

            {/* Dropdown Menu */}
            {/* <div className={`${this.state.isMenuOpen ? 'new_nav_show' : 'new_nav_hide'} new_nav_list navbar-inverse`} > */}
            <div className={`${this.state.navClass} new_nav_list navbar-inverse`} ref={this.navbar}>
              <ul className='nav navbar-nav'>
                <li>
                  <NavLink
                    to={"/dashboard"}
                    activeClassName={"active"}
                    onClick={this.closeNav.bind(this)}>
                    Dashboard
                  </NavLink>
                </li>
                {this.state.permList.includes("Your Crew") &&
                  parseInt(localStorage.getItem("avail")) === 1 && (
                    <li>
                      <NavLink
                        to={"/crew"}
                        activeClassName={"active"}
                        onClick={this.closeNav.bind(this)}>
                        Your Crew
                      </NavLink>
                    </li>
                  )}
                {this.state.permList.includes("Manage Resources") && (
                  <li>
                    <NavLink
                      to={"/resources"}
                      activeClassName={"active"}
                      onClick={this.closeNav.bind(this)}
                      isActive={(match, location) => {
                        if (location.pathname.includes("resource")) {
                          return true;
                        }
                        return false;
                      }}>
                      Resources
                    </NavLink>
                  </li>
                )}
                {this.state.permList.includes("Inventory") && (
                  <li>
                    <NavLink
                      to={"/inventory"}
                      activeClassName={"active"}
                      onClick={this.closeNav.bind(this)}>
                      Inventory
                    </NavLink>
                  </li>
                )}
                {this.state.permList.includes("Assign Staff") && (
                  <li>
                    <NavLink
                      to={"/assignstaff"}
                      activeClassName={"active"}
                      onClick={this.closeNav.bind(this)}>
                      Assign Staff
                    </NavLink>
                  </li>
                )}
                {this.state.permList.includes("Manage Applicants") && (
                  <li>
                    <NavLink
                      to={"/applicants"}
                      activeClassName={"active"}
                      onClick={this.closeNav.bind(this)}>
                      Applicants
                    </NavLink>
                  </li>
                )}
                {this.state.permList.includes("Chat") && (
                  <li>
                    <NavLink
                      to={"/chat"}
                      activeClassName={"active"}
                      onClick={this.closeNav.bind(this)}>
                      Messages{" "}
                      {count && pathname !== "/chat" ? (
                        <span className='notification-count'>{count <= 9 ? count : "9+"}</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                )}
                {/* {this.state.permList.includes("Manage Docs") && (
                  <li>
                    <NavLink
                      to={"/docs"}
                      activeClassName={"active"}
                      onClick={this.closeNav.bind(this)}>
                      Documents
                    </NavLink>
                  </li>
                )} */}
                {this.state.permList.includes("Manage Reports") && (
                  <li>
                    <NavLink
                      to={"/reports"}
                      activeClassName={"active"}
                      onClick={this.closeNav.bind(this)}>
                      Reports
                    </NavLink>
                  </li>
                )}
                   {this.state.permList.includes("View Activity Log") && (
                  <li>
                    <NavLink to={"/activity-log"} activeClassName={"active"} isActive={(match, location) => {
                      if (location.pathname === "/activity-log") {
                        return true;
                      }
                      return false;
                    }} onClick={this.closeNav.bind(this)}>
                      Activity log
                    </NavLink>
                  </li>
                  )}
                <li>
                  <NavLink
                    to={"/settings"}
                    activeClassName={"active"}
                    onClick={this.closeNav.bind(this)}>
                    Profile & Settings
                  </NavLink>
                </li>
                {/* <li className="setting_link"><a href="">Settings</a></li> */}
              </ul>
            </div>
            {/* Overlay */}
            {/* <div className={`${this.state.overlay} nav-overlay`}></div> */}
          </div>

          {/* Desktop */}
          <div className='row top_bar' style={{'top': banner_height}}>
            {this.state.permList && this.state.permList.includes("System Search") && (
              <div className='search left col-xs-5 col-sm-4 col-md-5 col-lg-6'>
                <form>
                  <input
                    ref={this.searchDesktop}
                    type='text'
                    name='search_box'
                    placeholder='Search'
                    onClick={this.showOverlay}
                    onKeyUp={this.keyUpFn}
                    autoComplete='off'
                  />
                </form>
                <div
                  id='searchResultTop'
                  ref={this.wrapperRef}
                  className={this.state.hideSearch + " universalSearch_result"}>
                  {searchArray}
                </div>
              </div>
            )}
            <div className='top_right col-xs-7 col-sm-8 col-md-7 col-lg-6'>
              <div className='top_outer'>
                <div className='profile'>
                  <img
                    src={image}
                    className='profile_pic'
                    onClick={() =>
                      this.state.isPaneOpenLeft
                        ? this.setState({ isPaneOpenLeft: false })
                        : this.setState({ isPaneOpenLeft: true })
                    }
                    alt='profile_pic'
                    onError={this.onImageError.bind(this)}
                  />
                </div>
                <div className='status'>
                  <p onClick={this.handleStatusChange}>
                    <span>Status: </span>
                    <span className='status_value'> {this.state.status} </span>
                  </p>
                </div>
                <div className='Logout_div'>
                  <Link className='logout' to='/login' onClick={this.logout}>
                    {" "}
                    Logout{" "}
                  </Link>
                </div>
                <div className='notification_div'>
                  <p
                    className={"notification " + this.state.notify_active + " " + notify}
                    id='desktop'
                    onClick={this.toggle}>
                    {this.state.count !== 0 && (
                      <span className='notify-count'>
                        {this.state.count > 0 ? this.state.count : ""}
                      </span>
                    )}
                  </p>
                  {/* Notification Dropdown */}
                  {this.state.permList && this.state.permList.includes("Notifications") && (
                    <Popover
                      className='notify_dropdown'
                      placement='bottom'
                      isOpen={this.state.PopoverOpenDesktop}
                      target='desktop'
                      toggle={this.toggle}>
                      <div className='pop_header'>
                        <PopoverHeader>Notifications</PopoverHeader>
                        <span className='mark_as_read' onClick={this.markAsRead}>
                          Mark All as Read
                        </span>
                      </div>
                      <PopoverBody id='notification_outer'>
                        <div className='notfication_listing'>
                          {this.state.new_list}
                          {this.state.old_list.length > 1 && this.state.old_list}
                          {this.state.applicationItem}
                          <Link
                            to='/notifications'
                            onClick={this.readMore}
                            className='btn-readmore'
                            id='btn-readmore'>
                            Read More
                          </Link>
                        </div>
                      </PopoverBody>
                    </Popover>
                  )}

                  {/* Settings Icon */}
                  {/* <Link
                    to={"/settings"}
                    className={
                      window.location.pathname === "/settings"
                        ? "settings settings_page "
                        : "settings"
                    }
                  /> */}
                </div>
              </div>
            </div>

            {/* Profile Slide In Menu */}
            <SlidingPane
              className='sliding_profile '
              overlayClassName='sliding_profile_overlay'
              isOpen={this.state.isPaneOpenLeft}
              onRequestClose={() =>
                this.state.isPaneOpenLeft
                  ? this.setState({ isPaneOpenLeft: false })
                  : this.setState({ isPaneOpenLeft: true })
              }>
              <div className='sliding_content desktopNavbar'>
                <div className='profile_name'>
                  {/* Back Button for Mobile */}
                  {/* <p className="back-button" onClick={()=> this.setState({ isPaneOpenLeft: false })}>&#x2039; &nbsp;Back</p> */}
                  <div
                    className='back-button slide-pane__close'
                    onClick={() => this.setState({ isPaneOpenLeft: false })}></div>
                  <div className='status' />
                  <p>
                    <span className={"color_code " + this.state.profile.colorcode} />
                    {this.state.profile.first_name + " " + (this.state.profile.middle_name ? this.state.profile.middle_name + " " : "") + this.state.profile.last_name}
                  </p>
                  <div>
                    <Link
                      to={"/settings"}
                      onClick={() => {
                        this.setState({ isPaneOpenLeft: false });
                      }}>
                      <img
                        className={(this.state.isGuest ? 'guest-code' : this.state.profile.active) + " profilePicture availability"}
                        src={
                          this.state.profile.profile_photo
                            ? config.apiUrl + "/" + this.state.profile.profile_photo
                            : config.apiUrl + "/" + config.altImg
                        }
                        alt='Name'
                        onError={this.onImageError.bind(this)}
                      />
                    </Link>
                  </div>
                  <div className='clearFix' />
                  <div className='emp_type'>
                    <p>{this.state.profile.user_type}</p>
                    {this.state.profile.crew_name && <p>{this.state.profile.crew_name}</p>}
                    <p>{this.state.profile.nvam_id}</p>
                    {/* <p>{this.state.profile.ica_number}</p> */}
                    {this.state.profile.primary_crew_name && (
                      <p>Primary Crew: {this.state.profile.primary_crew_name}</p>
                    )}
                                               
                    {this.state.profile.applying_company !== 0 && <p>Company: {getCompanyName(this.state.profile.applying_company)}</p>}
                    {this.state.profile.active=== currentStatus.Unavailable&&(
                                    <p>Unavailable: {(this.state.profile.unavailable_reason,
                                      this.state.profile.other_reason)}</p>
                                  )}

                  </div>
                  <div className='cp-qual profile-sep'>
                    <h4 className='cp-title'>Qualifications</h4>
                    {this.state.generalQualificationList}
                  </div>
                  <div className='cp-cellno profile-sep'>
                    <h4 className='cp-title'>Cell Number</h4>
                    <p className='crew-profile-detail'>
                      {this.state.profile && this.state.profile.phone ? (
                        <a
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          className='phone_email_link'
                          href={`tel:${this.state.profile.phone}`}
                          target='_top'>
                          {this.formatPhoneNumber(this.state.profile.phone)}
                        </a>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <div className='cp-sec profile-sep'>
                    <h4 className='cp-title'>Secondary Number</h4>
                    <p className='crew-profile-detail'>
                      {this.state.profile && this.state.profile.second_phone !== "null" ? (
                        <a
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          className='phone_email_link'
                          href={`tel:${this.state.profile.second_phone}`}
                          target='_top'>
                          {this.formatPhoneNumber(this.state.profile.second_phone)}
                        </a>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <div className='cp-email profile-sep'>
                    <h4 className='cp-title'>Email</h4>
                    <p className='crew-profile-detail'>
                      {this.state.profile && this.state.profile.email ? (
                        <a
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          className='phone_email_link'
                          href={`mailto:${this.state.profile.email}`}
                          target='_top'>
                          {this.state.profile.email}
                        </a>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <div className='cp-madr profile-sep'>
                    <h4 className='cp-title'>Mailing Address</h4>
                    <p className='crew-profile-detail'>
                      {this.state.profile.mailing_address
                        ? this.state.profile.mailing_address.address
                        : ""}
                    </p>
                    <p className='crew-profile-detail txtformat-capitalize'>
                      {this.state.profile.mailing_address
                        ? this.state.profile.mailing_address.city
                        : ""}
                    </p>
                    <p className='crew-profile-detail txtformat-capitalize'>
                      {this.state.profile.mailing_address
                        ? this.state.profile.mailing_address.state
                        : ""}
                    </p>
                    <p className='crew-profile-detail'>
                      {this.state.profile.mailing_address
                        ? this.state.profile.mailing_address.zip
                        : ""}
                    </p>
                  </div>
                  <div className='cp-pa profile-sep'>
                    <h4 className='cp-title'>Physical Address</h4>
                    <p className='crew-profile-detail'>
                      {this.state.profile.physical_address
                        ? this.state.profile.physical_address.address
                        : ""}
                    </p>
                    <p className='crew-profile-detail txtformat-capitalize'>
                      {this.state.profile.physical_address
                        ? this.state.profile.physical_address.city
                        : ""}
                    </p>
                    <p className='crew-profile-detail txtformat-capitalize'>
                      {this.state.profile.physical_address
                        ? this.state.profile.physical_address.state
                        : ""}
                    </p>
                    <p className='crew-profile-detail'>
                      {this.state.profile.physical_address
                        ? this.state.profile.physical_address.zip
                        : ""}
                    </p>
                  </div>
                  <div className='cp-sex profile-sep'>
                    <h4 className='cp-title'>Sex</h4>
                    <p className='crew-profile-detail'>{this.state.profile.gender}</p>
                  </div>
                  <div className='cp-contact1 profile-sep'>
                    <h4 className='cp-title'>Emergency Contact #1</h4>
                    <p className='crew-profile-detail'>
                      {this.state.profile.emgcontact1
                        ? this.state.profile.emgcontact1.fname +
                          " " +
                          this.state.profile.emgcontact1.lname
                        : ""}
                    </p>
                    <p className='crew-profile-detail'>
                      {this.state.profile.emgcontact1 ? (
                        // this.formatPhoneNumber(this.state.profile.emgcontact1.phone)
                        <a
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          className='phone_email_link'
                          href={`tel:${this.state.profile.emgcontact1.phone}`}
                          target='_top'>
                          {this.formatPhoneNumber(this.state.profile.emgcontact1.phone)}
                        </a>
                      ) : (
                        ""
                      )}
                    </p>
                    <p className='crew-profile-detail'>
                      {this.state.profile.emgcontact1 ? this.state.profile.emgcontact1.relation : ""}
                    </p>
                  </div>
                  <div className='cp-contact2 profile-sep'>
                    <h4 className='cp-title'>Emergency Contact #2</h4>
                    <p className='crew-profile-detail'>
                      {this.state.profile.emgcontact2
                        ? this.state.profile.emgcontact2.fname +
                          " " +
                          this.state.profile.emgcontact2.lname
                        : ""}
                    </p>
                    <p className='crew-profile-detail'>
                      {this.state.profile.emgcontact2 ? (
                        // this.formatPhoneNumber(this.state.profile.emgcontact2.phone)
                        <a
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          className='phone_email_link'
                          href={`tel:${this.state.profile.emgcontact2.phone}`}
                          target='_top'>
                          {this.formatPhoneNumber(this.state.profile.emgcontact2.phone)}
                        </a>
                      ) : (
                        ""
                      )}
                    </p>
                    <p className='crew-profile-detail'>
                      {this.state.profile.emgcontact2 ? this.state.profile.emgcontact2.relation : ""}
                    </p>
                  </div>
                  <div className='cp-drivers-license profile-sep'>
                    <h4 className='cp-title'>Drivers License</h4>
                    <p className='crew-profile-detail'>{this.state.profile.drivers_license}</p>
                  </div>
                  <div className='cp-weight profile-sep'>
                    <h4 className='cp-title'>Weight (lbs)</h4>
                    <p className='crew-profile-detail'>{this.state.profile.weight}</p>
                  </div>
                </div>
              </div>
            </SlidingPane>

            {this.state.statusOpen ? (
            <ChangeStatusModal forceCurrentUser open={this.state.statusOpen} onClose={this.statusCloseModal} timeOffFlag={timeOffFlag} />
          ) : null}

            {/* <NewModal
              id='status_popup'
              className='status_popup'
              open={this.state.statusOpen}
              onClose={this.statusCloseModal.bind(this)}
              little>
              <div id='statusPopup'>
                <div className='modal-head'>
                  <h4 className='modal-title'>Current Status</h4>
                </div>
                {this.state.statusStep === "1" && (
                  <div className='modal-body'>
                    <div className='status_step step1'>
                      <label>
                        You Are: <span className='green'>{this.state.status}</span>
                      </label>
                      <br />
                      <button className='button' onClick={this.setStep.bind(this, "2")}>
                        Change Current Status
                      </button>
                      <p className='step_no'>1/3</p>
                    </div>
                  </div>
                )}
                {this.state.statusStep === "2" && this.state.available && (
                  <div className='modal-body'>
                    <div className='status_step step2'>
                      <label>Do you know when you will return?</label>
                      <button className='button' onClick={this.setStatusReturn.bind(this, true)}>
                        Yes
                      </button>
                      <button
                        className='button grey'
                        onClick={this.setStatusReturn.bind(this, false)}>
                        No
                      </button>
                      <p className='step_no'>2/3</p>
                    </div>
                  </div>
                )}
                {this.state.statusStep === "2" && !this.state.available && (
                  <div className='modal-body'>
                    <div className='status_step step2'>
                      <label>Make Available?</label>
                      <button className='button' onClick={this.changeStatus.bind(this, true)}>
                        Yes
                      </button>
                      <button className='button grey' onClick={this.statusCloseModal.bind(this)}>
                        No
                      </button>
                      <p className='step_no'>2/3</p>
                    </div>
                  </div>
                )}
                {this.state.statusStep === "2a" && (
                  <div className='modal-body'>
                    <div className='status_step step4'>
                      <label>Choose date of return:</label>
                      <DatePicker
                        className='returnDateField'
                        name='returnDateField'
                        selected={this.state.returnDateField}
                        onChange={this.handleDateChange.bind(this)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                      />
                      <button className='button btn_margin' onClick={this.setStep.bind(this, "3")}>
                        Next
                      </button>
                      <p className='step_no'>2/3</p>
                    </div>
                  </div>
                )}
                {this.state.statusStep === "2b" && (
                  <div className='modal-body'>
                    <div className='status_step step2'>
                      <label>Make Unavailable?</label>
                      <button className='button' onClick={this.changeStatus.bind(this, true)}>
                        Yes
                      </button>
                      <button className='button grey' onClick={this.statusCloseModal.bind(this)}>
                        No
                      </button>
                      <p className='step_no'>2/3</p>
                    </div>
                  </div>
                )}
                {this.state.statusStep === "3" && (
                  <div className='modal-body'>
                    <div className='status_step step5'>
                      <label>Are you sure you want to switch to Unavailable?</label>
                      <p>
                        (returning{" "}
                        {this.state.returnDate === ""
                          ? moment().format("MM/DD/YY")
                          : this.state.returnDate}
                        )
                      </p>
                      <button className='button' onClick={this.changeStatus.bind(this, true)}>
                        Yes
                      </button>
                      <button className='button grey' onClick={this.statusCloseModal.bind(this)}>
                        No
                      </button>
                      <p>Time off must be approved by your supervisor.</p>
                      <p className='step_no'>3/3</p>
                    </div>
                  </div>
                )}
              </div>
            </NewModal> */}
          </div>
        </main>
      </section>
    );
  }
}
function mapStateToProps(state) {
  const { notification, user, search, permission, chat, docs } = state;
  return {
    notification,
    user,
    search,
    permission,
    chat,
    docs,
  };
}

const connectedHomePage = connect(mapStateToProps)(Navbar);
export { connectedHomePage as Navbar };