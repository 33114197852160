import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config } from "_helpers/config";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { docsActions } from "_actions/docs.actions";
import moment from "moment";
import { toast } from "react-toastify";
import { SignPad } from "Docs/Components";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";

class PaycheckDistribution extends Component {
  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.state = {
      rehire_doc: false,
      isNotesOpen: false,
      isChangesOpen: false,
      isLostOpen: false,
      isDiscrepanciesOpen: false,
      doc: {},
      form: {
        payment_method: "paper_check",
        paper_check_type: "mail_to_address",
        direct_deposit_type: "view_paystub_details",
        w2: "w2",
        w2_type: "e_copy",
        disclaimer_check: false,
        signed_date: moment().format("MM/DD/YYYY"),
        direct_deposit_must_complete: "dd_form"
      },
      errors: {},
      form_edited: false,
      enableOldVersion: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = navigate.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
    this.signContainer = React.createRef();
  }

  toggleList(list) {
    switch (list) {
      case "notes":
        this.state.isNotesOpen ? this.setState({ isNotesOpen: false }) : this.setState({ isNotesOpen: true });
        break;
      case "changes":
        this.state.isChangesOpen ? this.setState({ isChangesOpen: false }) : this.setState({ isChangesOpen: true });
        break;
      case "lost":
        this.state.isLostOpen ? this.setState({ isLostOpen: false }) : this.setState({ isLostOpen: true });
        break;
      case "discrepancies":
        this.state.isDiscrepanciesOpen
          ? this.setState({ isDiscrepanciesOpen: false })
          : this.setState({ isDiscrepanciesOpen: true });
        break;
      default:
        this.setState({
          isListOpen: false,
        });
        break;
    }
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date) {
      _.set(nextState, field, date.format("MM/DD/YYYY"));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: "",
      },
    });
  }

  handleFormChange(e) {
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { name, value, checked, type } = e.target;
      if (type == "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();

    if (err) {
      return;
    }

    let { form, doc } = this.state;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc ? (data.single_doc = true) : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState({
      ...nextState,
      errors: {
        ...this.state.errors,
        [field]: "",
      },
    });
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let enableOldVersion = false;
        let { form, user } = res.data;
        if (form.paper_check_type == "hold_in_chico" || form.direct_deposit_type == "hold_in_chico") {
          enableOldVersion = true;
        }
        // date needs to a moment object or Datepicker breaks.
        form.w2 = currentState.form.w2;
        form.signed_date = form.signed_date || currentState.form.signed_date;
        form.dob = form.dob || currentState.form.dob;
        if (!form?.printed_name) {
          form.printed_name = user?.full_name ?? ""
        }
        // spread the data coming from API into state
        this.setState({
          form,
          enableOldVersion,
        });
      }
    });
  }

  goBack() {
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen("sidebar");
    }
  }

  componentDidMount() {
    let docSlug = this.props.slug ? this.props.slug : this.props.match.params.doc;
    let doc;
    // if (this.props.rehire) {
    //   doc = this.props.docs.userDocs.find(
    //     (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //   );
    // } else {
    //   doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 1);
    // }

    if (this.props.giss) {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id === 5);
      } else {
        doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 4);
      }
    } else {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id === 2);
      } else {
        this.props.single_doc
          ? (doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id === 3))
          : (doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 1));
      }
    }
    this.props.single_doc && doc && (doc.id = this.props.new_doc_id);


    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;
    this.setState({
      doc,
      sign: {
        height: height,
        width: width,
      },
    });
    if(doc?.id){
      this.getFormData(doc.id);
    }
  }

  render() {
    let { form, errors, enableOldVersion } = this.state;
    let { adminMode ,single_doc} = this.props;

    const disableSignPad = single_doc ? false : adminMode;

    return (
      <div className="doc_info-rh">
        {/* Title */}
        <div className="doc-title">
          <img src={`${config.assetUrl}icons/doc-title-img.svg`} className="doc-title-img" alt="" />
          <span className="close-button" onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} alt="" />
          </span>
          <h3>Paycheck Distribution Form</h3>
        </div>
        <div className="doc_info-form">
          <form
            className="info-form"
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            name="candidate_info_page"
            noValidate
          >
            <div className="row form-row no_border pt-0">
              <div className="col">
                <div className="inputs-col">
                  {/*single input */}
                  <div className="inputs-head">
                    Employee Name<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors["form.printed_name"] ? "error" : ""}`}
                      name="printed_name"
                      id="printed_name"
                      value={form.printed_name}
                      field="form.printed_name"
                      placeholder="- Enter here -"
                      required
                    />
                    {errors["form.printed_name"] && (
                      <label htmlFor="printed_name" className="error_label">
                        {errors["form.printed_name"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="inputs-col">
                  I would like to have my paychecks or paystubs distributed as stipulated below.
                </div>
              </div>
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group agreement_checks">
                    <div className="check_radio_item">
                      <input
                        type="radio"
                        name="payment_method"
                        id="payment_method_paper_check"
                        field="form.payment_method"
                        value="paper_check"
                        checked={form.payment_method == "paper_check"}
                      />
                      <span className="radio_checkbox square"></span>
                      <label htmlFor="payment_method_paper_check" className="v-align-sub">
                        <strong>Papercheck</strong>
                      </label>
                    </div>
                  </div>
                  {form.payment_method === "paper_check" && (
                    <div className="check_radio_group agreement_radio">
                      {enableOldVersion && (
                        <div className="check_radio_item">
                          <input
                            type="radio"
                            name="paycheck"
                            id="paycheck1"
                            value="hold_in_chico"
                            field="form.paper_check_type"
                            checked={form.paper_check_type == "hold_in_chico"}
                            required
                          />
                          <span className="radio_checkbox"></span>
                          <label htmlFor="paycheck1" className="v-align-sub">
                            Hold in CHICO
                          </label>
                          <span className="notes">(Available for pick up after 9am on payday)</span>
                        </div>
                      )}
                      <div className="check_radio_item">
                        <input
                          type="radio"
                          name="paycheck"
                          id="paycheck2"
                          value="mail_to_address"
                          field="form.paper_check_type"
                          checked={form.paper_check_type == "mail_to_address"}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="paycheck2" className="v-align-sub">
                          Mailed to address on file
                        </label>
                        <span className="notes">(postage fee per paycheck based on current postage rates apply. Mailed day before payday. Date of delivery not guaranteed.)
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group agreement_checks">
                    <div className="check_radio_item">
                      <input
                        type="radio"
                        name="payment_method"
                        id="payment_method_dd"
                        field="form.payment_method"
                        value="direct_deposit"
                        checked={form.payment_method == "direct_deposit"}
                      />
                      <span className="radio_checkbox square"></span>
                      <label htmlFor="payment_method_dd" className="v-align-sub">
                        <strong>Direct Deposit*:</strong>
                        <i>{` Direct Deposit fee applies.`}</i>
                      </label>
                    </div>
                  </div>
                  {form.payment_method === "direct_deposit" && (
                    <div style={{ marginLeft: 30, display: "flex", flexDirection: "column" }}>
                      <label htmlFor="payment_method_dd" className="v-align-sub">
                        <strong> *Must complete Direct Deposit form:</strong>
                      </label>
                      <div className="check_radio_group agreement_radio">
                        <div className="check_radio_item">
                          <input
                            type="radio"
                            name="direct_deposit_must_complete"
                            value="dd_form"
                            field="form.direct_deposit_must_complete"
                            id="direct_deposit1"
                            checked={form.direct_deposit_must_complete == "dd_form"}
                            required
                          />
                          <span className="radio_checkbox"></span>
                          <label htmlFor="direct_deposit1" className="v-align-sub">
                            DD form & Banking Information attached
                          </label>
                        </div>
                        <div className="check_radio_item">
                          <input
                            type="radio"
                            name="direct_deposit_must_complete"
                            value="previous_submitted"
                            field="form.direct_deposit_must_complete"
                            id="direct_deposit1"
                            checked={form.direct_deposit_must_complete == "previous_submitted"}
                            required
                          />
                          <span className="radio_checkbox"></span>
                          <label htmlFor="direct_deposit1" className="v-align-sub">
                            Previously submitted – no change
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  {form.payment_method === "direct_deposit" && (
                    <div className="check_radio_group agreement_radio">
                      <label htmlFor="payment_method_dd" className="v-align-sub" style={{ marginLeft: 30, paddingBottom: 10 }}>
                        <strong>Select one option for delivery:</strong>
                      </label>
                      <div className="check_radio_item">
                        <input
                          type="radio"
                          name="direct_deposit"
                          value="view_paystub_details"
                          field="form.direct_deposit_type"
                          id="direct_deposit1"
                          checked={form.direct_deposit_type == "view_paystub_details"}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="direct_deposit1" className="v-align-sub">
                          No Stubs: View paystub details on ViewMyPaycheck
                        </label>
                        <span className="notes">(timesheets not included)</span>
                      </div>
                      {enableOldVersion && (
                        <div className="check_radio_item">
                          <input
                            type="radio"
                            name="direct_deposit"
                            value="hold_in_chico"
                            field="form.direct_deposit_type"
                            id="direct_deposit2"
                            checked={form.direct_deposit_type == "hold_in_chico"}
                            required
                          />
                          <span className="radio_checkbox"></span>
                          <label htmlFor="direct_deposit2" className="v-align-sub">
                            Hold in CHICO
                          </label>
                          <span className="notes">(Available for pick up after 9am on payday)</span>
                        </div>
                      )}
                      <div className="check_radio_item">
                        <input
                          type="radio"
                          name="direct_deposit"
                          value="mail_to_address"
                          field="form.direct_deposit_type"
                          id="direct_deposit3"
                          checked={form.direct_deposit_type == "mail_to_address"}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="direct_deposit3" className="v-align-sub">
                          Mail to address on file{" "}
                        </label>
                        <span className="notes">(Postage fee applies)</span>
                        {/* <span className="notes">
                          <i>*PLEASE CHECK IF DD IS SAME AS LAST YEAR*</i>
                        </span> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group agreement_checks">
                    <div className="check_radio_item">
                      <input type="radio" name="w2" id="w2" field="form.w2" value="w2" checked={form.w2 == "w2"} />
                      <span className="radio_checkbox square"></span>
                      <label htmlFor="w2" className="v-align-sub">
                        <strong>W-2:</strong>
                        {` Select one option for delivery below. This is how you will receive your W-2.`}
                      </label>
                    </div>
                    {errors["form.payment_method"] && (
                      <label htmlFor="payment_method" className="error_label">
                        {errors["form.payment_method"]}
                      </label>
                    )}
                  </div>
                  {/* Subitems */}
                  <div className="check_radio_group agreement_radio">
                    <div className="check_radio_item">
                      <input
                        type="radio"
                        name="w2_type"
                        value="e_copy"
                        id="w21"
                        checked={form.w2_type == "e_copy"}
                        field="form.w2_type"
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor="w21" className="v-align-sub">
                        ELECTRONIC copy received through ViewMyPayCheck
                      </label>
                      <span className="notes">(Intuit account)</span>
                    </div>
                    <div className="check_radio_item">
                      <input
                        type="radio"
                        name="w2_type"
                        value="mail_to_address"
                        id="w22"
                        checked={form.w2_type == "mail_to_address"}
                        field="form.w2_type"
                        required
                      />
                      <span className="radio_checkbox"></span>
                      <label htmlFor="w22" className="v-align-sub">
                        MAIL to address on file
                      </label>
                      <span className="notes">(Postage fee applies)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            {/* <div className="row form-row">
              <div className="col">
                <div className="disclaimers dis-notes">
                  <div className="inputs-head">
                    Notes
                    <button
                      type="button"
                      className={`toggler_btn ${this.state.isNotesOpen ? "toggled" : ""}`}
                      onClick={() => this.toggleList("notes")}
                    ></button>
                  </div>
                  {this.state.isNotesOpen && (
                    <Fragment>
                      <ul>
                        {enableOldVersion && (
                          <>
                            <li>Hold in CHICO - Available for pick up after 9am on payday</li>
                            <li>
                              Checks that are not picked up in a timely fashion will be mailed and postage charges will
                              apply
                            </li>
                          </>
                        )}
                        <li>
                          MAIL - postage fee per paycheck based on current postage rates apply. Mailed day before
                          payday. Date of delivery not guaranteed.
                        </li>
                      </ul>
                    </Fragment>
                  )}
                </div>
              </div>
            </div> */}
            {/* {((enableOldVersion && form.payment_method == "paper_check" && form.paper_check_type == "hold_in_chico") ||
              (enableOldVersion &&
                form.payment_method == "direct_deposit" &&
                form.direct_deposit_type == "hold_in_chico") ||
              !enableOldVersion) && (
              <div className="row form-row">
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">Authorized Pick Up</div>
                    <p className="m-0">
                      The following are authorized to pick up my paycheck from the Chico office location: Authorized
                      person(s) may be required to show proof of identification.
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">Authorized Person #1</div>
                    <div className="inputs-items group two-item">
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${errors["form.authorized_name1"] ? "error" : ""}`}
                          name="authorized_name1"
                          id="authorized_name1"
                          value={form.authorized_name1}
                          field="form.authorized_name1"
                          placeholder="Name"
                          required
                        />
                        {errors["form.authorized_name1"] && (
                          <label htmlFor="authorized_name1" className="error_label">
                            {errors["form.authorized_name1"]}
                          </label>
                        )}
                      </div>
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${errors["form.authorized_relation1"] ? "error" : ""}`}
                          name="authorized_relation1"
                          id="authorized_relation1"
                          value={form.authorized_relation1}
                          field="form.authorized_relation1"
                          placeholder="Relationship"
                          required
                        />
                        {errors["form.authorized_relation1"] && (
                          <label htmlFor="authorized_relation1" className="error_label">
                            {errors["form.authorized_relation1"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">Authorized Person #2</div>
                    <div className="inputs-items group two-item">
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${errors["form.authorized_name2"] ? "error" : ""}`}
                          name="authorized_name2"
                          id="authorized_name2"
                          value={form.authorized_name2}
                          field="form.authorized_name2"
                          placeholder="Name"
                          required
                        />
                        {errors["form.authorized_name2"] && (
                          <label htmlFor="authorized_name2" className="error_label">
                            {errors["form.authorized_name2"]}
                          </label>
                        )}
                      </div>
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${errors["form.authorized_relation2"] ? "error" : ""}`}
                          name="authorized_relation2"
                          id="authorized_relation2"
                          value={form.authorized_relation2}
                          field="form.authorized_relation2"
                          placeholder="Relationship"
                          required
                        />
                        {errors["form.authorized_relation2"] && (
                          <label htmlFor="authorized_relation2" className="error_label">
                            {errors["form.authorized_relation2"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
            {/*row ends*/}
            <div className="row form-row">
              <div className="col">
                <div className="disclaimers dis-notes">
                  <div className="inputs-head">
                    CHANGES
                    <button
                      type="button"
                      className={`toggler_btn ${this.state.isChangesOpen ? "toggled" : ""}`}
                      onClick={() => this.toggleList("changes")}
                    ></button>
                  </div>
                  {this.state.isChangesOpen && (
                    <Fragment>
                      <p>Any changes, including but not limited to the following, must be made in writing:</p>
                      <ul>
                        <li>Change in paycheck distribution preference.</li>
                        <li>Change in current mailing address.</li>
                        <li>Change in Direct Deposit or banking information.</li>
                      </ul>
                    </Fragment>
                  )}
                  <div className="separator" />
                  <div className="inputs-head">
                    LOST OR STOLEN CHECKS
                    <button
                      type="button"
                      className={`toggler_btn ${this.state.isLostOpen ? "toggled" : ""}`}
                      onClick={() => this.toggleList("lost")}
                    ></button>
                  </div>
                  {this.state.isLostOpen && (
                    <Fragment>
                      <p>
                        In the event a check must be re-issued due to loss or non-reporting of a
                        change of address, a stop-payment fee of $35.00 for each check will be issued.
                      </p>
                    </Fragment>
                  )}

                  <div className="separator" />
                  <div className="inputs-head">
                    DISCREPANCIES
                    <button
                      type="button"
                      className={`toggler_btn ${this.state.isDiscrepanciesOpen ? "toggled" : ""}`}
                      onClick={() => this.toggleList("discrepancies")}
                    ></button>
                  </div>
                  {this.state.isDiscrepanciesOpen && (
                    <Fragment>
                      <p>
                        Any discrepancies regarding work hours or pay rates should be discussed with the
                        field supervisor. The field supervisor will then contact the corporate office to resolve the issue.
                        Employees should avoid calling the office unless directed to do so by their supervisor.
                      </p>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Employee Signature<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className="sign_here"
                    >
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(this, "form.employee_signature")}
                            signature={form.employee_signature}
                            disabled={disableSignPad}
                          />
                          <input
                            type="hidden"
                            value={form.employee_signature}
                            name="employee_signature"
                            field="form.employee_signature"
                            required
                          ></input>
                          {errors["form.employee_signature"] && (
                            <label htmlFor="employee_signature" className="error_label">
                              {errors["form.employee_signature"]}
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                    {/* <div className="sign_here">
                                            <div className="sign_canvas">
                                                <div className="sign_placeholder">- Sign Here -</div>
                                                <canvas id="sign_here" />
                                            </div>
                                            <div className="sign_btn-group">
                                                add class "accept-sign" to the button to enable
                                                <button type="submit" className="aplcn-prev-button">Clear</button>
                                                <button type="submit" className="aplcn-next-btn accept-sign">Click to
                                                Accept Signature</button>
                                            </div>
                                        </div> */}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Date<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors["form.date"] ? "error" : ""} w-100`}
                      name="signed_date"
                      id="signed_date"
                      selected={moment(form.signed_date)}
                      field="form.signed_date"
                      placeholder="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(this, "form.signed_date")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                      required
                    />
                    {errors["form.signed_date"] && (
                      <label htmlFor="signed_date" className="error_label">
                        {errors["form.signed_date"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row">
              <div className="col">
                <div className="disclaimers">
                  <strong className="diss">Disclaimer: </strong>You must read and carefully understand the documents
                  that you are filling out and signing. These are legally binding documents and must be accurate. By
                  filling these forms out, you are agreeing to our{" "}
                  <a href="">Terms of Use, Privacy Policy and Employee Agreements.</a>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row last">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group check_disclaimer">
                    <div className="check_radio_item">
                      <input
                        type="checkbox"
                        name="disclaimer_check"
                        value="disclaimer_ture"
                        id="disclaimer_check"
                        field="form.disclaimer_check"
                        checked={form.disclaimer_check}
                        required
                      />
                      <span className="checkbox_checkbox"></span>
                      <label htmlFor="disclaimer_check">I have confirmed the above details are true and valid.</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <FormNav
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.disclaimer_check}
              mobileMode={this.props.mobileMode}
            />
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

let connectedPaycheckDistribution = compose(withRouter, connect(mapStateToProps))(PaycheckDistribution);
export { connectedPaycheckDistribution as PaycheckDistribution };
