import React from "react";
import { connect } from "react-redux";
import { applicantsActions } from "../_actions";
import DatePicker from "react-datepicker";

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      param: {
        var1: "all",
        var2: "all"
      },
      filter_list: []
    };
    this.goBack = this.goBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(applicantsActions.getFilters());
  }

  goBack() {
    this.props.goBack();
  }

  handleChange(event) {
    this.setState({
      param: {
        ...this.state.param,
        [event.target.name]: event.target.value
      },
      template: "custommail"
    });
  }

  handleFilter() {
    this.componentWillReceiveProps(this.props);
    this.props.filtering(this.state.param);
  }

  componentWillReceiveProps(nextProps) {
    const { filters, results } = nextProps;
    var filter_list = [];
    if (filters) {
      filters.map((item, index) => {
        filter_list.push(
          <option value={item.key} key={index}>
            {item.value}
          </option>
        );
        return filter_list;
      });
      this.setState({ filter_list });
    }
    if (results) {
      const { data } = results;
      this.props.filterList(data);
    }
  }

  handleDateChange(name, date) {
    this.setState({
      param: {
        ...this.state.param,
        [name]: date
      }
    });
  }

  render() {
    return (
      <div className="resource_container filter_container">
        <div className="breadcrumbs">
          <span onClick={this.goBack.bind(this)} className="green">
            Applicants
          </span>{" "}
          / Filter
        </div>
        <div className="page_title float">
          <h1>Filter</h1>
        </div>
        <div className="inner_head" />
        <div className="applicants_inner_head content_div filter_outer">
          <div className="filter_wrapper">
            <div className="filter-block1 filter-block left">
              <label>Date</label>
              <div className="filter-start-date filter-date left">
                <DatePicker
                  name="start_date"
                  placeholderText="mm/dd/yy"
                  selected={this.state.param.start_date}
                  onChange={this.handleDateChange.bind(this, "start_date")}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                <div>Start Date</div>
              </div>
              <div className="filter-end-date filter-date right">
                <DatePicker
                  name="end_date"
                  placeholderText="mm/dd/yy"
                  selected={this.state.param.end_date}
                  onChange={this.handleDateChange.bind(this, "end_date")}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                <div>End Date</div>
              </div>
            </div>
            <div className="filter-block2 filter-block left">
              <label>Variable #1</label>
              <select name="var1" onChange={this.handleChange.bind(this)}>
                {/* <option value="all">All</option> */}
                {this.state.filter_list}
              </select>
            </div>
            <div className="filter-block3 filter-block right">
              <label>Variable #2</label>
              <select name="var2" onChange={this.handleChange.bind(this)}>
                {/* <option value="all">All</option> */}
                {this.state.filter_list}
              </select>
            </div>
            <button
              className="button right"
              onClick={this.handleFilter.bind(this)}
            >
              Filter Applicants
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { filters, results } = state.applicants;
  return {
    filters,
    results
  };
}

const connectedApplicants = connect(mapStateToProps)(Filter);
export { connectedApplicants as Filter };
