import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
//import { createLogger } from 'redux-logger';
import rootReducer from "../_reducers";
import socketMiddleware from "../_middleware/socketMiddleware";

//const loggerMiddleware = createLogger();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  composeEnhancer(
    applyMiddleware(
      thunkMiddleware,
      socketMiddleware
      //loggerMiddleware
    )
  )
);
