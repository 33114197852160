import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { NoMobileSupport } from "_components/NoMobileSupport";
import { config } from "_helpers";
import { chatActions, docsActions, userActions } from "_actions";
import {
  CandidateInfoPage,
  EmployeeAgreement,
  MPN,
  PaycheckDistribution,
  DisabilityStatus,
  CoveredVeteran,
  //WageRateAgreement,
  F8850,
  // DisclosureStatement, // Disabled for NVAM clone
  // RightOfWorkers, // Disabled for NVAM clone
  // JobDescription, // Disabled for NVAM clone
  // MerchandiseOrder, // Disabled for NVAM clone
  PayDates,
  I9,
  W4,
  NVAMRehireForm,
  //EmployeeRehireForm,
  AuthorizationForDirectDeposit,
  GearIssueForm,
  AboutTreesHandbook,
  NVTSEmployeeHandbook,
  MealPeriodInformationAndWaiver,
} from "./Forms";
import { SidebarAdmin } from "./Components";
import { Pdf } from "./Components";
import I9Rehire from "./Forms/I9-rehire";
import GeneralConsentAndReleaseDoc from "./Forms/GeneralConsentAndReleaseDoc";

class Doc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire: false,
      lastPage: "w-4",
      loading: true,
      currentForm: null,
      currentSlug: "",
      edit: true,
      doc: {},
      pdf: {},
      adminMode: false,
      user: null,
    };
    this.getPdf = this.getPdf.bind(this);
    this.getForm = this.getForm.bind(this);
    this.toggleEditForm = this.toggleEditForm.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.redirectToDocs = this.redirectToDocs.bind(this);
  }

  // Get Active Form
  getPdf() {
    // getting active form
    let lastPage = false;
    let docSlug = this.props.match.params.doc;
    let selectedDoc; // = this.props.docs.userDocs.find((userDoc) => userDoc.slug == doc);
    if (this.state.rehire) {
      selectedDoc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id === 2
      );
    } else {
      selectedDoc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id == 1
      );
    }

    if (!selectedDoc) {
      return <Redirect to={{ pathname: `/docs` }} />;
    }

    let params = {
      id: selectedDoc.id,
      uid: this.props?.user?.profile?.data?.hash, // the user who logged in
      signature: this.props.chat.settings.signature,
    };
    // setting up url for pdf
    let url = new URL(`${config.apiUrl}/docs/pdf/${selectedDoc.slug}`);
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );

    this.state.rehire &&
      selectedDoc.slug === this.state.lastPage &&
      (lastPage = true); // for rehire
    !this.state.rehire &&
      selectedDoc.slug === this.state.lastPage &&
      (lastPage = true); // for onboarding
    // this.state.rehire && selectedDoc.slug === "w4" ? (lastPage = true) : selectedDoc.slug == "merchandise-order-form" && (lastPage = true); // checking if last page is reached

    return (
      <Pdf url={url.href} lastPage={lastPage} rehire={this.state.rehire} />
    );
  }

  // toggle form and pdf
  toggleEditForm() {
    this.setState({ edit: !this.state.edit });
  }

  resetForm() {
    this.setState({ edit: false });
  }

  // Find Form based on slug provided
  getForm() {
    let docSlug = this.props.match.params.doc;
    let selectedDoc = null; // = this.props.docs.userDocs.find((userDoc) => userDoc.slug == doc);
    if (this.state.rehire) {
      selectedDoc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id === 2
      );
    } else {
      selectedDoc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id == 1
      );
    }

    if (selectedDoc) {
      let { slug, id } = selectedDoc;
      let { adminMode } = this.state;

      switch (slug) {
        case "candidate-information-page":
          return (
            <CandidateInfoPage
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
            />
          );
        case "employee-agreement":
          return (
            <EmployeeAgreement
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
            />
          );
          case "general-consent-and-release":
        return (
          <GeneralConsentAndReleaseDoc
          id={id}
          adminMode={adminMode}
          resetForm={this.resetForm}
          rehire={this.state.rehire}
          doc={selectedDoc}
          docs={this.props.docs}
          setScreen = {this.setScreen}
          />
        );
        case "meal-period-information-and-waiver":
          return (
            <MealPeriodInformationAndWaiver
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
            />
          );
        case "mpn":
          return (
            <MPN
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
            />
          );
        case "about-trees-employee-handbook-acknowledgement-of-receipt":
          return (
            <AboutTreesHandbook
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
            />
          );
        case "nvts-employee-handbook-acknowledgement-of-receipt":
          return (
            <NVTSEmployeeHandbook
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
            />
          );
        case "paycheck-distribution-form":
          return (
            <PaycheckDistribution
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
            />
          );
        case "covered-veteran":
          return (
            <CoveredVeteran
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
            />
          );
        case "disability-status":
          return (
            <DisabilityStatus
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
            />
          );
        case "8850":
          return (
            <F8850
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
            />
          );
        // case "wage-rate-agreement": // Disabled for NVAM clone
        //   return (
        //     <WageRateAgreement
        //       id={id}
        //       adminMode={adminMode}
        //       resetForm={this.resetForm}
        //       rehire={this.state.rehire}
        //     />
        //   );
        // case "disclosure-statement-work-agreement": // Disabled for NVAM clone
        //   return (
        //     <DisclosureStatement
        //       id={id}
        //       adminMode={adminMode}
        //       resetForm={this.resetForm}
        //       rehire={this.state.rehire}
        //     />
        //   );
        // case "rights-of-workers":
        //   return (
        //     <RightOfWorkers
        //       id={id}
        //       adminMode={adminMode}
        //       resetForm={this.resetForm}
        //       rehire={this.state.rehire}
        //     />
        //   );
        // case "job-description": // Disabled for NVAM clone
        //   return (
        //     <JobDescription
        //       id={id}
        //       adminMode={adminMode}
        //       resetForm={this.resetForm}
        //       rehire={this.state.rehire}
        //     />
        //   );
        case "pay-dates":
          return (
            <PayDates
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
            />
          );
        case "i-9":
          return (
            <I9
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
            />
          );
        case "employee-re-hire-form":
          return (
            <NVAMRehireForm
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
            />
          );
        case "i-9-rehire":
          return (
            <I9Rehire
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
              giss={this.state.giss}
              doc={selectedDoc}
              docs={this.props.docs}
            />
          );
        case "authorization-for-direct-deposit":
          return (
            <AuthorizationForDirectDeposit
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
            />
          );
        case "w-4":
          return (
            <W4
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
              lastPage={this.state.lastPage === "w-4"}
            />
          );
        // case "merchandise-order-form":
        //   return (
        //     <MerchandiseOrder
        //       id={id}
        //       adminMode={adminMode}
        //       resetForm={this.resetForm}
        //       rehire={this.state.rehire}
        //       lastPage={this.state.lastPage === "merchandise-order-form"}
        //     />
        //   );

        case "gear-issue-form":
          return (
            <GearIssueForm
              id={id}
              adminMode={adminMode}
              resetForm={this.resetForm}
              rehire={this.state.rehire}
              lastPage={this.state.lastPage === "gear-issue-form"}
            />
          );

        default:
          return <Redirect to={{ pathname: `/docs` }} />;
      }
    }
  }

  // Get Doc types
  getUserDocs(hash) {
    this.props.dispatch(docsActions.getUserDocs(hash)).then((res) => {
      this.setState({ loading: false });
    });
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const rehire = urlParams.get("rehire");
    const hash = `?hash=${this.props.match.params.hash}`;
    let { permission } = this.props;

    if (permission.permissions) {
      if (
        permission.permissions.data &&
        permission.permissions.data.includes("Manage Docs")
      ) {
        this.setState({
          adminMode: true,
        });
      }
    }

    this.props.dispatch(docsActions.getFormOptions(this?.state?.rehire));
    this.props
      .dispatch(userActions.getProfile(hash)) // hash should be provide as ?hash=value; f**king idiots
      .then((res) => {
        // getting user docs with user hash passed from DocsList component as params (aka: parent)
        // to get signature
        this.setState({ user: res.data });
        this.props
          .dispatch(chatActions.getConversationSettings())
          .then((res) => {
            this.getUserDocs(this.props.match.params.hash);
          });
      });
  }

  componentWillReceiveProps(nextProps) {
    let category_id,
      user = { user_type_id: null },
      slug = "w-4";

    nextProps.docs &&
      nextProps.docs.activeForm &&
      nextProps.docs.activeForm.user &&
      (user = nextProps.docs.activeForm.user);

    user && nextProps.rehire ? (category_id = 2) : (category_id = 1);

    nextProps.docs &&
      nextProps.docs.userDocs.forEach((doc) => {
        doc.docs_categories_id == category_id && (slug = doc.slug);
      });

    this.setState({ lastPage: slug, rehire: nextProps.rehire });
  }

  componentDidUpdate(prevProps, prevState) {
    let { permission } = this.props;
    if (permission.permissions != prevProps.permission.permissions) {
      if (
        permission.permissions.data &&
        permission.permissions.data.includes("Manage Docs")
      ) {
        this.setState({
          adminMode: true,
        });
      }
    }
    const shouldFetchFormOptions = prevState?.rehire !== this?.state?.rehire;
    if (shouldFetchFormOptions) {
      this.props.dispatch(docsActions.getFormOptions(this?.state?.rehire));
    }
  }

  redirectToDocs = (hash) => {
    let { search } = this.props.location;
    if (search) {
      this.props.history.push(
        `/docs/${hash}/${
          String(search) === "?rehire=true"
            ? "employee-re-hire-form"
            : "candidate-information-page"
        }${search}`
      );
    } else {
      this.props.history.push(
        `/docs/${hash}/candidate-information-page?rehire=false`
      );
    }
    return;
  };

  render() {
    let { loading, edit } = this.state;

    let { hash, doc } = this.props.match.params;

    // if not doc slug is provided
    if (hash && !doc) {
      this.redirectToDocs(hash);
    }
    return (
      !loading && (
        <section className="d-flex w-100">
          {isMobileOnly && window.screen.width < 930 ? (
            <NoMobileSupport feature="docs" />
          ) : (
            <div className="doc_info">
              <SidebarAdmin
                active={this.props.match.params.doc}
                toggle={this.toggleEditForm}
                resetForm={this.resetForm}
                rehire={this.state.rehire}
              />
              {this.state.user && (edit ? this.getForm() : this.getPdf())}
            </div>
          )}
        </section>
      )
    );
  }
}

function mapStateToProps(state) {
  const { chat, docs, permission, user } = state;
  return { chat, docs, permission, user };
}

let connectedDocs = connect(mapStateToProps)(Doc);
export { connectedDocs as Doc };
