import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { authHeader, config } from '../_helpers';
// import { applicantsActions } from '../_actions';
import { manifestActions } from "../_actions";
import "../assets/styles/resource.css";
import { authHeader, config } from "../_helpers";

class CreateManifest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manifest_type_list: [],
      manifestError: "",
      manifest_type: "",
      contract_type: "",
      contract_numbers: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.yourCrew = this.yourCrew.bind(this);
    this.goBack = this.goBack.bind(this);
    this.getContracts = this.getContracts.bind(this);
    this.generateContracts = this.generateContracts.bind(this);
  }

  goBack() {
    this.props.cancel(this.props.props);
  }

  componentDidMount() {
    this.props.dispatch(manifestActions.manifestType());
    this.componentWillReceiveProps(this.props);
    this.getContracts();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type) {
      this.setState({ manifest_type: nextProps.type }, function () {});
    }

    var manifest_type_list = [];
    if (nextProps.manifest.manifest_type) {
      nextProps.manifest.manifest_type.map((item, index) => {
        manifest_type_list.push(
          <option key={index} value={item.id}>
            {item.value}
          </option>,
        );
        return manifest_type_list;
      });
      this.setState({ manifest_type_list });
    }
  }

  getContracts() {
    // Get all docs (multiple docs) by admin
    // appending queryParams with URL
    let url = new URL(`${config.apiUrl}/api/getlist/contractnumbers`);
    // Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    fetch(url, requestOptions).then(
      (response) => {
        response.json().then((res) => {
          this.setState({ contract_numbers: res });
        });
      },
      (err) => console.log(err),
    );
  }

  generateContracts(type) {
    let list = this.state.contract_numbers.filter((item) => item.base_manifests_id == type);
    let contracts = [];
    list.map((item, index) => {
      contracts.push(
        <option key={index} value={item.id}>
          {item.value}
        </option>,
      );
    });
    return contracts;
  }

  handleChange(event,type) {
    if (event.target.value !== "" && type == 1) {
      this.setState({
        [event.target.name]: event.target.value,
      });
      event.target.setAttribute("errors", "");
      sessionStorage.setItem("MID", event.target.value);
    }

    if (event.target.value !== "" && type == 2) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  }

  validate(e) {
    let isError = false;
    if (this.state.manifest_type === "") {
      isError = true;
      this.setState({
        manifestError: "error",
      });
    }

    return isError;
  }
  yourCrew(event) {
    event.preventDefault();
    const err = this.validate();
    if (!err) {
      this.props.yourCrew(this.state.manifest_type, this.state.contract_type);
    }
  }

  render() {
    // const { resource } = this.props;
    // const form = this.state.form;
    return (
      <div className="create_manifest_container">
        <div className="breadcrumbs">
          <Link to={"/resources"} className="green">
            Resources
          </Link>{" "}
          / <span onClick={this.goBack.bind(this)}>Details</span> / Create Manifest
        </div>
        <div className="page_title float">
          <h1>Create Manifest</h1>
        </div>
        <div className="manifest_type">
          <div className="inner_head" />
          <div className="content_div inner_container ">
            {/*<div className="goback" onClick={this.goBack.bind(this)}><p>Back</p></div>*/}
            <div className="manifest_type_form">
              <label>Choose Manifest Type</label>
              <select
                name="manifest_type"
                value={this.state.manifest_type}
                className="manifest_type_list"
                errors={this.state.manifestError}
                onChange={(e) => this.handleChange(e,1)}>
                <option value="">-Select-</option>
                {this.state.manifest_type_list}
              </select>

              {(this.state.manifest_type == 1 || this.state.manifest_type == 3) && (
                <div>
                  <label>Choose Contract Type</label>
                  <select name="contract_type" value={this.state.contract_type} className="manifest_type_list" onChange={(e) => this.handleChange(e,2)}>
                    <option value="">-Select-</option>
                    {this.generateContracts(this.state.manifest_type)}
                  </select>
                </div>
              )}

              <div className="btn_list">
                <button className="button grey" onClick={this.goBack.bind(this)}>
                  Cancel
                </button>
                <button className="button" onClick={this.yourCrew.bind(this)}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { manifest } = state;
  return {
    manifest,
  };
}

const connectedManifest = connect(mapStateToProps)(CreateManifest);
export { connectedManifest as CreateManifest };
