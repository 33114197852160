export const reportsConstants = {
  VEHICLE_REPORT_REQUEST: "VEHICLE_REPORT_REQUEST",
  VEHICLE_REPORT_SUCCESS: "VEHICLE_REPORT_SUCCESS",
  VEHICLE_REPORT_ERROR: "VEHICLE_REPORT_ERROR",

  EQU_SOLD_REPORT_REQUEST: "EQU_SOLD_REPORT_REQUEST",
  EQU_SOLD_REPORT_SUCCESS: "EQU_SOLD_REPORT_SUCCESS",
  EQU_SOLD_REPORT_ERROR: "EQU_SOLD_REPORT_ERROR",

  ALL_INV_REPORT_REQUEST: "ALL_INV_REPORT_REQUEST",
  ALL_INV_REPORT_SUCCESS: "ALL_INV_REPORT_SUCCESS",
  ALL_INV_REPORT_ERROR: "ALL_INV_REPORT_ERROR",

  GET_DOC_STATUS_LIST_REPORT_REQUEST: "GET_DOC_STATUS_LIST_REPORT_REQUEST",
  GET_DOC_STATUS_LIST_REPORT_SUCCESS: "GET_DOC_STATUS_LIST_REPORT_SUCCESS",
  GET_DOC_STATUS_LIST_REPORT_ERROR: "GET_DOC_STATUS_LIST_REPORT_ERROR",

  EXP_DATE_REPORT_REQUEST: "EXP_DATE_REPORT_REQUEST",
  EXP_DATE_REPORT_SUCCESS: "EXP_DATE_REPORT_SUCCESS",
  EXP_DATE_REPORT_ERROR: "EXP_DATE_REPORT_ERROR",
  
  MILEAGE_HOBBS_REPORT_REQUEST:"MILEAGE_HOBBS_REPORT_REQUEST",
  MILEAGE_HOBBS_REPORT_SUCCESS:"MILEAGE_HOBBS_REPORT_SUCCESS",
  MILEAGE_HOBBS_REPORT_ERROR:"MILEAGE_HOBBS_REPORT_ERROR",

  EMPLOYEE_BIRTHDAY_REQUEST: "EMPLOYEE_BIRTHDAY_REQUEST",
  EMPLOYEE_BIRTHDAY_SUCCESS: "EMPLOYEE_BIRTHDAY_SUCCESS",
  EMPLOYEE_BIRTHDAY_ERROR: "EMPLOYEE_BIRTHDAY_ERROR",

  APPLICANTS_REPORT_REQUEST:"APPLICANTS_REPORT_REQUEST",
  APPLICANTS_REPORT_SUCCESS:"APPLICANTS_REPORT_SUCCESS",
  APPLICANTS_REPORT_ERROR:"APPLICANTS_REPORT_ERROR",
};
