import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { userActions, permissionActions, inventoryActions, notificationActions, chatActions } from "../_actions";
import { authHeader, config } from "../_helpers";
import Modal from "react-responsive-modal";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import { AlertPopup } from "../_components/AlertPopup";
import ReactModal from "react-modal";
import NumberFormat from "react-number-format";
import { UserDocsList } from "./UserDocsList";
import { docsService } from "../_services/docs.service";
import { getCompanyName, unAvailableReason } from "../_utils/utils";
import ChangePassword from "ChangePassword";
import ChangeStatusModal from "_components/ChangeStatusModal";
import ApplicationHistoryAlert from "_components/ApplicantHistoryAlert";
import EmploymentHistory from "./EmploymentHistory";
import ViewExpiryAndAssignedDates from "_components/ViewExpiryAndAssignedDates"
import { userService } from "_services/user.service";
import { handleErrorResponse } from "_helpers/helpers";

ReactModal.setAppElement("#app");

function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
}

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hash: this.props.props,
      request_rehire_doc: false,
      request_rehire_doc_status: false,
      tab: "Profile",
      statusOpen: false,
      removeOpen: false,
      alertOpen: false,
      mailSendOpen: false,
      doc_request: "",
      requireDocs: false, // for status modal
      email: true,
      sms: true,
      status: "",
      available: "",
      statusStep: "1",
      returnDateField: moment(),
      returnDate: "",
      message: "",
      notes: "",
      alertPop: false,
      userName: "",
      afterSuccess: false,
      inventoryUserAlert: false,
      isGuest: false,
      unavailableReason: 0,
      reasonToDisplay: 0,
      unavailableErr: "",
      otherReason: "",
      displayOtherReason: "",
      sendingDDForm: false,
      send_dd_form_confirm: false,
    };
    this.goBack = this.goBack.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.handleRemoveEmployee = this.handleRemoveEmployee.bind(this);
    this.removeCloseModal = this.removeCloseModal.bind(this);
    this.alertOpenModal = this.alertOpenModal.bind(this);
    this.alertCloseModal = this.alertCloseModal.bind(this);
    this.mailSendOpenModal = this.mailSendOpenModal.bind(this);
    this.mailSendCloseModal = this.mailSendCloseModal.bind(this);
    this.setStatusReturn = this.setStatusReturn.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.downloadAllDocuments = this.downloadAllDocuments.bind(this);
    this.downloadAllDocumentsAsPDF = this.downloadAllDocumentsAsPDF.bind(this);
    this.unlockProfile = this.unlockProfile.bind(this);
    this.requireDocs = this.requireDocs.bind(this);
    this.lockProfile = this.lockProfile.bind(this);
    this.docRequestModalClose = this.docRequestModalClose.bind(this);
  }

  openDdformConfirmModal() {
    this.setState({ send_dd_form_confirm: true });
  }

  ddFormConfirmCloseModal() {
    this.setState({
      send_dd_form_confirm: false
    })
  }

  sendDDForm() {
    if (this.props.user.items.data.hash) {
      this.setState({
        ...this.state,
        sendingDDForm: true,
        send_dd_form_confirm: false,
      });
      let data = new FormData();
      data.append("user_hash", this.props.user.items.data.hash)
      userService.sendDDForm(data).then((res) => {
        if(res.success){
          toast.success(res.message)
        }
        else{
          handleErrorResponse(res);
        }
        this.setState({
          ...this.state,
          sendingDDForm: false
        });
      })
      .catch((error) => {
        toast.error("Failed to send email to user.");
        this.setState({
          ...this.state,
          sendingDDForm: false
        });
      });
    } else {
      toast.error("An unexpected error occurred.")
    }
  }

  statusOpenModal() {
    this.setState({ statusOpen: true, statusStep: "1" });
  }

  statusCloseModal() {
    this.setState({ statusOpen: false });
  }

  removeCloseModal() {
    this.setState({ removeOpen: false });
  }

  alertOpenModal() {
    this.setState({ alertOpen: true });
  }

  alertCloseModal() {
    this.setState({ alertOpen: false, redirect: "/resources" });
  }

  mailSendOpenModal() {
    this.setState({ mailSendOpen: true });
  }

  mailSendCloseModal() {
    this.setState({ mailSendOpen: false });
  }

  handleRemoveEmployee(e) {
    let inventoryList = this.props.inventory.inventory.data || [];
    if (inventoryList && inventoryList.length) {
      this.setState({ inventoryUserAlert: true });
    } else {
      this.setState({ removeOpen: true });
    }
  }

  handleChange(event) {
    if (event.target.name === "notes") {
      if (this.state.permList.includes("Employee Notes")) {
        this.setState({
          [event.target.name]: event.target.value,
          template: "custommail",
        });
      }
      return;
    }
    this.setState({
      [event.target.name]: event.target.value,
      template: "custommail",
    });
    // this.setState({ [event.target.name]: event.target.value, template:'custommail' });
  }

  handleCheck(event) {
    console.log(event.target.name);
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  changeTab(tab) {
    this.setState({ tab });
  }

  documentRequest(hash, doc_request) {
    this.setState({ doc_request });
    docsService.requestToFillDocs(hash, doc_request).then(
      (res) => {
        this.setState({ request_rehire_doc_status: res.success, request_rehire_doc: true });
        var hash = "?hash=" + this.state.hash;
        this.props.dispatch(userActions.getProfile(hash));
      },
      (err) => console.log(err)
    );
  }

  docRequestModalClose() {
    this.setState({ request_rehire_doc: false });
  }

  downloadAllDocuments(event, rehire = false) {
    let { hash } = this.state.profile;
    let params = {
      uid: this.props.user.profile.data.hash,
      signature: this.props.chat.settings.signature,
    };
    let url = new URL(`${config.apiUrl}/docs/download/${hash}`);
    rehire && (url = new URL(`${config.apiUrl}/docs/download/${hash}?rehire=true`));

    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
    window.open(url.href);
  }

  downloadAllDocumentsAsPDF(event, rehire = false) {
    let { hash } = this.state.profile;
    let params = {
      uid: this.props.user.profile.data.hash,
      signature: this.props.chat.settings.signature,
    };
    let url = new URL(`${config.apiUrl}/docs/download/pdf/${hash}`);
    rehire && (url = new URL(`${config.apiUrl}/docs/download/pdf/${hash}?rehire=true`));

    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
    window.open(url.href);
  }

  componentDidMount() {
    var hash = "?hash=" + this.state.hash;
    this.props.dispatch(userActions.getProfile(hash));
    this.props.dispatch(permissionActions.getPermission());
    let data = new FormData();
    data.append("hash", this.state.hash);
    this.props.dispatch(inventoryActions.getInventoryList(data));
    this.props.dispatch(chatActions.getConversationSettings());
  }

  componentWillReceiveProps(nextProps) {
    // this.props.dispatch(userActions.getProfile(hash));
    const { user, inventory, permission } = nextProps;
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;

      this.setState({
        permList,
      });
    }

    var generalQualificationList = [],
      inventory_list = [];
    if (inventory.inventory) {
      if (inventory.inventory.data) {
        var total_cost = 0;
        inventory.inventory.data.map((item, index) => {
          total_cost += parseInt(item.cost, 10);
          inventory_list.push(
            <div className="inv_item" key={index}>
              <div className="inv_cell">
                {index + 1 + ". " + item.unique_id + " (" + item.type + ") "}
                {/* <span className="cost">{"$"+item.cost}</span>*/}
                <NumberFormat
                  className="cost"
                  value={item.cost}
                  displayType={"text"}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </div>
              {/*<div className="inv_cell" data-header-title="Unique Id">{item.unique_id}</div>
                            <div className="inv_cell" data-header-title="Cost">{item.cost}</div>*/}
            </div>
          );
          return inventory_list;
        });
        this.setState({ inventory_list, total_cost });
      }
    }
    if (user.items) {
      // this.setState({ active: user.items.data.active });
      var profile = user.items.data;
      var available = profile.active === "Available" ? true : false;
      this.setState({
        active: profile.active,
        status: profile.active,
        is_locked: profile.is_locked,
        available: available,
        notes: this?.state?.notes ? this?.state?.notes : profile.notes,
        profile,
        hash: profile.hash,
        userName: profile.first_name,
        user_type: user.items.data.user_type,
        resource_id: user.items.data.crew_identifier_id,
        isGuest: user.items.data.status === 3 ? true : false,
        reasonToDisplay: user.items.data.unavailable_reason,
        displayOtherReason: user.items.data.other_reason,
      });
      if (profile.qualification) {
        profile.qualification.map((item, index) => {
          generalQualificationList.push(
            <p className="crew-profile-detail" key={index}>
              {item}
            </p>
          );
          return generalQualificationList;
        });
      }
    }

    this.setState({
      generalQualificationList,
      permList,
    });

    if (user.removeUser) {
      if (user.removeUser.success) {
        this.setState({
          alertHead: "Success",
          alertMsg: user.removeUser.message,
          removeOpen: false,
          alertOpen: this.state.removeOpen,
        });
        // this.props.goBack(this.props.resourceDetails);
      } else {
        var hash = "?hash=" + this.state.hash;
        // this.props.dispatch(userActions.getProfile(hash));
        // this.setState({ removeOpen: false });
        // alert(user.removeUser.message);
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: user.removeUser.message,
          alertFunction: "",
          removeOpen: false,
        });
      }
    }
    if (user.notes) {
      if (user.notes.success) {
      } else {
        // alert(user.notes.message);
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: user.notes.message,
          alertFunction: "",
        });
      }
      this.props.dispatch(userActions.getProfile(hash));
    }
  }

  setStatusReturn(status) {
    if (status) {
      var step = "2a";
    } else step = "2b";
    this.setState(
      {
        // statusStep:"3",
        statusStep: step,
      },
      function () {
        this.componentWillReceiveProps(this.props);
      }
    );
  }
  setUnAvailableReason = (reason, other) => {

    return unAvailableReason(reason, other);

  };

  setStep(step) {
    this.setState({ statusStep: step }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  updateNotes(hash) {
    let data = new FormData();
    data.append("hash", hash);
    data.append("note", this.state.notes);
    if (this.state.notes !== null) {
      this.setState({ savingNote: true })
      this.props.dispatch(userActions.updateEmployeeNote(data, "?hash=" + hash)).then(
        (res) =>{
          toast.success("Notes Saved !")
          this.setState({ savingNote: false })
        } ,
        (err) =>{
          toast.error("Notes Cannot be saved")
          this.setState({ savingNote: false })
        }
      );
    }
    // this.props.dispatch(userActions.getProfile("?hash="+hash));
  }

  handleDateChange(date) {
    this.setState({
      returnDateField: date,
      returnDate: date.format("MM/DD/YY"),
    });
  }

  changeStatus(status) {
    if (status) {
      var stat = this.state.status === "Available" ? "Unavailable" : "Available";
      var hash = "?hash=" + this.state.hash;
      let data = new FormData();
      this.statusCloseModal();
      data.append("end_date", this.state.returnDate);
      data.append("hash", this.state.hash);
      data.append("status", stat);
      this.props.dispatch(userActions.changeStatus(data, hash));
      this.props.dispatch(notificationActions.getCount());
      this.setState({ status: stat });
    }
  }

  lockProfile() {
    var stat = "Locked";
    var hash = "?hash=" + this.state.hash;
    let data = new FormData();
    this.statusCloseModal();
    data.append("hash", this.state.hash);
    data.append("status", stat);
    this.props.dispatch(userActions.lockUserToggle(data, hash));
    this.props.dispatch(notificationActions.getCount());
    this.setState({ status: stat });
  }

  requireDocs(e) {
    this.setState({ requireDocs: e.target.checked });
  }

  unlockProfile() {
    var stat = "Unlocked";
    var hash = "?hash=" + this.state.hash;
    let data = new FormData();
    this.statusCloseModal();
    data.append("hash", this.state.hash);
    data.append("status", stat);
    data.append("document", this.state.requireDocs);
    this.props.dispatch(userActions.lockUserToggle(data, hash));
    this.props.dispatch(notificationActions.getCount());
    this.setState({ status: stat });
  }

  remove(hash) {
    let data = new FormData();
    this.statusCloseModal();
    data.append("hash", hash);
    this.props.dispatch(userActions.removeEmployee(data));
    // this.props.goBack(this.props.resourceDetails);
    // this.setState({
    //     redirect: "/resources"
    // })
  }

  goBack() {
    this.props.goBack(this.props.resourceDetails);
  }

  toResourceList() {
    this.props.toResourceList();
  }

  editProfile(hash) {
    this.props.editProfile(hash);
  }

  print() {
    window.print();
  }

  sendMail(hash) {
    let data = new FormData();
    this.mailSendCloseModal();
    data.append("type", "mail");
    data.append("message", this.state.message);
    data.append("user_hash", hash);
    var self = this;
    fetch(config.apiUrl + "/api/employee/sendmails", {
      headers: authHeader(),
      method: "POST",
      body: data,
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        // alert( JSON.stringify( data.message ) )
        if (data.success) {
          self.setState({
            alertPop: true,
            alertHead: "Success",
            alertMsg: JSON.parse(JSON.stringify(data.message)),
            alertFunction: "",
          });
        } else {
          self.setState({
            alertPop: true,
            alertHead: "Error",
            alertMsg: JSON.parse(JSON.stringify(data.message)),
            alertFunction: "",
          });
        }
      });
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  render() {
    const { permission } = this.props;
    let user;
    this.props && this.props.user && this.props.user.items && (user = this.props.user.items.data);

    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }
    if (this.state.redirect === "/resources") {
      return <Redirect to={"/resources/" + this.state.resource_id} />;
    }

    if (this.state.profile) {
      return (
        // details
        <div className="resource_container profile_container">
          <div className="breadcrumbs">
            <Link onClick={this.toResourceList.bind(this)} to={"/resources"} className="green">
              Resources
            </Link>{" "}
            / <Link to={"/resources/" + this.state.resource_id}>Details</Link> / Profile
          </div>
          <div className="page_title float">
            <h1>{this.state.profile ? this.state.profile.first_name + " " + this.state.profile.last_name : ""}</h1>
            <div className="btn_list">
              {/*<button className="button remove_resource" onClick={this.goBack.bind(this)}>Back</button>*/}
              {this.state.tab == "Profile" ? (
                // Profile Buttons
                <React.Fragment>
                  {permList.includes("Manage Passwords") && (
                    <ChangePassword
                      userType="user"
                      user={this.state.profile}
                      isAdmin={this.props?.user?.profile?.data?.is_admin !== 0 ? true : false}
                      className={"edit_resource"}
                    />
                  )}
                  <button className="button print" onClick={this.print.bind(this)}>
                    Print
                  </button>
                  {permList.includes("Message Employees") && (
                    <button className="button edit_resource" onClick={this.mailSendOpenModal.bind(this)}>
                      Message
                    </button>
                  )}
                  {permList.includes("Status Update") && (
                    <button className="button edit_resource" onClick={this.statusOpenModal.bind(this)}>
                      Edit Status
                    </button>
                  )}
                  {permList.includes("Edit User Profile") && (
                    <button className="button edit_resource" onClick={this.editProfile.bind(this, this.state.hash)}>
                      Edit Profile
                    </button>
                  )}
                  {permList.includes("Remove Employee") && (
                    <button className="button edit_resource" onClick={this.handleRemoveEmployee.bind(this)}>
                      Remove Employee
                    </button>
                  )}
                  {permList.includes("Send DD Form") && (
                    <button
                      className="button edit_resource"
                      onClick={this.openDdformConfirmModal.bind(this)}
                      disabled={this.state.sendingDDForm}
                    >
                      Send DD Form
                    </button>
                )}
                </React.Fragment>
              ) : (
                // Document Buttons
                <React.Fragment>
                  {permList.includes("Manage Docs") && user.docs_status === -1 && (
                    <button
                      className={`button edit_resource ${user && user.rehire_docs_status !== -1 && "disabled"}`}
                      onClick={() => this.documentRequest(user.hash, "onboarding")}
                      disabled={user && user.docs_status !== -1}
                    >
                      {user && user.docs_status === -1
                        ? "Ask to Fill Onboarding Documents"
                        : "Asked to Fill Onboarding Documents"}
                    </button>
                  )}
                  {permList.includes("Manage Docs") && user.rehire_docs_status === -1 && user.docs_status >= 2 && (
                    <button
                      className={`button edit_resource ${user && user.rehire_docs_status !== -1 && "disabled"}`}
                      onClick={() => this.documentRequest(user.hash, "rehire")}
                      disabled={user && user.rehire_docs_status !== -1}
                    >
                      {user && user.rehire_docs_status === -1
                        ? "Ask to Fill Rehire Documents"
                        : "Asked to Fill Rehire Documents"}
                    </button>
                  )}
                  {/* Onboarding */}
                  {permList.includes("Manage Docs") && user.docs_status >= 2 && (
                    <button
                      className="button edit_resource"
                      onClick={this.downloadAllDocuments}
                      title="Download Onboarding documents as ZIP File"
                    >
                      Onboarding <i className="fas fa-file-archive text-white inline-icons"></i>
                    </button>
                  )}
                  {permList.includes("Manage Docs") && user.docs_status >= 2 && (
                    <button
                      className="button primary edit_resource"
                      onClick={this.downloadAllDocumentsAsPDF}
                      title="Download Onboarding documents as PDF"
                    >
                      Onboarding <i className="fas fa-file-pdf text-white inline-icons"></i>
                    </button>
                  )}

                  {/* Rehire */}
                  {permList.includes("Manage Docs") && user.rehire_docs_status >= 2 && (
                    <button
                      className="button edit_resource"
                      onClick={(event) => this.downloadAllDocuments(event, true)}
                      title="Download Rehire documents as ZIP File"
                    >
                      Rehire <i className="fas fa-file-archive text-white inline-icons"></i>
                    </button>
                  )}
                  {permList.includes("Manage Docs") && user.rehire_docs_status >= 2 && (
                    <button
                      className="button primary edit_resource"
                      onClick={(event) => this.downloadAllDocumentsAsPDF(event, true)}
                      title="Download Rehire documents as PDF"
                    >
                      Rehire <i className="fas fa-file-pdf text-white inline-icons"></i>
                    </button>
                  )}

                  {/* Ask Rehire Modal */}
                  <ReactModal
                    isOpen={this.state.request_rehire_doc}
                    onClose={this.docRequestModalClose.bind(this)}
                    contentLabel="Rehire Request"
                    overlayClassName="alertPopup"
                  >
                    <div id="rehire_request" className="popupModal">
                      <div className="modal-head">
                        <h4 className="modal-title">Request {this.state.doc_request} Docs</h4>
                      </div>
                      <div className="modal-body">
                        {this.state.request_rehire_doc_status ? (
                          <p>
                            Request for fill out{" "}
                            <span className="modal-word-highlight">
                              {this.state.doc_request.toUpperCase()} documents
                            </span>
                            ,sent out to the user.
                          </p>
                        ) : (
                          <p>
                            Unable to request <strong>{this.state.doc_request.toUpperCase()} documents</strong>!!!
                          </p>
                        )}
                        <div className="btn_list">
                          <button className="button" onClick={this.docRequestModalClose.bind(this)}>
                            Ok
                          </button>
                        </div>
                      </div>
                    </div>
                  </ReactModal>
                </React.Fragment>
              )}
            </div>
          </div>
          {/* Profile/Document Tab Navigation */}
          <div className="applicants_inner_head inner_head header_tabs">
            <h2 className={this.state.tab === "Profile" ? "active" : ""} onClick={this.changeTab.bind(this, "Profile")}>
              Profile
            </h2>
            {permList.includes("Manage Docs") && (
              <h2
                className={this.state.tab === "Documents" ? "active" : ""}
                onClick={this.changeTab.bind(this, "Documents")}
              >
                Documents
              </h2>
            )}
            {permList.includes("Manage Employment History") && (
              <h2
                className={this.state.tab === "Employment History" ? "active" : ""}
                onClick={this.changeTab.bind(this, "Employment History")}
              >
                Employment History
              </h2>
            )}
          </div>
          {/* Profile/Document Tab Content */}
          <div className="content_div settings">
            <div className="clearFix" />
            {(() => {
              switch (this.state.tab) {
                case "Profile":
                  return (
                    <React.Fragment>
                      <div className="profile1 col-sm-3">
                        <p className="name_emp">
                          <span
                            className={
                              this.state.is_locked === 1
                                ? "Grey color_code"
                                : this.state.profile && this.state.profile.colorcode
                                ? "color_code " + this.state.profile.colorcode
                                : "color_code"
                            }
                          />
                          {this.state.profile && this.state.profile.first_name
                            ? (
                              this.state.profile.first_name + " " +
                              (this.state.profile.middle_name ? this.state.profile.middle_name + " " : "") +
                              this.state.profile.last_name
                            )
                            : ""
                          }
                        </p>
                        <div>
                          {/* this.state.profile.active + " profilePicture availability"  */}
                          {/* `employee ${this.state.is_locked === 1 ? '' : (this.state.active === 'Available'? 'available':'unavailable')}` */}
                          <img
                            className={`profilePicture ${
                              this.state.is_locked === 1
                                ? "locked"
                                : this.state.isGuest
                                ? "guest-code"
                                : this.state.active === "Available"
                                ? "available"
                                : "unavailable"
                            }`}
                            src={
                              this.state.profile && this.state.profile.profile_photo
                                ? config.apiUrl + "/" + this.state.profile.profile_photo
                                : config.apiUrl + "/" + config.altImg
                            }
                            alt={
                              this.state.profile && this.state.profile.first_name
                                ? this.state.profile.first_name + " " + this.state.profile.last_name
                                : ""
                            }
                            onError={this.onImageError.bind(this)}
                          />
                        </div>
                        {/*<button className={this.state.active ? "avail_btn "+this.state.active : ""}>{this.state.active ? this.state.active : ""}</button>*/}
                        <div className="crew-profile-detail">
                          <p>
                            {this.state.profile && this.state.profile.user_type ? this.state.profile.user_type : ""}
                          </p>
                          {this.state.profile.crew_name && <p>{this.state.profile.crew_name}</p>}
                          <p>{this.state.profile && this.state.profile.nvam_id ? this.state.profile.nvam_id : ""}</p>
                          <p>{/*Removed ica_number for NVAM clone*/}</p>
                          {this.state.profile.primary_crew_name && (
                            <p>Primary Crew: {this.state.profile.primary_crew_name}</p>
                          )}
                          {this.state.reasonToDisplay !== 0 && (
                            <p>
                              Unavailable:{" "}
                              {this.setUnAvailableReason(this.state.reasonToDisplay, this.state.displayOtherReason)}
                            </p>
                          )}
                          {this.state.profile.applying_company !== 0 && (
                            <p>Company: {getCompanyName(this.state.profile.applying_company)}</p>
                          )}

                          <ViewExpiryAndAssignedDates data={this.state.profile} viewRT />

                        </div>
                        {this.state?.profile?.employment_history_count ? (
                          <ApplicationHistoryAlert />
                        ) : null}
                      </div>
                      <div className="profile2 cp-profile-info col-sm-5">
                        <div className="print_float">
                          <div className="cp-qual profile-sep">
                            <h4 className="cp-title">Qualifications</h4>
                            {this.state.generalQualificationList}
                          </div>
                          <div className="cp-cellno profile-sep">
                            <h4 className="cp-title">Cell Number</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.phone ? (
                                <a
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  className="phone_email_link"
                                  href={`tel:${this.state.profile.phone}`}
                                  target="_top"
                                >
                                  {formatPhoneNumber(this.state.profile.phone)}
                                </a>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                          <div className="cp-sec profile-sep">
                            <h4 className="cp-title">Secondary Number</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.second_phone !== "null" ? (
                                <a
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  className="phone_email_link"
                                  href={`tel:${this.state.profile.second_phone}`}
                                  target="_top"
                                >
                                  {formatPhoneNumber(this.state.profile.second_phone)}
                                </a>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                          <div className="cp-email profile-sep">
                            <h4 className="cp-title">Email</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.email ? (
                                <a
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  className="phone_email_link"
                                  href={`mailto:${this.state.profile.email}`}
                                  target="_top"
                                >
                                  {this.state.profile.email}
                                </a>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                          <div className="cp-madr profile-sep">
                            <h4 className="cp-title">Mailing Address</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.mailing_address
                                ? this.state.profile.mailing_address.address
                                : ""}
                            </p>
                            <p className="crew-profile-detail txtformat-capitalize">
                              {this.state.profile && this.state.profile.mailing_address
                                ? this.state.profile.mailing_address.city
                                : ""}
                            </p>
                            <p className="crew-profile-detail txtformat-capitalize">
                              {this.state.profile && this.state.profile.mailing_address
                                ? this.state.profile.mailing_address.state
                                : ""}
                            </p>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.mailing_address
                                ? this.state.profile.mailing_address.zip
                                : ""}
                            </p>
                          </div>
                        </div>
                        <div className="print_float">
                          <div className="cp-pa profile-sep">
                            <h4 className="cp-title">Physical Address</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.physical_address
                                ? this.state.profile.physical_address.address
                                : ""}
                            </p>
                            <p className="crew-profile-detail txtformat-capitalize">
                              {this.state.profile && this.state.profile.physical_address
                                ? this.state.profile.physical_address.city
                                : ""}
                            </p>
                            <p className="crew-profile-detail txtformat-capitalize">
                              {this.state.profile && this.state.profile.physical_address
                                ? this.state.profile.physical_address.state
                                : ""}
                            </p>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.physical_address
                                ? this.state.profile.physical_address.zip
                                : ""}
                            </p>
                          </div>
                          <div className="cp-sex profile-sep">
                            <h4 className="cp-title">Sex</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.gender ? this.state.profile.gender : ""}
                            </p>
                          </div>
                          <div className="cp-contact1 profile-sep">
                            <h4 className="cp-title">Emergency Contact #1</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.emgcontact1
                                ? this.state.profile.emgcontact1.fname + " " + this.state.profile.emgcontact1.lname
                                : ""}
                            </p>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.emgcontact1 ? (
                                // formatPhoneNumber(this.state.profile.emgcontact1.phone)
                                <a
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  className="phone_email_link"
                                  href={`tel:${this.state.profile.emgcontact1.phone}`}
                                  target="_top"
                                >
                                  {formatPhoneNumber(this.state.profile.emgcontact1.phone)}
                                </a>
                              ) : (
                                ""
                              )}
                            </p>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.emgcontact1
                                ? this.state.profile.emgcontact1.relation
                                : ""}
                            </p>
                          </div>
                          <div className="cp-contact2 profile-sep">
                            <h4 className="cp-title">Emergency Contact #2</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.emgcontact2
                                ? this.state.profile.emgcontact2.fname + " " + this.state.profile.emgcontact2.lname
                                : ""}
                            </p>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.emgcontact2 ? (
                                // formatPhoneNumber(this.state.profile.emgcontact2.phone)
                                <a
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  className="phone_email_link"
                                  href={`tel:${this.state.profile.emgcontact2.phone}`}
                                  target="_top"
                                >
                                  {formatPhoneNumber(this.state.profile.emgcontact2.phone)}
                                </a>
                              ) : (
                                ""
                              )}
                            </p>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.emgcontact2
                                ? this.state.profile.emgcontact2.relation
                                : ""}
                            </p>
                          </div>
                          <div className="cp-drivers-license profile-sep">
                            <h4 className="cp-title">Drivers License</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.drivers_license
                                ? this.state.profile.drivers_license
                                : ""}
                            </p>
                          </div>
                          {/*<div className="cp-experience profile-sep">
                                        <h4 className="cp-title">Experience</h4><p className="crew-profile-detail">More than 1 season</p>
                                </div>*/}
                          <div className="cp-weight profile-sep">
                            <h4 className="cp-title">Weight (lbs)</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile && this.state.profile.weight ? this.state.profile.weight : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="profile3 col-sm-4">
                        {/* Verified Documents Checkbox */}
                        {this.state.permList.includes("Manage Docs") &&
                          this.state.profile.docs_status === 3 &&
                          this.state.profile.userattribute !== null && (
                            <section>
                              <h4 className="cp-title">Verified Documents</h4>
                              <form className="verified_documents" onChange={(e) => this.handleCheck(e)}>
                                <ul>
                                  <li>
                                    <label htmlFor="drug_screening_doc" className="noselect disabled">
                                      <input
                                        type="checkbox"
                                        name="drug_screening_doc"
                                        id="drug_screening_doc"
                                        disabled
                                        checked={this.state.profile.userattribute.drug_screening_doc === 1}
                                      />
                                      Drug Screening
                                    </label>
                                  </li>
                                  <li>
                                    <label htmlFor="i9_doc" className="noselect disabled">
                                      <input
                                        type="checkbox"
                                        name="i9_doc"
                                        id="i9_doc"
                                        disabled
                                        checked={this.state.profile.userattribute.i9_doc === 1}
                                      />
                                      ID/I9
                                    </label>
                                  </li>
                                  <li>
                                    <label htmlFor="onboarding_doc" className="noselect disabled">
                                      <input
                                        type="checkbox"
                                        name="onboarding_doc"
                                        id="onboarding_doc"
                                        disabled
                                        checked={this.state.profile.userattribute.onboarding_doc === 1}
                                      />
                                      Onboarding Documents
                                    </label>
                                  </li>
                                </ul>
                              </form>
                            </section>
                          )}
                        {/* Text Area */}
                        {permList.includes("Employee Notes") && (
                          <section>
                            <textarea
                              name="notes"
                              className="single-notes-cp m-0"
                              placeholder="Notes..."
                              value={this.state.notes || ""}
                              rows="3"
                              cols="20"
                              onChange={this.handleChange}
                            >
                              {this.state.notes}
                            </textarea>
                            <p>
                              <button
                                className="btn btn-xs button"
                                disabled={this.state?.savingNote}
                                onClick={this.updateNotes.bind(this, this.props.props)}
                              >
                                Save Note
                              </button>
                            </p>
                          </section>
                        )}
                        {this.state.inventory_list && (
                          <div className="inventory_table">
                            {/*<div className="inv_head">
                                        <div className="inv_cell">Type</div>
                                        <div className="inv_cell">Unique Id</div>
                                        <div className="inv_cell">Cost</div>
                                    </div>*/}
                            {this.state.inventory_list}
                            <div className="inv_item total_cost">
                              <div className="inv_cell">
                                {/*Total<span className="cost">{"$"+this.state.total_cost}</span>*/}
                                Total
                                <NumberFormat
                                  value={this.state.total_cost}
                                  displayType={"text"}
                                  fixedDecimalScale={true}
                                  decimalScale={2}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  className="cost"
                                />
                              </div>
                              <p className="disclaimer">
                                Employee is responsible for the condition, care, and replacement value of issued
                                inventory. Issued Inventory not returned, lost or stolen will be the reasonability of
                                the employee. If you do not see the cost above of your current issued equipment, please
                                contact your supervisor.
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  );
                case "Documents":
                  return <UserDocsList userHash={this.state.hash} />;
                case "Employment History":
                  return <EmploymentHistory hash={this.state.hash} />;
                default:
              }
            })()}
          </div>
          {this.state.statusOpen ? (
            <ChangeStatusModal lockProfile open={this.state.statusOpen} onClose={this.statusCloseModal} />
          ) : null}
          {/* Status modal */}
       

          {/* uers upate success modal */}
          <ReactModal
            isOpen={this.state.alertOpen}
            onClose={this.alertCloseModal.bind(this)}
            contentLabel="Minimal Modal Example"
            overlayClassName="alertPopup"
          >
            <div id="statusPopup" className="popupModal">
              <div className="modal-head">
                <h4 className="modal-title">{this.state.alertHead}</h4>
                {/*<img className="closeModal" onClick={this.viewCloseModal.bind(this)} src={require("../assets/images/close_green.png")} />*/}
              </div>
              <div className="modal-body">
                <p>{this.state.alertMsg}</p>
                <div className="btn_list">
                  <button className="button" onClick={this.alertCloseModal.bind(this)}>
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </ReactModal>

          <Modal
            id="remove_popup"
            className="status_popup"
            open={this.state.send_dd_form_confirm}
            onClose={this.ddFormConfirmCloseModal.bind(this)}
            little
          >
            <div id="removePopup">
              <div className="modal-head">
                <h4 className="modal-title">
                  Direct Deposit Form
                </h4>
              </div>
              <div className="modal-body">
                <label>This will send an email to {this.state.profile ? this.state.profile.first_name + " " + this.state.profile.last_name : "this employee"}, with Direct Deposit form link.</label>
                <br />
                <button className="button grey" onClick={this.ddFormConfirmCloseModal.bind(this)}>
                  Cancel
                </button>
                <button className="button" onClick={this.sendDDForm.bind(this)}>
                  Send
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            id="remove_popup"
            className="status_popup"
            open={this.state.inventoryUserAlert}
            onClose={() => {
              this.setState({ inventoryUserAlert: false });
            }}
            little
          >
            <div id="removePopup">
              <div className="modal-head">
                <h4 className="modal-title">Error:Remove Inventory First</h4>
              </div>
              <div className="modal-body">
                <label>In order to remove this employee you need to remove their inventory first</label>
                <br />
                <Link to="/inventory">
                  <button className="button">Go to inventory</button>
                </Link>
                <button
                  className="button grey"
                  onClick={() => {
                    this.setState({ inventoryUserAlert: false });
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            id="remove_popup"
            className="status_popup"
            open={this.state.removeOpen}
            onClose={this.removeCloseModal.bind(this)}
            little
          >
            <div id="removePopup">
              <div className="modal-head">
                <h4 className="modal-title">
                  Remove Employee:{" "}
                  {this.state.profile ? this.state.profile.first_name + " " + this.state.profile.last_name : ""}
                </h4>
              </div>
              <div className="modal-body">
                <label>Are you sure you want to archive this employee?</label>
                <br />
                <button className="button grey" onClick={this.removeCloseModal.bind(this)}>
                  Cancel
                </button>
                <button className="button" onClick={this.remove.bind(this, this.state.hash)}>
                  Archive
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            id="email_popup"
            className="status_popup"
            open={this.state.mailSendOpen}
            onClose={this.mailSendCloseModal.bind(this)}
            little
          >
            <div id="emailPopup">
              <div className="modal-head">
                <h4 className="modal-title">Custom Message</h4>
              </div>
              <div className="modal-body">
                <textarea
                  name="message"
                  cols="80"
                  rows="10"
                  value={this.state.message}
                  onChange={this.handleChange.bind(this)}
                />

                <p className="step_no">Signature will automatically be included.</p>
                {/*<div className="btn_list">*/}
                <button className="button grey" onClick={this.mailSendCloseModal.bind(this)}>
                  Cancel
                </button>
                <button className="button" onClick={this.sendMail.bind(this, this.state.hash)}>
                  Send
                </button>
                {/*</div>*/}
              </div>
            </div>
          </Modal>
          {this.state.alertPop && (
            <AlertPopup
              head={this.state.alertHead}
              message={this.state.alertMsg}
              alertFunction={this.state.alertFunction}
              close={this.closeAlert.bind(this)}
            />
          )}
        </div>
      );
    }
    return (
      <div className="resource_container profile_container">
        <div className="page_title float">
          <h1>{this.state.profile ? this.state.profile.first_name + " " + this.state.profile.last_name : ""}</h1>
          <div className="btn_list">
            {/*<button className="button remove_resource" onClick={this.goBack.bind(this)}>Back</button>*/}
            {permList.includes("Manage Passwords") && (
              <ChangePassword
                userType="user"
                user={this.state.profile}
                isAdmin={this.props?.user?.profile?.data?.is_admin !== 0 ? true : false}
                className={"edit_resource"}
              />
            )}
            <button className="button print" onClick={this.print.bind(this)}>
              Print
            </button>
            {permList.includes("Message Employees") && (
              <button className="button edit_resource" onClick={this.mailSendOpenModal.bind(this)}>
                Message
              </button>
            )}
            {permList.includes("Status Update") && (
              <button className="button edit_resource" onClick={this.statusOpenModal.bind(this)}>
                Edit Status
              </button>
            )}
            {permList.includes("Edit User Profile") && (
              <button className="button edit_resource" onClick={this.editProfile.bind(this, this.state.hash)}>
                Edit Profile
              </button>
            )}
            {permList.includes("Remove Employee") && (
              <button className="button edit_resource" onClick={this.handleRemoveEmployee.bind(this)}>
                Remove Employee
              </button>
            )}
          </div>
        </div>
        <div className="applicants_inner_head inner_head" />
        <div className="content_div settings">
          <center id="feedLoader" className={this.state.loader ? this.state.loader : ""}>
            <img alt="Loader" src={require("../assets/images/loader.gif").default} />
          </center>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, permission, inventory, chat } = state;
  return {
    user,
    permission,
    inventory,
    chat,
  };
}

const connectedRegisterPage = connect(mapStateToProps)(Profile);
export { connectedRegisterPage as Profile };
