import React from "react";
import PropTypes from "prop-types";
import styles from "./formContainer.module.css";



const FormContainer = ({ title, children, isLoading }) => {
  return (
    <div className={styles.container}>
      <div className={styles.headerRoot}>
        <h3>{title}</h3>
      </div>
      <div className={`${styles.childrenContainer} ${isLoading && styles.submissionLoader}`}>
        {/* <form> */}
          {children}
        {/* </form> */}
      </div>
    </div>
  );
};

export default FormContainer;

FormContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool
};
