import React from 'react';
import { connect } from 'react-redux';
// import { manifestActions } from '../_actions';
import '../assets/styles/resource.css';
import { CreateManifest } from './CreateManifest';
import { userActions } from '../_actions';
import { SortYourCrew } from './SortYourCrew';
import { VehicleList } from './VehicleList';
import { Finalize } from './Finalize';
import { ResourceDetails } from '../Resources/ResourceDetails';

class Manifest extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            mainHtml: "",
            step: "CreateManifest",
            email: "",
            crew_id: "",
            crews: [],
            vehicles: [],
            prev: false,
        }
        this.yourCrew = this.yourCrew.bind(this);
        this.goToType = this.goToType.bind(this);
        this.toVehicle = this.toVehicle.bind(this);
        this.goToCrew = this.goToCrew.bind(this);
        this.finalize = this.finalize.bind(this);
        this.backToResource = this.backToResource.bind(this);
    }

    yourCrew(type) {
        this.setState({step:"SortYourCrew", type: type},function(){
            this.componentWillReceiveProps(this.props)
        })
    }

    goToType(type){
        this.setState({step:"CreateManifest", prevtype: type},function(){
            this.componentWillReceiveProps(this.props)
        })
    }

    goToCrew(crews){
        this.setState({step:"SortYourCrew", crews:crews, prev:true},function(){
            this.componentWillReceiveProps(this.props)
        })
    }

    toVehicle(crews){
        this.setState({step:"VehicleList", crews:crews},function(){
            this.componentWillReceiveProps(this.props)
        })
    }

    finalize(vehicles) {
        this.setState({step:"Finalize", vehicles:vehicles},function(){
            this.componentWillReceiveProps(this.props)
        })
    }

    backToResource(props){
        this.setState({ step:'Details', props:props },function(){
            this.componentWillReceiveProps(this.props)
        })
    }

    componentDidMount() {
        this.componentWillReceiveProps(this.props);
        this.props.dispatch(userActions.getAll()); 
    }

    componentWillReceiveProps(nextProps){

        const { user } = nextProps;
        if(user.items) {
            var userData = user.items.data;
            if(userData.crew_identifier_id){
                this.setState({ crew_id: userData.crew_identifier_id })
            }
        }


        var options;
        if(this.state.step === 'CreateManifest') {
            options = <div>     
                        <CreateManifest   
                            yourCrew={this.yourCrew}
                            crew_id={this.state.crew_id}
                            type={this.state.prevtype}
                        />    
                    </div>
        }

        if(this.state.step === 'Details') {
            options = <div>
                        <ResourceDetails 
                        goBack={this.changeTab.bind(this,'List')}
                        props={this.state.props}
                        // profile={this.profile.bind(this)}
                        // editResource={this.editResource.bind(this)}
                        // createManifest={this.createManifest.bind(this)}
                        // editManifest={this.editManifest.bind(this)}
                        // viewManifest={this.viewManifest.bind(this)}
                        // details={this.details.bind(this)}
                        />                         
                    </div>
        }

        if(this.state.step === 'SortYourCrew') {
            options = <div>     
                        <SortYourCrew 
                            crew_id={this.state.crew_id}
                            type={this.state.type}
                            previous={this.goToType}
                            next = {this.toVehicle.bind(this)}
                            crews={this.state.crews}
                            prev={this.state.prev}
                            backToResource={this.backToResource}
                        />    
                    </div>
        }

        if(this.state.step === 'VehicleList') {
            options = <div>     
                        <VehicleList 
                            crews={this.state.crews}
                            crew_id={this.state.crew_id}
                            previous={this.goToCrew.bind(this)}
                            next={this.finalize.bind(this)}
                        />    
                    </div>
        }

        if(this.state.step === 'Finalize') {
            options =     
                        <Finalize
                            crews={this.state.crews}
                            vehicles={this.state.vehicles}
                            previous={this.goToCrew.bind(this)}
                            next={this.finalize.bind(this)}
                        />    
                    
        }

        this.setState({mainHtml:options})
    }

    render() {        
        return (
            
                this.state.mainHtml
            
        );
    }
}

function mapStateToProps(state) {
    const { manifest, user, users } = state;
    return {
        manifest, user, users
    };
}

const connectedHomePage = connect(mapStateToProps)(Manifest);
export { connectedHomePage as Manifest };


