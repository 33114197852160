import React, { useRef, useState } from "react";
import TORPendingDocuments from "./TORPendingDocuments";
const Sort = {
  Asc: "asc",
  Dsc: "desc",
};

function TORDocumentsContainer() {
  const [globalSort, setGlobalSort] = useState("last_name");
  const [globalOrder, setGlobalOrder] = useState("asc");

  const setSortParams = (sort, order) => {
    const sortOrder = order ? Sort.Asc : Sort.Dsc;
    setGlobalSort(sort);
    setGlobalOrder(sortOrder);
  };

  return (
    <section className="user-docs">
      <h1 className="page_title">TOR Documents</h1>
      <div className="white">
        <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
          <TORPendingDocuments setSortParams={setSortParams} />
        </div>
      </div>
    </section>
  );
}

export default TORDocumentsContainer;
