import React from "react";
import { connect } from "react-redux";
import "../assets/styles/inventory.css";
import { inventoryActions, permissionActions, userActions } from "../_actions";
import { config } from "../_helpers";
import { AlertPopup } from "../_components/AlertPopup";
import NumberFormat from "react-number-format";
import moment from "moment";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

function LeftArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function RightArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

class InventoryView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      certificationList: [],
      mainHtml: "",
      type_list: [],
      equip_type: "",
      subPackages: [],
      type: "",
      name: "",
      cost: "",
      license_no: "",
      mspa_date: "",
      condition: "",
      make_model: "",
      notes: "",
      pic: "",
      subtype: "",
      imgPreview: "",
      isOpen: false,
      equipment: {},
      crop: { x: 20, y: 10, width: 150, height: 150 },
      pixelCrop: {
        x: "",
        y: "",
        width: "",
        height: "",
      },
      alertPop: false,
      prev_file_list: [],
      photoIndex: 0,
      permList: [],
      INVENTORY_VIEW_DATE_FORMAT: ""
    };
    this.goBack = this.goBack.bind(this);
    this.onImageError = this.onImageError.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(permissionActions.getPermission());
    this.props.dispatch(inventoryActions.getListEquipments());
    this.props.dispatch(inventoryActions.equipmentDetails(this.props.id));
    this.props.dispatch(userActions.getAll());
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altInvImg);
  }

  goBack() {
    this.props.goBack();
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }
  sliderImageChange(index) {
    this.setState({ photoIndex: index });
  }

  componentWillReceiveProps(nextProps) {
    const { equipments, equipList } = nextProps.inventory;
    const { permission, user } = nextProps;
    var subPackages = [],
      conditionList = [],
      dName = "",
      rId = "";

    this.setState({ INVENTORY_VIEW_DATE_FORMAT: user?.items?.data?.date_formats?.INVENTORY_VIEW_DATE_FORMAT });

    if (equipList) {
      if (equipList.data) {
        if (equipList.data.user) {
          dName =
            equipList.data.user.first_name +
            " " +
            equipList.data.user.last_name;
        }
        if (equipList.data.resource) {
          rId = equipList.data.resource.resource_name;
        }
        this.setState({
          type: equipList.data.type,
          is_archived: equipList.data.is_archived,
          type_id: equipList.data.type_id.toString(),
          name: equipList.data.unique_id,
          condition: equipList.data.current_condition,
          subtype: equipList.data.subpackage_id,
          cost: equipList.data.cost,
          license_no: equipList.data.serial_vin_number,
          notes: equipList.data.note,
          imgPreview: config.s3BucketUrl + equipList.data.pic,
          mspa_date: equipList.data.mspa_exp_date,
          make_model: equipList.data.vehicle_model_or_make,
          equip_id: equipList.data.id,
          year: equipList.data.year,
          resource: rId,
          driver: dName,
          equipment: equipList.data,
          prev_file_list: equipList.data.equipment_files,
          invPic: equipList.data.pic,
          state_of_licensure: equipList?.data?.state_of_licensure,
          equipment_owner: equipList?.data?.equipment_owner,
          insurance: equipList?.data?.insurance,
          job_code: equipList?.data?.job_code,
          class_code: equipList?.data?.class_code,
          arb_program: equipList?.data?.arb_program,
        });
        if (equipList.data.pic !== null) {
          this.setState({
            imgPreview: config.s3BucketUrl + equipList.data.pic,
          });
        } else {
          this.setState({ imgPreview: config.apiUrl + "/" + config.altInvImg });
        }
      }
    }

    /**Commented below code to avoid unnecessary popup in Inventory view page */
    // if (inventory.inventory) {
    //   if (inventory.inventory.success) {
    //     this.setState({
    //       alertPop: true,
    //       alertHead: "Success",
    //       alertMsg: inventory.inventory.message,
    //       alertFunction: this.goBack.bind(this),
    //     });
    //   } else {
    //     this.setState({
    //       alertPop: true,
    //       alertHead: "Error",
    //       alertMsg: inventory.inventory.message.name[0],
    //       alertFunction: "",
    //     });
    //   }
    // }

    if (equipments) {
      this.setState({
        type_list: equipments.EquipmentType,
        subPackages: equipments.SubPackages,
        divisionList: equipments.Division,
        conditionList: equipments.CurrentCondition,
        certificationList: equipments.EngineCertification,
      });
    }

    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
      this.setState({ permList });
    }
  }

  trimAndCheckForNull(value) {
    return value?.trim() ? value : "-";
  }

  render() {
    const gvw_list = ["1", "5", "6", "7", "8", "10", "14", "25"];
    const vehicle_list = ["1", "5", "6", "7", "8", "10", "14"];

    var image_list = [];
    if (this.state.prev_file_list) {
      if (this.state.prev_file_list.length > 1) {
        this.state.prev_file_list.forEach((item, index) =>
          image_list.push(
            <div className="upload_item" key={index}>
              <img
                width="200"
                onError={this.onImageError.bind(this)}
                key={index}
                src={config.s3BucketUrl + item.file_hash}
              />
            </div>
          )
        );
      }
    }
    var type_val,
      subCateg,
      division_value,
      certiType = "";
    this.state.type_list.map((item, index) => {
      if (item.id === this.state.type) {
        var type_val = item.value;
      }
    });
    var condition = "";
    if (this.state.conditionList) {
      this.state.conditionList.map((item, index) => {
        if (item.id == this.state.condition) {
          condition = item.value;
        }
      });
    }
    if (
      this.state.subPackages &&
      this.state.equipment &&
      this.state.equipment.subpackage_id
    ) {
      this.state.subPackages.map((item, index) => {
        if (item.id === this.state.equipment.subpackage_id) {
          subCateg = item.value;
        }
      });
    }
    if (
      this.state.divisionList &&
      this.state.equipment &&
      this.state.equipment.division_id
    ) {
      this.state.divisionList.map((item, index) => {
        if (item.id === this.state.equipment.division_id) {
          division_value = item.value;
        }
      });
    }

    if (
      this.state.certificationList.length &&
      this.state.equipment &&
      this.state.equipment.engine_certification
    ) {
      var id = this.state.equipment.engine_certification;
      this.state.certificationList.map((item) => {
        if (item.id == id) {
          certiType = item.value;
        }
      });
    }

    return (
      <div className="equipment_container">
        <div className="page_title float">
          <h1>Inventory Details</h1>
          {this.state.permList &&
            this.state.permList.includes("Add/Edit/Archive Equipments") &&
            this.state.is_archived === 0 && (
              <div className="btn_list">
                <div
                  className="button add_equip"
                  onClick={() => {
                    this.props.editInventory(
                      this.state.equip_id,
                      this.props.id,
                      "view"
                    );
                  }}
                >
                  Edit Equipment
                </div>
              </div>
            )}
        </div>
        <div className="inner_head"></div>
        <div className="inner_container">
          <div className="inventory_view">
            <div className="inv_field">
              {this.state.prev_file_list.length > 0 && (
                <div
                  className="image-box"
                  style={{
                    backgroundImage:
                      "url(" +
                      config.s3BucketUrl +
                      this.state.prev_file_list[this.state.photoIndex]
                        .file_hash +
                      ")",
                  }}
                  onClick={() => {
                    this.setState({ isOpen: true });
                  }}
                ></div>
              )}

              {/* <img src={this.state.prev_file_list.length>0&&config.s3BucketUrl+this.state.prev_file_list[this.state.photoIndex].file_hash} onError={this.onImageError.bind(this)}/> */}
            </div>
            <div className="inv_field img_list">
              {/* {image_list} */}

              <div className="inventory-select-slider">
                <Slider
                  {...{
                    infinite: true,
                    speed: 500,
                    prevArrow: <LeftArrow />,
                    nextArrow: <RightArrow />,
                    slidesToShow:
                      this.state.prev_file_list.length > 3
                        ? 3
                        : this.state.prev_file_list.length,
                    slidesToScroll: 1,
                  }}
                >
                  {this.state.prev_file_list &&
                    this.state.prev_file_list.length > 0 &&
                    this.state.prev_file_list.map((item, index) => {
                      return (
                        <div className="inv-slick-item-box" key={index}>
                          <div
                            style={{
                              backgroundImage:
                                "url(" +
                                config.s3BucketUrl +
                                item.file_hash +
                                ")",
                              border:
                                this.state.photoIndex === index
                                  ? "3px solid #00703C"
                                  : "",
                            }}
                            className="item-bg"
                            onClick={() => {
                              this.sliderImageChange(index);
                            }}
                          >
                            <img
                              alt="mail"
                              src={
                                require("../assets/images/placeholder.png")
                                  .default
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </div>
            <div className="inv_field">
              <label>Category</label>
              <p className="field">{this.state.type}</p>
            </div>
            {subCateg && (
              <div className="inv_field">
                <label>Sub-category</label>
                <p className="field">{subCateg}</p>
              </div>
            )}
            {this.state.type_id === "18" &&
              this.state.type_list.length != 0 && (
                <p>
                  {
                    this.state.type_list.find(
                      (item) => item.id == this.state.type_id
                    ).type_desc
                  }
                </p>
              )}
            <div className="inv_field">
              <label>Division</label>
              <p className="field">{division_value ? division_value : "-"}</p>
            </div>
            <div className="inv_field">
              <label>Unique ID</label>
              <p className="field">{this.state.name ? this.state.name : "-"}</p>
            </div>
            <div className="inv_field">
              <label>Resource</label>
              <p className="field">
                {this.state.resource ? this.state.resource : "-"}
              </p>
            </div>
            <div className="inv_field">
              <label>Driver / Employee</label>
              <p className="field">
                {this.state.driver ? this.state.driver : "-"}
              </p>
            </div>
            <div className="inv_field">
              <label>Description</label>
              <p className="field">
                {this.state.equipment && this.state.equipment.description
                  ? this.state.equipment.description
                  : "-"}
              </p>
            </div>
            <div className="inv_field">
              <label>Year</label>
              <p className="field">{this.state.year ? this.state.year : "-"}</p>
            </div>
            <div className="inv_field">
              <label>Make / Model</label>
              <p className="field">
                {this.state.make_model ? this.state.make_model : "-"}
              </p>
            </div>
            <div className="inv_field">
              <label>Serial Number / VIN</label>
              <p className="field">
                {this.state.equipment && this.state.equipment.serial_vin_number
                  ? this.state.equipment.serial_vin_number
                  : "-"}
              </p>
            </div>
            {gvw_list.includes(this.state.type_id) && (
              <div className="vehicle_fields">
                <div className="inv_field">
                  <label>GVW</label>
                  <p className="field">
                    {this.state.equipment.GVW ? this.state.equipment.GVW : "-"}
                  </p>
                </div>
                {vehicle_list.includes(this.state.type_id) && (
                  <>
                    <div className="inv_field">
                      <label>License Number</label>
                      <p className="field">
                        {this.state.equipment.vehicle_license
                          ? this.state.equipment.vehicle_license
                          : "-"}
                      </p>
                    </div>
                    <div className="inv_field">
                      <label>Engine Family</label>
                      <p className="field">
                        {this.state.equipment.engine_family
                          ? this.state.equipment.engine_family
                          : "-"}
                      </p>
                    </div>
                    <div className="inv_field">
                      <label>Engine Model</label>
                      <p className="field">
                        {this.state.equipment.engine_model
                          ? this.state.equipment.engine_model
                          : "-"}
                      </p>
                    </div>
                    <div className="inv_field">
                      <label>Engine Serial</label>
                      <p className="field">
                        {this.state.equipment.engine_serial
                          ? this.state.equipment.engine_serial
                          : "-"}
                      </p>
                    </div>
                    <div className="inv_field">
                      <label>Engine Description</label>
                      <p className="field">
                        {this.state.equipment.engine_description
                          ? this.state.equipment.engine_description
                          : "-"}
                      </p>
                    </div>
                    <div className="inv_field">
                      <label>Engine Certification</label>
                      <p className="field">
                        {this.state.equipment.engine_certification
                          ? certiType
                          : "-"}
                      </p>
                    </div>
                    <div className="inv_field">
                      <label>ARB</label>
                      <p className="field">
                        {this.state.equipment.ARB
                          ? this.state.equipment.ARB
                          : "-"}
                      </p>
                    </div>
                    <div className="inv_field">
                      <label>Non-Op</label>
                      <p className="field">
                        {this.state.equipment.non_op
                          ? this.state.equipment.non_op
                          : "-"}
                      </p>
                    </div>
                    <div className="inv_field">
                      <label>MSPA Exp. Date</label>
                      <p className="field">
                        {this.state.equipment.mspa_exp_date
                          ? moment(this.state.equipment.mspa_exp_date).format(
                              "MM/DD/YY"
                            )
                          : "-"}
                      </p>
                    </div>
                  </>
                )}
              </div>
            )}
            <div className="inv_field">
              <label>Current Condition</label>
              <p className="field">{condition !== "" ? condition : "-"}</p>
            </div>
            <div className="inv_field">
              <label>Year</label>
              <p className="field">
                {this.state.equipment.year ? this.state.equipment.year : "-"}
              </p>
            </div>
            <div className="inv_field">
              <label>Purchase Value</label>
              {this.state.equipment && this.state.equipment.cost ? (
                <NumberFormat
                  value={this.state.equipment.cost}
                  displayType={"text"}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              ) : (
                <p className="field">-</p>
              )}
            </div>
            <div className="inv_field">
              <label>Notes</label>
              <p className="field">
                {this.state.equipment.note ? this.state.equipment.note : "-"}
              </p>
            </div>
            {this.state.permList &&
              this.state.permList.includes("Inventory Sold Info") && (
                <React.Fragment>
                  <div className="inv_field">
                    <label>Sold Date</label>
                    <p className="field">
                      {this.state.equipment && this.state.equipment.sold_date
                        ? moment(this.state.equipment.sold_date).format(
                            "MM/DD/YY"
                          )
                        : "-"}
                    </p>
                  </div>
                  <div className="inv_field">
                    <label>Sold To</label>
                    <p className="field">
                      {this.state.equipment && this.state.equipment.sold_to
                        ? this.state.equipment.sold_to
                        : "-"}
                    </p>
                  </div>
                  <div className="inv_field">
                    <label>Sold Value</label>
                    <p className="field">
                      {this.state.equipment &&
                      this.state.equipment.sold_value ? (
                        <NumberFormat
                          value={this.state.equipment.sold_value}
                          displayType={"text"}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      ) : (
                        "-"
                      )}
                    </p>
                  </div>
                  <div className="inv_field">
                    <label>State Of Licensure</label>
                    <p className="field">
                      {this.trimAndCheckForNull(
                        this?.state?.equipment?.state_of_licensure
                      )}
                    </p>
                  </div>
                  <div className="inv_field">
                    <label>Owner Of Equipment</label>
                    <p className="field">
                      {this.trimAndCheckForNull(
                        this?.state?.equipment?.equipment_owner
                      )}
                    </p>
                  </div>
                  <div className="inv_field">
                    <label>Insurance</label>
                    <p className="field">
                      {this.trimAndCheckForNull(
                        this?.state?.equipment?.insurance
                      )}
                    </p>
                  </div>
                  <div className="inv_field">
                    <label>Job Code</label>
                    <p className="field">
                      {this.trimAndCheckForNull(
                        this?.state?.equipment?.job_code
                      )}
                    </p>
                  </div>
                  <div className="inv_field">
                    <label>Class Code</label>
                    <p className="field">
                      {this.trimAndCheckForNull(
                        this?.state?.equipment?.class_code
                      )}
                    </p>
                  </div>
                  <div className="inv_field">
                    <label>ARB Program</label>
                    <p className="field">
                      {this.trimAndCheckForNull(
                        this?.state?.equipment?.arb_program
                      )}
                    </p>
                  </div>
                  <div className="inv_field">
                    <label>Mileage/Hobbs Meter</label>
                    <p className="field">
                      {this.trimAndCheckForNull(
                        this?.state?.equipment?.mileage
                      )}
                    </p>
                  </div>
                  <div className="inv_field">
                    <label>Mileage/Hobbs Date</label>
                    <p className="field">
                        {this.state.equipment.mileage_date
                          ? moment(this.state.equipment.mileage_date).format(
                              this?.state?.INVENTORY_VIEW_DATE_FORMAT
                            )
                          : "-"}
                      </p>
                  </div>
                </React.Fragment>
              )}
          </div>
        </div>
        {this.state.alertPop && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            viewOpen="true"
            alertFunction={this.state.alertFunction}

            
            close={this.closeAlert.bind(this)}
          />
        )}
        {this.state.isOpen && this.state.prev_file_list.length > 0 && (
          <div className="gallery-pop-wrapper">
            <Lightbox
              mainSrc={
                config.s3BucketUrl +
                this.state.prev_file_list[this.state.photoIndex]
                  .file_original_hash
              }
              imageLoadErrorMessage={<span>Loading...</span>}
              nextSrc={
                config.s3BucketUrl +
                this.state.prev_file_list[
                  (this.state.photoIndex + 1) % this.state.prev_file_list.length
                ].file_original_hash
              }
              prevSrc={
                config.s3BucketUrl +
                this.state.prev_file_list[
                  (this.state.photoIndex +
                    this.state.prev_file_list.length -
                    1) %
                    this.state.prev_file_list.length
                ].file_original_hash
              }
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex +
                      this.state.prev_file_list.length -
                      1) %
                    this.state.prev_file_list.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex + 1) %
                    this.state.prev_file_list.length,
                })
              }
            />
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { permission, inventory, user } = state;
  return {
    permission,
    inventory,
    user
  };
}
const connectedHomePage = connect(mapStateToProps)(InventoryView);
export { connectedHomePage as InventoryView };
