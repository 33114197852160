import React, {
    useImperativeHandle,
    useState,
    forwardRef,
    useEffect,
  } from "react";
  import { useDispatch } from "react-redux";
  import ReactPaginate from "react-paginate";
  import moment from "moment";
  import Modal from "react-modal";
  import DatePicker from "react-datepicker";
  import TimePicker from "_components/utils/TimePicker/TimePicker";
  import AsyncSelect from "react-select/async";
  import { dashboardActions, resourceActions } from "../../_actions";
  import { ReactComponent as Calendar } from "assets/icons/calendar-icon.svg";
  import { toast } from "react-toastify";
  // import { withRouter } from 'react-router-dom';
  
  const ASC_CLASS = "sort-arrow asc";
  const DESC_CLASS = "sort-arrow desc";
  const TABLE_DATE_FORMAT = "MM/DD/YYYY H:mm:ss"
  const FILTER_DATE_FORMAT = "YYYY-MM-DD"
  
  const Sort = {
    Asc:"asc",
    Dsc:"desc"
  }
  
  const selectStyles = {
    control: (css) => ({
      ...css,
      width: 200 || "auto",
      opacity: 1,
      zIndex: 7,
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      opacity: 1,
      zIndex: 9999,
    }),
    option: (css) => ({ ...css, paddingRight: 36 + 8, zIndex: 9999 }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };
  
  const CustomDatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div className="activity-log-datepicker" onClick={onClick}>
      <input type="text" value={value} readOnly ref={ref} />
      <Calendar className="svg-icon" />
    </div>
  ));
  
  const initialStyleState = {
    dateTimeClass: DESC_CLASS,
    descClass: ASC_CLASS,
    performedByClass: ASC_CLASS,
    performedOnClass: ASC_CLASS,
  };
  
  const ActivityLog = forwardRef((props, ref) => {
    const { data, lastPage, isLoading, employees,refresh } = props;
  
    const dispatch = useDispatch();
    const now = moment()
  
    const [
      { dateTimeClass, descClass, performedByClass, performedOnClass },
      setStyleState,
    ] = useState(initialStyleState);
    const [order, setOrder] = useState(false);
    const [sortItem, setSortItem] = useState("created_at");
    const [currentPage, setCurrentPage] = useState(0);
    const [previewModal, setPreviewModal] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState({});
    const [filterModal, setFilterModal] = useState(false);
    const [startDate, setStartDate] = useState(now);
    const [startTime, setStartTime] = useState(now);
    const [endDate, setEndDate] = useState(now);
    const [endTime, setEndTime] = useState(now);
    const [performedBy, setPerformedBy] = useState("");
    const [performedOn, setPerformedOn] = useState("");
    const [description, setDescription] = useState("");
    const [isFilterON, setFilterON] = useState(false);
  
    const fetchActivityLogWithFilters = async (page) => {
      const startDateAndTime = `${startDate.format(FILTER_DATE_FORMAT)} ${startTime.second(0)?.format("HH:mm:ss")}`;
      const endDateAndTime = `${endDate.format(FILTER_DATE_FORMAT)} ${endTime.second(59)?.format("HH:mm:ss")}`;
      const reqData = new FormData();
      reqData.append("sort", sortItem);
      reqData.append("order", order ? Sort.Asc : Sort.Dsc);
      reqData.append("page", page || currentPage);
      if (isFilterON) {
        reqData.append("start_date", startDateAndTime);
        reqData.append("end_date", endDateAndTime);
        reqData.append("description", description);
        reqData.append("performed_by", performedBy?.value || "");
        reqData.append("performed_on", performedOn?.value || "");
      }
  
      await dispatch(dashboardActions.fetchActivityLog(reqData));
  
      setFilterModal(false);
      setPerformedBy("")
      setPerformedOn("")
    };
  
    const handlePageChange = (newPage) => {
      fetchActivityLogWithFilters(newPage?.selected + 1);
      setCurrentPage(newPage?.selected + 1);
    };
  
    const noDataAvailable = !isLoading && !data?.length;
  
    const sortList = (className, prevStyle, sort) => {
      const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
      const nextOrder = prevStyle !== DESC_CLASS ? false : true;
      setStyleState({ ...initialStyleState, [className]: newStyle });
      setOrder(nextOrder);
      setSortItem(sort);
    };
  
    const onClickProperty = (activity) => {
      setSelectedActivity(activity);
      setPreviewModal(true);
    };
  
    const onApplyFilterClick = () => {
      if (
        startDate.isAfter(endDate) ||
        endDate.isBefore(startDate)||
        startTime.isAfter(endTime) ||
        endTime.isBefore(startTime)
      ) {
        return toast.error("Please check the selected date or time range!");
      }
      if (isFilterON) return fetchActivityLogWithFilters();
      setFilterON(true);
    };
  
    const handleSearch = async (text) => {
      let data = new FormData();
      data.append("keyword", text);
      data.append("include_all_users", true);
      await dispatch(resourceActions.employeeList(data));
      const list = employees.data.data.map((employee) => {
        return {
          label: `${employee.last_name + " " + employee.first_name}`,
          value: employee.userid,
        };
      });
      return list;
    };
  
    const loadResources = (inputValue) =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(handleSearch(inputValue));
        }, 1000);
      });
  
    const onFilterModalClose = () => {
      setFilterModal(false);
    };
  
    const triggerFilterModal = () => {
      setFilterModal(true);
    };
  
    const onRefresh = () => {
      setFilterON(false);
      setCurrentPage(1);
      setStyleState({ ...initialStyleState, dateTimeClass: DESC_CLASS });
      setOrder(false);
      setSortItem("created_at");
      setStartDate(now)
      setStartTime(now)
      setEndDate(now)
      setEndTime(now)
      setDescription("")
      setPerformedBy("")
      setPerformedOn("")
    };
  
    useImperativeHandle(ref, () => ({
      onRefresh,
      triggerFilterModal,
      isFilterON
    }));
  
    const onNavigate = (hash) => {
      // props?.history?.push(`/profile/${hash}`)
      window.location.href = `/profile/${hash}`;
    }
  
    const renderPerformedOn = (activity) => {
      const { subject_type } = activity;
  
      switch (subject_type) {
        case "App\\Models\\JobApplication":
          return <p>{activity.performed_job_applicant?.first_name}</p> || "_";
        case "App\\Models\\Resource":
          return activity.performed_resource ?<p>{activity.performed_resource?.resource_name}</p>: "_";
        case "App\\Models\\Vehicle":
          return activity.performed_vehicle ?<p>{activity.performed_vehicle?.name}</p> : "_";
        case "App\\Models\\Equipment":
          return activity.performed_equipment?<p>{activity.performed_equipment?.unique_id}</p>: "_";
        case "App\\User":
          return !!activity.performed_on?.first_name &&
            !!activity.performed_on?.last_name
            ? activity.performed_on?.last_name +
              " " +
              activity.performed_on?.first_name
            : "_";
        default:
          return <p>NULL</p>;
      }
    };
  
    useEffect(() => {
      fetchActivityLogWithFilters();
    }, [sortItem,order,refresh,isFilterON]);
  
    return (
      <>
        {/* ----------------------PREVIEW MODAL--------------------*/}
        <Modal
          isOpen={previewModal}
          className="activity-modal"
          overlayClassName="activity-modal-overlay"
        >
          <div className="activity-modal-header">
            <h3>ACTIVITY DETAILS</h3>
          </div>
          <div className="activity-modal-body">
            <table className="mobile_hide report_list_width_full">
              <tbody className="report_list_table_tbody">
                <tr>
                  <td>Date {"&"} Time</td>
                  <td>
                    {moment(selectedActivity.created_at).isValid() ? moment.utc((selectedActivity.created_at)).format(TABLE_DATE_FORMAT) :"_"}
                  </td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{selectedActivity?.description || "_"}</td>
                </tr>
                <tr>
                  <td>Executed by</td>
                  {selectedActivity?.performed_by?<td style={{color:"#3F7E39"}}>{selectedActivity?.performed_by?.full_name_last_first}</td>:<td>NULL</td>}
                </tr>
                <tr>
                  <td>Recipient</td>
                  {selectedActivity?.performed_on?<td style={{color:"#3F7E39"}}>{selectedActivity?.performed_on?.full_name_last_first}</td>:<td>NULL</td>}
                </tr>
                <tr>
                  <td>IP</td>
                  <td>{selectedActivity.properties?.ip}</td>
                </tr>
                <tr>
                  <td>Device Info</td>
                  <td>{selectedActivity.properties?.device_info}</td>
                </tr>
              </tbody>
            </table>
            <div className="modal-close-btn">
              <button className="button" onClick={() => setPreviewModal(false)}>
                Close
              </button>
            </div>
          </div>
        </Modal>
        {/* ----------------------FILTER MODAL-------------*/}
        <Modal
          isOpen={filterModal}
          className="activity-modal"
          overlayClassName="activity-modal-overlay"
        >
          <div className="activity-modal-header">
            <h3>FILTER</h3>
          </div>
          <div className="activity-modal-body">
            <p>Start date and time</p>
            <div className="date-time-pickers">
              <DatePicker
                selected={startDate}
                onChange={setStartDate}
                showMonthDropdown
                showYearDropdown
                dateFormat="MM/DD/YYYY"
                dropdownMode="select"
                popperClassName="activity-date-picker-popper"
                customInput={<CustomDatePickerInput />}
              />
              <TimePicker value={startTime} onChange={setStartTime} />
            </div>
            <p>End date and time</p>
            <div className="date-time-pickers">
              <DatePicker
                selected={endDate}
                onChange={setEndDate}
                showMonthDropdown
                showYearDropdown
                dateFormat="MM/DD/YYYY"
                dropdownMode="select"
                popperClassName="activity-date-picker-popper"
                customInput={<CustomDatePickerInput />}
              />
              <TimePicker value={endTime} onChange={setEndTime} />
            </div>
            <p>Description</p>
            <div className="inp_sub_container">
              <input
                type="text"
                className="employee_search"
                value={description}
                name="keyword"
                placeholder="Search..."
                onChange={(e) => setDescription(e.target.value)}
                autoComplete="off"
              />
            </div>
            <p>Executed by</p>
            <div className="inp_sub_container">
              <AsyncSelect
                cacheOptions
                loadOptions={loadResources}
                onChange={(selection) => setPerformedBy(selection)}
                // isMulti
                defaultOptions
                isClearable
                styles={selectStyles}
                placeholder={"Search..."}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            <p style={{ paddingTop: "20px" }}>Recipient</p>
            <div className="inp_sub_container">
              <AsyncSelect
                cacheOptions
                loadOptions={loadResources}
                onChange={(selection) => setPerformedOn(selection)}
                // isMulti
                defaultOptions
                isClearable
                styles={selectStyles}
                placeholder={"Search..."}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            
            <div className="modal-close-btn">
              <button className="button cancel" onClick={onFilterModalClose}>
                Cancel
              </button>
              <button className="button" onClick={onApplyFilterClick}>
                Apply Filter
              </button>
            </div>
          </div>
        </Modal>
        <>
          <div className="history_lists outer_list table__scroll activity-log-table">
            <table className={`mobile_hide report_list_width_full ${data?.length?"data-table":"no-data"}`}>
              <thead>
                <tr className="report_list_table_row">
                  <th
                    onClick={() =>
                      sortList("dateTimeClass", dateTimeClass, "created_at")
                    }
                  >
                    <span>Date & time</span>
                    <span className={dateTimeClass} />
                  </th>
                  <th
                    onClick={() =>
                      sortList("descClass", descClass, "description")
                    }
                  >
                    <span>Description</span>
                    <span className={descClass} />
                  </th>
                  <th
                    onClick={() =>
                      sortList("performedByClass",performedByClass,"performed_by")
                    }
                  >
                    <span>Executed by</span>
                    <span className={performedByClass} />
                  </th>
                  <th
                    // onClick={() =>
                      // sortList("performedOnClass",performedOnClass,"performed_on")
                    // }
                  >
                    <span>Recipient</span>
                    {/* <span className={performedOnClass} /> */}
                  </th>
                  <th>
                    <span>Properties</span>
                  </th>
                </tr>
              </thead>
              <tbody className="report_list_table_tbody">
                {!isLoading ? (
                  data?.length ? (
                    data?.map((activity) => (
                      <tr>
                        <td>
                          {moment(activity.created_at).isValid() ? moment.utc(activity.created_at).format(TABLE_DATE_FORMAT) :"_"}
                        </td>
                        <td>{activity?.description || "_"}</td>
  
                        {activity?.performed_by?.full_name_last_first ?<td style={{color:"#3F7E39",cursor:"pointer"}} onClick={()=>onNavigate(activity?.performed_by?.hash)}>{activity?.performed_by?.full_name_last_first}</td>:<td>NULL</td>}
                        <td>
                        {renderPerformedOn(activity)}
                        </td>
                        
                        <td onClick={() => onClickProperty(activity)} style={{cursor:"pointer"}}>
                          {"ip: "+activity?.properties?.ip +", "+"device_info: "+activity?.properties?.device_info}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5}>There is no data available. Try adjusting the filter
                        options to get some results.</td>
                    </tr>
                  )
                ) : (
                  <center id="feedLoader">
                    <img
                      alt="Loader"
                      src={require("../../assets/images/loader.gif").default}
                    />
                  </center>
                )}
              </tbody>
            </table>
  
            {/* Mobile */}
            {noDataAvailable ? (
              <div className="dashboard_expiration_date_no_data for_mobile_history_list">
                There is no data available. Try adjusting the filter options to
                get some results.
              </div>
            ) : (
              <ActivityLogMobileView
                data={data}
                fetchActivityLogWithFilters={fetchActivityLogWithFilters}
                order={order}
                setOrder={setOrder}
                sortItem={sortItem}
                setSortItem={setSortItem}
                isLoading={isLoading}
                sortList={sortList}
                dateTimeClass={dateTimeClass}
                descClass={descClass}
                performedByClass={performedByClass}
                performedOnClass={performedOnClass}
                onNavigate={onNavigate}
              />
            )}
          </div>
          {!isLoading && !!data?.length && (
            <div className="fs-pagination-wrapper-outer" key={7}>
              <div className="customised-pagination right-align activity">
                <ReactPaginate
                  forcePage={currentPage ? currentPage - 1 : currentPage}
                  previousLabel={""}
                  nextLabel={""}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={lastPage}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  onPageChange={(data) => handlePageChange(data)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          )}
        </>
      </>
    );
  });
  
  export default ActivityLog;
  // export default withRouter(ActivityLog);
  
  const ActivityLogMobileView = (props) => {
    const {
      data,
      isLoading,
      sortList,
      dateTimeClass,
      descClass,
      performedByClass,
      performedOnClass,
      onNavigate
    } = props;
  
    return (
      <div className="mobile-view-wrapper">
        {isLoading && (
          <center id="feedLoader">
            <img
              alt="Loader"
              src={require("../../assets/images/loader.gif").default}
            />
          </center>
        )}
        {!isLoading &&
          data?.map((activity, index) => (
            <div className="for_mobile_history_list" key={index}>
              <hr></hr>
              <div className="history_items">
                <div className="status_item">
                  <div
                    className="list_item"
                    onClick={() =>
                      sortList("dateTimeClass", dateTimeClass, "created_at")
                    }
                  >
                    <strong>Date & Time</strong>
                    <span className={dateTimeClass} />
                  </div>
                  <div className="list_item">
                    {moment(activity.created_at).format(TABLE_DATE_FORMAT)}
                  </div>
                </div>
  
                <div className="status_item">
                  <div
                    className="list_item"
                    onClick={() =>
                      sortList("descClass", descClass, "description")
                    }
                  >
                    <strong>Description</strong>
                    <span className={descClass} />
                  </div>
                  <div className="list_item">{activity?.description}</div>
                </div>
  
                <div className="status_item">
                  <div
                    className="list_item"
                    onClick={() =>
                      sortList(
                        "performedByClass",
                        performedByClass,
                        "performed_by"
                      )
                    }
                  >
                    <strong>Executed by</strong>
                    <span className={performedByClass} />
                  </div>
                    {activity?.performed_by?.full_name_last_first ?<div className="list_item" style={{color:"#3F7E39",cursor:"pointer"}} onClick={()=>onNavigate(activity?.performed_by?.hash)}>{activity?.performed_by?.full_name_last_first}</div>:<div className="list_item">NULL</div>}
                </div>
  
                <div className="status_item">
                  <div
                    className="list_item"
                    // onClick={() =>
                    //   sortList(
                    //     "performedOnClass",
                    //     performedOnClass,
                    //     "performed_on"
                    //   )
                    // }
                  >
                    <strong>Recipient</strong>
                    {/* <span className={performedOnClass} /> */}
                  </div>
                  <div className="list_item">
                    {moment(activity.performed_on).isValid() ? moment(activity.performed_on).format(TABLE_DATE_FORMAT):"_"}
                  </div>
                </div>
  
                <div className="status_item">
                  <div className="list_item">
                    <strong>Properties</strong>
                  </div>
                  <div className="list_item">
                  {"ip: "+activity?.properties?.ip +", "+"device_info: "+activity?.properties?.device_info}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };
  