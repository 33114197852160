import {
  authHeader,
  config
} from "../_helpers";

export const chatService = {
  getConversations,
  getUsers,
  getCrews,
  getMessages,
  createConversation,
  sendMessage,
  setFavorite,
  updateMessage,
  deleteMessage,
  searchConversation,
  updateConversation,
  addParticipant,
  removeParticipant,
  getSignedDOUrl,
  uploadFile,
  getConversationSettings,
  readAllMessages,
  getAllCounts,
  getRecentMessages,
  getConversation
};

function getConversationSettings(data) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(config.apiUrl + '/api/chat/conversations/settings', requestOptions).then(handleResponse, handleError);
}

function readAllMessages(data) {
  let conversation_id = data.id
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(config.apiUrl + `/api/chat/conversations/markasread/${conversation_id}`, requestOptions).then(handleResponse, handleError);
}

function getAllCounts() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(config.apiUrl + `/api/notification/system/count`, requestOptions).then(handleResponse, handleError);
}

function getUsers(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/employee/getlist", requestOptions).then(
    handleResponse,
    handleError
  );
}

function getCrews(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/search/resource", requestOptions).then(
    handleResponse,
    handleError
  );
}

function getConversations(data) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  let {
    admin, page
  } = data;

  return fetch(
    config.apiUrl + `/api/chat/conversations/getall?admin_mode=${admin.active}&page=${page}`,
    requestOptions
  ).then(handleResponse, handleError);
}

function getConversation(data) {
  let { id, admin } = data;
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    config.apiUrl + `/api/chat/conversations/getconversation/${id}?admin_mode=${admin.active}`,
    requestOptions
  ).then(handleResponse, handleError);
}

function searchConversation(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(
    config.apiUrl + "/api/chat/conversations/search",
    requestOptions
  ).then(handleResponse, handleError);
}

function getMessages(data) {
  let {
    id,
    currentPage,
    admin_mode
  } = data;

  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    config.apiUrl + `/api/chat/conversations/get?id=${id}&page=${currentPage||1}&admin_mode=${admin_mode}`,
    requestOptions
  ).then(handleResponse, handleError);
}

function createConversation(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(
    config.apiUrl + "/api/chat/conversations/create",
    requestOptions
  ).then(handleResponse, handleError);
}

function updateConversation(data) {
  let requestData = new FormData();
  requestData.append("id", data.id);
  requestData.append("title", data.title);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: requestData
  };

  return fetch(
    config.apiUrl + "/api/chat/conversations/update",
    requestOptions
  ).then(handleResponse, handleError);
}

function addParticipant(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(
    config.apiUrl + "/api/chat/conversations/add",
    requestOptions
  ).then(handleResponse, handleError);
}

function removeParticipant(data) {
  let requestData = new FormData();
  requestData.append("id", data.conversation_id);

  if (data.user)
    requestData.append("users[]", data.user);

  if (data.crew)
    requestData.append("crews[]", data.crew);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: requestData
  };

  return fetch(
    config.apiUrl + "/api/chat/conversations/remove",
    requestOptions
  ).then(handleResponse, handleError);
}

function sendMessage(message) {

  let messageData = new FormData();
  messageData.append("temp_id", message.temp_id);
  messageData.append("message", message.body);
  messageData.append("id", message.conversation_id);
  messageData.append("type", message.type);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: messageData
  };

  return fetch(config.apiUrl + "/api/chat/message/send", requestOptions).then(
    handleResponse,
    handleError
  );
}

function setFavorite(data) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    config.apiUrl + `/api/chat/conversations/mark/${data.id}`,
    requestOptions
  ).then(handleResponse, handleError);
}

function uploadFile(file, url) {

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": `${file.type}; charset=utf-8`
    },
    body: file
  };

  return fetch(url, requestOptions).then(handleResponse, handleError);
}

function getSignedDOUrl(messageBody) {

  let {
    file,
    extension
  } = messageBody;

  let type = file.type.includes('image') ? 'image' : 'file';

  const requestOptions = {
    method: "POST",
    headers: authHeader()
  };

  return fetch(
    config.apiUrl +
    `/api/chat/message/getsignedurl?type=${type}&extension=${extension}`,
    requestOptions
  ).then(handleResponse, handleError);

}

function getRecentMessages() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    config.apiUrl + `/api/chat/conversations/getrecent`,
    requestOptions
  ).then(handleResponse, handleError);
}

function updateMessage(message) {
  let messageData = new FormData();
  messageData.append("message", message.body);
  messageData.append("id", message.id);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: messageData
  };

  return fetch(config.apiUrl + "/api/chat/message/update", requestOptions).then(
    handleResponse,
    handleError
  );
}

function deleteMessage(message) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    config.apiUrl + `/api/chat/message/delete/${message.id}`,
    requestOptions
  ).then(handleResponse, handleError);
}

function handleResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      // return json if it was returned in the response
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then(json => resolve(json));
      } else {
        resolve();
      }
    } else {
      // return error message from response body
      response.text().then(text => reject(text));
    }
  });
}

function handleError(error) {
  return Promise.reject(error && error.message);
}