import React from "react";
import { connect } from "react-redux";
import "../assets/styles/inventory.css";
import { inventoryActions, permissionActions } from "../_actions";
import { config } from "../_helpers";
import { AlertPopup } from "../_components/AlertPopup";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";

ReactModal.setAppElement("#app");

class ArchivedEquipments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permList: [],
      equipments: [],
      keyword: "",
      unArchiveIndex: null,
      unArchiveModalOpen: false,
      deleteModal: false,
      selectedInventory: null,
      alertPop: false,
      type: "",
      alertHead: "",
      alertMsg: "",
    };

    this.searchTimeout = null;
    this.goBack = this.goBack.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.unArchiveModalClose = this.unArchiveModalClose.bind(this);
    this.unArchiveModal = this.unArchiveModal.bind(this);
    this.unArchiveEquipment = this.unArchiveEquipment.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.clear = this.clear.bind(this);
    this.viewInventory = this.viewInventory.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.deleteInventory = this.deleteInventory.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(permissionActions.getPermission());
    this.props.dispatch(inventoryActions.getArchivedList({}));
  }

  componentWillReceiveProps(nextProps) {
    const { inventory, permission } = nextProps;
    let type_list = [];

    if (inventory) {
      let { archived, unArchivedEquipment } = inventory;

      if (archived) {
        this.setState({
          equipments: archived.data,
        });
      }

      if (unArchivedEquipment) {
        if (unArchivedEquipment.success && this.state.unArchiveIndex !== null) {
          let newList = this.state.equipments.filter(
            (item, index) => index !== this.state.unArchiveIndex,
          );

          this.setState({
            equipments: newList,
            unArchiveModalOpen: false,
            unArchiveIndex: null,
          });
        } else {
          this.setState({
            unArchiveModalOpen: false,
            unArchiveIndex: null,
          });
        }
      }

      if (inventory.equipments) {
        inventory.equipments.EquipmentType.forEach((item, index) => {
          type_list.push(
            <option key={index} value={item.id}>
              {item.value}
            </option>,
          );
        });
        this.setState({ type_list });
      }
    }

    if (permission) {
      let { permissions } = permission;

      if (permissions) {
        this.setState({
          permList: permissions.data,
        });
      }
    }
  }

  goBack() {
    this.props.goBack();
  }

  viewInventory(id) {
    this.props.history.push(``);
  }

  closeAlert() {
    this.setState({
      alertPop: false,
      alertHead: "",
      alertMsg: "",
    });
  }

  // Set dropbox values
  handleSelect(event) {
    this.setState({ [event.target.name]: event.target.value }, function () {
      this._getArchivedList();
    });
  }

  handleSearch(e) {
    let keyword = e.target.value;
    this.setState({ keyword }, () => {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this._getArchivedList();
      }, 500);
    });
  }

  _getArchivedList() {
    this.props.dispatch(
      inventoryActions.getArchivedList({ keyword: this.state.keyword, type: this.state.type }),
    );
  }

  clear() {
    this.setState({ keyword: "", type: "" }, () => this._getArchivedList());
  }

  openDeleteModal(item) {
    this.setState({ deleteModal: true, selectedInventory: item });
  }

  closeDeleteModal() {
    this.setState({ deleteModal: false, selectedInventory: "" });
  }

  deleteInventory() {
    let data = new FormData();
    data.append("id", this.state.selectedInventory.equipment_id);
    data.append("type", this.state.selectedInventory.equipment_type_id);
    this.props.dispatch(inventoryActions.forceDeleteInventory(data)).then((res) => {
      this._getArchivedList();
      this.closeDeleteModal();
    });
  }

  unArchiveModalClose() {
    this.setState({
      unArchiveModalOpen: false,
      unArchiveIndex: null,
    });
  }

  unArchiveModal(index) {
    this.setState({
      unArchiveModalOpen: true,
      unArchiveIndex: index,
    });
  }

  unArchiveEquipment() {
    let id = this.state.equipments[this.state.unArchiveIndex].id;
    this.props.dispatch(inventoryActions.unArchiveInventory({ id })).then(res =>{
      this._getArchivedList();
      let dataOption = new FormData();
      for (let key in this.props.filter){
        dataOption.append(key, this.props.filter[key]);
      }
      if (!dataOption.has("option")) {
        dataOption.append("option", "All");
      }
      this.props.dispatch(inventoryActions.getList(dataOption));
    });
  }

  render() {
    let permList = [];
    let { equipments, unArchiveModalOpen } = this.state;
    this.props.permission &&
      this.props.permission.permissions.data &&
      (permList = this.props.permission.permissions.data);

    return (
      <div className='equipment_container'>
        <div className='breadcrumbs'>
          <span className='green' onClick={this.goBack.bind(this)}>
            Inventory
          </span>{" "}
          /{" Archived Equipments"}
        </div>
        <div className='page_title float'>
          <h1>Archived Equipments</h1>
        </div>
        <div className='equipment_list'>
          <div className='inner_head filter-container'>
            {/* Type */}
            <select
              data-filter
              name='type'
              value={this.state.type}
              className='filter-items mr-1 mb-0'
              onChange={this.handleSelect}>
              <option value=''> - Equipment Type - </option>
              {this.state.type_list}
            </select>
            {/* Search */}
            <input
              type='text'
              className='equipment_search filter-items mr-1'
              name='keyword'
              value={this.state.keyword}
              placeholder='Search Equipments...'
              onChange={this.handleSearch.bind(this)}
              autoComplete='off'
            />
            {/* Clear */}
            <button className='button' onClick={this.clear}>
              Clear
            </button>
          </div>
          <div className='inner_container equipment_archived_list archived_wrapper'>
            <div className='appln_list_header list_header archived_list'>
              <div className='list_item_head'>Equipment</div>
              <div className='list_item_head'>Name</div>
              <div className='list_item_head'>Type</div>
              <div className='list_item_head'>Notes</div>
              <div className='unarchive list_item_head' />
              <div className='unarchive list_item_head' />
              <div className='unarchive list_item_head' />
            </div>
            {equipments.map((item, index) => {
              return (
                <div className='applicant_item' key={index}>
                  <div className='basic_info'>
                    <div className='list_item'>
                      {item.pic ? (
                        <img
                          className='pic'
                          src={config.s3BucketUrl + item.pic.replace(/\\/g, '')}
                          alt=''
                          onError={(e) => {
                            e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
                          }}
                        />
                      ) : (
                        <img className='pic' src={config.apiUrl + "/" + config.altImg} alt='' />
                      )}
                    </div>
                    <div className='appln_name list_item'>
                      <span className='small_title'>Name:&nbsp;</span>
                      {item.unique_id || "--"}
                    </div>
                    <div className='list_item'>
                      <span className='small_title'>Type:&nbsp;</span>
                      {item.type || "--"}
                    </div>
                    <div className='list_item'>
                      <span className='small_title'>Notes:&nbsp;</span>
                      {item.note || "--"}
                    </div>
                    {/* <div className="list_item type"><span className="small_title">SL No:&nbsp;</span>{item.serial_vin_number || "--"}</div>
                            <div className="list_item type"><span className="small_title">Lic No:&nbsp;</span>{item.vehicle_license || item.chipper_license || "--"}</div> */}
                    <div className='unarchive list_item' onClick={() => this.unArchiveModal(index)}>
                      Unarchive
                    </div>
                    {permList.includes("Add/Edit/Archive Equipments") && (
                      <div
                        className='unarchive list_item'
                        onClick={() => this.openDeleteModal(item)}>
                        Delete
                      </div>
                    )}
                    <div className='unarchive list_item'>
                      <Link
                        to={`/inventory/inventory-view/${item.id}`}
                        target='_blank'
                        className='unarchive'>
                        View
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* Empty List */}
            {equipments.length <= 0 && (
              <div className='applicant_item' key='noEmployees'>
                <p className='no_employee float'>No Equipments found</p>
              </div>
            )}
          </div>
        </div>

        {/* Delete Modal */}
        <ReactModal isOpen={this.state.deleteModal} overlayClassName='dltInvPopUp'>
          <div id='dltInvPopup'>
            <div className='modal-head'>
              <h4 className='modal-title'>Delete Confirmation</h4>
              <img
                alt='close'
                className='closeModal'
                onClick={this.closeDeleteModal}
                src={require("../assets/images/close_green.png").default}
              />
            </div>
            <div className='modal-body'>
              <p>Are you sure you want to Delete this equipment?</p>
              <div className='btn_list'>
                <button className='button grey' onClick={this.closeDeleteModal}>
                  Cancel
                </button>
                <button className='button' onClick={() => this.deleteInventory()}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={unArchiveModalOpen}
          onClose={this.unArchiveModalClose.bind(this)}
          overlayClassName='dltInvPopUp'>
          <div id='dltInvPopup'>
            <div className='modal-head'>
              <h4 className='modal-title'>Confirm</h4>
              <img
                alt='close'
                className='closeModal'
                onClick={this.unArchiveModalClose.bind(this)}
                src={require("../assets/images/close_green.png").default}
              />
            </div>
            <div className='modal-body'>
              <p>Are you sure you want to unarchive this equipment?</p>
              <div className='btn_list'>
                <button className='button grey' onClick={this.unArchiveModalClose.bind(this)}>
                  Cancel
                </button>
                <button className='button' onClick={this.unArchiveEquipment.bind(this)}>
                  Ok
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        {/* Alert */}
        <div>
          {this.state.alertPop === true && (
            <AlertPopup
              head={this.state.alertHead}
              message={this.state.alertMsg}
              alertFunction={this.state.alertFunction}
              close={this.closeAlert}
            />
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { permission, inventory } = state;
  return {
    permission,
    inventory,
  };
}
const connectedHomePage = connect(mapStateToProps)(ArchivedEquipments);
export { connectedHomePage as ArchivedEquipments };
