import { reportsConstants } from "../_constants";
import { reportsService } from "../_services";

export const reportsActions = {
  getVehicleReport,
  getEquipmentSoldReport,
  getAllInventoryReport,
  getDocStatusList,
  getExpirationDateReport,
  getEmployeesBirthday,
  getMileageHobbsReport,
  fetchApplicantsReport,

};

function getVehicleReport(data) {
  return (dispatch) => {
    dispatch(request());
    reportsService.getVehicleReport(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: reportsConstants?.VEHICLE_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: reportsConstants?.VEHICLE_REPORT_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return { type: reportsConstants?.VEHICLE_REPORT_ERROR, error };
  }
}

function getEquipmentSoldReport(data) {
  return (dispatch) => {
    dispatch(request());
    reportsService.getEquipmentSoldReport(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: reportsConstants?.EQU_SOLD_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: reportsConstants?.EQU_SOLD_REPORT_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return { type: reportsConstants?.EQU_SOLD_REPORT_ERROR, error };
  }
}

function getAllInventoryReport(data) {
  return (dispatch) => {
    dispatch(request());
    reportsService.getAllInventoryReport(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: reportsConstants?.ALL_INV_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: reportsConstants?.ALL_INV_REPORT_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return { type: reportsConstants?.ALL_INV_REPORT_ERROR, error };
  }
}

function getExpirationDateReport(data) {
  return (dispatch) => {
    dispatch(request());
    reportsService.getExpirationDateReport(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: reportsConstants?.EXP_DATE_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: reportsConstants?.EXP_DATE_REPORT_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return { type: reportsConstants?.EXP_DATE_REPORT_ERROR, error };
  }
}

function getDocStatusList(data) {
  return (dispatch) => {
    dispatch(request());

    reportsService.getDocStatusList(data).then(
      (list) => dispatch(success(list)),

      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: reportsConstants?.GET_DOC_STATUS_LIST_REPORT_REQUEST };
  }

  function success(response) {
    return {
      type: reportsConstants?.GET_DOC_STATUS_LIST_REPORT_SUCCESS,
      data: response?.data ?? {},
    };
  }

  function failure(error) {
    return { type: reportsConstants?.GET_DOC_STATUS_LIST_REPORT_ERROR, error };
  }
}

function getEmployeesBirthday(data) {
  return (dispatch) => {
    dispatch(request());
    reportsService.getEmployeesBirthday(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: reportsConstants?.EMPLOYEE_BIRTHDAY_REQUEST };
  }
  function success(response) {
    return {
      type: reportsConstants?.EMPLOYEE_BIRTHDAY_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return { type: reportsConstants?.EMPLOYEE_BIRTHDAY_ERROR, error };
  }
}

function getMileageHobbsReport(data) {
  return (dispatch) => {
    dispatch(request());
    reportsService.getMileageHobbsReport(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: reportsConstants?.MILEAGE_HOBBS_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: reportsConstants?.MILEAGE_HOBBS_REPORT_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return { type: reportsConstants?.MILEAGE_HOBBS_REPORT_ERROR, error };
  }
}

function fetchApplicantsReport(data) {
  return (dispatch) => {
    dispatch(request());
    reportsService.fetchApplicantsReport(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: reportsConstants?.APPLICANTS_REPORT_REQUEST };
  }
  function success(response) {
    return {
      type: reportsConstants?.APPLICANTS_REPORT_SUCCESS,
      data: response?.data ?? {},
    };
  }
  function failure(error) {
    return { type: reportsConstants?.APPLICANTS_REPORT_ERROR, error };
  }
}
