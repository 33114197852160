import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { reportsActions, resourceActions } from "_actions";
import { reportsService } from "../_services";
import DocStatusReportList from "./DocStatusListReport";
import { selectDocStatusList } from "_helpers/reduxSelector";
import "../assets/styles/report.css";

const MENU_WIDTH = 300;

const customStyles = {
  content: {
    width: "420px",
  },
};

const selectStyles = {
  control: (css) => ({
    ...css,
    width: MENU_WIDTH || "auto",
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 7,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 9999,
  }),
  option: (css) => ({ ...css, paddingRight: 36 + 8, zIndex: 9999 }),
};

const DocStatusListReport = (props) => {
  const dispatch = useDispatch();
  const resetRef = useRef(null);
  const [selectedResource, setSelectedResource] = useState();
  const [isAskFillPending, setIsAskFillPending] = useState(false);
  const [selectedUsersHash, setSelectedUsersHash] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keySearch, setKeySearch] = useState("");
  const [globalPage, setGlobalPage] = useState(0);
  const [isAllPagesSelected, setIsAllPagesSelected] = useState(false);
  const [isChecked, setIsChecked] = useState("");
  const [resetOption, setResetOption] = useState(1);

  const [sortAndOrder, setSortAndOrder] = useState({
    order: "asc",
    sortBy: "first_name",
  });

  const refresherReports = useSelector(selectDocStatusList);
  let selectedEmployee = useRef();

  const getRefresherReportWithFilters = (newPage, sort, order) => {
    handleSortAndOrderForPrint(sort, order);
    if (keySearch.length || selectedResource?.value) {
      setGlobalPage(0);
      newPage = 0;
    }
    let data = new FormData();
    data.append("page", newPage || globalPage || 0);
    data.append("resource_id", selectedResource?.value || 0);
    data.append("sort", sort || "first_name");
    data.append("order", order || "asc");
    data.append("keyword", keySearch || "");
    dispatch(reportsActions.getDocStatusList(data));
  };

  const onPrintClick = () => {
    let data = new FormData();
    data.append("resource_id", selectedResource?.value || 0);
    data.append("sort", sortAndOrder?.sortBy);
    data.append("order", sortAndOrder?.order);
    data.append("keyword", keySearch || "");
    data.append("report_type", "pdf");
    reportsService
      .generateDocStatusListPdf(data)
      .then((response) => redirectToPdf(response));
  };

  const onExportClick = () => {
    let data = new FormData();
    data.append("resource_id", selectedResource?.value || 0);
    data.append("sort", sortAndOrder?.sortBy);
    data.append("order", sortAndOrder?.order);
    data.append("keyword", keySearch || "");
    data.append("report_type", "csv");
    reportsService.generateDocStatusListCsv(data);
  };

  const redirectToPdf = (data) => {
    if (data?.success) {
      const pdfLink = data.data;
      var link = document.createElement("a");
      if (
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i)
      ) {
        link.href = pdfLink;
      } else {
        link.href = pdfLink;
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(pdfLink);
      }, 100);
    }
  };

  const handleSearch = async (text) => {
    let data = new FormData();
    data.append("keyword", text);
    const resources = dispatch(resourceActions.searchResource(data));
    let resourceList = [];
    const response = await resources;
    const items = response.data;
    var res = [];

    for (var key in items) {
      var arr = items[key];
      if (arr.length) {
        res = res.concat(arr);
      }
    }
    if (res.length > 0) {
      res.forEach((item) => {
        let resource = {
          label: item.resource_name,
          value: item.id,
        };
        resourceList.push(resource);
      });
    }
    return resourceList;
  };

  const loadResources = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(handleSearch(inputValue));
      }, 1000);
    });

  const handleSortAndOrderForPrint = (sortBy, order) => {
    let _sortAndOrder = { ...sortAndOrder };
    _sortAndOrder.sortBy = sortBy || _sortAndOrder.sortBy;
    _sortAndOrder.order = order || _sortAndOrder.order;
    setSortAndOrder(_sortAndOrder);
  };

  const checkboxStatus = (status = false) => {
    setIsChecked(status ? "checked" : "");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsAllPagesSelected(false);
    setSelectedUsersHash([]);
    resetRef.current.handleClearSelection();
  };

  const handleAskToFillDocument = async () => {
    setIsAskFillPending(true);
    let data = new FormData();
    selectedUsersHash?.forEach((hash, index) => {
      data.append(`hash[${index}]`, hash);
    });
    data.append("all_checked", isAllPagesSelected);
    data.append("reset_type", resetOption);
    if (keySearch.length) data.append("keyword", keySearch || "");

    reportsService
      .askToFillDocumentsByUsersHash(data)
      .then((response) => {
        toast.success(response.message);
        setIsAskFillPending(false);
        setIsModalOpen(false);
        getRefresherReportWithFilters();
        setSelectedUsersHash([]);
      })
      .catch((err) => {
        toast.error(err.message);
        setIsAskFillPending(false);
      });
    handleCancel();
  };

  const handleResetOptionSelect = (e) => {
    setResetOption(e.target.value);
    console.log(e.target.value);
  };

  useEffect(() => {
    if (keySearch.length) {
      setGlobalPage(0);
    }
    const timer = setTimeout(() => {
      getRefresherReportWithFilters();
    }, 1000);
    return () => clearTimeout(timer);
  }, [keySearch]);

  useEffect(() => {
    if (selectedUsersHash.length === 1) {
      const employeeHash = selectedUsersHash.at(0);
      selectedEmployee.current = refresherReports.data.find(
        (item) => item.hash === employeeHash
      );
      return;
    }
  }, [selectedUsersHash.length]);

  return (
    <div>
      <div className={props.isReportMenu ? "" : "dashboard_container"}>
        <div className="white report_table_container">
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head sold_rpt_mbl_flx">
                <h5 className="graph_header">Doc Status List</h5>

                <div
                  style={{ gap: `${props.isReportMenu ? "10px" : "0px"}` }}
                  className="report_head_btn_grp exp_multi_head_flx"
                >
                  <div className="report_multi_btn_grp exp_multi_btn_flx">
                    <div
                      style={{ minWidth: 300 }}
                      className="inp_sub_container"
                    >
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadResources}
                        onChange={(selection) => setSelectedResource(selection)}
                        // isMulti

                        defaultOptions
                        isClearable
                        styles={selectStyles}
                        placeholder={"Choose Resource Filter"}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>

                    <div
                      style={{ minWidth: 300 }}
                      className="inp_sub_container"
                    >
                      <input
                        type="text"
                        value={keySearch}
                        name="keyword"
                        placeholder="Search..."
                        onChange={(e) => setKeySearch(e.target.value)}
                        autoComplete="off"
                      />
                    </div>

                    {refresherReports?.data?.length && props.isReportMenu ? (
                      <div className="print-export-btn">
                        {selectedUsersHash?.length || isAllPagesSelected ? (
                          <button
                            className="print_button button"
                            onClick={(event) => setIsModalOpen(true)}
                            title="Ask to fill documents"
                            style={{ minWidth: "232px" }}
                          >
                            Ask to fill documents
                          </button>
                        ) : null}
                        <button
                          className="print_button"
                          onClick={(event) => {
                            onExportClick();
                          }}
                        >
                          Export
                        </button>
                        <button
                          className="print_button"
                          onClick={(event) => {
                            onPrintClick();
                          }}
                        >
                          Print
                        </button>
                      </div>
                    ) : (
                      <div className="dashboard-expiration-date-print-btn-mock" />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <DocStatusReportList
                selectedUsersHash={selectedUsersHash}
                setSelectedUsersHash={setSelectedUsersHash}
                refresherReports={refresherReports}
                getRefresherReport={getRefresherReportWithFilters}
                selectedResource={selectedResource}
                setIsAllPagesSelected={setIsAllPagesSelected}
                ref={resetRef}
                checkboxStatus={checkboxStatus}
                setGlobalPage={setGlobalPage}
              />
            </div>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={isModalOpen}
        contentLabel="Unloack Employees"
        style={customStyles}
        overlayClassName="RemoveApplntPopup emp_status_modal"
      >
        <div className="modal-head">
          <h4>Ask To Fill Documents</h4>

          <img
            alt="close"
            className="closeModal"
            onClick={() => (isAskFillPending ? {} : setIsModalOpen(false))}
            src={require("../assets/images/close_green.png").default}
          />
        </div>

        {/* <div className="modal-body emp_status">
          <p>
            Are you sure you want to ask to fill documents for the selected
            employees ?
          </p>
          <div className="confirm-btn">
            <button
              className="print_button button btn_close"
              onClick={handleCancel}
            >
              Cancel
            </button>
            {
              <button
                className="print_button button"
                onClick={handleAskToFillDocument}
              >
                Confirm
              </button>
            }
          </div>
        </div> */}

        <div className="modal-body">
          {isAllPagesSelected ? (
            <>
              <p style={{ color: "green" }}>
                Number of employees: {refresherReports.total}
              </p>
              <p style={{ color: "green" }}>
                Number of unselected employees: {selectedUsersHash.length}
              </p>
            </>
          ) : (
            <p style={{ color: "green" }}>
              Number of selected Employees:{" "}
              {selectedUsersHash.length || refresherReports.total}
            </p>
          )}

          <p>
            Are you sure you want to ask to fill documents for the selected
            employees?
          </p>

          <div className="radio-fields-modal">
            {(selectedUsersHash.length > 1 ||
              selectedEmployee?.current?.docs_status !== -1 ||
              isAllPagesSelected) && (
              <div className="radio-btn-modal">
                <input
                  type="radio"
                  name="reset-doc"
                  value={1}
                  id="reset-onboarding"
                  onChange={handleResetOptionSelect}
                  checked={resetOption == 1}
                />
                <label htmlFor="reset-onboarding">
                  Reset Onboarding documents
                </label>
              </div>
            )}
            {(selectedUsersHash.length > 1 ||
              selectedEmployee?.current?.rehire_docs_status !== -1 ||
              isAllPagesSelected) && (
              <div className="radio-btn-modal">
                <input
                  type="radio"
                  name="reset-doc"
                  value={2}
                  id="reset-rehire"
                  onChange={handleResetOptionSelect}
                  checked={resetOption == 2}
                />
                <label htmlFor="reset-rehire">Reset Rehire documents</label>
              </div>
            )}
            {(selectedUsersHash.length > 1 ||
              isAllPagesSelected ||
              (selectedEmployee?.current?.docs_status !== -1 &&
                selectedEmployee?.current?.rehire_docs_status !== -1)) && (
              <div className="radio-btn-modal">
                <input
                  type="radio"
                  name="reset-doc"
                  value={3}
                  id="reset-both"
                  onChange={handleResetOptionSelect}
                  checked={resetOption == 3}
                />
                <label htmlFor="reset-both">Reset both</label>
              </div>
            )}
            {(selectedUsersHash.length > 1 || isAllPagesSelected) && (
              <div className="radio-btn-modal">
                <input
                  type="radio"
                  name="reset-doc"
                  value={4}
                  id="last-submission"
                  onChange={handleResetOptionSelect}
                  checked={resetOption == 4}
                />
                <label htmlFor="last-submission">
                  Reset docs based on last submission (Onboarding/Rehire)
                </label>
              </div>
            )}
          </div>
          <div className="confirm-btn">
            <button
              className="print_button button btn_close"
              onClick={handleCancel}
            >
              Cancel
            </button>
            {
              <button
                className="print_button button"
                onClick={handleAskToFillDocument}
              >
                Confirm
              </button>
            }
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default DocStatusListReport;
