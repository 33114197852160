import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";
import cx from "classnames";
import moment from "moment";
import DatePicker from "react-datepicker";
import CustomToolTip from "_components/CustomToolTip";
import { applicantsActions, interviewActions } from "_actions";
import { validateForm } from "./formHandler.js";
import { getInterviewForm, isInterviewFomLoading } from "_helpers/reduxSelector";
import styles from "./interview.module.scss";

const DATE_FORMAT = "MM/DD/YYYY";
const INP_DATE_FORMAT = "MM/DD/YYYY";
const TIME_FORMAT = "h:mm A";

const initialState = (applicant, user) => ({
  full_name: `${applicant?.first_name} ${applicant?.middle_initial} ${applicant?.last_name}`,
  interview_date: moment(),
  scheduled_interview_time: moment()?.startOf("hour"),
  candidate_arrival_time: moment()?.startOf("hour"),
  //overall_rating: "",
  interview_recap: "",
  why_this_company: "",
  why_we_hire: "",
  interested_in_firefighter: "",
  personal_quality: "",
  expectation: "",
  skills_required: "",
  team_experience: "",
  worked_successfully: "",
  deal_multiple_change: "",
  left_without_notice: "",
  mistake_and_fix: "",
  handle_situations: "",
  respond_situation: "",
  biggest_prof_challenge: "",
  handle_conflict: "",
  respond_to_alcohol: "",
  inspire_others: "",
  where_five_years: "",
  your_goals: "",
  notes: "",
  tree_trimmer_feller_experience: "",
  equipments_used: "",
  knot_types: "",
  notches_types: "",
  rig_limb_removal_desc: "",
  tree_trimming_memory: "",
  tree_trimming_hazards: "",
  tree_trimming_types: "",
  accident_prevention_techniques: "",
  ppe_types: "",
  rope_climbing_types: "",
  climbing_spurs_time: "",
  reference_check_name_1: "",
  reference_check_number_1: "",
  reference_one_question_1: "",
  reference_one_question_2: "",
  reference_one_question_3: "",
  reference_one_question_4: "",
  reference_check_name_2: "",
  reference_check_number_2: "",
  reference_two_question_1: "",
  reference_two_question_2: "",
  reference_two_question_3: "",
  reference_two_question_4: "",
  facebook_link: "",
  instagram_link: "",
  social_media_notes: "",
  other: "",
  interviewer: user?.id,
  interviewer_date: moment(),
});

export const InterviewForm = ({ hash, applicant, setCurrentView, user, editMode, formId, getInterviewFormList }) => {
  const dispatch = useDispatch();
  const [
    {
      full_name,
      interview_date,
      scheduled_interview_time,
      candidate_arrival_time,
      //overall_rating,
      interview_recap,
      why_this_company,
      why_we_hire,
      interested_in_firefighter,
      personal_quality,
      expectation,
      skills_required,
      team_experience,
      worked_successfully,
      deal_multiple_change,
      left_without_notice,
      mistake_and_fix,
      handle_situations,
      respond_situation,
      biggest_prof_challenge,
      handle_conflict,
      respond_to_alcohol,
      inspire_others,
      where_five_years,
      your_goals,
      notes,
      tree_trimmer_feller_experience,
      equipments_used,
      knot_types,
      notches_types,
      rig_limb_removal_desc,
      tree_trimming_memory,
      tree_trimming_hazards,
      tree_trimming_types,
      accident_prevention_techniques,
      ppe_types,
      rope_climbing_types,
      climbing_spurs_time,
      reference_check_name_1,
      reference_check_number_1,
      reference_one_question_1,
      reference_one_question_2,
      reference_one_question_3,
      reference_one_question_4,
      reference_check_name_2,
      reference_check_number_2,
      reference_two_question_1,
      reference_two_question_2,
      reference_two_question_3,
      reference_two_question_4,
      facebook_link,
      instagram_link,
      social_media_notes,
      other,
      interviewer,
      interviewer_date,
    },
    setFormState
  ] = useState(initialState(applicant, user));

  const interviewForm = useSelector((state) => getInterviewForm(state));

  const loading = useSelector((state) => isInterviewFomLoading(state));

  useEffect(() => {
    const formData = interviewForm?.form;
    if (editMode && formData) {
      Object.keys(formData).forEach((key) => {
        const value = formData[key];
        if (key.includes("_date") && value) {
          let dt = moment(new Date(value)).format(INP_DATE_FORMAT);
          setFormState((prevState) => ({ ...prevState, [key]: moment(dt) }));
        } else if (key.includes("_time") && value) {
          let tm = moment(value, TIME_FORMAT).toDate();
          setFormState((prevState) => ({ ...prevState, [key]: moment(tm) }));
        } else if (value || value === 0) {
          setFormState((prevState) => ({ ...prevState, [key]: String(value) }));
        }
      });
    }
  }, [interviewForm]);

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const {
      target: { name, value }
    } = event;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDateChange = (name, value) => {
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const deleteIntForm = () => {
    const data = new FormData();
    data.append("interview_form_id", formId);
    dispatch(interviewActions.deleteInterviewForm(data)).then((res) => {
      if (res.success) {
        getInterviewFormList();
        setCurrentView("listView");
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    /**Reset error object */
    const submitter = event?.nativeEvent?.submitter?.name;
    const submittedForm = event.target;
    if (submitter !== "draft") {
      let err = validateForm(submittedForm, {});
      if (err && !_.isEmpty(err)) {
        setErrors(err);
        return;
      } else {
        setErrors({});
      }
    }
    else {
      let err = validateForm(submittedForm, {});
      const mandatoryDraftFields = ["interview_date", "scheduled_interview_time", "candidate_arrival_time"]
      let mandatoryDraftFieldErrors = {}
      mandatoryDraftFields?.forEach(key => {
        if (err?.[key]) {
          mandatoryDraftFieldErrors[key] = err[key]
        }
      })
      if (mandatoryDraftFieldErrors && !_.isEmpty(mandatoryDraftFieldErrors)) {
        setErrors(mandatoryDraftFieldErrors);
        return;
      } else {
        setErrors({});
      }
    }

    const form = {
      interviewer: interviewer
    };

    Object.keys(submittedForm).forEach((index) => {
      let el = submittedForm[index];
      if (el.name) {
        if (el.type === "radio" && el.checked) {
          form[el.name] = el.value;
        } else if (el.type === "date" && el.value) {
          form[el.name] = moment(el.value).format(DATE_FORMAT);
        } else if (el.type !== "radio") {
          form[el.name] = el.value;
        }
      }
    });

    const data = {
      applicant_id: hash,
      status: submitter === "draft" ? 2 : 1,
      interviewer: interviewer,
      date_of_interview: moment(interview_date)?.isValid() ? moment(interview_date).format(DATE_FORMAT) : null,
      date_of_submission: moment().format(DATE_FORMAT),
      form,
      form_id: editMode ? formId : ""
    };

    dispatch(interviewActions.saveInterviewForm(data)).then((res) => {
      if (res.success) {
        setCurrentView(submitter === "draft" ? "draftComplete" : "submitComplete");
        if (submitter !== "draft" && hash) {
          dispatch(applicantsActions?.viewDetails(hash))
        }
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <>
      {((editMode && !_.isEmpty(interviewForm)) || !editMode) && (
        <div className={styles.interview_form_wrapper}>
          <form onSubmit={handleSubmit} noValidate>
            <div className={styles.interview_form_body}>
              <h3>{editMode ? "Edit Interview Form" : "New Interview Form"}</h3>
              <div className={styles.form__wrapper}>
                <div className={styles.form__wrapper}>
                  <div className={styles.form_group_row}>
                    <div className={styles.form_group}>
                      <label htmlFor="full_name">Name of Candidate</label>
                      <input
                        type="text"
                        required
                        id="full_name"
                        name="full_name"
                        value={full_name}
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                    <div className={cx(styles.form_group, "full_width_date")}>
                      <label htmlFor="interview_date">Date of Interview</label>
                      <DatePicker
                        type="date"
                        className={`${errors["interview_date"] ? styles.error : ""}`}
                        id="interview_date"
                        name="interview_date"
                        selected={moment(interview_date)?.isValid() ? moment(interview_date) : null}
                        onChange={(date) => handleDateChange("interview_date", date)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>

                  <div className={styles.form_group_row}>
                    <div className={cx(styles.form_group, "full_width_date")}>
                      <label htmlFor="scheduled_interview_time">Scheduled Interview Time</label>
                      <DatePicker
                        id="scheduled_interview_time"
                        className={`${errors["scheduled_interview_time"] ? styles.error : ""}`}
                        name="scheduled_interview_time"
                        selected={moment(scheduled_interview_time)?.isValid() ? scheduled_interview_time : null}
                        onChange={(time) => handleDateChange("scheduled_interview_time", time)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Select Time"
                        dateFormat="h:mm A"
                        required
                      />
                    </div>
                    <div className={cx(styles.form_group, "full_width_date")}>
                      <label htmlFor="candidate_arrival_time">Candidate Arrival Time</label>
                      <DatePicker
                        id="candidate_arrival_time"
                        className={`${errors["candidate_arrival_time"] ? styles.error : ""}`}
                        name="candidate_arrival_time"
                        selected={moment(candidate_arrival_time)?.isValid() ? moment(candidate_arrival_time) : null}
                        onChange={(time) => handleDateChange("candidate_arrival_time", time)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Select Time"
                        dateFormat="h:mm A"
                        required
                      />
                    </div>
                  </div>

                  {/* <div className={`${styles.form_group} ${styles.overall_rating}`}>
                <label>Overall Rating</label>
                <div
                  className={`${
                    errors["overall_rating"]
                      ? cx(styles.error, styles.overall_rating_wrapper)
                      : styles.overall_rating_wrapper
                  }`}
                >
                  <p>Bad</p>
                  <div className={styles.radio_btn_wrapper}>
                    <div className={`${styles.radio_group}`}>
                      <label className={styles.container_radio}>
                        1
                        <input
                          type="radio"
                          required
                          id="one"
                          name="overall_rating"
                          value="1"
                          checked={overall_rating === "1"}
                          onChange={handleChange}
                        />
                        <span className={styles.radiobtn}></span>
                      </label>
                    </div>
                    <div className={`${styles.radio_group}`}>
                      <label className={styles.container_radio}>
                        2
                        <input
                          type="radio"
                          required
                          name="overall_rating"
                          id="two"
                          value="2"
                          checked={overall_rating === "2"}
                          onChange={handleChange}
                        />
                        <span className={styles.radiobtn}></span>
                      </label>
                    </div>
                    <div className={`${styles.radio_group}`}>
                      <label className={styles.container_radio}>
                        3
                        <input
                          type="radio"
                          required
                          name="overall_rating"
                          id="three"
                          value="3"
                          checked={overall_rating === "3"}
                          onChange={handleChange}
                        />
                        <span className={styles.radiobtn}></span>
                      </label>
                    </div>
                    <div className={`${styles.radio_group}`}>
                      <label className={styles.container_radio}>
                        4
                        <input
                          type="radio"
                          required
                          name="overall_rating"
                          id="four"
                          value="4"
                          checked={overall_rating === "4"}
                          onChange={handleChange}
                        />
                        <span className={styles.radiobtn}></span>
                      </label>
                    </div>
                    <div className={`${styles.radio_group}`}>
                      <label className={styles.container_radio}>
                        5
                        <input
                          type="radio"
                          required
                          name="overall_rating"
                          id="five"
                          value="5"
                          checked={overall_rating === "5"}
                          onChange={handleChange}
                        />
                        <span className={styles.radiobtn}></span>
                      </label>
                    </div>
                  </div>
                  <p>(Good)</p>
                </div>
              </div> */}

                  <div className={`${styles.form_group}`}>
                    <label htmlFor="interview_recap">Interview Recap</label>
                    <textarea
                      name="interview_recap"
                      id="interview_recap"
                      className={`${errors["interview_recap"] ? styles.error : ""}`}
                      rows="4"
                      value={interview_recap}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>

                  <div className={`${styles.form_group}`}>
                    <div className={`${styles.table_wrapper}`}>
                      <table>
                        <thead>
                          <th colSpan="7">Questions</th>
                          <th>
                            1 <span>(Not Acceptable / No experience)</span>
                          </th>
                          <th>2</th>
                          <th>3</th>
                          <th>4</th>
                          <th>
                            5 <span>(Exceptional)</span>
                          </th>
                        </thead>
                        <tbody>
                          <tr>
                            <td colSpan="7">Why do you want to work for this company?</td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="why_this_company"
                                    value={1}
                                    onChange={handleChange}
                                    checked={why_this_company === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_this_company"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="why_this_company"
                                    value={2}
                                    onChange={handleChange}
                                    checked={why_this_company === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_this_company"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="why_this_company"
                                    value={3}
                                    onChange={handleChange}
                                    checked={why_this_company === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_this_company"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="why_this_company"
                                    value={4}
                                    onChange={handleChange}
                                    checked={why_this_company === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_this_company"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="why_this_company"
                                    value={5}
                                    onChange={handleChange}
                                    checked={why_this_company === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_this_company"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">Why should we hire you?</td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    name="why_we_hire"
                                    value={1}
                                    onChange={handleChange}
                                    checked={why_we_hire === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_we_hire"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    name="why_we_hire"
                                    value={2}
                                    onChange={handleChange}
                                    checked={why_we_hire === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_we_hire"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    name="why_we_hire"
                                    value={3}
                                    onChange={handleChange}
                                    checked={why_we_hire === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_we_hire"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    name="why_we_hire"
                                    value={4}
                                    onChange={handleChange}
                                    checked={why_we_hire === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_we_hire"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="why_we_hire"
                                    value={5}
                                    onChange={handleChange}
                                    checked={why_we_hire === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_we_hire"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">What made you interested in the tree service industry?</td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="interested_in_firefighter"
                                    value={1}
                                    onChange={handleChange}
                                    checked={interested_in_firefighter === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["interested_in_firefighter"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="interested_in_firefighter"
                                    value={2}
                                    onChange={handleChange}
                                    checked={interested_in_firefighter === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["interested_in_firefighter"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="interested_in_firefighter"
                                    value={3}
                                    onChange={handleChange}
                                    checked={interested_in_firefighter === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["interested_in_firefighter"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="interested_in_firefighter"
                                    value={4}
                                    onChange={handleChange}
                                    checked={interested_in_firefighter === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["interested_in_firefighter"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="interested_in_firefighter"
                                    value={5}
                                    onChange={handleChange}
                                    checked={interested_in_firefighter === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["interested_in_firefighter"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              {" "}
                              <strong>What personal qualities or characteristics do you value most?</strong>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="personal_quality"
                                    value={1}
                                    onChange={handleChange}
                                    checked={personal_quality === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["personal_quality"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="personal_quality"
                                    value={2}
                                    onChange={handleChange}
                                    checked={personal_quality === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["personal_quality"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="personal_quality"
                                    value={3}
                                    onChange={handleChange}
                                    checked={personal_quality === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["personal_quality"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="personal_quality"
                                    value={4}
                                    onChange={handleChange}
                                    checked={personal_quality === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["personal_quality"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="personal_quality"
                                    value={5}
                                    onChange={handleChange}
                                    checked={personal_quality === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["personal_quality"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">What do you expect from this job?</td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="expectation"
                                    value={1}
                                    onChange={handleChange}
                                    checked={expectation === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["expectation"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="expectation"
                                    value={2}
                                    onChange={handleChange}
                                    checked={expectation === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["expectation"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="expectation"
                                    value={3}
                                    onChange={handleChange}
                                    checked={expectation === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["expectation"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="expectation"
                                    value={4}
                                    onChange={handleChange}
                                    checked={expectation === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["expectation"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="expectation"
                                    value={5}
                                    onChange={handleChange}
                                    checked={expectation === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["expectation"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7"> What skills do you think are required to perform this job effectively?</td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="skills_required"
                                    value={1}
                                    onChange={handleChange}
                                    checked={skills_required === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["skills_required"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="skills_required"
                                    value={2}
                                    onChange={handleChange}
                                    checked={skills_required === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["skills_required"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="skills_required"
                                    value={3}
                                    onChange={handleChange}
                                    checked={skills_required === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["skills_required"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="skills_required"
                                    value={4}
                                    onChange={handleChange}
                                    checked={skills_required === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["skills_required"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="skills_required"
                                    value={5}
                                    onChange={handleChange}
                                    checked={skills_required === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["skills_required"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">Describe a team experience that you found rewarding.</td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="team_experience"
                                    value={1}
                                    onChange={handleChange}
                                    checked={team_experience === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["team_experience"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="team_experience"
                                    value={2}
                                    onChange={handleChange}
                                    checked={team_experience === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["team_experience"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="team_experience"
                                    value={3}
                                    onChange={handleChange}
                                    checked={team_experience === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["team_experience"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="team_experience"
                                    value={4}
                                    onChange={handleChange}
                                    checked={team_experience === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["team_experience"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="team_experience"
                                    value={5}
                                    onChange={handleChange}
                                    checked={team_experience === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["team_experience"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              Can you tell me about a time when you worked successfully as a part of a team?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="worked_successfully"
                                    value={1}
                                    onChange={handleChange}
                                    checked={worked_successfully === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["worked_successfully"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="worked_successfully"
                                    value={2}
                                    onChange={handleChange}
                                    checked={worked_successfully === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["worked_successfully"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="worked_successfully"
                                    value={3}
                                    onChange={handleChange}
                                    checked={worked_successfully === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["worked_successfully"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="worked_successfully"
                                    value={4}
                                    onChange={handleChange}
                                    checked={worked_successfully === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["worked_successfully"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="worked_successfully"
                                    value={5}
                                    onChange={handleChange}
                                    checked={worked_successfully === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["worked_successfully"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>


                          <tr>
                            <td colSpan="7">
                              How do you deal with multiple changes while at work? Would you consider yourself a
                              flexible person?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="deal_multiple_change"
                                    value={1}
                                    onChange={handleChange}
                                    checked={deal_multiple_change === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["deal_multiple_change"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="deal_multiple_change"
                                    value={2}
                                    onChange={handleChange}
                                    checked={deal_multiple_change === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["deal_multiple_change"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="deal_multiple_change"
                                    value={3}
                                    onChange={handleChange}
                                    checked={deal_multiple_change === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["deal_multiple_change"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="deal_multiple_change"
                                    value={4}
                                    onChange={handleChange}
                                    checked={deal_multiple_change === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["deal_multiple_change"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="deal_multiple_change"
                                    value={5}
                                    onChange={handleChange}
                                    checked={deal_multiple_change === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["deal_multiple_change"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              <strong>
                                Have you ever left a job without giving two weeks notice, if so why?
                              </strong>{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="left_without_notice"
                                    value={1}
                                    onChange={handleChange}
                                    checked={left_without_notice === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["left_without_notice"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="left_without_notice"
                                    value={2}
                                    onChange={handleChange}
                                    checked={left_without_notice === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["left_without_notice"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="left_without_notice"
                                    value={3}
                                    onChange={handleChange}
                                    checked={left_without_notice === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["left_without_notice"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="left_without_notice"
                                    value={4}
                                    onChange={handleChange}
                                    checked={left_without_notice === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["left_without_notice"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="left_without_notice"
                                    value={5}
                                    onChange={handleChange}
                                    checked={left_without_notice === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["left_without_notice"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              Tell me about a time where you made a mistake and how did you fix it? What have you learned from
                              making mistakes in the workplace?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="mistake_and_fix"
                                    value={1}
                                    onChange={handleChange}
                                    checked={mistake_and_fix === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["mistake_and_fix"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="mistake_and_fix"
                                    value={2}
                                    onChange={handleChange}
                                    checked={mistake_and_fix === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["mistake_and_fix"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="mistake_and_fix"
                                    value={3}
                                    onChange={handleChange}
                                    checked={mistake_and_fix === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["mistake_and_fix"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="mistake_and_fix"
                                    value={4}
                                    onChange={handleChange}
                                    checked={mistake_and_fix === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["mistake_and_fix"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="mistake_and_fix"
                                    value={5}
                                    onChange={handleChange}
                                    checked={mistake_and_fix === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["mistake_and_fix"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              We spend a lot of time in close quarters for many days under stressful and difficult
                              situations. How will you handle a crew member that has a bad attitude and is bringing the
                              moral of the crew down?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_situations"
                                    value={1}
                                    onChange={handleChange}
                                    checked={handle_situations === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_situations"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_situations"
                                    value={2}
                                    onChange={handleChange}
                                    checked={handle_situations === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_situations"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_situations"
                                    value={3}
                                    onChange={handleChange}
                                    checked={handle_situations === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_situations"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_situations"
                                    value={4}
                                    onChange={handleChange}
                                    checked={handle_situations === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_situations"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_situations"
                                    value={5}
                                    onChange={handleChange}
                                    checked={handle_situations === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_situations"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              While on the job, if someone in the vehicle turns around rudely and disrespects you, how do you respond to that situation?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_situation"
                                    value={1}
                                    onChange={handleChange}
                                    checked={respond_situation === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_situation"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_situation"
                                    value={2}
                                    onChange={handleChange}
                                    checked={respond_situation === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_situation"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_situation"
                                    value={3}
                                    onChange={handleChange}
                                    checked={respond_situation === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_situation"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_situation"
                                    value={4}
                                    onChange={handleChange}
                                    checked={respond_situation === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_situation"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_situation"
                                    value={5}
                                    onChange={handleChange}
                                    checked={respond_situation === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_situation"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              What has been the biggest professional challenge you've faced, and how did you handle it?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="biggest_prof_challenge"
                                    value={1}
                                    onChange={handleChange}
                                    checked={biggest_prof_challenge === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["biggest_prof_challenge"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="biggest_prof_challenge"
                                    value={2}
                                    onChange={handleChange}
                                    checked={biggest_prof_challenge === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["biggest_prof_challenge"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="biggest_prof_challenge"
                                    value={3}
                                    onChange={handleChange}
                                    checked={biggest_prof_challenge === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["biggest_prof_challenge"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="biggest_prof_challenge"
                                    value={4}
                                    onChange={handleChange}
                                    checked={biggest_prof_challenge === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["biggest_prof_challenge"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="biggest_prof_challenge"
                                    value={5}
                                    onChange={handleChange}
                                    checked={biggest_prof_challenge === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["biggest_prof_challenge"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              Have you resolved a conflict with a colleague or superior? How did you handle it?
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_conflict"
                                    value={1}
                                    onChange={handleChange}
                                    checked={handle_conflict === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_conflict"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_conflict"
                                    value={2}
                                    onChange={handleChange}
                                    checked={handle_conflict === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_conflict"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_conflict"
                                    value={3}
                                    onChange={handleChange}
                                    checked={handle_conflict === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_conflict"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_conflict"
                                    value={4}
                                    onChange={handleChange}
                                    checked={handle_conflict === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_conflict"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_conflict"
                                    value={5}
                                    onChange={handleChange}
                                    checked={handle_conflict === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_conflict"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              <strong>
                                If you see a coworker with a bag of weed or a bottle of alcohol while on the job, what would you do?
                              </strong>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_to_alcohol"
                                    value={1}
                                    onChange={handleChange}
                                    checked={respond_to_alcohol === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_to_alcohol"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_to_alcohol"
                                    value={2}
                                    onChange={handleChange}
                                    checked={respond_to_alcohol === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_to_alcohol"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_to_alcohol"
                                    value={3}
                                    onChange={handleChange}
                                    checked={respond_to_alcohol === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_to_alcohol"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_to_alcohol"
                                    value={4}
                                    onChange={handleChange}
                                    checked={respond_to_alcohol === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_to_alcohol"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_to_alcohol"
                                    value={5}
                                    onChange={handleChange}
                                    checked={respond_to_alcohol === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_to_alcohol"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              When have you had to take the lead and inspire others? How did you do this?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="inspire_others"
                                    value={1}
                                    onChange={handleChange}
                                    checked={inspire_others === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["inspire_others"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="inspire_others"
                                    value={2}
                                    onChange={handleChange}
                                    checked={inspire_others === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["inspire_others"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="inspire_others"
                                    value={3}
                                    onChange={handleChange}
                                    checked={inspire_others === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["inspire_others"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="inspire_others"
                                    value={4}
                                    onChange={handleChange}
                                    checked={inspire_others === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["inspire_others"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="inspire_others"
                                    value={5}
                                    onChange={handleChange}
                                    checked={inspire_others === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["inspire_others"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              <strong>Where do you want to be in five years? </strong>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="where_five_years"
                                    value={1}
                                    onChange={handleChange}
                                    checked={where_five_years === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["where_five_years"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="where_five_years"
                                    value={2}
                                    onChange={handleChange}
                                    checked={where_five_years === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["where_five_years"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="where_five_years"
                                    value={3}
                                    onChange={handleChange}
                                    checked={where_five_years === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["where_five_years"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="where_five_years"
                                    value={4}
                                    onChange={handleChange}
                                    checked={where_five_years === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["where_five_years"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="where_five_years"
                                    value={5}
                                    onChange={handleChange}
                                    checked={where_five_years === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["where_five_years"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">Tell me about some of your goals? </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="your_goals"
                                    value={1}
                                    onChange={handleChange}
                                    checked={your_goals === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["your_goals"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="your_goals"
                                    value={2}
                                    onChange={handleChange}
                                    checked={your_goals === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["your_goals"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="your_goals"
                                    value={3}
                                    onChange={handleChange}
                                    checked={your_goals === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["your_goals"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="your_goals"
                                    value={4}
                                    onChange={handleChange}
                                    checked={your_goals === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["your_goals"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="your_goals"
                                    value={5}
                                    onChange={handleChange}
                                    checked={your_goals === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["your_goals"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className={`${styles.form_group} ${styles.tree_trimmer_feller_experience}`}>
                    <CustomToolTip
                        label={
                         "Possible Answers: Felling / removals of trees, different sizes, Trimming, reducing, shaping, pollarding"
                      }
                      position="bottom"
                      >
                        <label htmlFor="tree_trimmer_feller_experience">What type of experience do you have as a tree trimmer/ feller?</label>
                    </CustomToolTip>
                    <textarea
                      name="tree_trimmer_feller_experience"
                      id="tree_trimmer_feller_experience"
                      rows="4"
                      value={tree_trimmer_feller_experience}
                      onChange={handleChange}
                      required
                      className={`${errors["tree_trimmer_feller_experience"] ? styles.error : ""}`}
                    ></textarea>
                  </div>

                  <div className={`${styles.form_group} ${styles.equipments_used}`}>
                    <CustomToolTip
                        label={
                          "Possible Answers: • Chain saws • Ropes • Climbing gear • Hand tools • Dump trucks/ chip trucks • Chippers • Aerial lifts/ bucket trucks • Stump grinders • Loaders /skid steers • Cranes"
                      }
                      position="bottom"
                      >
                        <label htmlFor="equipments_used">What type of equipments have you used?</label>
                    </CustomToolTip>
                    <textarea
                      name="equipments_used"
                      id="equipments_used"
                      rows="4"
                      value={equipments_used}
                      onChange={handleChange}
                      required
                      className={`${errors["equipments_used"] ? styles.error : ""}`}
                    ></textarea>
                  </div>

                  <div className={`${styles.form_group} ${styles.knot_types}`}>
                    <CustomToolTip
                        label={
                          "Possible Answers: • Taught line hitch • Prusik • Blakes Hitch • Clove Hitch • Bowline • Alpine Butterfly • Figure eight • Sheet bend • Timber Hitch • 1 Half Hitch"
                      }
                      position="bottom"
                      >
                        <label htmlFor="knot_types">Name at least five knots used for climbing trees / tree work.</label>
                    </CustomToolTip>
                    <textarea
                      name="knot_types"
                      id="knot_types"
                      rows="4"
                      value={knot_types}
                      onChange={handleChange}
                      required
                      className={`${errors["knot_types"] ? styles.error : ""}`}
                    ></textarea>
                  </div>

                  <div className={`${styles.form_group} ${styles.notches_types}`}>
                    <CustomToolTip
                        label={
                         "Possible Answers: • Open face • Humboldt • Conventional"
                      }
                      position="bottom"
                      >
                      <label htmlFor="notches_types">Name three types of felling Notches.</label>
                    </CustomToolTip>
                    <textarea
                      name="notches_types"
                      id="notches_types"
                      rows="4"
                      value={notches_types}
                      onChange={handleChange}
                      required
                      className={`${errors["notches_types"] ? styles.error : ""}`}
                    ></textarea>
                  </div>

                  <div className={`${styles.form_group} ${styles.rig_limb_removal_desc}`}>
                    <CustomToolTip
                        label={
                         "Possible Answers: • Inspect tree to make sure it could withstand shock load of limb when removed • Choose the right size rope according to green wood weight • Determine what kind of rigging equipment would be used • Know what knots to be used • Have ground personal that would understand how the rigging will be setup and used • Cut wood pieces in sizes that equipment could handle 10% rule"
                      }
                      position="bottom"
                      >
                      <label htmlFor="rig_limb_removal_desc">Please describe how you would rig a 500-pound limb for removal, over a glass house.</label>
                    </CustomToolTip>
                    <textarea
                      name="rig_limb_removal_desc"
                      id="rig_limb_removal_desc"
                      rows="4"
                      value={rig_limb_removal_desc}
                      onChange={handleChange}
                      required
                      className={`${errors["rig_limb_removal_desc"] ? styles.error : ""}`}
                    ></textarea>
                  </div>

                  <div className={`${styles.form_group} ${styles.tree_trimming_memory}`}>
                    <label htmlFor="tree_trimming_memory">Describe a tree trimming job that you completed that stands out in your memory, and why.</label>
                    <textarea
                      name="tree_trimming_memory"
                      id="tree_trimming_memory"
                      rows="4"
                      value={tree_trimming_memory}
                      onChange={handleChange}
                      required
                      className={`${errors["tree_trimming_memory"] ? styles.error : ""}`}
                    ></textarea>
                  </div>

                  <div className={`${styles.form_group} ${styles.tree_trimming_hazards}`}>
                    <CustomToolTip
                        label={
                           "Possible Answers: • Cutting oneself with sharp tools / chainsaws • Falling from height • Being struck by falling branches or debris • Electrocution from contact with power lines • Sprains and strains from lifting pulling carrying heavy items"
                        }
                      position="bottom"
                      >
                      <label htmlFor="tree_trimming_hazards">What are some of the most common hazards associated with tree trimming?</label>
                    </CustomToolTip>
                    <textarea
                      name="tree_trimming_hazards"
                      id="tree_trimming_hazards"
                      rows="4"
                      value={tree_trimming_hazards}
                      onChange={handleChange}
                      required
                      className={`${errors["tree_trimming_hazards"] ? styles.error : ""}`}
                    ></textarea>
                  </div>

                  <div className={`${styles.form_group} ${styles.tree_trimming_types}`}>
                    <CustomToolTip
                        label={
                         "Possible Answers: • Chain Saw • Hand saws • Pole saws • Pole pruners • Loppers • Hand pruners • Hydraulic Loppers • Hydraulic saws"
                      }
                      position="bottom"
                      >
                      <label htmlFor="tree_trimming_types">Name five types of tree trimming/ pruning equipment.</label>
                    </CustomToolTip>
                    <textarea
                      name="tree_trimming_types"
                      id="tree_trimming_types"
                      rows="4"
                      value={tree_trimming_types}
                      onChange={handleChange}
                      required
                      className={`${errors["tree_trimming_types"] ? styles.error : ""}`}
                    ></textarea>
                  </div>

                  <div className={`${styles.form_group} ${styles.accident_prevention_techniques}`}>
                    <CustomToolTip
                        label={
                         "Possible Answers: • Wear proper safety gear PPE’s • Use proper tools for the job • Use sharp tools • Be aware of your surroundings, including power lines, traffic, pedestrians, co-workers, equipment..."
                      }
                      position="bottom"
                      >
                    <label htmlFor="accident_prevention_techniques">What are some of the best ways to prevent accidents and injuries while performing tree work?</label>
                    </CustomToolTip>
                    <textarea
                      name="accident_prevention_techniques"
                      id="accident_prevention_techniques"
                      rows="4"
                      value={accident_prevention_techniques}
                      onChange={handleChange}
                      required
                      className={`${errors["accident_prevention_techniques"] ? styles.error : ""}`}
                    ></textarea>
                  </div>

                  <div className={`${styles.form_group} ${styles.ppe_types}`}>
                    <CustomToolTip
                        label={
                          "Possible Answers: • Hard Hats • Hearing Protection • Safety Glasses Z87 • Snug fitting clothing • Gloves • Chaps • Boots"
                      }
                      position="bottom"
                      >
                    <label htmlFor="ppe_types">Name PPEs for tree work.</label>
                    </CustomToolTip>
                    <textarea
                      name="ppe_types"
                      id="ppe_types"
                      rows="4"
                      value={ppe_types}
                      onChange={handleChange}
                      required
                      className={`${errors["ppe_types"] ? styles.error : ""}`}
                    ></textarea>
                  </div>

                  <div className={`${styles.form_group} ${styles.rope_climbing_types}`}>
                    <CustomToolTip
                        label={
                          "Possible Answers: • Moving rope system, double rope system • Stationary rope system, single line system"
                        }
                      position="bottom"
                      >
                    <label htmlFor="rope_climbing_types">Describe two types of rope climbing systems for tree work?</label>
                    </CustomToolTip>
                    <textarea
                      name="rope_climbing_types"
                      id="rope_climbing_types"
                      rows="4"
                      value={rope_climbing_types}
                      onChange={handleChange}
                      required
                      className={`${errors["rope_climbing_types"] ? styles.error : ""}`}
                    ></textarea>
                  </div>

                  <div className={`${styles.form_group} ${styles.climbing_spurs_time}`}>
                    <CustomToolTip
                        label={
                          "Possible Answers: • Tree removals • Thick bark trees "
                        }
                      position="bottom"
                      >
                      <label htmlFor="climbing_spurs_time">When should climbing spurs be used in tree work?</label>
                    </CustomToolTip>
                    <textarea
                      name="climbing_spurs_time"
                      id="climbing_spurs_time"
                      rows="4"
                      value={climbing_spurs_time}
                      onChange={handleChange}
                      required
                      className={`${errors["climbing_spurs_time"] ? styles.error : ""}`}
                    ></textarea>
                  </div>
                  
                  <div className={`${styles.form_group} ${styles.notes}`}>
                    <label htmlFor="notes">Notes</label>
                    <textarea
                      name="notes"
                      id="notes"
                      rows="4"
                      value={notes}
                      onChange={handleChange}
                      required
                      className={`${errors["notes"] ? styles.error : ""}`}
                    ></textarea>
                  </div>

                  <div className={`${styles.grouped_inputs}`}>
                    <h4>Reference check #1</h4>
                    <div className={styles.form_group_row}>
                      <div className={styles.form_group}>
                        <label htmlFor="refcheck_01_name">Name of Candidate</label>
                        <input
                          type="text"
                          required
                          id="reference_check_name_1"
                          className={`${errors["reference_check_name_1"] ? styles.error : ""}`}
                          value={reference_check_name_1}
                          name="reference_check_name_1"
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.form_group}>
                        <label htmlFor="refcheck_01_num">#</label>
                        <input
                          type="text"
                          required
                          id="reference_check_number_1"
                          className={`${errors["reference_check_number_1"] ? styles.error : ""}`}
                          value={reference_check_number_1}
                          name="reference_check_number_1"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className={styles.form_group}>
                      <label htmlFor="refcheck_01-01">Did they show up on time consistently?</label>
                      <input
                        type="text"
                        required
                        id="reference_one_question_1"
                        className={`${errors["reference_one_question_1"] ? styles.error : ""}`}
                        value={reference_one_question_1}
                        name="reference_one_question_1"
                        onChange={handleChange}
                      />
                    </div>
                    <div className={styles.form_group}>
                      <label htmlFor="refcheck_01-02">Would you hire them again?</label>
                      <input
                        type="text"
                        required
                        id="reference_one_question_2"
                        className={`${errors["reference_one_question_2"] ? styles.error : ""}`}
                        value={reference_one_question_2}
                        name="reference_one_question_2"
                        onChange={handleChange}
                      />
                    </div>
                    <div className={styles.form_group}>
                      <label htmlFor="refcheck_01-03">How was their attitude while working for you?</label>
                      <input
                        type="text"
                        required
                        id="reference_one_question_3"
                        className={`${errors["reference_one_question_3"] ? styles.error : ""}`}
                        value={reference_one_question_3}
                        name="reference_one_question_3"
                        onChange={handleChange}
                      />
                    </div>
                    <div className={styles.form_group}>
                      <label htmlFor="refcheck_01-04">How would you describe their work ethic?</label>
                      <input
                        type="text"
                        required
                        id="reference_one_question_4"
                        className={`${errors["reference_one_question_4"] ? styles.error : ""}`}
                        value={reference_one_question_4}
                        name="reference_one_question_4"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className={`${styles.grouped_inputs}`}>
                    <h4>Reference check #2</h4>
                    <div className={styles.form_group_row}>
                      <div className={styles.form_group}>
                        <label htmlFor="refcheck_02_name">Name of Candidate</label>
                        <input
                          type="text"
                          required
                          id="reference_check_name_2"
                          className={`${errors["reference_check_name_2"] ? styles.error : ""}`}
                          value={reference_check_name_2}
                          name="reference_check_name_2"
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.form_group}>
                        <label htmlFor="refcheck_02_num">#</label>
                        <input
                          type="text"
                          required
                          id="reference_check_number_2"
                          className={`${errors["reference_check_number_2"] ? styles.error : ""}`}
                          value={reference_check_number_2}
                          name="reference_check_number_2"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className={styles.form_group}>
                      <label htmlFor="refcheck_02-01">Did they show up on time consistently?</label>
                      <input
                        type="text"
                        required
                        id="reference_two_question_1"
                        className={`${errors["reference_two_question_1"] ? styles.error : ""}`}
                        value={reference_two_question_1}
                        name="reference_two_question_1"
                        onChange={handleChange}
                      />
                    </div>
                    <div className={styles.form_group}>
                      <label htmlFor="refcheck_02-02">Would you hire them again?</label>
                      <input
                        type="text"
                        required
                        id="reference_two_question_2"
                        className={`${errors["reference_two_question_2"] ? styles.error : ""}`}
                        value={reference_two_question_2}
                        name="reference_two_question_2"
                        onChange={handleChange}
                      />
                    </div>
                    <div className={styles.form_group}>
                      <label htmlFor="refcheck_02-03">How was their attitude while working for you?</label>
                      <input
                        type="text"
                        required
                        id="reference_two_question_3"
                        className={`${errors["reference_two_question_3"] ? styles.error : ""}`}
                        value={reference_two_question_3}
                        name="reference_two_question_3"
                        onChange={handleChange}
                      />
                    </div>
                    <div className={styles.form_group}>
                      <label htmlFor="refcheck_02-04">How would you describe their work ethic?</label>
                      <input
                        type="text"
                        required
                        id="reference_two_question_4"
                        className={`${errors["reference_two_question_4"] ? styles.error : ""}`}
                        value={reference_two_question_4}
                        name="reference_two_question_4"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className={`${styles.grouped_inputs} ${styles.sm_check}`}>
                    <h4>Social Media Check</h4>
                    <div className={styles.form_group_row}>
                      <div className={styles.form_group}>
                        <label htmlFor="sm_check-fb">Facebook</label>
                        <input
                          type="text"
                          required
                          id="facebook_link"
                          value={facebook_link}
                          name="facebook_link"
                          onChange={handleChange}
                          className={`${errors["facebook_link"] ? styles.error : ""}`}
                        />
                      </div>
                      <div className={styles.form_group}>
                        <label htmlFor="sm_check-insta">Instagram</label>
                        <input
                          type="text"
                          required
                          id="instagram_link"
                          className={`${errors["instagram_link"] ? styles.error : ""}`}
                          value={instagram_link}
                          name="instagram_link"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className={`${styles.form_group} ${styles.notes}`}>
                      <label htmlFor="sm_check-notes">Notes</label>
                      <textarea
                        id="social_media_notes"
                        className={`${errors["social_media_notes"] ? styles.error : ""}`}
                        rows="4"
                        value={social_media_notes}
                        name="social_media_notes"
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                  </div>

                  <div className={`${styles.form_group} ${styles.notes}`}>
                    <label htmlFor="other">Other</label>
                    <textarea
                      name="other"
                      id="other"
                      rows="4"
                      value={other}
                      onChange={handleChange}
                      required
                      className={`${errors["other"] ? styles.error : ""}`}
                    ></textarea>
                  </div>

                  <div className={styles.form_group_row}>
                    <div className={styles.form_group}>
                      <label htmlFor="interviewer_name">Name of Interviewer</label>
                      <input type="text" id="" name="" value={user?.full_name} disabled />
                    </div>
                    <div className={cx(styles.form_group, "full_width_date")}>
                      <label htmlFor="interviewer_date">Date on Form Submission</label>
                      <DatePicker
                        type="date"
                        className={styles.date_picker}
                        popperClassName={styles.date_picker}
                        wrapperClassName={styles.date_picker}
                        id="interviewer_date"
                        name="interviewer_date"
                        selected={interviewer_date}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.submit_action__wrapper}>
              <div className={cx(styles.input__wrapper, styles.no_margin_input)}>
                <input
                  type="button"
                  name="draft"
                  className={styles.button}
                  value="Back"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentView("listView");
                  }}
                />
                {editMode && (
                  <input
                    type="button"
                    name="draft"
                    className={styles.button}
                    value="Delete"
                    onClick={(e) => {
                      e.preventDefault();
                      deleteIntForm();
                    }}
                    disabled={loading}
                  />
                )}
              </div>
              <div className={styles.input__wrapper}>
                <input type="submit" name="draft" className={styles.button} value="Save as Draft" disabled={loading} />
                <input type="submit" name="submit" className={styles.button} value="Submit" disabled={loading} />
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
