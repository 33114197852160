import { notificationConstants } from '../_constants';

export function notification(state = {}, action) {
  switch (action.type) {
    case notificationConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case notificationConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading:false,
        list: action.notification
      };
    case notificationConstants.GETALL_FAILURE:
      return { 
        ...state,
        error: action.error
      };
    case notificationConstants.GETLASTSEEN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case notificationConstants.GETLASTSEEN_SUCCESS:
      return {
        ...state,
        loading:false,
        lastSeen: action.lastSeen
      };
    case notificationConstants.GETLASTSEEN_FAILURE:
      return { 
        ...state,
        error: action.error
      };
    case notificationConstants.MARKALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case notificationConstants.MARKALL_SUCCESS:
      return {
        ...state,
        loading:false,
        markall: action.markall
      };
    case notificationConstants.MARKALL_FAILURE:
      return { 
        ...state,
        error: action.error
      };

    case notificationConstants.SETMARK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case notificationConstants.SETMARK_SUCCESS:
      return {
        ...state,
        loading:false,
        read: action.read
      };
    case notificationConstants.SETMARK_FAILURE:
      return { 
        ...state,
        error: action.error
      };

    case notificationConstants.GETCOUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case notificationConstants.GETCOUNT_SUCCESS:
      return {
        ...state,
        loading:false,
        count: action.count
      };
    case notificationConstants.GETCOUNT_FAILURE:
      return { 
        ...state,
        error: action.error
      }; 

    case notificationConstants.PINNED_REQUEST:
      return {
        ...state,
        loading: true
      };
    case notificationConstants.PINNED_SUCCESS:
      return {
        ...state,
        loading:false,
        pinned: action.pinned
      };
    case notificationConstants.PINNED_FAILURE:
      return { 
        ...state,
        error: action.error
      };  

    case notificationConstants.DELETEBULLET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case notificationConstants.DELETEBULLET_SUCCESS:
      return {
        ...state,
        loading:false,
        dltBulletin: action.dltBulletin
      };
    case notificationConstants.DELETEBULLET_FAILURE:
      return { 
        ...state,
        error: action.error
      };   
    default:
      return state
  }
}
