import React from 'react';
import { connect } from 'react-redux';
import { YourCrewDetails } from './YourCrewDetails';
import { Profile } from './Profile';
import '../assets/styles/resource.css';

class YourCrew extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            mainHtml: "",
            step: "YourCrewDetails",
        }
        this.profile = this.profile.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    profile(hash){
        this.setState({ step:'Profile', hash:hash },function(){
            this.componentWillReceiveProps(this.props)
        })
    }
    goBack(){
        this.setState({ step:'YourCrewDetails' },function(){
            this.componentWillReceiveProps(this.props)
        })
    }

    componentDidMount() {
        this.componentWillReceiveProps(this.props)
    }

    componentWillReceiveProps(nextProps){ 
        
        var options;
        if(this.state.step === 'YourCrewDetails') {
            options = <div >
                        <YourCrewDetails 
                            profile={this.profile.bind(this)}
                        />   		
                    </div>		
        }

        
        if(this.state.step === 'Profile') {
            options = <div>
                        <Profile 
                        goBack={this.goBack} 
                        hash={this.state.hash}/>   			           
                    </div>
        }
        
        this.setState({mainHtml:options})
    }
       

    render() {
        
        return (
    
            <div>                
                {this.state.mainHtml}  		
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { vehicles } = state;
    return {
        vehicles,
    };
}

const connectedResourcePage = connect(mapStateToProps)(YourCrew);
export { connectedResourcePage as YourCrew };
