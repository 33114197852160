/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import { config } from "_helpers";

class ListItem extends Component {
  constructor(props) {
    super(props);
    this.navigate = this.navigate.bind(this);
  }

  navigate(to) {
    this.props.history.push(to);
  }

  render() {
    const { uid, signature } = this.props;
    return (
      <div className='applicant_item list_item'>
        {this.props.view ? (
          <div className='homeEmp_list'>
            <div className='basic_info unavailable_employe'>
              <div
                className='item_cell first_item'
                onClick={() => this.props.viewDoc(this.props.doc)}>
                {/* <span><input type="checkbox" /></span> */}
              </div>

              <div
                className='item_cell cursor-pointer'
                onClick={() => this.props.viewDoc(this.props.doc)}>
                <img
                  className='doc-list-img'
                  src={`${config.assetUrl}icons/${
                    this.props.status === 2 || this.props.status === 3
                      ? "doc-check-circle.svg"
                      : "doc-refresh-ico.svg"
                  } `}
                  alt='refresh'
                />

                <span>{this.props.doc_type_name}</span>
              </div>
              <div
                className='item_cell cursor-pointer'
                onClick={() => this.props.viewDoc(this.props.doc)}>
                <span>
                  {`${this.props.first_name} ${this.props.last_name}`}{" "}
                </span>
              </div>
              <div
                className='item_cell cursor-pointer'
                onClick={() => this.props.viewDoc(this.props.doc)}>
                <span>{this.props.updated_at}</span>
              </div>
              <div className='item_cell'>
                <a
                  href={`${config.baseUrl}docs/pdf/${this.props.slug}?id=${this.props.id}&uid=${uid}&signature=${signature}`}
                  target='_blank'>
                  <img src={`${config.assetUrl}icons/doc-admin-download.svg`} alt='bell' />
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className='homeEmp_list'>
            <div className='basic_info unavailable_employe'>
              <div className='item_cell first_item'>
                {/* <span><input type="checkbox" /></span> */}
              </div>

              <div
                className='item_cell cursor-pointer'
                onClick={() =>
                  this.navigate(
                    `${this.props.url}/${this.props.hash}/${this.props.slug}?rehire=${this.props.rehire}`,
                  )
                }>
                <img
                  className='doc-list-img'
                  src={`${config.assetUrl}icons/${
                    this.props.status === 2 || this.props.status === 3
                      ? "doc-check-circle.svg"
                      : "doc-refresh-ico.svg"
                  } `}
                  alt='refresh'
                />

                <span>{this.props.doc_type_name}</span>
              </div>
              <div
                className='item_cell cursor-pointer'
                onClick={() =>
                  this.navigate(
                    `${this.props.url}/${this.props.hash}/${this.props.slug}?rehire=${this.props.rehire}`,
                  )
                }>
                <span>
                  {`${this.props.first_name} ${this.props.last_name}`}{" "}
                </span>
              </div>
              <div
                className='item_cell cursor-pointer'
                onClick={() =>
                  this.navigate(
                    `${this.props.url}/${this.props.hash}/${this.props.slug}?rehire=${this.props.rehire}`,
                  )
                }>
                <span>{this.props.updated_at}</span>
              </div>
              <div className='item_cell'>
                <a
                  href={`${config.baseUrl}docs/pdf/${this.props.slug}?id=${this.props.id}&uid=${uid}&signature=${signature}`}
                  target='_blank'>
                  <img src={`${config.assetUrl}icons/doc-admin-download.svg`} alt='bell' />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export { ListItem };
