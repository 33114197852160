import React, { Component } from 'react'
import { config } from '../_helpers/config';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { docsActions } from '../_actions/docs.actions';
class DocsWelcome extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.generateDocs = this.generateDocs.bind(this);
    }

    generateDocs() {

        this.props.dispatch(docsActions.generateDocs()).then(res => {
            if (res.success) {
                let { path } = this.props.match;
                this.props.history.push(`${path}/docs/candidate-information-page?rehire=false`)
            }
        });

    }

    render() {

        let user = this.props.user.profile ? this.props.user.profile.data : {};

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="onboarding-docs">
                        <div className="doc-home">
                            <div className="doc-home-info">
                                <img src={`${config.assetUrl}icons/doc-home.svg`} className="doc-home-img" alt="" />
                                <h3 className="doc-username">Hi, {user.first_name} {user.last_name}.</h3>
                                {user && (
                                    <p>Please complete the on-boarding process by filling out the documents. If done already in person, please wait for the admin to approve your profile.</p>
                                )}
                                <button onClick={this.generateDocs} className="doc-btn">Onboarding Documents</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state;
    return { user };
}

let connectedDocsWelcome = compose(withRouter, connect(mapStateToProps))(DocsWelcome);
export { connectedDocsWelcome as DocsWelcome };