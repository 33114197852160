import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reportsActions, resourceActions } from "_actions";
import { reportsService } from "_services";
import ExpirationDateReport from "./ExpirationDatesReport";
import {
  expirationDateReport,
  // isExpirationDateReportLoading,
} from "_helpers/reduxSelector";

// import { exportToCSV } from "_utils/export";

import "../assets/styles/report.css";
import AsyncSelect from "react-select/async";
import ExpirationDateFilters, {
  expirationDateFilterOptions,
  selectAllOption,
} from "ExpirationDateStatus/ExpirationDateFilters";

const MENU_WIDTH = 300;

const selectStyles = {
  control: (css) => ({
    ...css,
    width: MENU_WIDTH || "auto",
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 7,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 9999,
  }),
  option: (css) => ({ ...css, paddingRight: 36 + 8, zIndex: 9999 }),
};

const ExpirationDateReportListContainer = (props) => {
  const dispatch = useDispatch();

  const expirationDateReportData = useSelector((state) =>
    expirationDateReport(state)
  );

  const [globalSort, setGlobalSort] = useState("first_name");
  const [globalOrder, setGlobalOrder] = useState("asc");
  const [selectedResource, setSelectedResource] = useState();
  const [currentFilters, setCurrentFilters] = useState([]);
  const [currentFiltersRaw, setCurrentFiltersRaw] = useState([]);

  const fetchExpirationDateReport = (newPage, sort, order) => {
    let data = new FormData();
    data.append("filter", JSON.stringify(currentFilters));
    data.append("page", newPage || 0);
    data.append("resource_id", selectedResource?.value || 0);
    data.append("sort", sort || "first_name");
    data.append("order", order || "asc");
    dispatch(reportsActions.getExpirationDateReport(data));
    // if (newPage) {
    //   setCurrentPage(newPage);
    // }
  };

  const setSortParams = (sort, order) => {
    const sortOrder = order ? "asc" : "desc";
    setGlobalSort(sort);
    setGlobalOrder(sortOrder);
  };

  const redirectToPdf = (data) => {
    if (data?.success) {
      const pdfLink = data.data;
      var link = document.createElement("a");
      if (
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i)
      ) {
        link.href = pdfLink;
      } else {
        link.href = pdfLink;
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(pdfLink);
      }, 100);
    }
  };

  const onExportClick = () => {
    let data = new FormData();
    data.append("filter", JSON.stringify(currentFilters));
    data.append("resource_id", selectedResource?.value || 0);
    data.append("sort", globalSort ?? "");
    data.append("order", globalOrder ?? "");
    data.append("report_type", "csv");
    console.log(globalSort)
    reportsService.generateExpDateReportCSV(data);
  };

  const onPrintClick = () => {
    let data = new FormData();
    data.append("filter", JSON.stringify(currentFilters));
    data.append("resource_id", selectedResource?.value || 0);
    data.append("sort", globalSort ?? "");
    data.append("order", globalOrder ?? "");
    data.append("report_type", "pdf");
    reportsService
      .generateExpDateReportPDF(data)
      .then((response) => redirectToPdf(response));
  };

  const handleSearch = async (text) => {
    let data = new FormData();
    data.append("keyword", text);
    const resources = dispatch(resourceActions.searchResource(data));
    let resourceList = [];
    const response = await resources;
    const items = response.data;
    var res = [];

    for (var key in items) {
      var arr = items[key];
      if (arr.length) {
        res = res.concat(arr);
      }
    }
    if (res.length > 0) {
      res.forEach((item) => {
        let resource = {
          label: item.resource_name,
          value: item.id,
        };
        resourceList.push(resource);
      });
    }
    return resourceList;
  };

  const loadResources = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(handleSearch(inputValue));
      }, 1000);
    });

  const handleOnChange = (newFilters, event) => {
    const hasSelectedAllOptions =
      (event?.action === "select-option" && event?.option?.value === "all") ||
      newFilters?.length === expirationDateFilterOptions?.length;
    if (hasSelectedAllOptions) {
      setCurrentFiltersRaw(selectAllOption);
      setCurrentFilters(["all"]);
    } else {
      setCurrentFiltersRaw(newFilters);
      setCurrentFilters(newFilters.map((item) => item?.value));
      setGlobalSort("first_name")
      setGlobalOrder("asc")
    }
  };

  useEffect(() => {
    fetchExpirationDateReport();
  }, [selectedResource, currentFilters, currentFiltersRaw]);

  // to set "all" as the selected option in the initial render
  useEffect(() => {
    const newFilters = [
      {
        value: "all",
        label: "All",
        color: "#0052CC",
        isFixed: true,
      },
    ];
    const event = {
      action: "select-option",
      option: {
        value: "all",
        label: "All",
        color: "#0052CC",
        isFixed: true,
      },
      name: "expiration-dates",
    };
    handleOnChange(newFilters, event);
  }, []);

  return (
    <div>
      <div className={props.isReportMenu ? "" : "dashboard_container"}>
        <div className="white report_table_container">
          {!props.isReportMenu && (
            <div className="back_to_board dashboard_expiration_date_nav">
              <div className="back_button">
                <img
                  src={require("../assets/images/back.svg").default}
                  alt=""
                />{" "}
                <span>
                  <Link to="/">Back to dashboard</Link>
                </span>
              </div>
              {expirationDateReportData?.users?.data.length ? (
                <button
                  href=""
                  className="print_button"
                  onClick={(event) => {
                    onExportClick();
                  }}
                >
                  {/* <img alt="" src={require("../assets/images/print.svg").default} /> Print */}
                  Export
                </button>
              ) : (
                <div className="dashboard-expiration-date-print-btn-mock" />
              )}
            </div>
          )}
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head">
                <h5 className="graph_header">Expiration Date Report</h5>
                <div
                  style={{ gap: `${props.isReportMenu ? "10px" : "0px"}` }}
                  className="report_head_btn_grp"
                >
                  <div style={{ minWidth: 300 }} className="inp_sub_container">
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadResources}
                      onChange={(selection) => setSelectedResource(selection)}
                      // isMulti
                      defaultOptions
                      isClearable
                      styles={selectStyles}
                      placeholder={"Choose Resource Filter"}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                  <div style={{ minWidth: 300 }} className="inp_sub_container">
                    <ExpirationDateFilters
                      value={currentFiltersRaw}
                      options={
                        currentFilters?.includes("all")
                          ? selectAllOption
                          : selectAllOption.concat(expirationDateFilterOptions)
                      }
                      onChange={(newFilters, event) =>
                        handleOnChange(newFilters, event)
                      }
                    />
                  </div>

                  <div className="print-export-btn">
                    {props.isReportMenu &&
                    expirationDateReportData?.users?.data.length &&
                    currentFiltersRaw.length ? (
                      <>
                        <button
                          href=""
                          className="print_button"
                          onClick={(event) => {
                            onExportClick();
                          }}
                        >
                          {/* <img alt="" src={require("../assets/images/print.svg").default} /> Print */}
                          Export
                        </button>
                        <button
                          href=""
                          className="print_button"
                          onClick={(event) => {
                            onPrintClick();
                          }}
                        >
                          {/* <img alt="" src={require("../assets/images/print.svg").default} /> Print */}
                          Print
                        </button>
                      </>
                    ) : (
                      <div className="dashboard-expiration-date-print-btn-mock" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ExpirationDateReport
                data={
                  currentFiltersRaw.length
                    ? expirationDateReportData?.users?.data
                    : []
                }
                lastPage={expirationDateReportData?.users?.last_page}
                fetchExpirationDateReport={fetchExpirationDateReport}
                setSortParams={setSortParams}
                currentFilters={currentFilters}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpirationDateReportListContainer;
