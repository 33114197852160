import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import { isAllInventoryReportLoading } from "_helpers/reduxSelector";
import moment from "moment";

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
  nameClass: ASC_CLASS,
  resourceClass: ASC_CLASS,
  statusClass: ASC_CLASS,
  dlClass: ASC_CLASS,
  mspaClass: ASC_CLASS,
  printClass: ASC_CLASS,
  physicalClass: ASC_CLASS,
  firstAidClass: ASC_CLASS,
  fallerClass: ASC_CLASS,
  harassClass: ASC_CLASS,
  rtClass: ASC_CLASS,
};

const formatDate = (date) => (date ? moment(date).format("MM/DD/YY") : "-");
const getStatusLabel = (status) => {
  switch (status) {
    case 0:
      return "Inactive";
    case 1:
      return "Active";
    case 2:
      return "Locked";
    case 3:
      return "Guest";
    default:
      return "-";
  }
};

const ExpirationDateReport = ({
  fetchExpirationDateReport,
  data,
  lastPage,
  setSortParams,
  currentFilters = [],
}) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("");
  const [page, setPage] = useState(0);
  const isLoading = useSelector((state) => isAllInventoryReportLoading(state));
  const [
    {
      nameClass,
      resourceClass,
      statusClass,
      dlClass,
      mspaClass,
      printClass,
      physicalClass,
      firstAidClass,
      fallerClass,
      harassClass,
      rtClass,
    },
    setStyleState,
  ] = useState(initialStyleState);

  const isSelectedAll = currentFilters.includes("all");
  const isResource = isSelectedAll || currentFilters.includes("resource");
  const isStatus = isSelectedAll || currentFilters.includes("status");
  const isDL = isSelectedAll || currentFilters.includes("dl");
  const isDOLPhysical = isSelectedAll || currentFilters.includes("dol_physical");
  const isFirstAid = isSelectedAll || currentFilters.includes("firstaid");
  const isSexualHarassment = isSelectedAll || currentFilters.includes("sexualharrassment");
  const isFallerEquals = isSelectedAll || currentFilters.includes("fallerquals");
  // const isRt130 = isSelectedAll || currentFilters.includes("rt130");
  // const isRT130 = isSelectedAll || currentFilters.includes("rt130");
  // debugger

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      fetchExpirationDateReport(page, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const handlePageChange = (newPage) => {
    const sortOrder = order ? "asc" : "desc";
    fetchExpirationDateReport(newPage?.selected + 1, sortItem, sortOrder);
    setPage(newPage?.selected);
  };

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams(sort, nextOrder);
  };

  const noDataAvailable = !isLoading && !data?.length;
  return (
    <div className="history_lists outer_list exp-list">
      <table className="mobile_hide report_list_width_full">
        <thead>
          <tr className="report_list_table_row test-class">
            <th onClick={() => sortList("nameClass", nameClass, "first_name")}>
              <div className="report_table_header">
                <span>Employee Name</span>
                <span className={nameClass} />
              </div>
            </th>
            {((isStatus && isResource) ||
              (!isStatus && isResource) ||
              (!isStatus && !isResource)) && (
              <>
                <th
                  onClick={() =>
                    sortList("resourceClass", resourceClass, "resource_name")
                  }
                >
                  <div className="report_table_header">
                    <span>Resource</span>
                    <span className={resourceClass} />
                  </div>
                </th>
              </>
            )}

            {((isStatus && isResource) ||
              (isStatus && !isResource) ||
              (!isStatus && !isResource)) && (
              <>
                <th
                  onClick={() => sortList("statusClass", statusClass, "status")}
                >
                  <div className="report_table_header">
                    <span>Status</span>
                    <span className={statusClass} />
                  </div>
                </th>
              </>
            )}
            {isDL && (
              <>
                <th onClick={() => sortList("dlClass", dlClass, "dl_expiry")}>
                  <div className="report_table_header">
                    <span>Drivers License Expiration Date</span>
                    <span className={dlClass} />
                  </div>
                </th>
              </>
            )}
            {isDOLPhysical && (
              <th
                onClick={() =>
                  sortList("physicalClass", physicalClass, "physical_expiry")
                }
              >
                <div className="report_table_header">
                  <span>DMV Physical</span>
                  <span className={physicalClass} />
                </div>
              </th>
            )}

            {isFirstAid && (
              <>
                <th
                  onClick={() =>
                    sortList("firstAidClass", firstAidClass, "first_aid_expiry")
                  }
                >
                  <div className="report_table_header">
                    <span>First Aid/CPR</span>
                    <span className={firstAidClass} />
                  </div>
                </th>
              </>
            )}
            {isFallerEquals && (
              <>
                <th
                  onClick={() =>
                    sortList("fallerClass", fallerClass, "faller_quals")
                  }
                >
                  <div className="report_table_header">
                    <span>Faller Qualification</span>
                    <span className={fallerClass} />
                  </div>
                </th>
              </>
            )}
            {isSexualHarassment && (
              <>
                <th
                  onClick={() =>
                    sortList(
                      "harassClass",
                      harassClass,
                      "sexual_harassment_expiry"
                    )
                  }
                >
                  <div className="report_table_header">
                    <span>Sexual Harassment</span>
                    <span className={harassClass} />
                  </div>
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody className="report_list_table_tbody">
          {noDataAvailable ? (
            <tr>
              <td colSpan="10">
                <div className="dashboard_expiration_date_no_data">
                  No Data Found
                </div>
              </td>
            </tr>
          ) : (
            data?.map((item) => (
              <tr key={item.id}>
                <td>{`${item?.last_name} ${item?.first_name}` ?? "-"}</td>
                {((isStatus && isResource) ||
                  (isResource && !isStatus) ||
                  (!isResource && !isStatus)) && (
                  <td>{item.resource_name ? `${item.resource_name}` : "-"}</td>
                )}
                {((isStatus && isResource) ||
                  (!isResource && isStatus) ||
                  (!isResource && !isStatus)) && (
                  <td>
                    {item?.status ? `${getStatusLabel(item?.status)}` : "-"}
                  </td>
                )}
                {isDL && (
                  <td>
                    {item?.dl_expiry ? `${formatDate(item?.dl_expiry)}` : "-"}
                  </td>
                )}
                {isDOLPhysical && (
                  <td>
                    {item?.physical_expiry
                      ? `${formatDate(item?.physical_expiry)}`
                      : "-"}
                  </td>
                )}
                {isFirstAid && (
                  <td>
                    {item?.first_aid_expiry
                      ? `${formatDate(item?.first_aid_expiry)}`
                      : "-"}
                  </td>
                )}
                {isFallerEquals && (
                  <td>
                    {item?.faller_quals
                      ? `${formatDate(item?.faller_quals)}`
                      : "-"}
                  </td>
                )}
                {isSexualHarassment && (
                  <td>
                    {item?.sexual_harassment_expiry
                      ? `${formatDate(item?.sexual_harassment_expiry)}`
                      : "-"}
                  </td>
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>
      {noDataAvailable ? (
        <div className="dashboard_expiration_date_no_data for_mobile_history_list">
          No Data Found
        </div>
      ) : (
        <ExpirationDateReportMobileView
          data={data}
          fetchExpirationDateReport={fetchExpirationDateReport}
          setSortParams={setSortParams}
          currentFilters={currentFilters}
        />
      )}

      {!isLoading && data?.length ? (
        <div className="fs-pagination-wrapper-outer" key={7}>
          <div className="customised-pagination right-align">
            <ReactPaginate
              forcePage={page}
              previousLabel={""}
              nextLabel={""}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={lastPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => handlePageChange(data)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ExpirationDateReport;

const ExpirationDateReportMobileView = ({
  data,
  fetchExpirationDateReport,
  setSortParams,
  currentFilters
}) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("");
  const [
    {
      nameClass,
      resourceClass,
      statusClass,
      dlClass,
      mspaClass,
      printClass,
      physicalClass,
      firstAidClass,
      fallerClass,
      harassClass,
      rtClass,
    },
    setStyleState,
  ] = useState(initialStyleState);

  const isSelectedAll = currentFilters.includes("all");
  const isResource = isSelectedAll || currentFilters.includes("resource");
  const isStatus = isSelectedAll || currentFilters.includes("status");
  const isDL = isSelectedAll || currentFilters.includes("dl");
  const isDOL = isSelectedAll || currentFilters.includes("dol");
  const isFirstAid = isSelectedAll || currentFilters.includes("firstaid");
  const isSexualHarassment =
    isSelectedAll || currentFilters.includes("sexualharrassment");
  const isFallerEquals =
    isSelectedAll || currentFilters.includes("fallerquals");
  // const isRt130 = isSelectedAll || currentFilters.includes("rt130");
  // const isRT130 = isSelectedAll || currentFilters.includes("rt130");

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      fetchExpirationDateReport(0, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams?.(sort, nextOrder);
  };

  return (
    <div>
      {data?.map((item) => (
        <React.Fragment key={item.id}>
          <div className="for_mobile_history_list exp-mob-list">
            <hr></hr>
            <div className="history_items">
              <div className="status_item">
                <div
                  className="list_item"
                  onClick={() => sortList("nameClass", nameClass, "first_name")}
                >
                  <strong>Employee Name</strong>
                  <span className={nameClass} />
                </div>
                <div className="list_item">{`${item?.first_name} ${item?.last_name}`}</div>
              </div>
              {((isStatus && isResource) ||
              (!isStatus && isResource) ||
              (!isStatus && !isResource)) &&
                <>
                  <div className="status_item">
                    <div
                      className="list_item"
                      onClick={() =>
                        sortList(
                          "resourceClass",
                          resourceClass,
                          "resource_name"
                        )
                      }
                    >
                      <strong>Resource</strong>
                      <span className={resourceClass} />
                    </div>
                    <div className="list_item">{item?.resource_name}</div>
                  </div>
                </>
              }
              {((isStatus && isResource) ||
              (isStatus && !isResource) ||
              (!isStatus && !isResource)) && (
                <>
                  <div className="status_item">
                    <div
                      className="list_item"
                      onClick={() =>
                        sortList("statusClass", statusClass, "status")
                      }
                    >
                      <strong>Status</strong>
                      <span className={statusClass} />
                    </div>
                    <div className="list_item">
                      {getStatusLabel(item?.is_locked)}
                    </div>
                  </div>
                </>
              )}
              {isDL && (
                <>
                  <div className="status_item">
                    <div
                      className="list_item"
                      onClick={() => sortList("dlClass", dlClass, "dl_expiry")}
                    >
                      <strong>Drivers License Expiration Date</strong>
                      <span className={dlClass} />
                    </div>
                    <div className="list_item">
                      {formatDate(item?.dl_expiry)}
                    </div>
                  </div>
                </>
              )}
              {isDOL && (
                <>
                  <div className="status_item">
                    <div
                      className="list_item"
                      onClick={() =>
                        sortList(
                          "physicalClass",
                          physicalClass,
                          "physical_expiry"
                        )
                      }
                    >
                      <strong>DMV Physical</strong>
                      <span className={physicalClass} />
                    </div>
                    <div className="list_item">
                      {formatDate(item?.physical_expiry)}
                    </div>
                  </div>
                </>
              )}
              {isFirstAid && (
                <>
                  <div className="status_item">
                    <div
                      className="list_item"
                      onClick={() =>
                        sortList(
                          "firstAidClass",
                          firstAidClass,
                          "first_aid_expiry"
                        )
                      }
                    >
                      <strong>First Aid/CPR</strong>
                      <span className={firstAidClass} />
                    </div>
                    <div className="list_item">
                      {formatDate(item?.first_aid_expiry)}
                    </div>
                  </div>
                </>
              )}
              {isFallerEquals && (
                <>
                  <div className="status_item">
                    <div
                      className="list_item"
                      onClick={() =>
                        sortList("fallerClass", fallerClass, "faller_quals")
                      }
                    >
                      <strong>Faller Qualification</strong>
                      <span className={fallerClass} />
                    </div>
                    <div className="list_item">
                      {formatDate(item?.faller_quals)}
                    </div>
                  </div>
                </>
              )}
              {isSexualHarassment && (
                <>
                  <div className="status_item">
                    <div
                      className="list_item"
                      onClick={() =>
                        sortList(
                          "harassClass",
                          harassClass,
                          "sexual_harassment_expiry"
                        )
                      }
                    >
                      <strong>Sexual Harassment</strong>
                      <span className={harassClass} />
                    </div>
                    <div className="list_item">
                      {formatDate(item?.sexual_harassment_expiry)}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
