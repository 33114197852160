/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import _invert from "lodash/invert";
import { isDocStatusListLoading } from "_helpers/reduxSelector";
import CustomCheckbox from "Docs/Components/Checkbox";
import { getFontAwIconAndTitle } from "_helpers/lockDocs";
import CustomToolTip from "_components/CustomToolTip";
import { userAccountStatus, userStatus } from "_constants";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { docsService } from "_services";

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
  nameClass: ASC_CLASS,
  companyClass: ASC_CLASS,
  resourceClass: ASC_CLASS,
  typeClass: ASC_CLASS,
  statusClass: ASC_CLASS,
  onboardingClass: ASC_CLASS,
  rehireClass: ASC_CLASS,
};

const DocStatusReportList = forwardRef((props, ref) => {
  const {
    getRefresherReport,
    refresherReports,
    selectedResource,
    selectedUsersHash,
    setSelectedUsersHash,
    setGlobalPage,
    // checkboxStatus,
    setIsAllPagesSelected,
  } = props;

  const isLoading = useSelector((state) => isDocStatusListLoading(state));
  const data = refresherReports?.data ?? [];
  const allHashes = data.map((item) => item.hash);
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("first_name");
  const [
    {
      nameClass,
      companyClass,
      resourceClass,
      statusClass,
      onboardingClass,
      rehireClass,
    },
    setStyleState,
  ] = useState(initialStyleState);
  const [actionHash, setActionHash] = useState();
  const [selectedResetHash, setSelectedResetHash] = useState();
  const [docType, setDocType] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allSelectedPages, setAllSelectedPages] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSelectedFlag, setPageSelectedFlag] = useState(false);
  const [allPageSelectedFlag, setAllPageSelectedFlag] = useState(false);
  const [allEmpSelectFlag, setAllEmpSelectFlag] = useState(false);
  const [unselectedEmployees, setUnselectedEmployees] = useState([]);
  const [unSelectedPages, setUnSelectedPages] = useState([]);

  let manuallyFullySelected = allHashes.every((hash) =>
    employees.includes(hash)
  );

  const type = React.useMemo(() => _invert(userAccountStatus), []);

  const status = React.useMemo(() => _invert(userStatus), []);

  const handlePageChange = (newPage) => {
    const sortOrder = order ? "asc" : "desc";
    getRefresherReport(
      newPage?.selected + 1,
      sortItem || "first_name",
      sortOrder
    );

    setPageNumber(newPage?.selected + 1);
  };
  const wrapperRef = useRef(null);

  const handleAllSelectPerPage = (e) => {
    if (allEmpSelectFlag) {
      if (!manuallyFullySelected) {
        setEmployees([...new Set(employees.concat(allHashes))]);
        setSelectedUsersHash(allHashes);
        setIsAllPagesSelected(false);
        return;
      } else {
        return handleClearSelection();
      }
    }
    if (allSelectedPages?.includes(pageNumber)) {
      //when user unchecks all selected, remove page and their checkbox statuses
      setAllSelectedPages(
        allSelectedPages.filter((item) => item !== pageNumber)
      );
      const filtered = employees.filter((emp) => !allHashes.includes(emp));
      setEmployees(filtered);
      setSelectedUsersHash(filtered);
      setAllEmpSelectFlag(false);
      setAllPageSelectedFlag(false);
      return;
    }

    if (manuallyFullySelected) {
      manuallyFullySelected = false;
      handleClearSelection();
      return;
    }

    setIsAllPagesSelected(false);
    // setAllSelectedPages([...allSelectedPages, page]);
    setAllSelectedPages([...new Set(allSelectedPages.concat(pageNumber))]);
    setEmployees([...new Set(employees.concat(allHashes))]); // UI mockup
    setSelectedUsersHash([...new Set(employees.concat(allHashes))]); // api payload
  };

  const handleFullEmployeesSelect = () => {
    setUnSelectedPages([]);
    setEmployees(allHashes);
    setAllPageSelectedFlag(true);
    setSelectedUsersHash([]);
    if (allHashes.length === 1) return;
    setIsAllPagesSelected(true); //for ask to fill api
  };

  const handleClearSelection = () => {
    setAllSelectedPages([]);
    setEmployees([]);
    setSelectedUsersHash([]);
    setPageNumber(1);
    setIsAllPagesSelected(false);
    setPageSelectedFlag(false);
    setAllPageSelectedFlag(false);
    setAllEmpSelectFlag(false);
  };

  const handleClearSelectionOnSort = () => {
    setAllSelectedPages([]);
    setEmployees([]);
    setSelectedUsersHash([]);
    setIsAllPagesSelected(false);
    setPageSelectedFlag(false);
    setAllPageSelectedFlag(false);
    setAllEmpSelectFlag(false);
  };

  useImperativeHandle(ref, () => ({
    handleClearSelection,
  }));

  const resetDocStatus = (event, hash, reset_type) => {
    event.preventDefault();
    setDocType(reset_type);
    setSelectedResetHash(hash);
    setIsModalOpen(true);
    setActionHash("");
  };

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getRefresherReport(pageNumber, sortItem, sortOrder);
    }
  }, [sortItem, order, selectedResource]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    handleClearSelectionOnSort();
  };

  const handleUserSelect = (newHash) => {
    if (allEmpSelectFlag) {
      setAllPageSelectedFlag(false);
      setUnSelectedPages([...new Set(unSelectedPages.concat(pageNumber))]);
      setUnselectedEmployees([...new Set(unselectedEmployees.concat(newHash))]);
      setAllSelectedPages(allSelectedPages.filter((p) => p !== pageNumber));
      if (employees.includes(newHash)) {
        setEmployees(employees.filter((emp) => emp !== newHash));
        return;
      } else {
        setEmployees([...new Set(employees.concat(newHash))]);
        return;
      }
    }

    let _usersHash = [...employees];
    const index = _usersHash?.findIndex((hash) => hash === newHash);
    if (index > -1) {
      _usersHash.splice(index, 1);
      setAllSelectedPages(
        allSelectedPages.filter((item) => item !== pageNumber)
      );
    } else {
      _usersHash.push(newHash);
    }
    setSelectedUsersHash(_usersHash);
    setEmployees(_usersHash);
  };

  const handleClickOutside = (event) => {
    if (event.target.className !== "three-dots") {
      setActionHash("");
    }
  };

  const processReset = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("hash", selectedResetHash);
    formData.append("reset_type", docType);
    docsService.resetDocumentStatus(formData).then((response) => {
      if (response.success) {
        toast.success(response.message);
        getRefresherReport(pageNumber); //pagination issue after reset-doc api call fixed
      } else {
        toast.error(response.message);
      }
      setLoading(false);
      setIsModalOpen(false);
    });
  };

  useEffect(() => {
    if (allEmpSelectFlag) handleClearSelection();
  }, [pageNumber]);

  useEffect(() => {
    //if all pages are selected, then only send unselected employees to the api
    if (unselectedEmployees.length) setSelectedUsersHash(unselectedEmployees);
  }, [unselectedEmployees.length]);

  useEffect(() => {
    const isFullPageSelected = employees.length
      ? allHashes.every((hash) => employees.includes(hash))
      : false;
    if (isFullPageSelected) {
      setPageSelectedFlag(true);
      return;
    }
    if (allPageSelectedFlag) {
      setIsAllPagesSelected(true); // for api from parent
      return;
    }
    setPageSelectedFlag(false);
  }, [employees.length, pageNumber, allHashes]);

  useEffect(() => {
    if (allHashes.length === 1) {
      setPageSelectedFlag(false);
    }
  }, [allHashes, employees]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    if (pageSelectedFlag && allPageSelectedFlag)
      return setAllEmpSelectFlag(true);
  }, [pageSelectedFlag, allPageSelectedFlag]);

  if (allHashes.length === 0) {
    manuallyFullySelected = false;
  }

  return (
    <div className="history_lists outer_list">
      <table className="mobile_hide report_list_width_full long__report tab_hide expiry_report">
        <tr className="report_list_table_row">
          <th>
            <div className="report_table_header">
              <div>
                <CustomCheckbox
                  checked={
                    allSelectedPages.includes(pageNumber) ||
                    manuallyFullySelected
                    // allPageSelectedFlag
                  }
                  onChange={(e) => handleAllSelectPerPage(e)}
                />
              </div>
            </div>
          </th>

          <th onClick={() => sortList("nameClass", nameClass, "first_name")}>
            <div className="report_table_header">
              <span>Employee Name</span>

              <span className={nameClass} />
            </div>
          </th>

          <th
            onClick={() =>
              sortList("companyClass", companyClass, "applying_company")
            }
          >
            <div className="report_table_header">
              <span>Company Name</span>

              <span className={companyClass} />
            </div>
          </th>

          <th
            onClick={() =>
              sortList("resourceClass", resourceClass, "resource_name")
            }
          >
            <div className="report_table_header">
              <span>Resource</span>

              <span className={resourceClass} />
            </div>
          </th>

          <th onClick={() => sortList("statusClass", statusClass, "active")}>
            <div className="report_table_header">
              <span>Status</span>

              <span className={statusClass} />
            </div>
          </th>

          <th
            onClick={() =>
              sortList("onboardingClass", onboardingClass, "docs_status")
            }
          >
            <div className="report_table_header">
              <span>Onboarding</span>

              <span className={onboardingClass} />
            </div>
          </th>

          <th
            onClick={() =>
              sortList("rehireClass", rehireClass, "rehire_docs_status")
            }
          >
            <div className="report_table_header">
              <span>Rehire</span>

              <span className={rehireClass} />
            </div>
          </th>

          <th>
            <div className="report_table_header"></div>
          </th>
        </tr>

        <tbody className="report_list_table_tbody">
          {pageSelectedFlag && (
            <tr className="page-employees-selected">
              {!allPageSelectedFlag ? (
                <td colSpan={7} className="td-center">
                  All <strong>{data?.length}</strong> employees on this page are
                  selected.{" "}
                  <span
                    className="select-full-employees"
                    onClick={handleFullEmployeesSelect}
                  >
                    {`${
                      refresherReports?.total !== allHashes.length
                        ? `Select all ${refresherReports?.total} employees.`
                        : ""
                    } `}
                  </span>
                </td>
              ) : (
                <td colSpan={7} className="td-center">
                  All <strong>{refresherReports?.total}</strong> employees are
                  selected.{" "}
                  <span
                    className="select-full-employees"
                    onClick={handleClearSelection}
                  >
                    <strong>Clear selection.</strong>
                  </span>
                </td>
              )}
            </tr>
          )}

          {data?.length
            ? data.map((item, index) => (
                <tr key={index}>
                  <td>
                    {/* Doc Status 3 : Approved */}
                    {/* 
                    {((item?.docs_status === DOC_NOT_FILLED && item?.rehire_docs_status === DOC_NOT_FILLED) ||
                      (item?.docs_status === DOC_APPROVED && item?.rehire_docs_status === DOC_NOT_FILLED)) && ( */}

                    <div>
                      <CustomCheckbox
                        checked={employees?.includes(item?.hash)}
                        onChange={() => handleUserSelect(item?.hash)}
                      />
                    </div>

                    {/* )} */}
                  </td>

                  <td>
                    {item?.first_name} {item?.last_name}
                  </td>

                  <td>
                    {item?.applying_company === 1 ? "About Trees" : "NVTS"}
                  </td>
                  <td>{item?.resource_name}</td>

                  <td>{status?.[item?.active]}</td>

                  <td>
                    <div>
                      <CustomToolTip
                        label={
                          getFontAwIconAndTitle(item?.docs_status, "onboarding")
                            ?.title
                        }
                      >
                        <i
                          className={
                            getFontAwIconAndTitle(
                              item?.docs_status,

                              "onboarding"
                            )?.className
                          }
                        ></i>
                      </CustomToolTip>
                    </div>
                  </td>

                  <td>
                    <div>
                      <CustomToolTip
                        label={
                          getFontAwIconAndTitle(
                            item?.rehire_docs_status,

                            "rehire"
                          )?.title
                        }
                      >
                        <i
                          className={
                            getFontAwIconAndTitle(
                              item?.rehire_docs_status,

                              "rehire"
                            )?.className
                          }
                        ></i>
                      </CustomToolTip>
                    </div>
                  </td>

                  <td>
                    <div class="dropdown-container">
                      <div
                        class="three-dots"
                        onClick={() => setActionHash(item?.hash)}
                      ></div>
                      {item?.hash === actionHash && (
                        <div class="dropdown" ref={wrapperRef}>
                          <div className="reset_btn_container">
                            <a
                              className="btn btn__reset"
                              onClick={(e) =>
                                resetDocStatus(e, item?.hash, "1")
                              }
                            >
                              Reset Onboarding
                            </a>
                            <a
                              className="btn btn__reset"
                              onClick={(e) =>
                                resetDocStatus(e, item?.hash, "2")
                              }
                            >
                              Reset Rehire
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            : !isLoading && (
                <tr>
                  <td colSpan={10}>
                    <div className="dashboard_expiration_date_no_data">
                      No Data Found
                    </div>
                  </td>
                </tr>
              )}
        </tbody>
      </table>

      {data?.length ? (
        <>
          <RefresherReportMobileView
            type={type}
            status={status}
            data={data}
            getRefresherReport={getRefresherReport}
            handleUserSelect={handleUserSelect}
            selectedUsersHash={selectedUsersHash}
            resetDocStatus={resetDocStatus}
          />

          {data?.length ? (
            <div className="fs-pagination-wrapper-outer" key={7}>
              <div className="customised-pagination right-align">
                <ReactPaginate
                  previousLabel={""}
                  nextLabel={""}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={refresherReports?.last_page}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(data) => handlePageChange(data)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          ) : null}
        </>
      ) : (
        !isLoading && (
          <div className="dashboard_expiration_date_no_data for_mobile_history_list">
            No Data Found
          </div>
        )
      )}

      <ReactModal isOpen={isModalOpen} contentLabel="Reset Documents">
        <div className="modal-head">
          <img
            alt="close"
            className="closeModal"
            onClick={() => setIsModalOpen(false)}
            src={require("../assets/images/close_green.png").default}
          />
        </div>
        <div className="modal-body emp_status">
          <p>{`Are you sure you want reset the ${
            docType === "1" ? "Onboarding" : "Rehire"
          } documents?`}</p>
          <button
            className="button"
            onClick={() => processReset()}
            disabled={loading}
          >
            Confirm
          </button>
        </div>
      </ReactModal>
    </div>
  );
});

export default DocStatusReportList;

const RefresherReportMobileView = ({
  data,
  getRefresherReport,
  type,
  status,
  selectedUsersHash,
  handleUserSelect,
  resetDocStatus,
}) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("first_name");

  const [
    {
      nameClass,
      companyClass,
      resourceClass,
      statusClass,
      onboardingClass,
      rehireClass,
    },
    setStyleState,
  ] = useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getRefresherReport(0, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
  };

  return data.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list tab_show">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div className="list_item">
              <CustomCheckbox
                checked={selectedUsersHash?.includes(item?.hash)}
                onChange={() => handleUserSelect(item?.hash)}
              />
            </div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("nameClass", nameClass, "first_name")}
            >
              <strong>Employee Name</strong>
              <span className={nameClass} />
            </div>
            <div className="list_item">{`${item?.first_name} ${item?.last_name}`}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("companyClass", companyClass, "applying_company")
              }
            >
              <strong>Company Name</strong>
              <span className={companyClass} />
            </div>
            <div className="list_item">
              {" "}
              {item?.applying_company === 1 ? "About Trees" : "NVTS"}
            </div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("resourceClass", resourceClass, "resource_name")
              }
            >
              <strong>Resource</strong>
              <span className={resourceClass} />
            </div>
            <div className="list_item">{item?.resource_name}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("statusClass", statusClass, "resource_name")
              }
            >
              <strong>Status</strong>
              <span className={statusClass} />
            </div>
            <div className="list_item">{status?.[item?.active]}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("onboardingClass", onboardingClass, "resource_name")
              }
            >
              <strong>Onboarding</strong>
              <span className={onboardingClass} />
            </div>
            <div className="list_item">
              {getFontAwIconAndTitle(item?.docs_status, "onboarding")?.title}
            </div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("rehireClass", rehireClass, "resource_name")
              }
            >
              <strong>Rehire</strong>
              <span className={rehireClass} />
            </div>
            <div className="list_item">
              {getFontAwIconAndTitle(item?.rehire_docs_status, "rehire")?.title}
            </div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("onboardingClass", onboardingClass, "resource_name")
              }
            >
              <div className="reset_btn_container">
                <a
                  className="btn btn__reset"
                  onClick={(e) => resetDocStatus(e, item?.hash, "1")}
                >
                  Reset Onboarding
                </a>
              </div>
            </div>
            <div className="list_item">
              <div className="reset_btn_container">
                <a
                  className="btn btn__reset"
                  onClick={(e) => resetDocStatus(e, item?.hash, "2")}
                >
                  Reset Rehire
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};
