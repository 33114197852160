import { inventoryConstants } from "../_constants";

const initialState = {
  inventoryNotes: []
}

export function inventory(state = initialState, action) {
  switch (action.type) {
    case inventoryConstants.PRINTURL_REQUEST:
      return {
        ...state,
        printLoading: true,
      };
    case inventoryConstants.PRINTURL_SUCCESS:
      return {
        ...state,
        printLoading: false,
        printUrl: action.printUrl,
      };
    case inventoryConstants.PRINTURL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.EQUPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.EQUPLOAD_SUCCESS:
      return {
        ...state,
        uploadedImage: action.uploadedImage,
      };
    case inventoryConstants.EQUPLOAD_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.GETEQP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.GETEQP_SUCCESS:
      return {
        ...state,
        equipments: action.equipments,
      };
    case inventoryConstants.GETEQP_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.GETSTATS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.GETSTATS_SUCCESS:
      return {
        ...state,
        stats: action.stats,
      };
    case inventoryConstants.GETSTATS_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.SETINV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.SETINV_SUCCESS:
      return {
        ...state,
        inventory: action.inventory,
      };
    case inventoryConstants.SETINV_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.GETLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.GETLIST_SUCCESS:
      return {
        ...state,
        list: action.list,
      };
    case inventoryConstants.GETLIST_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.GET_PAGINATED_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.GET_PAGINATED_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.data,
      };
    case inventoryConstants.GET_PAGINATED_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.EQUIP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.EQUIP_SUCCESS:
      return {
        ...state,
        equipList: action.equipList,
      };
    case inventoryConstants.EQUIP_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.GETUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.GETUSER_SUCCESS:
      return {
        ...state,
        users: action.users,
      };
    case inventoryConstants.GETUSER_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.ASSIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.ASSIGN_SUCCESS:
      return {
        ...state,
        assign: action.assign,
      };
    case inventoryConstants.ASSIGN_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.AVAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.AVAIL_SUCCESS:
      return {
        ...state,
        available: action.available,
      };
    case inventoryConstants.AVAIL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.INVENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.INVENT_SUCCESS:
      return {
        ...state,
        inventory: action.inventory,
      };
    case inventoryConstants.INVENT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.DLTINV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.DLTINV_SUCCESS:
      return {
        ...state,
        dltInv: action.dltInv,
      };
    case inventoryConstants.DLTINV_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.FORCE_DLTINV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.FORCE_DLTINV_SUCCESS:
      return {
        ...state,
        dltInv: action.dltInv,
      };
    case inventoryConstants.FORCE_DLTINV_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.DELETEFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.DELETEFILE_SUCCESS:
      return {
        ...state,
        dltFile: action.dltFile,
      };
    case inventoryConstants.DELETEFILE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.ARCHIVED_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.ARCHIVED_LIST_SUCCESS:
      return {
        ...state,
        archived: action.archived,
      };
    case inventoryConstants.ARCHIVED_LIST_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.UNARCHIVE_EQUIPMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.UNARCHIVE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        unArchivedEquipment: action.unArchivedEquipment,
      };
    case inventoryConstants.UNARCHIVE_EQUIPMENT_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case inventoryConstants.GET_INVENTORY_NOTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case inventoryConstants.GET_INVENTORY_NOTES_SUCCESS:
      return {
        ...state,
        inventoryNotes: action,
      };
    case inventoryConstants.GET_INVENTORY_NOTES_ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
