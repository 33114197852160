import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isApplicantsReportLoading } from "_helpers/reduxSelector";
import ReactPaginate from "react-paginate";

const ASC_CLASS = "sort-arrow asc";
const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
  nameClass: ASC_CLASS,
  emailClass:ASC_CLASS,
  raceClass: ASC_CLASS,
  resourceClass: ASC_CLASS,
  veteranClass:ASC_CLASS,
  genderClass:ASC_CLASS,
  statusClass:ASC_CLASS,
  dateClass:ASC_CLASS,
  companyClass:ASC_CLASS
};

const renderStatus = (statusCode) => {
  switch (statusCode) {
    case 0:
      return "Not selected"
    case 1:
      return "Received"
    case 2:
      return "Was hired"
    case 3:
      return "In review"
    case 4:
      return "Offered position"
    case 10:
      return "Do not hire"
    default:
      return "-"
  }
}

const Sort = {
  Asc:"asc",
  Dsc:"desc"
}

const ApplicantsReport = (props) => {
  const {
    data,
    setSortParams,
    fetchApplicantsReport,
    lastPage,
    keySearch
  } = props;

  const [
    { nameClass, raceClass, genderClass, veteranClass, statusClass,emailClass,dateClass, companyClass },
    setStyleState,
  ] = useState(initialStyleState);
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("last_name");
  const [page, setPage] = useState(1);

  const isLoading = useSelector((state) => isApplicantsReportLoading(state));

  const handlePageChange = (newPage) => {
    const sortOrder = order ? Sort.Asc : Sort.Dsc;
    fetchApplicantsReport(newPage?.selected + 1, sortItem, sortOrder);
    setPage(newPage?.selected+1);
  };

  const noDataAvailable = !isLoading && !data?.length;

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams(sort, nextOrder);
  };

  useEffect(() => {
    const sortOrder = order ? Sort.Asc : Sort.Dsc;
    if (sortItem) {
      fetchApplicantsReport(page, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  useEffect(() => {
    setPage(0);
  }, []);

  useEffect(() => {
    //to show active page highlighted on the pagination
    if (keySearch.length) setPage(1);
  }, [keySearch]);


  return (
    <>
      <div className="history_lists outer_list table__scroll">
        {!isLoading ? (
          <table className="mobile_hide report_list_width_full">
            <thead>
              <tr className="report_list_table_row">
                <th
                  onClick={() => sortList("nameClass", nameClass, "last_name")}
                >
                  <span>Name</span>
                  <span className={nameClass} />
                </th>
                <th
                  onClick={() => sortList("emailClass", emailClass, "email")}
                >
                  <span>Email</span>
                  <span className={emailClass} />
                </th>
                <th
                  onClick={() => sortList("companyClass", companyClass, "company")}
                >
                  <span>Applying Company</span>
                  <span className={companyClass} />
                </th>
                <th onClick={() => sortList("raceClass", raceClass, "race")}>
                  <span>Race</span>
                  <span className={raceClass} />
                </th>
                <th
                  onClick={() => sortList("genderClass", genderClass, "gender")}
                >
                  <span>Gender</span>
                  <span className={genderClass} />
                </th>
                <th
                  onClick={() =>
                    sortList("veteranClass", veteranClass, "veteran")
                  }
                >
                  <span>Veteran</span>
                  <span className={veteranClass} />
                </th>
                <th
                  onClick={() => sortList("statusClass", statusClass, "status")}
                >
                  <span>Status</span>
                  <span className={statusClass} />
                </th>

                <th
                  onClick={() => sortList("dateClass", dateClass, "date")}
                >
                  <span>Date</span>
                  <span className={dateClass} />
                </th>
                
              </tr>
            </thead>
            <tbody className="report_list_table_tbody">
              {noDataAvailable ? (
                <tr>
                  <td colSpan="7">
                    <div className="dashboard_expiration_date_no_data">
                      No Data Found
                    </div>
                  </td>
                </tr>
              ) : (
                data?.map((item, index) => {
                  const name = item?.last_name + " " + item?.first_name;
                  return (
                    <tr key={index}>
                      <td>{name ?? "-"}</td>
                      <td className="applicants-report-email-desktop">{item?.email_address ?? "-"}</td>
                      <td>{item?.applying_company == 1 ? "About Trees" : "NVTS"}</td>
                      <td >{item?.race || "-"}</td>
                      <td className="applicants-report-gender-desktop">{item?.gender || "-"}</td>
                      <td style={{width:"95px"}}>{item?.veteran || "-"}</td>
                      <td style={{width:"95px"}}>{renderStatus(item?.status)}</td>
                      <td>{item?.date ||"_"}</td>

                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        ) : (
          <center id="feedLoader">
            <img
              alt="Loader"
              src={require("../assets/images/loader.gif").default}
            />
          </center>
        )}

        {/* Mobile */}
        {noDataAvailable ? (
          <div className="dashboard_expiration_date_no_data for_mobile_history_list">
            No Data Found
          </div>
        ) : (
          <ApplicantsReportMobileView
            data={data}
            fetchApplicantsReport={fetchApplicantsReport}
            setSortParams={setSortParams}
          />
        )}
      </div>
      {!isLoading && data?.length ? (
        <div className="fs-pagination-wrapper-outer" key={7}>
          <div className="customised-pagination right-align">
            <ReactPaginate
              forcePage={page ? page-1:page}
              previousLabel={""}
              nextLabel={""}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={lastPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => handlePageChange(data)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ApplicantsReport;

const ApplicantsReportMobileView = ({
  data,
  fetchApplicantsReport,
  setSortParams,
}) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("last_name");
  const [{ nameClass, emailClass, raceClass, genderClass, veteranClass, statusClass,dateClass, companyClass }, setStyleState] =
    useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      fetchApplicantsReport(0, sortItem, sortOrder);
    } 
  }, [sortItem, order]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams?.(sort, nextOrder);
  };

  return data?.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("nameClass", nameClass, "last_name")}
            >
              <strong>Name</strong>
              <span className={nameClass} />
            </div>
            <div className="list_item">{ item.last_name+ " " +item?.first_name ?? "-"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("emailClass", emailClass, "email")}
            >
              <strong>Email</strong>
              <span className={emailClass} />
            </div>
            <div className="list_item">{ item?.email_address ?? "-"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("companyClass", companyClass, "company")}
            >
              <strong>Applying Company</strong>
              <span className={companyClass} />
            </div>
            <div className="list_item">{item?.applying_company == 1 ? "About Trees" : "NVTS"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("raceClass", raceClass, "race")
              }
            >
              <strong>Race</strong>
              <span className={raceClass} />
            </div>
            <div className="list_item">{item?.race ?? "-"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("genderClass", genderClass, "gender")
              }
            >
              <strong>Gender</strong>
              <span className={genderClass} />
            </div>
            <div className="list_item">{item?.gender ?? "-"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("veteranClass", veteranClass, "veteran")
              }
            >
              <strong>Veteran</strong>
              <span className={veteranClass} />
            </div>
            <div className="list_item">{item?.veteran ?? "-"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("statusClass", statusClass, "status")
              }
            >
              <strong>Status</strong>
              <span className={statusClass} />
            </div>
            <div className="list_item">{renderStatus(item?.status)}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("dateClass", dateClass, "date")
              }
            >
              <strong>Date</strong>
              <span className={dateClass} />
            </div>
            <div className="list_item">{item?.date}</div>
          </div>

        </div>
      </div>
    </React.Fragment>
  ));
};
