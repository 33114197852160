import { applicantStatsConstants } from '../_constants';
import { applicantStatsService } from '../_services';

export const applicantStatsActions = {
    getStatistics,
};

function getStatistics() {
    return dispatch => {
        dispatch(request());

        applicantStatsService.getStatistics()
            .then(
                applicantStats => dispatch(success(applicantStats)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: applicantStatsConstants.GETAPPLNSTATS_REQUEST } }
    function success(applicantStats) { return { type: applicantStatsConstants.GETAPPLNSTATS_SUCCESS, applicantStats } }
    function failure(error) { return { type: applicantStatsConstants.GETAPPLNSTATS_FAILURE, error } }
}
