import React, { Component, Fragment } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { navigate } from '../Forms/formHandlers';

class Pdf extends Component {
  constructor(props) {
    // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    super(props);

    this.state = {
      numPages: null,
      pageNumber: 1,
      loading:true
    }

    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);
    this.navigate = navigate.bind(this);
    this.redirect = this.redirect.bind(this);
    // Refs
    this.pdfContainer = React.createRef();
  }

  //  Calls after PDF loaded
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ 
      numPages,
      loading:false
    });
  }
  redirect() {
    let { hash } = this.props.match.params;
    this.props.history.push(`/verification/${hash}`)
  }
  next() {
    if (this.state.pageNumber < this.state.numPages) {
      this.setState({
        pageNumber: this.state.pageNumber + 1
      })
    }
  }
  prev() {
    if (this.state.pageNumber > 1) {
      this.setState({
        pageNumber: this.state.pageNumber - 1
      })
    }
  }

  resizeHandler(){
    const width = this.pdfContainer.clientWidth;
      this.setState({
        pdf: {
          width: width,
        }
      })
  }

  componentDidMount() {

    const width = this.pdfContainer.clientWidth;
    this.setState({
      pdf: {
        width: width,
      }
    });

    window.addEventListener('resize', this.resizeHandler);

  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.resizeHandler);
  }

  componentDidUpdate(prevProps){
    let { url } = prevProps;
    if(url !== this.props.url){
      this.setState({loading:true})
    }
  }

  render() {
    
    const { pageNumber, numPages, loading } = this.state;
    let { docs, lastPage } = this.props;
    let { doc } = this.props.match.params;
    let { userDocs } = docs;
    let activeDocIndex = userDocs.findIndex(item => item.slug === doc);

    return (
      <div className="doc_info-rh" ref={(elem) => this.pdfContainer = elem}>
        {/* Document */}
        <div className="doc-wrapper">
          {
            this.props.url && 
            this.state.pdf &&
            <Fragment>
              { 
                loading &&
                <div className="doc-pdf-loader">
                  <img
                    alt="loader"
                    className="login_loader"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                />
                </div>
              }
              <Document
                file={this.props.url}
                onLoadSuccess={this.onDocumentLoadSuccess}
                loading={false}
              >
                {Array.from(
                  new Array(numPages),
                  (el, index) => (
                    <div className="doc-page" key={`page_${index + 1}`}>
                      <Page
                        pageNumber={index + 1}
                        width={this.state.pdf.width - 100}
                        scale={2}
                        loading={false}
                      />
                  </div>
                  ),
                )}
              </Document>
            </Fragment>
            }
        </div>
        <div className="action_buttons">
          <button onClick={() => this.navigate('prev',this.props.rehire)} className="aplcn-prev-button" disabled={activeDocIndex == 0}>Previous</button>
          { !!(lastPage && this.props?.user?.profile?.data?.is_admin) &&
            <button onClick={() => this.redirect()} className="aplcn-next-btn">
              Approve
            </button>}
          {!lastPage &&
            <button onClick={() => this.navigate('next',this.props.rehire)} className="aplcn-next-btn">
              Next
            </button>}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  let { docs,user } = state;
  return { docs,user };
}

let connectedPdf = compose(withRouter, connect(mapStateToProps))(Pdf)
export { connectedPdf as Pdf };