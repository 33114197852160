import React from "react";
import { connect } from "react-redux";
import { authHeader, config } from "../_helpers";
// import { applicantsActions } from '../_actions';
import { resourceActions, chatActions } from "../_actions";
import "../assets/styles/resource.css";
import { AlertPopup } from "../_components/AlertPopup";

function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
}
class EmployeeVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceId: "",
      hash: "",
      name: "",
      position: "",
      phone: "",
      email: "",
      options: [],
      roles: [],
      alertPop: false,
      docsStatus: "",
      drug_screening_doc: false,
      rehire_docs_completed_status: false,
      docs_completed_status: false,
      i9_doc: false,
      onboarding_doc: false,
      userattributes: {
        drug_screening_doc: 0,
        i9_doc: 0,
        onboarding_doc: 0,
      },
      loading: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.generateCheckbox = this.generateCheckbox.bind(this);
  }

  componentDidMount() {
    const hash = this.props.match.params.hash;
    this.setState({ hash });
    this.props.dispatch(resourceActions.getAll());
    this.props.dispatch(resourceActions.getVerificationDetails(hash));
    this.props.dispatch(chatActions.getConversationSettings());
  }

  componentWillReceiveProps(nextProps) {
    const { resource, employee } = nextProps;
    var options = [];

    if (employee.items) {
      const {
        name,
        phone,
        email,
        position,
        crew_identifier_id,
        status,
        docs_status,
        roles,
        rehire_docs_status,
        rehire_docs_completed_status,
        docs_completed_status,
        userattribute
      } = employee.items.data;

      this.setState({
        name,
        phone,
        email,
        position,
        resourceId: crew_identifier_id,
        status,
        docsStatus: docs_status,
        roles,
        rehire_docs_status,
        rehire_docs_completed_status,
        docs_completed_status,
        loading: false,
        userattributes: userattribute.length > 0 ? userattribute[0] : this.state.userattributes
      });
    }
    if (resource.items) {
      Object.keys(resource.items.data).forEach((key) => {
        if (key && key !== "") {
          resource.items.data[key].map((item, index) => {
            options.push(
              <option key={index} value={item.id}>
                {item.resource_name}
              </option>
            );
          });
        }
        return options;
      });

      this.setState({ options });
    }
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  handleChange(event) {
    this.setState({ resourceId: event.target.value });
  }

  validate(e) {
    let isError = false;
    if (this.state.resourceId === "" || this.state.resourceId === undefined) {
      isError = true;
      this.setState({
        resourceIdError: "error",
      });
    }
    return isError;
  }

  handleStatus(status, rehire = false, approveAll = false) {
    let url,
      data = new FormData();
    const err = this.validate();
    if (!status) {
      data.append("hash", this.state.hash);
      url = "/api/denyemployee";
    } else {
      const err = this.validate();
      if (!err) {
        data.append("hash", this.state.hash);
        data.append("drug_screening_doc", this.state.drug_screening_doc);
        data.append("i9_doc", this.state.i9_doc);
        data.append("onboarding_doc", this.state.onboarding_doc);
        data.append("resourceId", this.state.resourceId);
        if (approveAll) {
          data.append("approve_all", approveAll);
        }
        status === 1
          ? (url = `/api/verification?rehire=${rehire}`)
          : (url = `/api/employee/skipdocs?rehire=${rehire}`);
      }
    }
    if (!err && status === 1) {
      const hash = this.props.match.params.hash;
      var self = this;
      fetch(config.apiUrl + url, {
        headers: authHeader(),
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            self.setState({
              alertPop: true,
              alertHead: "Success",
              alertMsg: JSON.parse(JSON.stringify(data.message)),
              alertFunction: self.props.dispatch(
                resourceActions.getVerificationDetails(hash)
              ),
            });

            let params = {
              uid: this.props.user?.profile?.data?.hash,
              signature: this.props.chat.settings.signature,
            };

            let url = new URL(
              `${config.apiUrl}/docs/download/pdf/${hash}?rehire=${rehire}`
            );
            Object.keys(params).forEach((key) =>
              url.searchParams.append(key, params[key])
            );
            // window.open(url.href);
            window.location.href = url.href; //Added this line to avoid the opening of new tab.
          } else {
            self.setState({
              alertPop: true,
              alertHead: "Error",
              alertMsg: JSON.parse(JSON.stringify(data.message)),
              alertFunction: "",
            });
          }
        });
    } else if (status === 2) {
      fetch(config.apiUrl + url, {
        headers: authHeader(),
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          this.props.history.push(`/profile/${this.state.hash}`);
        });
    }
  }

  handleCheckbox(el) {
    let key = el.name;
    if (el.checked) {
      this.setState({ [key]: true });
    } else {
      this.setState({ [key]: false });
    }
  }

  generateCheckbox() {
    let userattributes = [];
    for (let [key, value] of Object.entries(this.state.userattributes)) {
      let name =""
      switch(key) {
        case "drug_screening_doc":
          name = "Drug Screening"
          break;
        case "i9_doc":
          name = "ID/I9"
          break;
        case "onboarding_doc":
          name = "Onboarding Documents"
          break;
        default:
          break;
      }
      name && userattributes.push(
        <div className="current_qualification_item" key={key}>
          <input
            type="checkbox"
            id={key}
            name={key}
            defaultChecked={value === 1}
            onChange={(e) => this.handleCheckbox(e.currentTarget)}
          />
          <label htmlFor={key}>
            <span />
          </label>
          <span className="wpcf7-list-item-label">{name}</span>
        </div>
      );
    }
    return userattributes;
  }

  render() {
    let buttonGroup = { "margin-top": "3rem" },
      listItem = { "margin-right": "2rem", display: "inline-block" };

    let { loading } = this.state;

    return (
      <div className="resource_container">
        <div className="page_title float">
          <h1>Verify Employee</h1>
        </div>

        {!loading && (
          <div className="resource_list emp_verify_wrap">
            <div className="formField">
              <label>Name:</label>
              <h4 className="txt-capitalize">{this.state.name}</h4>
            </div>
            <div className="formField">
              <label>Position:</label>
              <h4>{this.state.position}</h4>
            </div>
            <div className="formField">
              <label>Resource type *</label>
              <select
                name="resource_type"
                id="resource_type"
                value={this.state.resourceId}
                onChange={this.handleChange}
                errors={this.state.resourceIdError}
              >
                <option key={"a"} value="0">
                  Assign Resource*
                </option>
                {this.state.options}
              </select>
            </div>
            <div className="formField">
              <label>Cell number:</label>
              <h4>
                {this.state.phone ? (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${this.state.phone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(this.state.phone)}
                  </a>
                ) : (
                  ""
                )}
                {/* {formatPhoneNumber(this.state.phone)} */}
              </h4>
            </div>
            <div className="formField">
              <label>Email:</label>
              <h4>
                {this.state.email ? (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`mailto:${this.state.email}`}
                    target="_top"
                  >
                    {this.state.email}
                  </a>
                ) : (
                  ""
                )}
                {/* {this.state.email} */}
              </h4>
            </div>

            {/* Checkboxes */}
            <div className="formField">
              <label>
                Check each box below to indicate it has been submitted and
                verified:
              </label>
              <div className="clearfix">{this.generateCheckbox()}</div>
            </div>
            {this.state.roles.length > 0 && (
              <div className="formField">
                <label>The user have following roles</label>
                {this.state.roles.map((role) => (
                  <h4 style={listItem}>{role}</h4>
                ))}
              </div>
            )}

            {/* Buttons */}

            {this.state.docsStatus === 2 && (
              <div className="formField" style={buttonGroup}>
                <button
                  className="btn button grey create_manifest marg-right-20"
                  onClick={() => this.handleStatus(0, false)}
                >
                  Deny
                </button>
                <button
                  className="btn button create_manifest"
                  onClick={() => this.handleStatus(1, false)}
                >
                  Approve &amp; Download (Onboarding)
                </button>
              </div>
            )}

            {this.state.rehire_docs_status === 2 && (
              <div className="formField" style={buttonGroup}>
                <button
                  className="btn button grey create_manifest marg-right-20"
                  onClick={() => this.handleStatus(0, true)}
                >
                  Deny
                </button>
                <button
                  className="btn button create_manifest"
                  onClick={() => this.handleStatus(1, true)}
                >
                  Approve &amp; Download (Rehire)
                </button>
              </div>
            )}

            <div className="verification-buttons" style={buttonGroup}>

              {(this.state.docsStatus === 1 ||
                this.state.docsStatus === -1 ||
                this.state.docsStatus === 0) &&
                this.state.roles.length === 0 && (
                  <button
                    className="btn button create_manifest verification-button"
                    onClick={() => this.handleStatus(2, false)}
                  >
                    Skip Docs &amp; Approve (Onboarding)
                  </button>
                )}
              {
                this.state.docsStatus === -1 &&
                this.state.docs_completed_status ? (
                  <button
                    className="btn button create_manifest verification-button"
                    onClick={() => this.handleStatus(1, false, true)}
                  >
                    Approve Documents (Onboarding)
                  </button>
                ) :
                this.state.rehire_docs_status === -1 &&
                this.state.rehire_docs_completed_status ? (
                  <button
                    className="btn button create_manifest verification-button"
                    onClick={() => this.handleStatus(1, true, true)}
                  >
                    Approve Documents (Rehire)
                  </button>
                ) :
                this.state.roles.length !== 0 ? (
                  <p className=" emp_status verification-button">
                    User roles already assigned and there is no pending docs to
                    approve
                  </p>
                ) : 
                (
                  ""
                )
              }

              {/* Commented out temporarily */}
              {/* {this.state.docsStatus === 3 && (this.state.rehire_docs_status === 1 || this.state.rehire_docs_status === -1 || this.state.rehire_docs_status === 0) && this.state.roles.length === 0 && (
              <button className="btn button create_manifest verification-button" onClick={() => this.handleStatus(2, true)}>
                Skip Docs &amp; Approve (Rehire)
              </button>
            )} */}
              {/* Messages */}
              {this.state.docsStatus === 1 && (
                <p className=" emp_status verification-button">
                  Please fill out all the Onboarding documents.
                </p>
              )}
              {this.state.rehire_docs_status === 1 && (
                <p className=" emp_status verification-button">
                  Please fill out all the Rehire documents.
                </p>
              )}
              {/* this.state.docsStatus === -1 && <p className=" emp_status">Please contact support team.</p> */}
              {this.state.docsStatus === 3 && (
                <p className=" emp_status verification-button">
                  Onboarding documents already approved
                </p>
              )}
              {this.state.rehire_docs_status === 3 && (
                <p className=" emp_status verification-button">
                  Rehire documents already approved
                </p>
              )}
            </div>
          </div>
        )}

        {this.state.alertPop === true && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { resource, employee, chat, user } = state;
  return {
    resource,
    employee,
    chat,
    user,
  };
}

const connectedResource = connect(mapStateToProps)(EmployeeVerification);
export { connectedResource as EmployeeVerification };
