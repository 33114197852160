import { authHeader, config } from "../_helpers";

export const dashboardService = {
  fetchActivityLog,
};

function fetchActivityLog(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + "/api/activity", requestOptions).then(
    handleResponse,
    handleError
  );
}

function handleResponse(response) {
    return new Promise((resolve, reject) => {
      if (response.ok) {
        // return json if it was returned in the response
        var contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          response.json().then((json) => resolve(json));
        } else {
          resolve();
        }
      } else {
        // return error message from response body
        response.text().then((text) => reject(text));
      }
    });
  }
  
  function handleError(error) {
    return Promise.reject(error && error.message);
  }