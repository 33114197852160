import React from "react";

const NoInterviewFormAlert = () => {
  return (
    <div
      style={{
        padding: 5,
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <i
        style={{ color: "rgb(245, 124, 0)", marginRight: 8, marginTop: 4 }}
        className="fas fa-exclamation-triangle"
      ></i>
      <p style={{ margin: 0, textAlign: "left" }}>
        This applicant can't be hired since no interview forms are available.
      </p>
    </div>
  );
};

export default NoInterviewFormAlert;
