export const dashboardConstants = {

    GETBULLETIN_REQUEST: 'DASHBOARD_GETBULLETIN_REQUEST',
    GETBULLETIN_SUCCESS: 'DASHBOARD_GETBULLETIN_SUCCESS',
    GETBULLETIN_FAILURE: 'DASHBOARD_GETBULLETIN_FAILURE',

    // VIEWAPPLN_REQUEST: 'DASHBOARD_VIEWAPPLN_REQUEST',
    // VIEWAPPLN_SUCCESS: 'DASHBOARD_VIEWAPPLN_SUCCESS',
    // VIEWAPPLN_FAILURE: 'DASHBOARD_VIEWAPPLN_FAILURE',

    ACTIVITY_LOG_REQUEST:"ACTIVITY_LOG_REQUEST",
    ACTIVITY_LOG_SUCCESS:"ACTIVITY_LOG_SUCCESS",
    ACTIVITY_LOG_ERROR:"ACTIVITY_LOG_ERROR"

};
