import { resourceConstants } from '../_constants';

export function employee(state = {}, action) {
  switch (action.type) {
    case resourceConstants.EMPVER_REQUEST:
      return {
        loading: true
      };
    case resourceConstants.EMPVER_SUCCESS:
      return {
        items: action.employee
      };
    case resourceConstants.EMPVER_FAILURE:
      return { 
        error: action.error
      };
    case resourceConstants.GETEMPLOYEES_REQUEST:
    return {
      loading: true
    };
    case resourceConstants.GETEMPLOYEES_SUCCESS:
    return {
      list: action.employee
    };
    case resourceConstants.GETEMPLOYEES_FAILURE:
    return { 
      error: action.error
    };
      
    default:
      return state
  }
}