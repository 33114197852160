import { resourceConstants } from '../_constants';

export function resource(state = {}, action) {
	switch (action.type) {

		case resourceConstants.GETALL_REQUEST:
		return {
			loading: true
		};
		case resourceConstants.GETALL_SUCCESS:
		return {
			items: action.resource
		};
		case resourceConstants.GETALL_FAILURE:
		return { 
			error: action.error
		};
// ----------------------------------------------------------
		case resourceConstants.GET_RESOURCE_TYPE_REQUEST:
		return {
			loading: true
		};
		case resourceConstants.GET_RESOURCE_TYPE_SUCCESS:
		return {
			resourceTypes: action.resourceTypes
		};
		case resourceConstants.GET_RESOURCE_TYPE_FAILURE:
		return { 
			error: action.error
		};
// ----------------------------------------------------------
		case resourceConstants.GETPROJECTS_REQUEST:
		return {
			loading: true
		};
		case resourceConstants.GETPROJECTS_SUCCESS:
		return {
			projects: action.projects
		};
		case resourceConstants.GETPROJECTS_FAILURE:
		return { 
			error: action.error
		};
// ------------------------------------------------------------
		case resourceConstants.GETARCHIVEDRESOURCES_REQUEST:
		return {
			loading: true
		};
		case resourceConstants.GETARCHIVEDRESOURCES_SUCCESS:
		return {
			archived: {...action.resources}
		};
		case resourceConstants.GETARCHIVEDRESOURCES_FAILURE:
		return { 
			error: action.error
		};

		case resourceConstants.GETDRIVER_REQUEST:
		return {
			loading: true
		};
		case resourceConstants.GETDRIVER_SUCCESS:
		return {
			driver: action.driver
		};
		case resourceConstants.GETDRIVER_FAILURE:
		return { 
			error: action.error
		};

		case resourceConstants.SETPOS_REQUEST:
		return {
			loading: true
		};
		case resourceConstants.SETPOS_SUCCESS:
		return {
			position: action.position
		};
		case resourceConstants.SETPOS_FAILURE:
		return { 
			error: action.error
		};     

		case resourceConstants.UNARCHIVE_REQUEST:
			return {
				loading: true
			};
		case resourceConstants.UNARCHIVE_SUCCESS:
			return {
				unarchive: action.unarchive
			};
		case resourceConstants.UNARCHIVE_FAILURE:
			return { 
				error: action.error
			};  


		case resourceConstants.UNARCHIVE_RESOURCE_REQUEST:
			return {
				loading: true
			};
		case resourceConstants.UNARCHIVE_RESOURCE_SUCCESS:
			return {
				unarchive_index: action.index
			};
		case resourceConstants.UNARCHIVE_RESOURCE_FAILURE:
			return { 
				error: action.error
			};  



		case resourceConstants.REMOVERES_REQUEST:
			return {
				loading: true
			};
		case resourceConstants.REMOVERES_SUCCESS:
			return {
				remove: action.remove
			};
		case resourceConstants.REMOVERES_FAILURE:
			return { 
				error: action.error
			}; 	 



		case resourceConstants.DELETE_RESOURCE_IMAGE_REQUEST:
			return {
				loading: true
			};
		case resourceConstants.DELETE_RESOURCE_IMAGE_SUCCESS:
			return {
				loading:false
			};
		case resourceConstants.DELETE_RESOURCE_IMAGE_FAILURE:
			return { 
				loading:false
			}; 	 


		case resourceConstants.GET_EMPLOYMENT_HISTORY_REQUEST:
			return {
				...state,
				isEmploymentHistoryLoading: true
			};
		case resourceConstants.GET_EMPLOYMENT_HISTORY_SUCCESS:
			return {
				...state,
				isEmploymentHistoryLoading: false,
				employmentHistory: action?.payload?.data
			};
		case resourceConstants.GET_EMPLOYMENT_HISTORY_FAILURE:
			return {
				...state,
				isEmploymentHistoryLoading: false,
				employmentHistory: null
			}; 	 
		default:
		return state
	}
}