import React from "react";
import { connect } from "react-redux";
import { authHeader, config } from "../_helpers";
import { applicantsActions } from "../_actions";
import { permissionActions } from "../_actions";
import "../assets/styles/applicants.css";
import moment from "moment";
import ReactModal from "react-modal";
import { AlertPopup } from "../_components/AlertPopup";
import { toast } from "react-toastify";
import NoInterviewFormAlert from "_components/NoInterviewFormAlert/NoInterviewFormAlert";


ReactModal.setAppElement("#app");

function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
}

const applicantStatuses = {
  "NOT_SELECTED_AND_ARCHIVED": "remove",
  "RECEIVED": "received",
  "WAS_HIRED": "hire",
  "IN_REVIEW": "inreview",
  "OFFERED_POSITION": "offered",
  "DO_NOT_SELECT_AND_ARCHIVE": "donthire",
}

class ViewApplicant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusUpdating:false,
      flag: true,
      applicationItem: [],
      notes: "",
      statusOpen: false,
      removeStatusOpen: false,
      removeAction: "",
      hash: this.props.hash,
      email: this.props.email,
      selected: "",
      applicant_list: [],
      hideSearch: "hide",
      user_list: [],
      alertPop: false,
      borderError: "",
      alertPop: false,
      appication_history: [],
    };
    this.prevNext = this.prevNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.headerSearch = this.headerSearch.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.removeStatusOpenModal = this.removeStatusOpenModal.bind(this);
    this.removeStatusCloseModal = this.removeStatusCloseModal.bind(this);
    this.removeApplicant = this.removeApplicant.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.forwardApplicant = this.forwardApplicant.bind(this);
    this.keyUpFn = this.keyUpFn.bind(this);
    this.goBack = this.goBack.bind(this);
    this.fileDownload = this.fileDownload.bind(this);
    this.userClick = this.userClick.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.checkNull = this.checkNull.bind(this);
  }

  // statusOpenModal() {
  //     this.setState({ statusOpen: true });
  // }

  statusOpenModal(hash, status, selectedApplicantNoInterviewForms) {
    this.setState({
      statusOpen: true,
      removeApplicant: hash,
      currentStatusUpdate: status > -1 ? status : "",
      selectedApplicantNoInterviewForms
    });
  }

  statusCloseModal() {
    this.setState({ statusOpen: false, statusUpdating: false, selectedApplicantNoInterviewForms: false });
  }

  removeStatusOpenModal(status) {
    this.setState({ removeStatusOpen: true, currentStatusUpdate: status });
  }

  removeStatusCloseModal() {
    this.setState({ removeStatusOpen: false });
    this.props?.setEnableStatusModal(false);
  }

  editApplicant(hash) {
    this.props.editApplicant(this.state.hash);
  }

  viewHistory() {
    this.props.viewHistory(this.state.hash);
  }

  // isActive(value){
  //     return ((value===this.state.selected) ?'active':'default');
  // }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  fileDownload(files) {
    //
    if (files.length > 0) {
      files.map((file, index) => {
        const response = {
          file: config.apiUrl + "/" + file.file_hash,
        };
        // this.newWindow(response.file)
        setTimeout(function () {
          window.open(response.file);
        }, 1);
      });
    }
  }

  // newWindow(url){
  //   
  //   let link     = document.createElement('a');
  //   link.href    = url;
  //   link.target  = '_blank';
  //   link.click();
  // }

  componentDidMount() {
    this.props.dispatch(applicantsActions.viewDetails(this.state.hash));
    this.props.dispatch(permissionActions.getPermission());
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  componentWillReceiveProps(nextProps) {
    const applicants = nextProps.applicants.list;
    const { allAdmin } = nextProps.search;
    var user_list = [];

    // Dispalys success alert message
    if (applicants) {
      if (applicants.success) {
        this.setState({
          hash: applicants.data.hash,
          email: applicants.data.email_address,
          applicant_list: applicants,
          notes: applicants.data.notes,
          appication_history: applicants.data.appication_history,
        });
      } else {
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: JSON.parse(JSON.stringify(applicants.message)),
          alertFunction: this.goBack,
        });
      }
    }
    if (allAdmin) {
      const userSearchList = allAdmin.data;
      if (userSearchList) {
        userSearchList.data.map((item, index) => {
          var name = item.first_name + " " + item.last_name;
          user_list.push(
            <li className="user_click" onClick={this.userClick.bind(this, name, item.email, item.hash)} key={index}>
              <span>{item.first_name + " " + item.last_name}</span>
              <img
                src={
                  item.profile_photo ? config.apiUrl + "/" + item.profile_photo : config.apiUrl + "/" + config.altImg
                }
                onError={this.onImageError.bind(this)}
              />
            </li>
          );
        });
        this.setState({ user_list });
      }
    }
    if (nextProps.enableStatusModal){
      this.removeStatusOpenModal(applicants ? applicants.data.applicant_status : "");
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  keyUpFn(e) {
    var textInput = document.getElementById("txtSearchUsersForForward");
    clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(
      function () {
        this.headerSearch(textInput.value);
      }.bind(this),
      1000
    );
  }

  headerSearch(text) {
    var content = "";
    let data = new FormData();
    data.append("keyword", text);

    if (text.length === 0) {
      this.setState({ hideSearch: "hide" });
    } else if (text.length >= 1) {
      this.setState({ hideSearch: "show", search_close: "show" });
      this.props.dispatch(permissionActions.adminSearch(data));
    } else {
      this.setState({ formShow: "show" });
    }
    this.props.dispatch(permissionActions.getPermission());
  }

  userClick(name, email, hash, event) {
    this.setState({ to: email, user_hash: hash });
    document.getElementById("txtSearchUsersForForward").value = name;
    this.setState({ hideSearch: "hide" });
  }

  updateNotes(hash) {
    let data = new FormData();
    data.append("hash", hash);
    data.append("note", this.state.notes);
    this.props.dispatch(applicantsActions.updateNoteSingle(data, hash));
  }

  goBack() {
    this.props.goBack();
  }

  print() {
    window.print();
  }

  printManifest() {
    var hash = this.props.hash;
    var data = "?hash=" + hash + "";
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(config.apiUrl + "/api/printapplicant" + data, requestOptions)
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        const pdfLink = data.data;

        var link = document.createElement("a");
        if (window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i)) {
          link.href = pdfLink;
        } else {
          link.href = pdfLink;
          link.target = "_blank";
        }
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          document.body.removeChild(link);
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(pdfLink);
        }, 100);
      });
  }

  sendMail() {
    this.props.sendMail(this.state.email);
  }

  chooseAction(action) {
    var status = {
      denied: 0,
      received: 1,
      hire: 2,
      inreview: 3,
      offered: 4,
      donthire: 10,
      remove: 0,
    };
    this.setState({
      removeAction: action,
      selected: action,
      currentStatusUpdate: status[action],
      validMsg: "",
      borderError: "",
    });
  }

  // set active class for selected option
  isActive(value) {
    var status = {
      0: "remove",
      1: "received",
      2: "hire",
      3: "inreview",
      4: "offered",
      10: "donthire",
    };
    if (this.state.currentStatusUpdate > -1 && status.hasOwnProperty(this.state.currentStatusUpdate)) {
      if (status[this.state.currentStatusUpdate] === value) {
        return "active";
      }
    }
    return "";
    // return value === this.state.selected ? "active" : "default";
  }

  validate() {
    var err = false;
    if (this.state.removeAction === "") {
      return true;
    }
  }

  checkNull(value) {
    let flag = false;
    if (value === null) {
      flag = true;
    } else if (value === "") {
      flag = true;
    } else if (value === "null") {
      flag = true;
    } else if (value === undefined) {
      flag = true;
    }
    return flag;
  }
  prevNext(hash) {
    this.props.dispatch(applicantsActions.viewDetails(hash));
  }

  removeApplicant() {

    if(
      (this.state.removeAction === applicantStatuses.OFFERED_POSITION ||
        this.state.removeAction === applicantStatuses.WAS_HIRED) &&
        !this?.state?.applicant_list?.data?.interview_form_count
    ){
      return toast.error(" The applicant hasn't filled out the interview form.")
    }

    var err = this.validate();
    if (!err) {
      this.setState({ statusUpdating: true })
      let data = new FormData();
      data.append("hash", this.state.hash);
      data.append("action", this.state.removeAction);
      this.props.dispatch(applicantsActions.removeApplicant(data))?.then(res => {
        if (res?.success) {
          this.props?.goBack();
          this.statusCloseModal();
        }
        else {
          this.updateApplicantStatusErrorToast(res?.message)
        }
      })?.catch(err => {
        this.updateApplicantStatusErrorToast(err?.message)
      })
    } else {
      this.setState({
        borderError: "borderError",
        validMsg: "Please choose an option!",
      });
    }
  }

  updateApplicantStatusErrorToast(message) {
    toast?.error(message);
    this.setState({ statusUpdating: false })
  }


  forwardApplicant() {
    let data = new FormData();
    this.statusCloseModal();
    data.append("hash", this.state.hash);
    data.append("to", this.state.to);

    var self = this;
    fetch(config.apiUrl + "/api/forwardapplicant", {
      headers: authHeader(),
      method: "POST",
      body: data,
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        if (data.success) {
          self.setState({
            alertPop: true,
            alertHead: "Success",
            alertMsg: JSON.parse(JSON.stringify(data.message)),
            alertFunction: "",
          });
        } else {
          self.setState({
            alertPop: true,
            alertHead: "Error",
            alertMsg: JSON.parse(JSON.stringify(data.message)),
            alertFunction: "",
          });
        }
      });
  }

  render() {
    const applicants = this.props.applicants.list;
    let university1 = [],
      university2 = [],
      license = [],
      reference1 = [],
      reference2 = [],
      reference3 = [],
      professional1 = [],
      professional2 = [],
      professional3 = [],
      applying_position = [],
      qualifications = [],
      reference1_flag = false,
      reference2_flag = false,
      reference3_flag = false,
      professional1_flag = false,
      professional2_flag = false,
      professional3_flag = false;

    let prevNext = [];
    if (applicants && applicants.success) {
      if (this.props.applnType === "") {
        if (applicants && applicants.data.previous !== null) {
          prevNext.push(
            <p key="prev" className="gotoPrev left" onClick={this.prevNext.bind(this, applicants.data.previous.hash)}>
              <span>Previous Applicant: </span>
              {applicants.data.previous.first_name + " " + applicants.data.previous.last_name}{" "}
            </p>
          );
        }
        if (applicants && applicants.data.next !== null) {
          prevNext.push(
            <p key="next" className="gotoNext right" onClick={this.prevNext.bind(this, applicants.data.next.hash)}>
              <span>Next Applicant: </span>
              {applicants.data.next.first_name + " " + applicants.data.next.last_name}{" "}
            </p>
          );
        }
      }
    }
    function checkProperties(obj) {
      for (var key in obj) {
        if (key !== "id") {
          if (obj[key] !== null && obj[key] != "" && obj[key] != "undefined" && obj[key] != "null") return false;
        }
      }
      return true;
    }

    const { permission } = this.props;
    let permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }

    if (applicants && applicants.success) {
      var notes;
      if (this.state.notes !== null) {
        notes = this.state.notes;
      } else {
        notes = "";
      }

      let dateObj,
        reference1_start_date_Obj,
        reference1_end_date_Obj,
        dateObj1,
        dateObj2,
        reference2_start_date_Obj,
        reference2_end_date_Obj,
        dateObj3,
        dateObj4,
        reference3_start_date_Obj,
        dateObj5,
        reference3_end_date_Obj,
        reference1_date,
        reference2_date,
        reference3_date;

      if (applicants.data.reference1 && applicants.data.reference1.start_date !== "") {
        reference1_start_date_Obj = moment(applicants.data.reference1.start_date).format("MM/DD/YY");
      } else {
        reference1_start_date_Obj = "";
      }

      if (applicants.data.reference1 && applicants.data.reference1.end_date !== "") {
        reference1_end_date_Obj = moment(applicants.data.reference1.end_date).format("MM/DD/YY");
      } else {
        reference1_end_date_Obj = "";
      }

      if (applicants.data.reference2 && applicants.data.reference2.start_date !== "") {
        reference2_start_date_Obj = moment(applicants.data.reference2.start_date).format("MM/DD/YY");
      } else {
        reference2_start_date_Obj = "";
      }

      if (applicants.data.reference2 && applicants.data.reference2.end_date !== "") {
        reference2_end_date_Obj = moment(applicants.data.reference2.end_date).format("MM/DD/YY");
      } else {
        reference2_end_date_Obj = "";
      }

      if (applicants.data.reference3 && applicants.data.reference3.start_date !== "") {
        reference3_start_date_Obj = moment(applicants.data.reference3.start_date).format("MM/DD/YY");
      } else {
        reference3_start_date_Obj = "";
      }

      if (applicants.data.reference3 && applicants.data.reference3.end_date !== "") {
        reference3_end_date_Obj = moment(applicants.data.reference3.end_date).format("MM/DD/YY");
      } else {
        reference3_end_date_Obj = "";
      }

      if (reference3_start_date_Obj !== "" && reference3_end_date_Obj !== "") {
        reference3_date = reference3_start_date_Obj + " - " + reference3_end_date_Obj;
      } else {
        reference3_date = "";
      }
      if (reference2_start_date_Obj !== "" && reference2_end_date_Obj !== "") {
        reference2_date = reference2_start_date_Obj + " - " + reference2_end_date_Obj;
      } else {
        reference2_date = "";
      }
      if (reference1_start_date_Obj !== "" && reference1_end_date_Obj !== "") {
        reference1_date = reference1_start_date_Obj + " - " + reference1_end_date_Obj;
      } else {
        reference1_date = "";
      }
      if (checkProperties(applicants.data.university1)) {
        university1.push(
          <p key="university1" id="avTechSchool1" className="av-answer av-answer-clear">
            <span className="av-empty">Did not fill out</span>
          </p>
        );
      } else {
        university1.push(
          <div key="university1" id="avTechSchool1" className="av-answer av-answer-clear">
            {applicants.data.university1.name !== "null" && applicants.data.university1.name !== "undefined" && (
              <p className="av-empty">{applicants.data.university1.name}</p>
            )}
            {applicants.data.university1.duration !== "null" &&
              applicants.data.university1.duration !== "undefined" && (
                <p className="av-empty">{applicants.data.university1.duration}</p>
              )}
            {applicants.data.university1.course !== "null" && applicants.data.university1.course !== "undefined" && (
              <p className="av-empty">{applicants.data.university1.course}</p>
            )}
            {applicants.data.university1.gratuate_status !== "null" &&
              applicants.data.university1.gratuate_status !== "undefined" && (
                <p className="av-empty">{applicants.data.university1.gratuate_status}</p>
              )}
          </div>
        );
      }
      if (checkProperties(applicants.data.university2)) {
        university2.push(
          <p key="university2" id="avTechSchool1" className="av-answer av-answer-clear">
            <span className="av-empty">Did not fill out</span>
          </p>
        );
      } else {
        university2.push(
          <div key="university2" id="avTechSchool2" className="av-answer av-answer-clear">
            {applicants.data.university2.name !== "null" && applicants.data.university2.name !== "undefined" && (
              <p className="av-empty">{applicants.data.university2.name}</p>
            )}
            {applicants.data.university2.duration !== "null" &&
              applicants.data.university2.duration !== "undefined" && (
                <p className="av-empty">{applicants.data.university2.duration}</p>
              )}
            {applicants.data.university2.course !== "null" && applicants.data.university2.course !== "undefined" && (
              <p className="av-empty">{applicants.data.university2.course}</p>
            )}
            {applicants.data.university2.gratuate_status !== "null" &&
              applicants.data.university2.gratuate_status !== "undefined" && (
                <p className="av-empty">{applicants.data.university2.gratuate_status}</p>
              )}
          </div>
        );
      }

      if (checkProperties(applicants.data.license)) {
        license.push(
          <p key="license" id="avLicense" className="av-answer av-answer-clear">
            <span className="av-empty">Did not fill out</span>
          </p>
        );
      } else {
        license.push(
          <div key="license" id="avLicense" className="av-answer av-answer-clear">
            {applicants.data.license.state_id && applicants.data.license.state_id !== "null" && (
              <p className="av-empty">{applicants.data.license.state_id}</p>
            )}
            {applicants.data.license.no && applicants.data.license.no !== "null" && (
              <p className="av-empty">{applicants.data.license.no}</p>
            )}
            {applicants.data.license.exp_date && applicants.data.license.exp_date !== "null" && (
              <p className="av-empty">{applicants.data.license.exp_date}</p>
            )}
            {applicants.data.license.class && applicants.data.license.class !== "null" && (
              <p className="av-empty">{applicants.data.license.class}</p>
            )}
            {applicants.data.license.endorsement && applicants.data.license.endorsement !== "null" && (
              <p className="av-empty">{applicants.data.license.endorsement}</p>
            )}
          </div>
        );
      }

      if (
        applicants.data.reference1 &&
        applicants.data.reference1.last_employee &&
        !this.checkNull(applicants.data.reference1.last_employee)
      ) {
        reference1_flag = true;
        reference1.push(
          <div key="reference" id="avReference1" className="av-answer av-answer-clear">
            <span>{applicants.data.reference1.last_employee}</span>
            {applicants.data.reference1.address &&
              applicants.data.reference1.address !== "null" &&
              applicants.data.reference1.address !== null &&
              applicants.data.reference1.address !== "" && (
                <p className="view_inner_p">
                  {applicants.data.reference1.address}
                  <br />
                </p>
              )}
            {applicants.data.reference1.city_id &&
              applicants.data.reference1.city_id !== "null" &&
              applicants.data.reference1.city_id !== null &&
              applicants.data.reference1.city_id !== "" && <p>{applicants.data.reference1.city_id}&nbsp;</p>}
            {applicants.data.reference1.state_id &&
              applicants.data.reference1.state_id !== "null" &&
              applicants.data.reference1.state_id !== null &&
              applicants.data.reference1.state_id !== "" && (
                <p className="view_inner_p">{applicants.data.reference1.state_id}</p>
              )}
            {applicants.data.reference1.position &&
              applicants.data.reference1.position !== "null" &&
              applicants.data.reference1.position !== null &&
              applicants.data.reference1.position !== "" && <p>{applicants.data.reference1.position}</p>}
            {reference1_date && <p className="view_inner_p">{reference1_date}</p>}
            {applicants.data.reference1.supervisor_name &&
              applicants.data.reference1.supervisor_name !== "null" &&
              applicants.data.reference1.supervisor_name !== null &&
              applicants.data.reference1.supervisor_name !== "" && <p>{applicants.data.reference1.supervisor_name}</p>}
            {applicants.data.reference1.alt_contact_name &&
              applicants.data.reference1.alt_contact_name !== "null" &&
              applicants.data.reference1.alt_contact_name !== null &&
              applicants.data.reference1.alt_contact_name !== "" && (
                <p>{applicants.data.reference1.alt_contact_name}</p>
              )}
            {applicants.data.reference1.telephone &&
              applicants.data.reference1.telephone !== "null" &&
              applicants.data.reference1.telephone !== null &&
              applicants.data.reference1.telephone !== "" && (
                <p>
                  {/* {formatPhoneNumber(
                    applicants.data.reference1.telephone + " (phone)"
                  )} */}
                  (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${applicants.data.reference1.telephone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(applicants.data.reference1.telephone + " (phone)")}
                  </a>
                  )
                </p>
              )}
            {applicants.data.reference1.fax &&
              applicants.data.reference1.fax !== "null" &&
              applicants.data.reference1.fax !== null &&
              applicants.data.reference1.fax !== "" && (
                <p className="view_inner_p">{applicants.data.reference1.fax + " (fax)"}</p>
              )}
            {applicants.data.reference1.responsibilities &&
              applicants.data.reference1.responsibilities !== "null" &&
              applicants.data.reference1.responsibilities !== null &&
              applicants.data.reference1.responsibilities !== "" && (
                <p className="view_inner_p">
                  <span>Responsabilities:</span>
                  <br />
                  <span>{applicants.data.reference1.responsibilities}</span>
                </p>
              )}
            {applicants.data.reference1.reason_for_leaving &&
              applicants.data.reference1.reason_for_leaving !== "null" &&
              applicants.data.reference1.reason_for_leaving !== null &&
              applicants.data.reference1.reason_for_leaving !== "" && (
                <p className="view_inner_p">
                  <span>Reason for Leaving:</span>
                  <br />
                  <span>{applicants.data.reference1.reason_for_leaving}</span>
                </p>
              )}
          </div>
        );
      } else {
        reference1_flag = false;
        reference1.push(
          <p key="reference" id="avReference1" className={`av-answer av-answer-clear`}>
            <span className={`av-empty ${!reference1_flag ? "disabled" : ""}`}>Did not fill out</span>
          </p>
        );
      }
      //
      if (
        applicants.data.reference2 &&
        applicants.data.reference2.last_employee &&
        !this.checkNull(applicants.data.reference2.last_employee)
      ) {
        reference2_flag = true;
        reference2.push(
          <div key="reference2" id="avReference2" className="av-answer av-answer-clear">
            <p>{applicants.data.reference2.last_employee}</p>
            {applicants.data.reference2.address &&
              applicants.data.reference2.address !== "null" &&
              applicants.data.reference2.address !== null &&
              applicants.data.reference2.address !== "" && (
                <p className="view_inner_p">{applicants.data.reference2.address}</p>
              )}
            {applicants.data.reference2.city_id &&
              applicants.data.reference2.city_id !== "null" &&
              applicants.data.reference2.city_id !== null &&
              applicants.data.reference2.city_id !== "" && <p>{applicants.data.reference2.city_id}&nbsp;</p>}
            {applicants.data.reference2.state_id &&
              applicants.data.reference2.state_id !== "null" &&
              applicants.data.reference2.state_id !== null &&
              applicants.data.reference2.state_id !== "" && (
                <p className="view_inner_p">{applicants.data.reference2.state_id}</p>
              )}
            {applicants.data.reference2.position &&
              applicants.data.reference2.position !== "null" &&
              applicants.data.reference2.position !== null &&
              applicants.data.reference2.position !== "" && <p>{applicants.data.reference2.position}</p>}
            {reference2_date && <p className="view_inner_p">{reference2_date}</p>}
            {applicants.data.reference2.supervisor_name &&
              applicants.data.reference2.supervisor_name !== "null" &&
              applicants.data.reference2.supervisor_name !== null &&
              applicants.data.reference2.supervisor_name !== "" && <p>{applicants.data.reference2.supervisor_name}</p>}
            {applicants.data.reference2.alt_contact_name &&
              applicants.data.reference2.alt_contact_name !== "null" &&
              applicants.data.reference2.alt_contact_name !== null &&
              applicants.data.reference2.alt_contact_name !== "" && (
                <p>{applicants.data.reference2.alt_contact_name}</p>
              )}
            {applicants.data.reference2.telephone &&
              applicants.data.reference2.telephone !== "null" &&
              applicants.data.reference2.telephone !== null &&
              applicants.data.reference2.telephone !== "" && (
                <p>
                  (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${applicants.data.reference2.telephone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(applicants.data.reference2.telephone + " (phone)")}
                  </a>
                  )
                  {/* {formatPhoneNumber(applicants.data.reference2.telephone) +
                    " (phone)"} */}
                </p>
              )}
            {applicants.data.reference2.fax &&
              applicants.data.reference2.fax !== "null" &&
              applicants.data.reference2.fax !== null &&
              applicants.data.reference2.fax !== "" && (
                <p className="view_inner_p">{applicants.data.reference2.fax + " (fax)"}</p>
              )}
            {applicants.data.reference2.responsibilities &&
              applicants.data.reference2.responsibilities !== "null" &&
              applicants.data.reference2.responsibilities !== null &&
              applicants.data.reference2.responsibilities !== "" && (
                <p className="view_inner_p">
                  <span>Responsabilities:</span>
                  <br />
                  <span>{applicants.data.reference2.responsibilities}</span>
                </p>
              )}
            {applicants.data.reference2.reason_for_leaving &&
              applicants.data.reference2.reason_for_leaving !== "null" &&
              applicants.data.reference2.reason_for_leaving !== null &&
              applicants.data.reference2.reason_for_leaving !== "" && (
                <p className="view_inner_p">
                  <span>Reason for Leaving:</span>
                  <br />
                  <span>{applicants.data.reference2.reason_for_leaving}</span>
                </p>
              )}
          </div>
        );
      } else {
        reference2_flag = false;
        reference2.push(
          <p key="reference2" id="avReference2" className="av-answer av-answer-clear">
            <span className={`av-empty ${!reference2_flag ? "disabled" : ""}`}>Did not fill out</span>
          </p>
        );
      }
      //

      if (
        applicants.data.reference3 &&
        applicants.data.reference3.last_employee &&
        !this.checkNull(applicants.data.reference3.last_employee)
      ) {
        reference3_flag = true;
        reference3.push(
          <div key="reference3" id="avReference3" className="av-answer av-answer-clear">
            <p>{applicants.data.reference3.last_employee}</p>
            {applicants.data.reference3.address &&
              applicants.data.reference3.address !== "null" &&
              applicants.data.reference3.address !== null &&
              applicants.data.reference3.address !== "" && (
                <p className="view_inner_p">{applicants.data.reference3.address}</p>
              )}
            {applicants.data.reference3.city_id &&
              applicants.data.reference3.city_id !== "null" &&
              applicants.data.reference3.city_id !== null &&
              applicants.data.reference3.city_id !== "" && <p>{applicants.data.reference3.city_id}&nbsp;</p>}
            {applicants.data.reference3.state_id &&
              applicants.data.reference3.state_id !== "null" &&
              applicants.data.reference3.state_id !== null &&
              applicants.data.reference3.state_id !== "" && (
                <p className="view_inner_p">{applicants.data.reference3.state_id}</p>
              )}
            {applicants.data.reference3.position &&
              applicants.data.reference3.position !== "null" &&
              applicants.data.reference3.position !== null &&
              applicants.data.reference3.position !== "" && <span>{applicants.data.reference3.position}</span>}
            {reference3_date && <p className="view_inner_p">{reference3_date}</p>}
            {applicants.data.reference3.supervisor_name &&
              applicants.data.reference3.supervisor_name !== "null" &&
              applicants.data.reference3.supervisor_name !== null &&
              applicants.data.reference3.supervisor_name !== "" && <p>{applicants.data.reference3.supervisor_name}</p>}
            {applicants.data.reference3.alt_contact_name &&
              applicants.data.reference3.alt_contact_name !== "null" &&
              applicants.data.reference3.alt_contact_name !== null &&
              applicants.data.reference3.alt_contact_name !== "" && (
                <p>{applicants.data.reference3.alt_contact_name}</p>
              )}
            {applicants.data.reference3.telephone &&
              applicants.data.reference3.telephone !== "null" &&
              applicants.data.reference3.telephone !== null &&
              applicants.data.reference3.telephone !== "" && (
                <p>
                  (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${applicants.data.reference3.telephone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(applicants.data.reference3.telephone + " (phone)")}
                  </a>
                  )
                  {/* {formatPhoneNumber(
                    applicants.data.reference3.telephone + " (phone)"
                  )} */}
                </p>
              )}
            {applicants.data.reference3.fax &&
              applicants.data.reference3.fax !== "null" &&
              applicants.data.reference3.fax !== null &&
              applicants.data.reference3.fax !== "" && (
                <p className="view_inner_p">{applicants.data.reference3.fax + " (fax)"}</p>
              )}
            {applicants.data.reference3.responsibilities &&
              applicants.data.reference3.responsibilities !== "null" &&
              applicants.data.reference3.responsibilities !== null &&
              applicants.data.reference3.responsibilities !== "" && (
                <p className="view_inner_p">
                  <span>Responsabilities:</span>
                  <br />
                  <span>{applicants.data.reference3.responsibilities}</span>
                </p>
              )}
            {applicants.data.reference3.reason_for_leaving &&
              applicants.data.reference3.reason_for_leaving !== "null" &&
              applicants.data.reference3.reason_for_leaving !== null &&
              applicants.data.reference3.reason_for_leaving !== "" && (
                <p className="view_inner_p">
                  <span>Reason for Leaving:</span>
                  <br />
                  <span>{applicants.data.reference3.reason_for_leaving}</span>
                </p>
              )}
          </div>
        );
      } else {
        reference3_flag = false;
        reference3.push(
          <p key="reference3" id="avReference3" className="av-answer av-answer-clear">
            <span className={`av-empty ${!reference3_flag ? "disabled" : ""}`}>Did not fill out</span>
          </p>
        );
      }

      if (checkProperties(applicants.data.personalreference1)) {
        professional1_flag = false;
        professional1.push(
          <p key="personal" id="avProfessionalReference1" className="av-answer av-answer-clear">
            <span className={`av-empty ${!professional1_flag ? "disabled" : ""}`}>Did not fill out</span>
          </p>
        );
      } else {
        professional1_flag = true;
        professional1.push(
          <div key="personal" id="avProfessionalReference1" className="av-answer av-answer-clear">
            {applicants.data.personalreference1.name && applicants.data.personalreference1.name !== "null" && (
              <p className="av-empty">{applicants.data.personalreference1.name}</p>
            )}
            {applicants.data.personalreference1.relationship &&
              applicants.data.personalreference1.relationship !== "null" && (
                <p className="av-empty">{applicants.data.personalreference1.relationship}</p>
              )}
            {applicants.data.personalreference1.phone && applicants.data.personalreference1.phone !== "null" && (
              <p className="av-empty">
                (
                <a
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  className="phone_email_link"
                  href={`tel:${applicants.data.personalreference1.phone}`}
                  target="_top"
                >
                  {formatPhoneNumber(applicants.data.personalreference1.phone)}
                </a>
                ){/* {formatPhoneNumber(applicants.data.personalreference1.phone)} */}
              </p>
            )}
            {applicants.data.personalreference1.known && applicants.data.personalreference1.known !== "null" && (
              <p className="av-empty">{applicants.data.personalreference1.known}</p>
            )}
          </div>
        );
      }

      if (checkProperties(applicants.data.personalreference2)) {
        professional2_flag = false;
        professional2.push(
          <p key="personal1" id="avProfessionalReference2" className="av-answer av-answer-clear">
            <span className={`av-empty ${!professional2_flag ? "disabled" : ""}`}>Did not fill out</span>
          </p>
        );
      } else {
        professional2_flag = true;
        professional2.push(
          <div key="personal1" id="avProfessionalReference2" className="av-answer av-answer-clear">
            {applicants.data.personalreference2.name && applicants.data.personalreference2.name !== "null" && (
              <p className="av-empty">{applicants.data.personalreference2.name}</p>
            )}
            {applicants.data.personalreference2.relationship &&
              applicants.data.personalreference2.relationship !== "null" && (
                <p className="av-empty">{applicants.data.personalreference2.relationship}</p>
              )}
            {applicants.data.personalreference2.phone &&
              applicants.data.personalreference2.phone !== "null" &&
              formatPhoneNumber(applicants.data.personalreference2.phone) && (
                <p className="av-empty">
                  (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${applicants.data.personalreference2.phone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(applicants.data.personalreference2.phone)}
                  </a>
                  ){/* {formatPhoneNumber(applicants.data.personalreference2.phone)} */}
                </p>
              )}
            {applicants.data.personalreference2.known && applicants.data.personalreference2.known !== "null" && (
              <p className="av-empty">{applicants.data.personalreference2.known}</p>
            )}
          </div>
        );
      }

      if (checkProperties(applicants.data.personalreference3)) {
        professional2_flag = false;
        professional3.push(
          <p key="personal2" id="avProfessionalReference3" className="av-answer av-answer-clear">
            <span className={`av-empty ${!professional3_flag ? "disabled" : ""}`}>Did not fill out</span>
          </p>
        );
      } else {
        professional2_flag = true;
        professional3.push(
          <div key="personal2" id="avProfessionalReference3" className="av-answer av-answer-clear">
            {applicants.data.personalreference3.name && applicants.data.personalreference3.name !== "null" && (
              <p className="av-empty">{applicants.data.personalreference3.name}</p>
            )}
            {applicants.data.personalreference3.relationship &&
              applicants.data.personalreference3.relationship !== "null" && (
                <p className="av-empty">{applicants.data.personalreference3.relationship}</p>
              )}
            {applicants.data.personalreference3.phone && applicants.data.personalreference3.phone !== "null" && (
              <p className="av-empty">
                (
                <a
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  className="phone_email_link"
                  href={`tel:${applicants.data.personalreference3.phone}`}
                  target="_top"
                >
                  {formatPhoneNumber(applicants.data.personalreference3.phone)}
                </a>
                ){/* {formatPhoneNumber(applicants.data.personalreference3.phone)} */}
              </p>
            )}
            {applicants.data.personalreference3.known && applicants.data.personalreference3.known !== "null" && (
              <p className="av-empty">{applicants.data.personalreference3.known}</p>
            )}
          </div>
        );
      }
      if (
        applicants.data.applying_position_id !== null &&
        applicants.data.applying_position_id !== "null" &&
        applicants.data.applying_position_id !== ""
      ) {
        applicants.data.applying_position.map((item, index) => {
          applying_position.push(<p key={index}>{item}</p>);
        });
      } else {
        applying_position.push(
          <span key="empty" className="av-empty">
            Did not fill out
          </span>
        );
      }

      if (
        applicants.data.qualification_id !== null &&
        applicants.data.qualification_id !== "null" &&
        applicants.data.qualification_id !== ""
      ) {
        applicants.data.qualification.map((item, index) => {
          qualifications.push(<p key={index}>{item}</p>);
        });
      } else {
        qualifications.push(
          <span key="empty" className="av-empty">
            Did not fill out
          </span>
        );
      }
    }
    //

    
    return (
      <div className="applicants_container application_details">
        {/* <div className="breadcrumbs">
          <span onClick={this.goBack.bind(this)} className="green">
            Applicants
          </span>{" "}
          / View Applicant
        </div> */}
        <div className="applicant_hdr_btn_grp">
          <h3 className="left">Job Application</h3>
          <div className="top_btn_group new_btn_grp flex-group">
          {/* {permList.includes("Manage Passwords") && (
            <ChangePassword userType="applicant" user={applicants?.data} isAdmin={true} />
          )}
          {permList.includes("Email Applicants") && (
            <button className="button" onClick={this.sendMail.bind(this)}>
              Email
            </button>
          )} */}

          {permList.includes("Manage Applicants") && (
            <button className="button" onClick={this.editApplicant.bind(this, this.props.hash)}>
              Edit
            </button>
          )}
          {/* {permList.includes("Manage Applicants") && (
            <button
              className="button"
              onClick={this.removeStatusOpenModal.bind(this, applicants ? applicants.data.applicant_status : "")}
            >
              Update Status
            </button>
          )} */}

          {permList.includes("Manage Applicants") && (
            <button className="button" onClick={this.printManifest.bind(this)}>
              Print
            </button>
          )}

          {permList.includes("Manage Applicants") && (
            <button className="button" onClick={this.statusOpenModal.bind(this)}>
              Forward
            </button>
          )}
          </div>
        </div>
        <div className="applicants_inner_head">
          {this.state.appication_history.length > 0 && (
            <div className="previous_history" onClick={this.viewHistory.bind(this)}>
              <img src={require("../assets/images/history-clock-button.svg").default} alt="" />
              <span>View Previous History</span>
            </div>
          )}
        </div>
        <div className="inner_container col-lg-12" id="applicants_inner">
          <div className="applicantNavigation">{prevNext}</div>
          <div className="clearfix" />
          <div className="applicant-view-container">
            <h3 className="left">
              Full Application:{" "}
              {applicants
                ? applicants.data.first_name + " " + applicants.data.middle_initial + " " + applicants.data.last_name
                : ""}
            </h3>
            <h3 className="right">
              Date Applied: {applicants ? moment(applicants.data.date_applied).format("MM/DD/YY") : ""}
            </h3>

            <textarea
              name="notes"
              className="single-notes"
              placeholder="Notes..."
              value={notes && notes !== "null" ? notes : ""}
              rows="3"
              cols="20"
              onBlur={this.updateNotes.bind(this, this.state.hash)}
              onChange={this.handleChange}
            >
              {notes}
            </textarea>

            <div className="left col-50 applicant-view-left">
              <h4 className="av-title">Basic Information</h4>

              <p className="av-question">Where you are applying for?</p>
              <p id="avCompanyName" className="av-answer av-answer-clear">
                {applicants && Number(applicants.data.applying_company) === 1
                  ? "About Trees"
                  : "North Valley Tree Service"}
              </p>
              <p className="av-question">
                How did you hear about{" "}
                {applicants && Number(applicants.data.applying_company) === 1
                  ? "About Trees"
                  : "North Valley Tree Service"}
                ?
              </p>
              <p id="avSourceOfKnowledge" className="av-answer av-answer-clear">
                {applicants ? applicants.data.source_of_knowledge : ""}
              </p>
              <p id="avEmpName" className="av-answer">
                {applicants &&
                applicants.data.nvam_employee !== "" &&
                applicants.data.nvam_employee !== null &&
                applicants.data.nvam_employee !== "null" &&
                applicants.data.nvam_employee !== ""
                  ? applicants.data.nvam_employee
                  : ""}
              </p>
              <br />
              <p className="av-question">What position are you applying for?</p>
              <p id="avPositionApplyingFor" className="av-answer av-answer-clear">
                {applicants && applicants.data.position_applying_for ? (
                  applicants.data.position_applying_for
                ) : (
                  <span key="empty" className="av-empty">
                    Did not fill out{" "}
                  </span>
                )}
              </p>
              <br />
              <div>
                <div className="left col-50">
                  <p id="avName" className="av-answer">
                    {applicants
                      ? applicants.data.first_name +
                        " " +
                        applicants.data.middle_initial +
                        " " +
                        applicants.data.last_name
                      : ""}
                  </p>
                  <p id="avAddress1" className="av-answer">
                    {applicants &&
                    applicants.data.mailing_address1 !== null &&
                    applicants.data.mailing_address1 !== "null" &&
                    applicants.data.mailing_address1 !== ""
                      ? applicants.data.mailing_address1
                      : ""}
                  </p>
                  <p id="avAddress2" className="av-answer">
                    {applicants &&
                    applicants.data.mailing_address2 !== null &&
                    applicants.data.mailing_address2 !== "null" &&
                    applicants.data.mailing_address2 !== ""
                      ? applicants.data.mailing_address2
                      : ""}
                  </p>
                  <p id="avAddress2" className="av-answer">
                    {applicants &&
                    applicants.data &&
                    applicants.data.city_id !== null &&
                    applicants.data.city_id !== "null" &&
                    applicants.data.city_id !== ""
                      ? `${applicants.data.city_id}, `
                      : ""}
                    {applicants &&
                    applicants.data &&
                    applicants.data.state_id !== null &&
                    applicants.data.state_id !== "null" &&
                    applicants.data.state_id !== ""
                      ? applicants.data.state_id
                      : ""}
                  </p>
                  <p id="avCityStateZip" className="av-answer">
                    {applicants &&
                    applicants.data.zip_id !== null &&
                    applicants.data.zip_id !== "null" &&
                    applicants.data.zip_id !== ""
                      ? applicants.data.zip_id
                      : ""}
                  </p>
                </div>
                <div className="right col-50">
                  {applicants &&
                    applicants.data.gender !== null &&
                    applicants.data.gender !== "null" &&
                    applicants.data.gender !== "" && (
                      <p id="avGender" className="av-answer">
                        Gender: <span className="av-empty">{applicants.data.gender}</span>
                      </p>
                    )}
                  {applicants &&
                    applicants.data.race !== null &&
                    applicants.data.race !== "null" &&
                    applicants.data.race !== "" && (
                      <p id="avRace" className="av-answer" ng-if="user.type  == 1">
                        Race: <span className="av-empty">{applicants.data.race}</span>
                      </p>
                    )}
                  {applicants &&
                    applicants.data.veteran !== null &&
                    applicants.data.veteran !== "null" &&
                    applicants.data.veteran !== "" && (
                      <p id="avVeteran" className="av-answer" ng-if="user.type  == 1">
                        Veteran: <span className="av-empty">{applicants.data.veteran}</span>
                      </p>
                    )}
                </div>
                <div className="clearfix" />
                <br />
                <p id="avEmail" className="av-answer">
                  {applicants &&
                  applicants.data.email_address !== "null" &&
                  applicants.data.email_address !== null &&
                  applicants.data.email_address !== "" ? (
                    <a
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      className="phone_email_link"
                      href={`mailto:${applicants.data.email_address}`}
                      target="_top"
                    >
                      {applicants.data.email_address}
                    </a>
                  ) : (
                    ""
                  )}
                </p>
                <p id="avPhone" className="av-answer">
                  {applicants &&
                  applicants.data.phone_number !== "null" &&
                  applicants.data.phone_number !== null &&
                  applicants.data.phone_number !== "" ? (
                    <a
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      className="phone_email_link"
                      href={`tel:${applicants.data.phone_number}`}
                      target="_top"
                    >
                      {formatPhoneNumber(applicants.data.phone_number + "(Main)")}
                    </a>
                  ) : (
                    //   formatPhoneNumber(applicants.data.phone_number)+' (Main)'
                    ""
                  )}
                </p>
                <p id="avSecPhone" className="av-answer">
                  {applicants &&
                  applicants.data.secondary_phone !== "" &&
                  applicants.data.secondary_phone !== null &&
                  applicants.data.secondary_phone !== "null" ? (
                    <a
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      className="phone_email_link"
                      href={`tel:${applicants.data.secondary_phone}`}
                      target="_top"
                    >
                      {formatPhoneNumber(applicants.data.secondary_phone + "(Other)")}
                    </a>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <br />

              {/* <p className="av-question"> // Disabled for NVAM clone
                Are you willing to be on call 24 hours a day, 7 days a week,
                work long shifts, go weeks without a day off, endure adverse..
                Etc.?
              </p>
              <p id="avQuestion1" className="av-answer av-answer-clear">
                {applicants && applicants.data.question1 ? (
                  applicants.data.question1
                ) : (
                    <span className="av-empty">Did not fill out</span>
                  )}
              </p>
              <br />

              <p className="av-question">
                Are physically fit to perform duties required to be a NVAM?
              </p>
              <p id="avQuestion2" className="av-answer av-answer-clear">
                {applicants && applicants.data.question2 ? (
                  applicants.data.question2
                ) : (
                    <span className="av-empty">Did not fill out</span>
                  )}
              </p>
              <br /> */}

              <p className="av-question">Will you comply to our company drug and smoking policies?</p>
              <p id="avQuestion3" className="av-answer av-answer-clear">
                {applicants && applicants.data.question3 ? (
                  applicants.data.question3
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              <br />

              <h4 className="av-title">Education and History</h4>

              <p className="av-question">High School:</p>
              {applicants && applicants.data.highschool_name ? (
                <p id="avHighSchool" className="av-answer av-answer-clear">
                  <span className="av-empty">
                    {applicants &&
                    applicants.data.highschool_name !== "null" &&
                    applicants.data.highschool_name !== null &&
                    applicants.data.highschool_name !== ""
                      ? applicants.data.highschool_name
                      : ""}
                  </span>
                  {applicants &&
                  applicants.data.highschool_duration !== "null" &&
                  applicants.data.highschool_duration !== null &&
                  applicants.data.highschool_duration !== "" ? (
                    <span className="av-empty">
                      {applicants.data.highschool_duration}
                      <br />
                    </span>
                  ) : (
                    <span className="av-empty">
                      Did not fill out
                      <br />
                    </span>
                  )}

                  <span className="av-empty">
                    {applicants &&
                    applicants.data.highschool_course !== "null" &&
                    applicants.data.highschool_course !== null &&
                    applicants.data.highschool_course !== ""
                      ? applicants.data.highschool_course
                      : ""}
                  </span>
                  <span className="av-empty">
                    {applicants &&
                    applicants.data.highschool_gratuate_status !== "null" &&
                    applicants.data.highschool_gratuate_status !== null &&
                    applicants.data.highschool_gratuate_status !== ""
                      ? applicants.data.highschool_gratuate_status
                      : ""}
                  </span>
                  <br />
                </p>
              ) : (
                <p id="avHighSchool" className="av-answer av-answer-clear">
                  <span className="av-empty">
                    Did not fill out
                    <br />
                  </span>
                </p>
              )}

              <p className="av-question">University / Tech School:</p>
              {university1}
              <br />

              <p className="av-question">University / Tech School:</p>
              {university2}
              <br />

              <p className="av-question">Additional Information:</p>
              <p id="avAdditionalInfo" className="av-answer av-answer-clear">
                {applicants &&
                applicants.data.applicant_info !== null &&
                applicants.data.applicant_info !== "null" &&
                applicants.data.applicant_info !== "" ? (
                  applicants.data.applicant_info
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              <br />

              <p className="av-question">Have you worked for NVAM or Firestorm in the past?</p>
              <p id="avNVAMPastWork" className="av-answer av-answer-clear">
                {applicants &&
                applicants.data.worked_for_nvam !== null &&
                applicants.data.worked_for_nvam !== "null" &&
                applicants.data.worked_for_nvam !== "" ? (
                  applicants.data.worked_for_nvam
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              {applicants &&
              applicants.data &&
              applicants.data.which_company &&
              applicants.data.which_company !== null &&
              applicants.data.which_company !== "null" &&
              applicants.data.which_company !== "" ? (
                <div>
                  <p className="av-question">Company:</p>
                  <p className="av-answer av-answer-clear">{applicants.data.which_company}</p>
                </div>
              ) : (
                ""
              )}
              {applicants &&
              applicants.data.reason_leave_nvam !== null &&
              applicants.data.reason_leave_nvam !== "null" &&
              applicants.data.reason_leave_nvam !== "" ? (
                <p className="av-answer av-answer-clear">
                  {applicants.data.reason_leave_nvam}
                  <br />
                </p>
              ) : (
                ""
              )}

              {!reference1_flag && !reference2_flag && !reference3_flag && (
                <h4 className="av-title">No past work experiences</h4>
              )}
              <p className={`av-question ${!reference1_flag ? "disabled" : ""}`}>
                Past Experiences / Employment Record / References - #1:
              </p>
              {reference1}
              <br />

              <p className={`av-question ${!reference2_flag ? "disabled" : ""}`}>
                Past Experiences / Employment Record / References - #2
              </p>
              {reference2}
              <br />

              <p className={`av-question ${!reference3_flag ? "disabled" : ""}`}>
                Past Experiences / Employment Record / References - #3
              </p>
              {reference3}
              <br />

              {!professional1_flag && !professional2_flag && !professional3_flag && (
                <h4 className="av-title">No past professional reference</h4>
              )}
              <p className={`av-question ${!professional1_flag ? "disabled" : ""}`}>Professional Reference - #1:</p>
              {professional1}
              <br />

              <p className={`av-question ${!professional2_flag ? "disabled" : ""}`}>Professional Reference - #2:</p>
              {professional2}
              <br />

              <p className={`av-question ${!professional3_flag ? "disabled" : ""}`}>Professional Reference - #3:</p>
              {professional3}
              <br />
            </div>

            <div className="right col-50 applicant-view-right">
              <h4 className="av-title">Availability and Experience</h4>

              {/* <p className="av-question">Current Certification Status:</p> // Disabled for NVAM clone
              <p id="avCertStatus" className="av-answer av-answer-clear">
                {applicants && applicants.data.certification_status ? (
                  applicants.data.certification_status
                ) : (
                    <span className="av-empty">Did not fill out</span>
                  )}
              </p>
              <br /> */}

              <p className="av-question">Qualification:</p>
              <p id="avGenQualification" className="av-answer av-answer-clear">
                {applicants && applicants.data.general_qualification ? (
                  applicants.data.general_qualification
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              <br />

              {/* <p className="av-question"> // Removed for NVAM clone
                Anticipated Living Location for the Season:
              </p>
              <p id="avAnticipatedLoc" className="av-answer av-answer-clear">
                {applicants && applicants.data.anticipated_location ? (
                  applicants.data.anticipated_location
                ) : (
                    <span className="av-empty">Did not fill out</span>
                  )}
              </p>
              <br /> */}

              {/* <p className="av-question">Anticipated relocation date:</p> // Removed for NVAM clone
              <p
                id="avAnticipatedLocDate"
                className="av-answer av-answer-clear"
              >
                {applicants && applicants.data.anticipated_relocation ? (
                  moment(applicants.data.anticipated_relocation).format(
                    "MM/DD/YY"
                  )
                ) : (
                    <span className="av-empty">Did not fill out</span>
                  )}
              </p>
              <br /> */}

              <p className="av-question">Desired Reporting Location:</p>
              <p id="avReportingLoc" className="av-answer av-answer-clear">
                {applicants && applicants.data.reportinglocation ? (
                  applicants.data.reportinglocation
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              <br />

              <p className="av-question">Position Applying for:</p>
              <div id="avApplyingPosition" className="av-answer av-answer-clear">
                {applying_position}
              </div>
              <br />

              {/* <p className="av-question">Position to be Considered:</p>  // Disabled for NVAM
              <p id="avPositionOn" className="av-answer av-answer-clear">
                {applicants &&
                applicants.data.position_on_id !== null &&
                applicants.data.position_on_id !== "null" &&
                applicants.data.position_on_id !== "" ? (
                  applicants.data.position_on
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              <br /> */}

              <p className="av-question">Current Qualifications:</p>
              <div id="avQualification" className="av-answer av-answer-clear">
                {qualifications}
              </div>
              <br />

              <p className="av-question">Copies of Qualification / Certificates:</p>
              <div id="fileQualificatns" className="av-answer av-answer-clear">
                {applicants && applicants.success && applicants.data.filequalification.length > 0 ? (
                  <div>
                    <p>{applicants.data.filequalification.length + " attached files"} </p>

                    <button
                      className="button"
                      type="button"
                      onClick={this.fileDownload.bind(this, applicants.data.filequalification)}
                    >
                      Download attachments
                    </button>
                  </div>
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </div>
              <br />

              <h4 className="av-title">Driving History</h4>

              <p className="av-question">Drivers License</p>
              {license}
              <br />

              <p className="av-question">
                Have you ever been denied a license, permit or privilege to operate a motor vehicle?
              </p>
              <p id="avDeniedLicense" className="av-answer av-answer-clear">
                {applicants &&
                applicants.data.denied_license !== "null" &&
                applicants.data.denied_license !== null &&
                applicants.data.denied_license !== "" ? (
                  applicants.data.denied_license
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              <br />

              <p className="av-question">Has any license, permit or privilege ever been revoked?</p>
              <div id="avRevokedLicense" className="av-answer av-answer-clear">
                {applicants &&
                applicants.data.license_revoked !== "null" &&
                applicants.data.license_revoked !== null &&
                applicants &&
                applicants.data.license_revoked !== "" ? (
                  applicants.data.license_revoked
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
                <br />
                {applicants &&
                applicants.data.reason_license_revoked !== "null" &&
                applicants.data.reason_license_revoked !== null &&
                applicants.data.reason_license_revoked !== "" ? (
                  <p>
                    <br />
                    <span>Explain:</span> <br />
                    <span>{applicants.data.reason_license_revoked}</span>
                  </p>
                ) : (
                  ""
                )}
              </div>
              <br />
            </div>
          </div>
          <div className="clearfix" />
        </div>

        <ReactModal
          isOpen={this.state.statusOpen}
          onClose={this.statusCloseModal.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="ForwardPopup"
        >
          <div className="modal-head">
            <h4 className="modal-title">Forward Application</h4>
            <img
              className="closeModal"
              onClick={this.statusCloseModal.bind(this)}
              src={require("../assets/images/close_green.png").default}
            />
          </div>
          <div className="modal-body" id="allAdminPopup">
            <div className="status_step step1">
              <label>Search User:</label>
              <br />
              <input
                name="to"
                className="txtSearchUsersForForward"
                id="txtSearchUsersForForward"
                onKeyUp={this.keyUpFn}
                autoComplete="off"
              />
              <ul id="searchResultTop" className={this.state.hideSearch}>
                {this.state.user_list}
              </ul>
              <div className="btn_list">
                <button className="button grey" onClick={this.statusCloseModal.bind(this)}>
                  Cancel
                </button>
                <button className="button" onClick={this.forwardApplicant.bind(this)}>
                  Email
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={this.state.removeStatusOpen}
          onClose={this.removeStatusCloseModal.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="RemoveApplntPopup"
        >
          <div className="modal-head">
            <h4>Update Applicant Status</h4>
            <img
              className="closeModal"
              onClick={() => this?.state?.statusUpdating ? {} : this.removeStatusCloseModal()}
              src={require("../assets/images/close_green.png").default}
            />
          </div>
          <div id="remove_applnt" className="modal-body">
            {!this?.state?.applicant_list?.data?.interview_form_count ? <NoInterviewFormAlert /> : null}

            {/*<h4>Remove Applicant {}</h4>*/}
            <div className="rmOptionBox float">
              <div
                className={this.isActive("received") + " rmOptionWrap col-lg-333 " + this.state.borderError}
                onClick={this.chooseAction.bind(this, "received")}
              >
                <div className="rmOption">Received</div>
              </div>

              <div
                className={this.isActive("inreview") + " rmOptionWrap col-lg-333 " + this.state.borderError}
                onClick={this.chooseAction.bind(this, "inreview")}
              >
                <div className="rmOption">In Review</div>
              </div>

              <div
                className={this.isActive("offered") + " rmOptionWrap col-lg-333 " + this.state.borderError}
                onClick={this.chooseAction.bind(this, "offered")}
              >
                <div className="rmOption">Offered Position</div>
              </div>

              <div
                className={this.isActive("hire") + " rmOptionWrap col-lg-333 " + this.state.borderError}
                onClick={this.chooseAction.bind(this, "hire")}
              >
                <div className="rmOption">Was Hired</div>
              </div>

              <div
                className={this.isActive("remove") + " rmOptionWrap col-lg-333 " + this.state.borderError}
                onClick={this.chooseAction.bind(this, "remove")}
              >
                <div className="rmOption">Not Selected & Archive</div>
              </div>
              <div
                className={this.isActive("donthire") + " rmOptionWrap col-lg-333 " + this.state.borderError}
                onClick={this.chooseAction.bind(this, "donthire")}
              >
                <div className="rmOption">Do Not Hire & Archive</div>
              </div>
            </div>

            {/* <p className="sig_message">"Send Email" will automatically inform the applicant of denial.</p> */}
            <button disabled={this?.state?.statusUpdating}
              className="button grey" onClick={this.removeStatusCloseModal.bind(this)}>
              Cancel
            </button>
            <button disabled={this?.state?.statusUpdating}
              className="button" onClick={this.removeApplicant.bind(this)}>
              Update
            </button>
          </div>
        </ReactModal>

        {this.state.alertPop && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            viewOpen="true"
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { applicants, permission, search } = state;
  return {
    applicants,
    permission,
    search,
  };
}

const connectedHomePage = connect(mapStateToProps)(ViewApplicant);
export { connectedHomePage as ViewApplicant };
