import { authHeader, config } from "../_helpers";

export const inventoryService = {
  getListEquipments,
  getInventoryStats,
  createInventory,
  getList,
  equipmentDetails,
  getMemberList,
  assignEquipment,
  getAvailableList,
  getCrewAllList,
  getInventoryList,
  deleteInventory,
  forceDeleteInventory,
  deleteInvFile,
  getUploadUrl,
  uploadToStore,
  printInventory,
  getArchivedList,
  unArchiveInventory,
  getPaginatedList,
  uploadExcelToServer,
  deleteMileageFile,
  addNote,
  getNotes
};

function printInventory(data, id) {

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + `/api/inventory/pdf/${id}`, requestOptions).then(
    handleResponse,
    handleError
  );

}

function getListEquipments() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(config.apiUrl + "/api/getlistequipment", requestOptions).then(
    handleResponse,
    handleError
  );
}

function getArchivedList(options) {

  let { keyword, type } = options;

  let data = new FormData();

  keyword && data.append("keyword", keyword);
  type && data.append("type", type);
  

  data.append("option", "Archived");

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/inventory/getlist", requestOptions).then(
    handleResponse,
    handleError
  );
}

function uploadToStore(data, url) {

  const requestOptions = {
    method: "PUT",
    headers: {
      'x-amz-acl': 'public-read',
      'Content-Type': 'image/jpg; charset=utf-8'
    },
    body: data
  };

  return fetch(url, requestOptions).then(
    handleResponse,
    handleError
  );
}

function getUploadUrl(data) {

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/inventory/getsignedurl", requestOptions).then(
    handleResponse,
    handleError
  );

}



function getInventoryStats(data = "") {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    config.apiUrl + "/api/stats/inventory_stats" + data,
    requestOptions
  ).then(handleResponse, handleError);
}

function createInventory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/inventory/create", requestOptions).then(
    handleResponse,
    handleError
  );
}

function deleteInventory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/inventory/delete", requestOptions).then(
    handleResponse,
    handleError
  );
}

function forceDeleteInventory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/inventory/forcedelete", requestOptions).then(
    handleResponse,
    handleError
  );
}

function unArchiveInventory(options) {

  let { id } = options;

  let data = new FormData();
  data.append('id', id)

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/inventory/unarchivequipment", requestOptions).then(
    handleResponse,
    handleError
  );
}

function getList(data = "") {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/inventory/getlist", requestOptions).then(
    handleResponse,
    handleError
  );
}

function equipmentDetails(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    config.apiUrl + "/api/inventory/equipmentdetails/" + id,
    requestOptions
  ).then(handleResponse, handleError);
}

function getPaginatedList(data) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  let { id, page } = data;

  return fetch(
    config.apiUrl + `/api/inventory/getlistpaginated?crew_identifier_id=${id}&page=${page}`,
    requestOptions
  ).then(handleResponse, handleError);
}

function getMemberList(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(
    config.apiUrl + "/api/inventory/getcrewlist",
    requestOptions
  ).then(handleResponse, handleError);
}

function assignEquipment(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(
    config.apiUrl + "/api/inventory/assignequipment",
    requestOptions
  ).then(handleResponse, handleError);
}
function getCrewAllList(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(
    config.apiUrl + "/api/inventory/getcrewalllist",
    requestOptions
  ).then(handleResponse, handleError);
}

function getAvailableList(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(
    config.apiUrl + "/api/inventory/getavailablelist",
    requestOptions
  ).then(handleResponse, handleError);
}

function getInventoryList(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(
    config.apiUrl + "/api/inventory/getcrewmemberlist",
    requestOptions
  ).then(handleResponse, handleError);
}

function deleteInvFile(id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: id
  };

  return fetch(
    config.apiUrl + "/api/inventory/deletefile",
    requestOptions
  ).then(handleResponse, handleError);
}

function uploadExcelToServer(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/inventory/importdata", requestOptions).then(
    handleResponse,
    handleError
  );
}


function deleteMileageFile(id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: id
  };

  return fetch(
    config.apiUrl + "/api/inventory/deleteinventoryfile",
    requestOptions
  ).then(handleResponse, handleError);
}

function addNote(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/inventory/add-note", requestOptions).then(
    handleResponse,
    handleError
  );
}

function getNotes(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(config.apiUrl + "/api/inventory/list-note", requestOptions).then(
    handleResponse,
    handleError
  );
}


function handleResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      // return json if it was returned in the response
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then(json => resolve(json));
      } else {
        resolve();
      }
    } else {
      // return error message from response body
      response.text().then(text => reject(text));
    }
  });
}

function handleError(error) {
  return Promise.reject(error && error.message);
}
