import React from "react";
import PropTypes from "prop-types";
import styles from "./radioButton.module.css";


const RadioButton = ({ label, onChange, rootStyles = {}, ...props }) => {
  return (
    <div className={styles.radioButtonWrap} style={rootStyles}>
      <input type="radio" className={styles.radioInput}  onChange={event => {
        onChange?.(event)
      }} {...props} />
      <label className={styles.radiolabel}>{label}</label>
    </div>
  );
};

export default RadioButton;

RadioButton.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

