import React from "react";
import { config } from "../_helpers";
import prettySize from "prettysize";
import moment from 'moment';
import OutsideClickHandler from 'react-outside-click-handler';
import ReactModal from 'react-modal';

function Spinner(params) {
  return (
    <React.Fragment>
      <span className="downloadAnim">
        <img
          src={`${config.assetUrl}icons/circle.svg`}
          className="spinner fa-spin"
        />
      </span>
      <span className="download-complete hide">
        <img
          src={`${config.assetUrl}icons/save-tick.svg`}
          className="closeIco"
        />
      </span>
    </React.Fragment>
  );
}

function Tick({ status }) {
  return (
    <span className={`download-complete ${status === 'sent' ? '' : 'hide'}`}>
      <img src={`${config.assetUrl}icons/download-file.svg`} className="closeIco downloadIco" alt=""/>
    </span>
  );
}

class FileMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editOptions: false,
      deletePopup: false,
      formats: {
        pdf: ['pdf'],
        word: ['doc', 'docx'],
        excel: ['xls', 'xlsx'],
        powerpoint: ['ppt', 'pptx'],
        archive: ['zip', 'rar'],
        csv: ['csv'],
        audio: ['mp3', 'aac', 'wav', 'ogg']
      }
    };
    this.getFileType = this.getFileType.bind(this);
    this.deleteMessage = this.deleteMessage.bind(this);
    this.parseJson = this.parseJson.bind(this);
  }

  getFileType(extension) {
    let type = ''
    for (let key in this.state.formats) {
      if (
        key &&
        this.state.formats[key] &&
        this.state.formats[key].includes(extension)) {
        type = '-' + key;
        break;
      }
    }
    return type;
  }

  deleteMessage() {
    this.props.deleteMessage(this.props.message);
    this.setState({ deletePopup: false })
  }

  parseJson(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }

  render() {

    let { message, settings, user, admin, permissions } = this.props;
    let { body, status, sender, created_at, conversation_id, deleted_at } = message;

    let { profile } = user;

    body = this.parseJson(body);

    return (
      <div className="msg-users">
        <ReactModal
          isOpen={this.state.deletePopup}
          className="delete-modal"
          overlayClassName="delete-modal-overlay"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="deleteMsg">Are you sure you want to delete this message?</h4>
            </div>
            <div className="modal-footer">
              <div className="modal-btn-actions">
                <button type="button" onClick={() => { this.setState({ deletePopup: false, editOptions: false }) }} className="btn msg-cancel-btn">Cancel</button>
                <button type="button" className="btn msg-btn" onClick={this.deleteMessage}>Delete</button>
              </div>
            </div>
          </div>
        </ReactModal>
        <div className="msg-user-pic">
          <img
            alt=""
            src={`${config.baseUrl}${sender.profile_photo}`}
            onError={e => {
              e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
            }}
          />
        </div>
        {
          deleted_at && deleted_at != null ?
            <div className="msg-messageData">
              <div className="msg-name">
                {profile && sender.id == profile.data.id ? 'You' : `${sender.first_name} ${sender.last_name}`}
              </div>
              <div className="time">{moment(created_at).format('h:mm A')}</div>
              <div className="msg-full">
                {body}
              </div>
            </div>
            :
            <div className="msg-messageData">
              <div className="msg-name">
                {profile && sender.id == profile.data.id ? 'You' : `${sender.first_name} ${sender.last_name}`}
              </div>
              <div className="time">{moment(created_at).format('h:mm A')}</div>

              {body.type.includes("image") ? (
                <div className="msg-full">
                  <div className="image-container">
                    {status == "sent" ? (
                      <a href={`${config.baseUrl}chat/message/getfile/${body.file}?id=${conversation_id}&uid=${profile.data.hash}&signature=${settings.signature}`} target="_blank">
                        <div>
                          <img className="msg-image" src={`${config.baseUrl}chat/message/getfile/${body.thumb}?id=${conversation_id}&uid=${profile.data.hash}&signature=${settings.signature}`} />
                        </div>
                      </a>
                    ) : (
                        <div>
                          <img src={body.thumb} className="msg-image"></img>
                          <Spinner />
                        </div>
                      )}
                  </div>
                </div>
              ) : (
                  <div className="msg-full">
                    <a href={status == "sent" ?`${config.baseUrl}chat/message/getfile/${body.file}?id=${conversation_id}&uid=${profile.data.hash}&signature=${settings.signature}`:`#`} target={status == 'sent'?`_blank`:''} className="documemt-link">
                      <span className="doc-icon">
                        <i className={`fas fa-file${this.getFileType(body.extension)}`}></i>
                        {/* <img src={`${config.assetUrl}icons/doc-icon.svg`} /> */}
                      </span>
                      <span className="doc-details">
                        {body.file_name}
                        <span className="doc-size">
                          {prettySize(body.size)} {body.extension}
                        </span>
                      </span>
                      {
                        status == "sent" ?
                          <Tick status={status} /> :
                          <Spinner />
                      }
                    </a>
                  </div>
                )}
            </div>

        }

        {
          admin.active && permissions.includes('Delete messages') &&
          <React.Fragment>
            {
              this.state.editOptions ?
                <OutsideClickHandler onOutsideClick={() => { this.setState({ editOptions: false }) }}>
                  <div className="msg-action">
                    <button onClick={() => { this.setState({ deletePopup: true, editOptions: false }) }} className="btn btn-default btn-delete"><i className="fas fa-trash-alt"></i> Delete Message</button>
                  </div>
                </OutsideClickHandler>
                :
                !deleted_at ?
                  <div className="msg-modify">
                    <a onClick={() => { this.setState({ editOptions: true }) }} id="msg-edit"><img src={`${config.assetUrl}icons/msg-modify.svg`} /></a>
                  </div>
                  : ''
            }
          </React.Fragment>
        }
      </div>
    );
  }
}

export default FileMessage;
