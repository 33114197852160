import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import footerLogo from "../assets/images/nvam.png";

class Position extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      captain: false,
    };
    this.goBack = this.goBack.bind(this);
    this.information = this.information.bind(this);
  }

  componentDidMount() {}

  information(position) {
    // if(position === "Superintendent / Engine Captain"){ // Removed for NVAM clone
    //     this.setState({ captain: true})
    // }
    // else{
    //     this.setState({ captain: false})
    //     this.props.information(this.props.type, position)
    // }
    this.props.information(this.props.type, position);
  }

  goBack() {
    this.props.toResourceType();
  }

  render() {
    return (
      <div className="resource_type">
        <div className="login_header">
          <Link to={"/login"}>
            <img src={footerLogo} alt="NVAM" />
          </Link>
          <p>Employee Management System</p>
        </div>

        <div className="top_head">
          <span className="back" onClick={this.goBack}>
            Back
          </span>
          <span className="title">Step 2 - Choose Position</span>
        </div>

        <div className="signup-contentarea">
          <div
            id="choosePositionWrapA"
            className={
              this.props.type === "NonAdminStaff" && !this.state.captain ? "position-select" : "hide position-select"
            }
          >
            <button onClick={this.information.bind(this, "Crew Lead")}>
              <span className="pos-sel">Crew Lead</span>
            </button>
            <button onClick={this.information.bind(this, "Groundsperson")}>
              <span className="pos-sel">Groundsperson</span>
            </button>
            <button onClick={this.information.bind(this, "Climber")}>
              <span className="pos-sel">Climber</span>
            </button>
            <button onClick={this.information.bind(this, "Equipment Operator")}>
              <span className="expand-opts">Equipment Operator</span>
            </button>
            <button onClick={this.information.bind(this, "Mechanic")}>
              <span className="expand-opts">Mechanic</span>
            </button>
          </div>
          <div
            id="choosePositionWrapB"
            className={
              this.props.type === "AdminStaff" && !this.state.captain ? "position-select" : "hide position-select"
            }
          >
            <button onClick={this.information.bind(this, "Division Manager")}>
              <span className="expand-opts">Division Manager</span>
            </button>
            <button onClick={this.information.bind(this, "Operations Manager")}>
              <span className="expand-opts">Operations Manager</span>
            </button>
            <button onClick={this.information.bind(this, "Arborist/Estimator")}>
              <span className="expand-opts">Arborist/Estimator</span>
            </button>
            <button onClick={this.information.bind(this, "Office Staff")}>
              <span className="expand-opts">Office Staff</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registering } = state.registration;
  return {
    registering,
  };
}

const connectedRegisterPage = connect(mapStateToProps)(Position);
export { connectedRegisterPage as Position };
