import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import moment from "moment";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// User Components
import { config } from "_helpers/config";
import { docsActions } from "_actions/docs.actions";
import { usPhone } from "_utils/formatter";
import { SignPad } from "Docs/Components";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";

class GearIssueForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire_doc: false,
      isListAOpen: false,
      isListBOpen: false,
      pages: [1],
      page: 1,
      user: {
        mailing_address: {
          country: "",
        },
      },
      form: {
        employee_printed_name: "",
        date: moment().format("MM/DD/YYYY"),
        employee_signature: "",
        crew: "",
        employee_signature_date: moment().format("MM/DD/YYYY"),
        superintendent_signature: "",
        superintendent_signature_date: moment().format("MM/DD/YYYY"),
        phone: "",
        petzel: {
          checked: false,
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        visor: {
          checked: false,
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        earmuff_3m: {
          checked: false,
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        sena_headset: {
          checked: false,
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        harness: {
          checked: false,
          model: "",
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        chaps: {
          checked: false,
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        spurs: {
          checked: false,
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        flip_line: {
          checked: false,
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        chainsaw_pants: {
          checked: false,
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        groundman_pants: {
          checked: false,
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        rain_gear: {
          checked: false,
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        weather_gear: {
          checked: false,
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        crew_ipad: {
          checked: false,
          serial: "",
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        wex_card_pin: {
          checked: false,
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        visa: {
          checked: false,
          number: "",
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        misc: {
          checked: false,
          item: "",
          cost: "",
          initial_received: "",
          return_date: moment().format("MM/DD/YYYY"),
          initial_returned: "",
        },
        husq_stihl_echo: {
          checked: false,
          serial: "",
        },
        drug_test: false,
        employee_handbook: false,
        orientation: false,
        door_code: {
          checked: false,
          code: "",
        },
        key_box_code: {
          checked: false,
          code: "",
        },
        has_job_appropriate_accessories: false,
        personal_climbing_items: {
          checked: false,
          items: "",
        },
        item_total: 0,
        disclaimer_check: false,
      },
      errors: {},
      form_edited: false,
      companyName:"",
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = navigate.bind(this);
    this.navigatePage = this.navigatePage.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.skipHandler = this.skipHandler.bind(this);
    this.computeTotal = this.computeTotal.bind(this);
    this.goBack = this.goBack.bind(this);
    this.getFormData = this.getFormData.bind(this);
    // Refs
    this.form = React.createRef();
    this.signContainer = React.createRef();
  }
  // END OF CONSTRUCTOR

  // Properties
  items = [
    "petzel",
    "visor",
    "earmuff_3m",
    "sena_headset",
    "harness",
    "chaps",
    "spurs",
    "flip_line",
    "chainsaw_pants",
    "groundman_pants",
    "rain_gear",
    "weather_gear",
    "crew_ipad",
    "wex_card_pin",
    "visa",
    "misc",
  ];

  // Methods
  toggleList(list) {
    switch (list) {
      case "privacy-1":
        this.state.isListAOpen
          ? this.setState({ isListAOpen: false })
          : this.setState({ isListAOpen: true });
        break;
      case "privacy-2":
        this.state.isListBOpen
          ? this.setState({ isListBOpen: false })
          : this.setState({ isListBOpen: true });
        break;
      default:
        this.setState({
          isListOpen: false,
        });
        break;
    }
  }

  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState(
      {
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      },
      () => {
        // might need to call some handlers later
      }
    );
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date !== null) {
      _.set(nextState, field, date.format("MM/DD/YYYY"));

      this.setState({
        ...nextState,
        errors: {
          [field]: "",
        },
      });
    }
  }

  navigatePage(to) {
    let { page } = this.state;

    switch (to) {
      case "next":
        this.setState({ page: page + 1 });
        return;
      case "prev":
        this.setState({ page: page - 1 });
        return;
      default:
        return;
    }
  }

  handleFormChange(e) {
    // safety check for datepicker
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { value, checked, type } = e.target;

      if (type == "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }

      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handlePhoneChange(e) {
    e.target.value = usPhone(e.target.value);
  }

  skipHandler() {
    let { doc } = this.state;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      skippedPages: [1, 2],
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.state.rehire_doc && (data.rehire = true);
    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode) {
          this.props.setScreen("sidebar");
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        toast.error(res.message);
      }
    });
  }

  submitForm(e) {
    let single_doc = this.props.single_doc ? true : false;
    e.preventDefault();

    let err = this.validate();

    if (err) return;

    let { form, doc, user, page, pages } = this.state;
    let { adminMode } = this.props;

    for (let key in form) {
      this.items.includes(key) && form[key].qty === "" && (form[key].qty = 0);
    }

    if (adminMode && !single_doc && pages && page < pages.length) {
      // single_doc
      this.navigatePage("next");
      return;
    }

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
      single_doc,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc
      ? (data.single_doc = true)
      : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    // API CALL
    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        // this.navigate("next");
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        } else if (this.props.lastPage) {
          if (adminMode) {
            this.props.single_doc
              ? this.props.tabChange("list") // single_doc
              : this.props.history.push(`/docs`);
          } else {
            this.props.single_doc
              ? this.props.tabChange("list") // single_doc
              : this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  computeTotal() {
    const decimalPlaces = 2;
    let item_total = 0;
    let addon = 0;
    // console.log("%c Computing Total", "font-size:24px;color:orangered;");
    this.items.forEach((item) => {
      if (
        (item === "tshirt" || item === "long_sleeve_tshirt") &&
        (this.state.form[item].size === "xxl" ||
          this.state.form[item].size === "xxxl")
      ) {
        addon = Number(
          Math.round(
            parseFloat(
              parseFloat(addon) +
                parseFloat(this.state.form[item].qty * 2) +
                "e" +
                decimalPlaces
            )
          ) +
            "e-" +
            decimalPlaces
        ).toFixed(decimalPlaces);
      } else if (
        (item === "sweater" || item === "crew_neck_sweater") &&
        (this.state.form[item].size === "xxl" ||
          this.state.form[item].size === "xxxl")
      ) {
        addon = Number(
          Math.round(
            parseFloat(
              parseFloat(addon) +
                parseFloat(this.state.form[item].qty * 5) +
                "e" +
                decimalPlaces
            )
          ) +
            "e-" +
            decimalPlaces
        ).toFixed(decimalPlaces); 
      }

      // console.log(item_total, item, this.state.form[item].price * this.state.form[item].qty, this.state.form[item].size, addon);
      item_total =
        item_total + this.state.form[item].price * this.state.form[item].qty;
      item_total = Number(
        Math.round(
          parseFloat(
            parseFloat(item_total) +
              parseFloat(
                this.state.form[item].price * this.state.form[item].qty
              ) +
              "e" +
              decimalPlaces
          )
        ) +
          "e-" +
          decimalPlaces
      ).toFixed(decimalPlaces);
    });

    // return item_total + addon;
    return Number(
      Math.round(
        parseFloat(
          parseFloat(item_total) + parseFloat(addon) + "e" + decimalPlaces
        )
      ) +
        "e-" +
        decimalPlaces
    ).toFixed(decimalPlaces);
  }
  // GET FORM data from api with id passed in props
  getFormData = (id) =>{
    console.log("call")
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        debugger
        const currentState = this.state;
        let { user, form } = res.data;

        // date needs to a moment object or Datepicker breaks.
        form.date = form.date || currentState.form.date;
        form.employee_signature_date = form.employee_signature_date || currentState.form.employee_signature_date;
        form.superintendent_signature_date = form.superintendent_signature_date || currentState.form.superintendent_signature_date;
        form.petzel.return_date = form.petzel.return_date || currentState.form.petzel.return_date;
        form.visor.return_date = form.visor.return_date || currentState.form.visor.return_date;
        form.earmuff_3m.return_date = form.earmuff_3m.return_date || currentState.form.earmuff_3m.return_date;
        form.sena_headset.return_date = form.sena_headset.return_date || currentState.form.sena_headset.return_date;
        form.harness.return_date = form.harness.return_date || currentState.form.harness.return_date;
        form.chaps.return_date = form.chaps.return_date || currentState.form.chaps.return_date;
        form.spurs.return_date = form.spurs.return_date || currentState.form.spurs.return_date;
        form.flip_line.return_date = form.flip_line.return_date || currentState.form.flip_line.return_date;
        form.chainsaw_pants.return_date = form.chainsaw_pants.return_date || currentState.form.chainsaw_pants.return_date;
        form.groundman_pants.return_date = form.groundman_pants.return_date || currentState.form.groundman_pants.return_date;
        form.rain_gear.return_date = form.rain_gear.return_date || currentState.form.rain_gear.return_date;
        form.weather_gear.return_date = form.weather_gear.return_date || currentState.form.weather_gear.return_date;
        form.crew_ipad.return_date = form.crew_ipad.return_date || currentState.form.crew_ipad.return_date;
        form.wex_card_pin.return_date = form.wex_card_pin.return_date || currentState.form.wex_card_pin.return_date;
        form.visa.return_date = form.visa.return_date || currentState.form.visa.return_date;
        form.misc.return_date = form.misc.return_date || currentState.form.misc.return_date;
        form.misc.return_date = form.misc.return_date || currentState.form.misc.return_date;

        // spread the data coming from API into state
        this.setState({ user, form });
      }
    });
  }

  goBack() {
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen("sidebar");
    }
  }

  componentDidMount() {
    let docSlug = this.props.slug
      ? this.props.slug
      : this.props.match.params.doc;
    let doc = null;
    let companyName;
    let { activeForm } = this.props.docs;

    if (Object.keys(activeForm).length) {
      companyName =
        parseInt(activeForm.user.applying_company) === 1
          ? "About Trees"
          : "NVTS";
    }

    this.props.single_doc
      ? (doc = this.props.docs.userDocs.find(
          (doc) => doc.slug == docSlug && doc.docs_categories_id === 3
        )) // other document 3
      : this.props.rehire
      ? (doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 2
        ))
      : (doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 1
        ));

    // let doc = this.props.docs.userDocs.find((doc) => doc.slug == docSlug);
    this.props.single_doc && doc && (doc.id = this.props.new_doc_id);
    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;

    this.setState(
      {
        doc,
        sign: {
          height: height,
          width: width,
        },
        companyName,
      },
      () => {
        //(!this.props.single_doc || this.props.view_doc) &&
        doc && this.getFormData(doc.id);
      } // single_doc
    );
  }

  componentDidUpdate = (prevProps, prevState) => {
    let companyName = "";
    let { activeForm } = this.props.docs;
    if (Object.keys(activeForm).length) {
      companyName =
        parseInt(activeForm.user.applying_company) === 1
          ? "About Trees"
          : "NVTS";
    }
    if (prevState.companyName !== companyName) {
      this.setState({
        companyName,
      });
    }
  };

  render() {
    let { user, form, errors, page, pages, companyName } = this.state;
    let { adminMode, single_doc } = this.props;
    let { crewsList } = this.props.docs;

    return (
      <div className="doc_info-rh">
        {/* Title */}
        <div className="doc-title">
          <img src={`${config.assetUrl}icons/doc-title-img.svg`} className="doc-title-img" alt="icon" />
          <span className="close-button" onClick={single_doc ? this.props.viewList : this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} alt="icon" />
          </span>
          <h3>Gear Issue Form</h3>
        </div>

        {/* static HTML markup */}
        <div className="doc_info-form">
          <form
            noValidate
            className="info-form merchandise"
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
          >
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">Employee Name</div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors["form.employee_printed_name"] ? "error" : ""}`}
                      name="employee_printed_name"
                      id="employee_printed_name"
                      value={form.employee_printed_name}
                      field="form.employee_printed_name"
                      placeholder="- Enter here -"
                      required
                    />
                    {errors["form.employee_printed_name"] && (
                      <label htmlFor="employee_printed_name" className="error_label">
                        {errors["form.employee_printed_name"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">Crew</div>
                  <div className="inputs-items">
                    {/* <input
                      type="text"
                      className={`${errors["form.crew"] ? "error" : ""}`}
                      name="crew"
                      id="crew"
                      value={form.crew}
                      field="form.crew"
                      placeholder="- Enter here -"
                      required
                    /> */}
                    <select
                      className={`${errors["form.crew"] ? "error" : ""}`}
                      name="crew"
                      id="crew"
                      value={form.crew}
                      field="form.crew"
                      required
                    >
                      <option value="">- Select Crew --</option>
                      {crewsList && crewsList.map((item, key) => (
                        <option key={key} value={item.resource_name}>
                          {item.resource_name}
                        </option>
                      ))}
                    </select>

                    {errors["form.crew"] && (
                      <label htmlFor="crew" className="error_label">
                        {errors["form.crew"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">Date</div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors["form.date"] ? "error" : ""} w-100`}
                      name="date"
                      id="date"
                      required
                      selected={moment(form.date)}
                      field="form.date"
                      placeholderText="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(this, "form.date")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="on"
                    />
                    {errors["form.date"] && (
                      <label htmlFor="date" className="error_label">
                        {errors["form.date"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row form-row show-mob">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">Items</div>
                </div>
              </div>
            </div>

            {/* DESKTOP VIEW */}
            {/* Accessories Table */}
            {!this.props.mobileMode && (
              <div className="row form-row no-border pb-2 nvam-gear-issue-table-wrapper">
                <div className="col">
                  <div className="inputs-col">
                    <table className="inputs_table">
                      <tbody>
                        {/* Heading */}
                        <tr>
                          <td width="42.4%" style={{ "text-align": "left" }}>
                            <b>Issued Gear</b>
                          </td>
                          <td width="10.6%" className="price_amt">
                            Replacement Cost
                          </td>
                          <td width="13.7%">
                            <b>INITIAL Received</b>
                          </td>
                          <td width="18%">
                            <b>RETURN Date</b>
                          </td>
                          <td width="13.3%">
                            <b>INITIAL Returned</b>
                          </td>
                        </tr>

                        {/* PETZEL HardHat */}
                        <tr>
                          <td>
                            <div className="check_radio_group check_disclaimer d-flex start">
                              <div className="check_radio_item">
                                <input
                                  type="checkbox"
                                  name="petzel"
                                  id="petzel"
                                  field="form.petzel.checked"
                                  checked={form.petzel.checked}
                                />
                                <span className="checkbox_checkbox"></span>
                                <label htmlFor="petzel">PETZEL HARDHAT</label>
                              </div>
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="petzel_hardhat_cost"
                                id="petzel_hardhat_cost"
                                field="form.petzel.cost"
                                placeholder="0"
                                value={form.petzel.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="petzel_hardhat_initial_received"
                                id="petzel_hardhat_initial_received"
                                field="form.petzel.initial_received"
                                placeholder="0"
                                value={form.petzel.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="petzel_return_date"
                                id="petzel_return_date"
                                required
                                selected={moment(form.petzel.return_date)}
                                field="petzel.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.petzel.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="petzel_hardhat_initial_returned"
                                id="petzel_hardhat_initial_returned"
                                field="form.petzel.initial_returned"
                                placeholder="0"
                                value={form.petzel.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* Visor */}
                        <tr>
                          <td>
                            <div className="check_radio_group check_disclaimer d-flex start">
                              <div className="check_radio_item">
                                <input
                                  type="checkbox"
                                  name="visor"
                                  id="visor"
                                  field="form.visor.checked"
                                  checked={form.visor.checked}
                                />
                                <span className="checkbox_checkbox"></span>
                                <label htmlFor="visor">VISOR</label>
                              </div>
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="visor"
                                id="visor"
                                field="form.visor.cost"
                                placeholder="0"
                                value={form.visor.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="visor_initial_received"
                                id="visor_initial_received"
                                field="form.visor.initial_received"
                                placeholder="0"
                                value={form.visor.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="visor_return_date"
                                id="visor_return_date"
                                selected={moment(form.visor.return_date)}
                                field="form.visor.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.visor.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="visor_initial_returned"
                                id="visor_initial_returned"
                                field="form.visor.initial_returned"
                                placeholder="0"
                                value={form.visor.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* 3M EARMUFF */}
                        <tr>
                          <td>
                            <div className="check_radio_group check_disclaimer d-flex start">
                              <div className="check_radio_item">
                                <input
                                  type="checkbox"
                                  name="earmuff_3m"
                                  id="earmuff_3m"
                                  field="form.earmuff_3m.checked"
                                  checked={form.earmuff_3m.checked}
                                />
                                <span className="checkbox_checkbox"></span>
                                <label htmlFor="earmuff_3m">3M EARMUFF</label>
                              </div>
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="earmuff_3m"
                                id="earmuff_3m"
                                field="form.earmuff_3m.cost"
                                placeholder="0"
                                value={form.earmuff_3m.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="earmuff_3m_initial_received"
                                id="earmuff_3m_initial_received"
                                field="form.earmuff_3m.initial_received"
                                placeholder="0"
                                value={form.earmuff_3m.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="earmuff_3m_return_date"
                                id="earmuff_3m_return_date"
                                selected={moment(form.earmuff_3m.return_date)}
                                field="form.earmuff_3m.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.earmuff_3m.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="earmuff_3m_initial_returned"
                                id="earmuff_3m_initial_returned"
                                field="form.earmuff_3m.initial_returned"
                                placeholder="0"
                                value={form.earmuff_3m.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/*  SENA HEADSET */}
                        <tr>
                          <td>
                            <div className="check_radio_group check_disclaimer d-flex start">
                              <div className="check_radio_item">
                                <input
                                  type="checkbox"
                                  name="sena_headset"
                                  id="sena_headset"
                                  field="form.sena_headset.checked"
                                  checked={form.sena_headset.checked}
                                />
                                <span className="checkbox_checkbox"></span>
                                <label htmlFor="sena_headset">SENA HEADSET</label>
                              </div>
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="sena_headset"
                                id="sena_headset"
                                field="form.sena_headset.cost"
                                placeholder="0"
                                value={form.sena_headset.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="sena_headset_initial_received"
                                id="sena_headset_initial_received"
                                field="form.sena_headset.initial_received"
                                placeholder="0"
                                value={form.sena_headset.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="sena_headset_return_date"
                                id="sena_headset_return_date"
                                selected={moment(form.sena_headset.return_date)}
                                field="form.sena_headset.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.sena_headset.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="sena_headset_initial_returned"
                                id="sena_headset_initial_returned"
                                field="form.sena_headset.initial_returned"
                                placeholder="0"
                                value={form.sena_headset.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* HARNSES */}
                        <tr>
                          <td>
                            <div className="check_radio_group check_disclaimer d-flex start">
                              <div className="check_radio_item">
                                <input
                                  type="checkbox"
                                  name="harness"
                                  id="harness"
                                  field="form.harness.checked"
                                  checked={form.harness.checked}
                                />
                                <span className="checkbox_checkbox"></span>
                                <label htmlFor="harness">HARNESS</label>
                              </div>
                            </div>
                            <div className="inputs-items nvam-hanging">
                              <p className="m-0">Model:</p>
                              <input
                                type="text"
                                className="qty"
                                name="model"
                                id="model"
                                field="form.harness.model"
                                placeholder="Serial No:"
                                value={form.harness.model}
                              />
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="harness"
                                id="harness"
                                field="form.harness.cost"
                                placeholder="0"
                                value={form.harness.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="harness_initial_received"
                                id="harness_initial_received"
                                field="form.harness.initial_received"
                                placeholder="0"
                                value={form.harness.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="harness_return_date"
                                id="harness_return_date"
                                selected={moment(form.harness.return_date)}
                                field="form.harness.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.harness.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="harness_initial_returned"
                                id="harness_initial_returned"
                                field="form.harness.initial_returned"
                                placeholder="0"
                                value={form.harness.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* CHAPS */}
                        <tr>
                          <td>
                            <div className="check_radio_group check_disclaimer d-flex start">
                              <div className="check_radio_item">
                                <input
                                  type="checkbox"
                                  name="chaps"
                                  id="chaps"
                                  field="form.chaps.checked"
                                  checked={form.chaps.checked}
                                />
                                <span className="checkbox_checkbox"></span>
                                <label htmlFor="chaps">CHAPS</label>
                              </div>
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="chaps"
                                id="chaps"
                                field="form.chaps.cost"
                                placeholder="0"
                                value={form.chaps.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="chaps_initial_received"
                                id="chaps_initial_received"
                                field="form.chaps.initial_received"
                                placeholder="0"
                                value={form.chaps.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="chaps_return_date"
                                id="chaps_return_date"
                                selected={moment(form.chaps.return_date)}
                                field="form.chaps.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.chaps.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="chaps_initial_returned"
                                id="chaps_initial_returned"
                                field="form.chaps.initial_returned"
                                placeholder="0"
                                value={form.chaps.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* SPURS */}
                        <tr>
                          <td>
                            <div className="check_radio_group check_disclaimer d-flex start">
                              <div className="check_radio_item">
                                <input
                                  type="checkbox"
                                  name="spurs"
                                  id="spurs"
                                  field="form.spurs.checked"
                                  checked={form.spurs.checked}
                                />
                                <span className="checkbox_checkbox"></span>
                                <label htmlFor="spurs">SPURS</label>
                              </div>
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="spurs"
                                id="spurs"
                                field="form.spurs.cost"
                                placeholder="0"
                                value={form.spurs.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="spurs_initial_received"
                                id="spurs_initial_received"
                                field="form.spurs.initial_received"
                                placeholder="0"
                                value={form.spurs.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="spurs_return_date"
                                id="spurs_return_date"
                                selected={moment(form.spurs.return_date)}
                                field="form.spurs.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.spurs.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="spurs_initial_returned"
                                id="spurs_initial_returned"
                                field="form.spurs.initial_returned"
                                placeholder="0"
                                value={form.spurs.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* FLIP LINE */}
                        <tr>
                          <td>
                            <div className="check_radio_group check_disclaimer d-flex start">
                              <div className="check_radio_item">
                                <input
                                  type="checkbox"
                                  name="flip_line"
                                  id="flip_line"
                                  field="form.flip_line.checked"
                                  checked={form.flip_line.checked}
                                />
                                <span className="checkbox_checkbox"></span>
                                <label htmlFor="flip_line">FLIP LINE</label>
                              </div>
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="flip_line"
                                id="flip_line"
                                field="form.flip_line.cost"
                                placeholder="0"
                                value={form.flip_line.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="flip_line_initial_received"
                                id="flip_line_initial_received"
                                field="form.flip_line.initial_received"
                                placeholder="0"
                                value={form.flip_line.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="flip_line_return_date"
                                id="flip_line_return_date"
                                selected={moment(form.flip_line.return_date)}
                                field="form.flip_line.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.flip_line.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="flip_line_initial_returned"
                                id="flip_line_initial_returned"
                                field="form.flip_line.initial_returned"
                                placeholder="0"
                                value={form.flip_line.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* CHAINSAW PANTS */}
                        <tr>
                          <td>
                            <div className="check_radio_group check_disclaimer d-flex start">
                              <div className="check_radio_item">
                                <input
                                  type="checkbox"
                                  name="chainsaw_pants"
                                  id="chainsaw_pants"
                                  field="form.chainsaw_pants.checked"
                                  checked={form.chainsaw_pants.checked}
                                />
                                <span className="checkbox_checkbox"></span>
                                <label htmlFor="chainsaw_pants">CHAINSAW PANTS</label>
                              </div>
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="chainsaw_pants"
                                id="chainsaw_pants"
                                field="form.chainsaw_pants.cost"
                                placeholder="0"
                                value={form.chainsaw_pants.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="chainsaw_pants_initial_received"
                                id="chainsaw_pants_initial_received"
                                field="form.chainsaw_pants.initial_received"
                                placeholder="0"
                                value={form.chainsaw_pants.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="chainsaw_pants_return_date"
                                id="chainsaw_pants_return_date"
                                selected={moment(form.chainsaw_pants.return_date)}
                                field="form.chainsaw_pants.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.chainsaw_pants.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="chainsaw_pants_initial_returned"
                                id="chainsaw_pants_initial_returned"
                                field="form.chainsaw_pants.initial_returned"
                                placeholder="0"
                                value={form.chainsaw_pants.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* GROUNDMAN PANTS */}
                        <tr>
                          <td>
                            <div className="check_radio_group check_disclaimer d-flex start">
                              <div className="check_radio_item">
                                <input
                                  type="checkbox"
                                  name="groundman_pants"
                                  id="groundman_pants"
                                  field="form.groundman_pants.checked"
                                  checked={form.groundman_pants.checked}
                                />
                                <span className="checkbox_checkbox"></span>
                                <label htmlFor="groundman_pants">GROUNDMAN PANTS</label>
                              </div>
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="groundman_pants"
                                id="groundman_pants"
                                field="form.groundman_pants.cost"
                                placeholder="0"
                                value={form.groundman_pants.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="groundman_pants_initial_received"
                                id="groundman_pants_initial_received"
                                field="form.groundman_pants.initial_received"
                                placeholder="0"
                                value={form.groundman_pants.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="groundman_pants_return_date"
                                id="groundman_pants_return_date"
                                selected={moment(form.groundman_pants.return_date)}
                                field="form.groundman_pants.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.groundman_pants.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="groundman_pants_initial_returned"
                                id="groundman_pants_initial_returned"
                                field="form.groundman_pants.initial_returned"
                                placeholder="0"
                                value={form.groundman_pants.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* RAIN GEAR */}
                        <tr>
                          <td>
                            <div className="check_radio_group check_disclaimer d-flex start">
                              <div className="check_radio_item">
                                <input
                                  type="checkbox"
                                  name="rain_gear"
                                  id="rain_gear"
                                  field="form.rain_gear.checked"
                                  checked={form.rain_gear.checked}
                                />
                                <span className="checkbox_checkbox"></span>
                                <label htmlFor="rain_gear">RAIN GEAR</label>
                              </div>
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="rain_gear"
                                id="rain_gear"
                                field="form.rain_gear.cost"
                                placeholder="0"
                                value={form.rain_gear.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="rain_gear_initial_received"
                                id="rain_gear_initial_received"
                                field="form.rain_gear.initial_received"
                                placeholder="0"
                                value={form.rain_gear.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="rain_gear_return_date"
                                id="rain_gear_return_date"
                                selected={moment(form.rain_gear.return_date)}
                                field="form.rain_gear.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.rain_gear.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="rain_gear_initial_returned"
                                id="rain_gear_initial_returned"
                                field="form.rain_gear.initial_returned"
                                placeholder="0"
                                value={form.rain_gear.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* WEATHER GEAR */}
                        <tr>
                          <td>
                            <div className="check_radio_group check_disclaimer d-flex start">
                              <div className="check_radio_item">
                                <input
                                  type="checkbox"
                                  name="weather_gear"
                                  id="weather_gear"
                                  field="form.weather_gear.checked"
                                  checked={form.weather_gear.checked}
                                />
                                <span className="checkbox_checkbox"></span>
                                <label htmlFor="weather_gear">WEATHER GEAR</label>
                              </div>
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="weather_gear"
                                id="weather_gear"
                                field="form.weather_gear.cost"
                                placeholder="0"
                                value={form.weather_gear.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="weather_gear_initial_received"
                                id="weather_gear_initial_received"
                                field="form.weather_gear.initial_received"
                                placeholder="0"
                                value={form.weather_gear.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="weather_gear_return_date"
                                id="weather_gear_return_date"
                                selected={moment(form.weather_gear.return_date)}
                                field="form.weather_gear.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.weather_gear.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="weather_gear_initial_returned"
                                id="weather_gear_initial_returned"
                                field="form.weather_gear.initial_returned"
                                placeholder="0"
                                value={form.weather_gear.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* CREW IPAD */}
                        <tr>
                          <td>
                            <div className="check_radio_group check_disclaimer d-flex start">
                              <div className="check_radio_item">
                                <input
                                  type="checkbox"
                                  name="crew_ipad"
                                  id="crew_ipad"
                                  field="form.crew_ipad.checked"
                                  checked={form.crew_ipad.checked}
                                />
                                <span className="checkbox_checkbox"></span>
                                <label htmlFor="crew_ipad">CREW IPAD</label>
                              </div>
                            </div>
                            <div className="inputs-items nvam-hanging">
                              <p className="m-0">Serial #</p>
                              <input
                                type="text"
                                className="qty"
                                name="crew_ipad_serial"
                                id="crew_ipad_serial"
                                field="form.crew_ipad.serial"
                                placeholder="Serial No:"
                                value={form.crew_ipad.serial}
                              />
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="crew_ipad"
                                id="crew_ipad"
                                field="form.crew_ipad.cost"
                                placeholder="0"
                                value={form.crew_ipad.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="crew_ipad_initial_received"
                                id="crew_ipad_initial_received"
                                field="form.crew_ipad.initial_received"
                                placeholder="0"
                                value={form.crew_ipad.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="crew_ipad_return_date"
                                id="crew_ipad_return_date"
                                selected={moment(form.crew_ipad.return_date)}
                                field="form.crew_ipad.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.crew_ipad.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="crew_ipad_initial_returned"
                                id="crew_ipad_initial_returned"
                                field="form.crew_ipad.initial_returned"
                                placeholder="0"
                                value={form.crew_ipad.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* WEX CARD PIN */}
                        <tr>
                          <td>
                            <div className="check_radio_group check_disclaimer d-flex start">
                              <div className="check_radio_item">
                                <input
                                  type="checkbox"
                                  name="wex_card_pin"
                                  id="wex_card_pin"
                                  field="form.wex_card_pin.checked"
                                  checked={form.wex_card_pin.checked}
                                />
                                <span className="checkbox_checkbox"></span>
                                <label htmlFor="wex_card_pin">WEX CARD PING</label>
                              </div>
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="wex_card_pin"
                                id="wex_card_pin"
                                field="form.wex_card_pin.cost"
                                placeholder="0"
                                value={form.wex_card_pin.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="wex_card_pin_initial_received"
                                id="wex_card_pin_initial_received"
                                field="form.wex_card_pin.initial_received"
                                placeholder="0"
                                value={form.wex_card_pin.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="wex_card_pin_return_date"
                                id="wex_card_pin_return_date"
                                selected={moment(form.wex_card_pin.return_date)}
                                field="form.wex_card_pin.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.wex_card_pin.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="wex_card_pin_initial_returned"
                                id="wex_card_pin_initial_returned"
                                field="form.wex_card_pin.initial_returned"
                                placeholder="0"
                                value={form.wex_card_pin.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* VISA */}
                        <tr>
                          <td>
                            <div className="check_radio_group check_disclaimer d-flex start">
                              <div className="check_radio_item">
                                <input
                                  type="checkbox"
                                  name="visa"
                                  id="visa"
                                  field="form.visa.checked"
                                  checked={form.visa.checked}
                                />
                                <span className="checkbox_checkbox"></span>
                                <label htmlFor="visa">VISA</label>
                              </div>
                            </div>
                            <div className="inputs-items nvam-hanging">
                              <p className="m-0">Last 4 of card at issue date:</p>
                              <small>(Subject to change)</small>
                              <input
                                type="text"
                                className="qty"
                                name="visa"
                                id="visa"
                                field="form.visa.number"
                                placeholder="Last 4 Digits"
                                value={form.visa.number}
                              />
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="visa"
                                id="visa"
                                field="form.visa.cost"
                                placeholder="0"
                                value={form.visa.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="visa_initial_received"
                                id="visa_initial_received"
                                field="form.visa.initial_received"
                                placeholder="0"
                                value={form.visa.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="visa_return_date"
                                id="visa_return_date"
                                selected={moment(form.visa.return_date)}
                                field="form.visa.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.visa.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="visa_initial_returned"
                                id="visa_initial_returned"
                                field="form.visa.initial_returned"
                                placeholder="0"
                                value={form.visa.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* MISC */}
                        <tr>
                          <td>
                            <div className="nvam-d-flex">
                              <div className="check_radio_group check_disclaimer d-flex start">
                                <div className="check_radio_item">
                                  <input
                                    type="checkbox"
                                    name="misc"
                                    id="misc"
                                    field="form.misc.checked"
                                    checked={form.misc.checked}
                                  />
                                  <span className="checkbox_checkbox"></span>
                                  <label htmlFor="misc">Misc:</label>
                                </div>
                              </div>
                              {/* <div className="inputs-head">MISC</div> */}
                              <div className="inputs-items">
                                <input
                                  type="text"
                                  className="qty"
                                  name="misc"
                                  id="misc"
                                  field="form.misc.item"
                                  placeholder=""
                                  value={form.misc.item}
                                />
                              </div>
                            </div>
                          </td>
                          <td className="price_amt">
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="misc"
                                id="misc"
                                field="form.misc.cost"
                                placeholder="0"
                                value={form.misc.cost}
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="misc_initial_received"
                                id="misc_initial_received"
                                field="form.misc.initial_received"
                                placeholder="0"
                                value={form.misc.initial_received}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="misc_return_date"
                                id="misc_return_date"
                                selected={moment(form.misc.return_date)}
                                field="form.misc.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.misc.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="misc_initial_returned"
                                id="misc_initial_returned"
                                field="form.misc.initial_returned"
                                placeholder="0"
                                value={form.misc.initial_returned}
                              />
                            </div>
                          </td>
                        </tr>

                        {/* Husq / STIHL / ECHO (Serial/Model) : */}
                        <tr>
                          <td colSpan="5">
                            <div className="inputs-col">
                              <div className="check_radio_group check_disclaimer nvam-d-flex">
                                <div className="check_radio_item">
                                  <input
                                    type="checkbox"
                                    name="husq_stihl_echo"
                                    id="husq_stihl_echo"
                                    field="form.husq_stihl_echo.checked"
                                    checked={form.husq_stihl_echo.checked}
                                  />
                                  <span className="checkbox_checkbox"></span>
                                  <label htmlFor="drug_test">Husq / STIHL / ECHO (Serial/Model) :</label>
                                </div>
                                <div className="inputs-items">
                                  <input
                                    type="text"
                                    className="qty"
                                    name="husq_stihl_echo"
                                    id="husq_stihl_echo"
                                    field="form.husq_stihl_echo.serial"
                                    placeholder=""
                                    value={form.husq_stihl_echo.serial}
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>

                        {/* DRUG TEST */}
                        <tr>
                          <td colSpan="5">
                            <div className="inputs-col">
                              <div className="check_radio_group check_disclaimer d-flex start">
                                <div className="check_radio_item">
                                  <input
                                    type="checkbox"
                                    name="drug_test"
                                    id="drug_test"
                                    field="form.drug_test"
                                    checked={form.drug_test}
                                  />
                                  <span className="checkbox_checkbox"></span>
                                  <label htmlFor="drug_test">Drug Test</label>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>

                        {/* Employee Handbook (Issued and Read) */}
                        <tr>
                          <td colSpan="5">
                            <div className="inputs-col">
                              <div className="check_radio_group check_disclaimer d-flex start">
                                <div className="check_radio_item">
                                  <input
                                    type="checkbox"
                                    name="employee_handbook"
                                    id="employee_handbook"
                                    field="form.employee_handbook"
                                    checked={form.employee_handbook}
                                  />
                                  <span className="checkbox_checkbox"></span>
                                  <label htmlFor="employee_handbook">Employee Handbook (Issued and Read)</label>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>

                        {/* Orientation (ANZI Standards, Ear Plug, PPE and Heavy Lifting Training)*/}
                        <tr>
                          <td colSpan="5">
                            <div className="inputs-col">
                              <div className="check_radio_group check_disclaimer d-flex start">
                                <div className="check_radio_item">
                                  <input
                                    type="checkbox"
                                    name="orientation"
                                    id="orientation"
                                    field="form.orientation"
                                    checked={form.orientation}
                                  />
                                  <span className="checkbox_checkbox"></span>
                                  <label htmlFor="orientation">
                                    Orientation (ANZI Standards, Ear Plug, PPE and Heavy Lifting Training)
                                    <ul>
                                      <li>Issued PPE (Ear Plugs, Saftey Glasses, Gloves)</li>
                                    </ul>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>

                        {/* Door Code */}
                        <tr>
                          <td colSpan="5">
                            <div className="inputs-col">
                              <div className="check_radio_group check_disclaimer nvam-d-flex flex-start">
                                <div className="check_radio_item">
                                  <input
                                    type="checkbox"
                                    name="door_code"
                                    id="door_code"
                                    field="form.door_code.checked"
                                    checked={form.door_code.checked}
                                  />
                                  <span className="checkbox_checkbox"></span>
                                  <label htmlFor="door_code">Door Code</label>
                                </div>
                                <div className="inputs-items felx-none">
                                  <input
                                    type="text"
                                    className="qty"
                                    name="door_code"
                                    id="door_code"
                                    field="form.door_code.code"
                                    placeholder=""
                                    value={form.door_code.code}
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>

                        {/* Key box code */}
                        <tr>
                          <td colSpan="5">
                            <div className="inputs-col">
                              <div className="check_radio_group check_disclaimer nvam-d-flex flex-start">
                                <div className="check_radio_item">
                                  <input
                                    type="checkbox"
                                    name="key_box_code"
                                    id="key_box_code"
                                    field="form.key_box_code.checked"
                                    checked={form.key_box_code.checked}
                                  />
                                  <span className="checkbox_checkbox"></span>
                                  <label htmlFor="key_box_code">Key Box Code</label>
                                </div>
                                <div className="inputs-items felx-none">
                                  <input
                                    type="text"
                                    className="qty"
                                    name="key_box_code"
                                    id="key_box_code"
                                    field="form.key_box_code.code"
                                    placeholder=""
                                    value={form.key_box_code.code}
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>

                        {/* Has job appropriate footwear and pants */}
                        <tr>
                          <td colSpan="5">
                            <div className="inputs-col">
                              <div className="check_radio_group check_disclaimer d-flex start">
                                <div className="check_radio_item">
                                  <input
                                    type="checkbox"
                                    name="has_job_appropriate_accessories"
                                    id="has_job_appropriate_accessories"
                                    field="form.has_job_appropriate_accessories"
                                    checked={form.has_job_appropriate_accessories}
                                  />
                                  <span className="checkbox_checkbox"></span>
                                  <label htmlFor="has_job_appropriate_accessories">
                                    Has job appropriate footwear and pants
                                  </label>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>

                        {/* Personal Climbing Items */}
                        <tr>
                          <td colSpan="5">
                            <div className="inputs-col">
                              <div className="check_radio_group check_disclaimer d-flex start">
                                <div className="check_radio_item">
                                  <input
                                    type="checkbox"
                                    name="personal_climbing_items"
                                    id="personal_climbing_items"
                                    field="form.personal_climbing_items.checked"
                                    checked={form.personal_climbing_items.checked}
                                  />
                                  <span className="checkbox_checkbox"></span>
                                  <label htmlFor="personal_climbing_items">Personal Climbing Items</label>
                                </div>
                              </div>
                              <div className="inputs-items nvam-hanging">
                                <textarea
                                  type="text"
                                  className="qty"
                                  name="personal_climbing_items"
                                  id="personal_climbing_items"
                                  field="form.personal_climbing_items.items"
                                  placeholder="List items belonging to person before employment"
                                  value={form.personal_climbing_items.items}
                                ></textarea>
                              </div>
                            </div>
                          </td>
                        </tr>

                        {/* Total */}
                        <tr>
                          <td colspan="5" className="totals-info">
                            <p>
                              All gear and equipment issued by North Valley Arbor Management dba {companyName} must be
                              returned in satisfactory and working condition. It is completely understood that I will be
                              charged for the replacement cost for any item(s) not returned or returned in unusable
                              condition or damaged due to operator misuse or abuse as deemed by North Valley Arbor
                              Management dba {companyName} . I understand that the replacement cost quoted above is an
                              estimated cost and the actual cost may be more due to increase cost and shipping charges.
                            </p>
                            <p>
                              All gear and equipment must be returned within 72 hours of termination of employment.
                              Should gear not be returned within the designated time, I agree to a restocking and
                              administrative fee of $500 above and beyond the replacement cost.
                            </p>
                            <p>
                              I authorize North Valley Arbor Management dba {" "}
                              {companyName} to deduct said charges from my wages or any other compensation due. If the
                              balance due is not satisfied, an invoice will be issued and sent to the last known
                              address. Failure to pay the invoice within 60 days will result in collection or legal
                              referral.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="5" className="totals-count">
                            <div className="nvam-d-flex align-center">
                              <div className="inputs-head mb-0 mr-1">Total cost of items: $ </div>

                              <div className="inputs-items felx-none">
                                <input
                                  type="text"
                                  className=""
                                  name="item_total"
                                  id="item_total"
                                  field="form.item_total"
                                  placeholder="Total"
                                  // value={`$ ${this.computeTotal()}`}
                                  disabled
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {/* MOBILE VIEW */}
            {this.props.mobileMode && (
              <section className="nvam-gear-issue-mobile-wrap">
                <Accordion allowZeroExpanded className="custom-accordion col">
                  {/* PETZEL HAT */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="petzel"
                              id="petzel"
                              field="form.petzel.checked"
                              checked={form.petzel.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="petzel">PETZEL HARDHAT</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.petzel.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">PETZEL HARDHAT</div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="petzel_hardhat_cost"
                                id="petzel_hardhat_cost"
                                field="form.petzel.cost"
                                placeholder="0"
                                value={form.petzel.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="petzel_hardhat_initial_received"
                                id="petzel_hardhat_initial_received"
                                field="form.petzel.initial_received"
                                placeholder="0"
                                value={form.petzel.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="petzel_return_date"
                                id="petzel_return_date"
                                selected={moment(form.petzel.return_date)}
                                field="form.petzel.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.petzel.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="petzel_hardhat_initial_returned"
                                id="petzel_hardhat_initial_returned"
                                field="form.petzel.initial_returned"
                                placeholder="0"
                                value={form.petzel.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* VISOR */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="visor"
                              id="visor"
                              field="form.visor.checked"
                              checked={form.visor.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="visor">VISOR</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.visor.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">VISOR</div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="visor"
                                id="visor"
                                field="form.visor.cost"
                                placeholder="0"
                                value={form.visor.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="visor_initial_received"
                                id="visor_initial_received"
                                field="form.visor.initial_received"
                                placeholder="0"
                                value={form.visor.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="visor_return_date"
                                id="visor_return_date"
                                selected={moment(form.visor.return_date)}
                                field="form.visor.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.visor.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="visor_initial_returned"
                                id="visor_initial_returned"
                                field="form.visor.initial_returned"
                                placeholder="0"
                                value={form.visor.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* 3M EARMUFF */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="earmuff_3m"
                              id="earmuff_3m"
                              field="form.earmuff_3m.checked"
                              checked={form.earmuff_3m.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="earmuff_3m">3M EARMUFF</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.earmuff_3m.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">3M EARMUFF</div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="earmuff_3m"
                                id="earmuff_3m"
                                field="form.earmuff_3m.cost"
                                placeholder="0"
                                value={form.earmuff_3m.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="earmuff_3m_initial_received"
                                id="earmuff_3m_initial_received"
                                field="form.earmuff_3m.initial_received"
                                placeholder="0"
                                value={form.earmuff_3m.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="earmuff_3m_return_date"
                                id="earmuff_3m_return_date"
                                selected={moment(form.earmuff_3m.return_date)}
                                field="form.earmuff_3m.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.earmuff_3m.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="earmuff_3m_initial_returned"
                                id="earmuff_3m_initial_returned"
                                field="form.earmuff_3m.initial_returned"
                                placeholder="0"
                                value={form.earmuff_3m.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/*  SENA HEADSET */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="sena_headset"
                              id="sena_headset"
                              field="form.sena_headset.checked"
                              checked={form.sena_headset.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="sena_headset">SENA HEADSET</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.sena_headset.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">SENA HEADSET</div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="sena_headset"
                                id="sena_headset"
                                field="form.sena_headset.cost"
                                placeholder="0"
                                value={form.sena_headset.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="sena_headset_initial_received"
                                id="sena_headset_initial_received"
                                field="form.sena_headset.initial_received"
                                placeholder="0"
                                value={form.sena_headset.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="sena_headset_return_date"
                                id="sena_headset_return_date"
                                selected={moment(form.sena_headset.return_date)}
                                field="form.sena_headset.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.sena_headset.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="sena_headset_initial_returned"
                                id="sena_headset_initial_returned"
                                field="form.sena_headset.initial_returned"
                                placeholder="0"
                                value={form.sena_headset.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* HARNSES */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="harness"
                              id="harness"
                              field="form.harness.checked"
                              checked={form.harness.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="harness">HARNESS</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.harness.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">HARNESS</div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="harness"
                                id="harness"
                                field="form.harness.cost"
                                placeholder="0"
                                value={form.harness.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="harness_initial_received"
                                id="harness_initial_received"
                                field="form.harness.initial_received"
                                placeholder="0"
                                value={form.harness.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="harness_return_date"
                                id="harness_return_date"
                                selected={moment(form.harness.return_date)}
                                field="form.harness.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.harness.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="harness_initial_returned"
                                id="harness_initial_returned"
                                field="form.harness.initial_returned"
                                placeholder="0"
                                value={form.harness.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* CHAPS */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="chaps"
                              id="chaps"
                              field="form.chaps.checked"
                              checked={form.chaps.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="chaps">CHAPS</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.chaps.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">CHAPS</div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="harness"
                                id="harness"
                                field="form.harness.cost"
                                placeholder="0"
                                value={form.harness.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="chaps_initial_received"
                                id="chaps_initial_received"
                                field="form.chaps.initial_received"
                                placeholder="0"
                                value={form.chaps.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="chaps_return_date"
                                id="chaps_return_date"
                                selected={moment(form.chaps.return_date)}
                                field="form.chaps.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.chaps.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="chaps_initial_returned"
                                id="chaps_initial_returned"
                                field="form.chaps.initial_returned"
                                placeholder="0"
                                value={form.chaps.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* SPURS */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="spurs"
                              id="spurs"
                              field="form.spurs.checked"
                              checked={form.spurs.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="spurs">SPURS</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.spurs.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">SPURS</div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="spurs"
                                id="spurs"
                                field="form.spurs.cost"
                                placeholder="0"
                                value={form.spurs.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="spurs_initial_received"
                                id="spurs_initial_received"
                                field="form.spurs.initial_received"
                                placeholder="0"
                                value={form.spurs.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="spurs_return_date"
                                id="spurs_return_date"
                                selected={moment(form.spurs.return_date)}
                                field="form.spurs.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.spurs.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="spurs_initial_returned"
                                id="spurs_initial_returned"
                                field="form.spurs.initial_returned"
                                placeholder="0"
                                value={form.spurs.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* FLIP LINE  */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="flip_line"
                              id="flip_line"
                              field="form.flip_line.checked"
                              checked={form.flip_line.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="flip_line">FLIP LINE</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.flip_line.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">FLIP LINE </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="flip_line"
                                id="flip_line"
                                field="form.flip_line.cost"
                                placeholder="0"
                                value={form.flip_line.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="flip_line_initial_received"
                                id="flip_line_initial_received"
                                field="form.flip_line.initial_received"
                                placeholder="0"
                                value={form.flip_line.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="flip_line_return_date"
                                id="flip_line_return_date"
                                selected={moment(form.flip_line.return_date)}
                                field="form.flip_line.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.flip_line.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="flip_line_initial_returned"
                                id="flip_line_initial_returned"
                                field="form.flip_line.initial_returned"
                                placeholder="0"
                                value={form.flip_line.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* CHAINSAW PANTS   */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="chainsaw_pants"
                              id="chainsaw_pants"
                              field="form.chainsaw_pants.checked"
                              checked={form.chainsaw_pants.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="chainsaw_pants">CHAINSAW PANTS</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.chainsaw_pants.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">CHAINSAW PANTS </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="chainsaw_pants"
                                id="chainsaw_pants"
                                field="form.chainsaw_pants.cost"
                                placeholder="0"
                                value={form.chainsaw_pants.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="chainsaw_pants_initial_received"
                                id="chainsaw_pants_initial_received"
                                field="form.chainsaw_pants.initial_received"
                                placeholder="0"
                                value={form.chainsaw_pants.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="chainsaw_pants_return_date"
                                id="chainsaw_pants_return_date"
                                selected={moment(form.chainsaw_pants.return_date)}
                                field="form.chainsaw_pants.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.chainsaw_pants.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="chainsaw_pants_initial_returned"
                                id="chainsaw_pants_initial_returned"
                                field="form.chainsaw_pants.initial_returned"
                                placeholder="0"
                                value={form.chainsaw_pants.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* GROUNDMAN PANTS  */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="groundman_pants"
                              id="groundman_pants"
                              field="form.groundman_pants.checked"
                              checked={form.groundman_pants.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="groundman_pants">GROUNDMAN PANTS</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.groundman_pants.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">GROUNDMAN PANTS </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="groundman_pants"
                                id="groundman_pants"
                                field="form.groundman_pants.cost"
                                placeholder="0"
                                value={form.groundman_pants.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="groundman_pants_initial_received"
                                id="groundman_pants_initial_received"
                                field="form.groundman_pants.initial_received"
                                placeholder="0"
                                value={form.groundman_pants.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="groundman_pants_return_date"
                                id="groundman_pants_return_date"
                                selected={moment(form.groundman_pants.return_date)}
                                field="form.groundman_pants.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.groundman_pants.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="groundman_pants_initial_returned"
                                id="groundman_pants_initial_returned"
                                field="form.groundman_pants.initial_returned"
                                placeholder="0"
                                value={form.groundman_pants.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* RAIN GEAR  */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="rain_gear"
                              id="rain_gear"
                              field="form.rain_gear.checked"
                              checked={form.rain_gear.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="rain_gear">RAIN GEAR</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.rain_gear.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">RAIN GEAR</div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="rain_gear"
                                id="rain_gear"
                                field="form.rain_gear.cost"
                                placeholder="0"
                                value={form.rain_gear.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="rain_gear_initial_received"
                                id="rain_gear_initial_received"
                                field="form.rain_gear.initial_received"
                                placeholder="0"
                                value={form.rain_gear.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="rain_gear_return_date"
                                id="rain_gear_return_date"
                                selected={moment(form.rain_gear.return_date)}
                                field="form.rain_gear.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.rain_gear.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="rain_gear_initial_returned"
                                id="rain_gear_initial_returned"
                                field="form.rain_gear.initial_returned"
                                placeholder="0"
                                value={form.rain_gear.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* RAIN GEAR  */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="rain_gear"
                              id="rain_gear"
                              field="form.rain_gear.checked"
                              checked={form.rain_gear.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="rain_gear">RAIN GEAR</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.rain_gear.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">RAIN GEAR</div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="rain_gear"
                                id="rain_gear"
                                field="form.rain_gear.cost"
                                placeholder="0"
                                value={form.rain_gear.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="rain_gear_initial_received"
                                id="rain_gear_initial_received"
                                field="form.rain_gear.initial_received"
                                placeholder="0"
                                value={form.rain_gear.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="rain_gear_return_date"
                                id="rain_gear_return_date"
                                selected={moment(form.rain_gear.return_date)}
                                field="form.rain_gear.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.rain_gear.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="rain_gear_initial_returned"
                                id="rain_gear_initial_returned"
                                field="form.rain_gear.initial_returned"
                                placeholder="0"
                                value={form.rain_gear.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* WEATHER GEAR   */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="weather_gear"
                              id="weather_gear"
                              field="form.weather_gear.checked"
                              checked={form.weather_gear.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="weather_gear">WEATHER GEAR</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.weather_gear.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">WEATHER GEAR</div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="weather_gear"
                                id="weather_gear"
                                field="form.weather_gear.cost"
                                placeholder="0"
                                value={form.weather_gear.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="weather_gear_initial_received"
                                id="weather_gear_initial_received"
                                field="form.weather_gear.initial_received"
                                placeholder="0"
                                value={form.weather_gear.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="weather_gear_return_date"
                                id="weather_gear_return_date"
                                selected={moment(form.weather_gear.return_date)}
                                field="form.weather_gear.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.weather_gear.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="weather_gear_initial_returned"
                                id="weather_gear_initial_returned"
                                field="form.weather_gear.initial_returned"
                                placeholder="0"
                                value={form.weather_gear.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* CREW IPAD   */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="crew_ipad"
                              id="crew_ipad"
                              field="form.crew_ipad.checked"
                              checked={form.crew_ipad.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="crew_ipad">CREW IPAD</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.crew_ipad.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">CREW IPAD</div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="crew_ipad"
                                id="crew_ipad"
                                field="form.crew_ipad.cost"
                                placeholder="0"
                                value={form.crew_ipad.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="crew_ipad_initial_received"
                                id="crew_ipad_initial_received"
                                field="form.crew_ipad.initial_received"
                                placeholder="0"
                                value={form.crew_ipad.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="crew_ipad_return_date"
                                id="crew_ipad_return_date"
                                selected={moment(form.crew_ipad.return_date)}
                                field="form.crew_ipad.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.crew_ipad.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="crew_ipad_initial_returned"
                                id="crew_ipad_initial_returned"
                                field="form.crew_ipad.initial_returned"
                                placeholder="0"
                                value={form.crew_ipad.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* WEX CARD PIN   */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="wex_card_pin"
                              id="wex_card_pin"
                              field="form.wex_card_pin.checked"
                              checked={form.wex_card_pin.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="wex_card_pin">WEX CARD PIN</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.wex_card_pin.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">WEX CARD PIN </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="wex_card_pin"
                                id="wex_card_pin"
                                field="form.wex_card_pin.cost"
                                placeholder="0"
                                value={form.wex_card_pin.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="wex_card_pin_initial_received"
                                id="wex_card_pin_initial_received"
                                field="form.wex_card_pin.initial_received"
                                placeholder="0"
                                value={form.wex_card_pin.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="wex_card_pin_return_date"
                                id="wex_card_pin_return_date"
                                selected={moment(form.wex_card_pin.return_date)}
                                field="form.wex_card_pin.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.wex_card_pin.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="wex_card_pin_initial_returned"
                                id="wex_card_pin_initial_returned"
                                field="form.wex_card_pin.initial_returned"
                                placeholder="0"
                                value={form.wex_card_pin.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* VISA  */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="visa"
                              id="visa"
                              field="form.visa.checked"
                              checked={form.visa.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="visa">VISA</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.visa.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">VISA </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="visa"
                                id="visa"
                                field="form.visa.cost"
                                placeholder="0"
                                value={form.visa.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="visa_initial_received"
                                id="visa_initial_received"
                                field="form.visa.initial_received"
                                placeholder="0"
                                value={form.visa.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="visa_return_date"
                                id="visa_return_date"
                                selected={moment(form.visa.return_date)}
                                field="form.visa.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.visa.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="visa_initial_returned"
                                id="visa_initial_returned"
                                field="form.visa.initial_returned"
                                placeholder="0"
                                value={form.visa.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* MISC  */}
                  <AccordionItem className="accordion-item">
                    {/* Accordion Heading */}
                    <AccordionItemHeading className="accordion-heading">
                      <AccordionItemButton className="accordion-button">
                        <div className="check_radio_group check_disclaimer">
                          <div className="check_radio_item d-flex start">
                            <input
                              type="checkbox"
                              name="misc"
                              id="misc"
                              field="form.misc.checked"
                              checked={form.misc.checked}
                            />
                            <span className="checkbox_checkbox"></span>
                            <label htmlFor="misc">MISC</label>
                          </div>
                        </div>
                        <p>
                          <span>{form.wex_card_pin.cost}</span>
                          <span>
                            <i className="fa fa-chevron-right"></i>
                          </span>
                        </p>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    {/* Accordion Content */}
                    <AccordionItemPanel className="accordion-content">
                      <div className="content">
                        <section>
                          <p className="inputs-head">Issued Gear</p>
                          <p className="inputs-col">
                            <div className="inputs-head">MISC </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Replacement Cost</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="misc"
                                id="misc"
                                field="form.misc.cost"
                                placeholder="0"
                                value={form.misc.cost}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Received</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="misc_initial_received"
                                id="misc_initial_received"
                                field="form.misc.initial_received"
                                placeholder="0"
                                value={form.misc.initial_received}
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Return Date</p>
                          <p>
                            <div className="inputs-items">
                              <DatePicker
                                className={`w-100`}
                                name="misc_return_date"
                                id="misc_return_date"
                                selected={moment(form.misc.return_date)}
                                field="form.misc.return_date"
                                placeholderText="mm/dd/yyyy"
                                onChange={this.handleDateChange.bind(this, "form.misc.return_date")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="on"
                              />
                            </div>
                          </p>
                        </section>

                        <section>
                          <p className="inputs-head">Initial Returned</p>
                          <p>
                            <div className="inputs-items">
                              <input
                                type="text"
                                className="qty"
                                name="misc_initial_returned"
                                id="misc_initial_returned"
                                field="form.misc.initial_returned"
                                placeholder="0"
                                value={form.misc.initial_returned}
                              />
                            </div>
                          </p>
                        </section>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* End of Accordion */}
                </Accordion>

                <div>
                  {/* Husq / STIHL / ECHO (Serial/Model) : */}
                  <div className="inputs-col">
                    <div className="check_radio_group check_disclaimer d-flex start">
                      <div className="check_radio_item">
                        <input
                          type="checkbox"
                          name="husq_stihl_echo"
                          id="husq_stihl_echo"
                          field="form.husq_stihl_echo.checked"
                          checked={form.husq_stihl_echo.checked}
                        />
                        <span className="checkbox_checkbox"></span>
                        <label htmlFor="drug_test">Husq / STIHL / ECHO (Serial/Model) :</label>
                        <input
                          type="text"
                          className="qty"
                          name="husq_stihl_echo"
                          id="husq_stihl_echo"
                          field="form.husq_stihl_echo.serial"
                          placeholder="Last 4 Digits"
                          value={form.husq_stihl_echo.serial}
                        />
                      </div>
                    </div>
                  </div>

                  {/* DRUG TEST */}
                  <div className="inputs-col">
                    <div className="check_radio_group check_disclaimer d-flex start">
                      <div className="check_radio_item">
                        <input
                          type="checkbox"
                          name="drug_test"
                          id="drug_test"
                          field="form.drug_test"
                          checked={form.drug_test}
                        />
                        <span className="checkbox_checkbox"></span>
                        <label htmlFor="drug_test">Drug Test</label>
                      </div>
                    </div>
                  </div>

                  {/* Employee Handbook (Issued and Read) */}
                  <div className="inputs-col">
                    <div className="check_radio_group check_disclaimer d-flex start">
                      <div className="check_radio_item">
                        <input
                          type="checkbox"
                          name="employee_handbook"
                          id="employee_handbook"
                          field="form.employee_handbook"
                          checked={form.employee_handbook}
                        />
                        <span className="checkbox_checkbox"></span>
                        <label htmlFor="employee_handbook">Employee Handbook (Issued and Read)</label>
                      </div>
                    </div>
                  </div>

                  {/* Orientation (ANZI Standards, Ear Plug, PPE and Heavy Lifting Training)*/}
                  <div className="inputs-col">
                    <div className="check_radio_group check_disclaimer d-flex start">
                      <div className="check_radio_item">
                        <input
                          type="checkbox"
                          name="orientation"
                          id="orientation"
                          field="form.orientation"
                          checked={form.orientation}
                        />
                        <span className="checkbox_checkbox"></span>
                        <label htmlFor="orientation">
                          Orientation (ANZI Standards, Ear Plug, PPE and Heavy Lifting Training)
                          <ul>
                            <li>Issued PPE (Ear Plugs, Saftey Glasses, Gloves)</li>
                          </ul>
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Door Code */}
                  <div className="inputs-col">
                    <div className="check_radio_group check_disclaimer d-flex start">
                      <div className="check_radio_item">
                        <input
                          type="checkbox"
                          name="door_code"
                          id="door_code"
                          field="form.door_code.checked"
                          checked={form.door_code.checked}
                        />
                        <span className="checkbox_checkbox"></span>
                        <label htmlFor="door_code">Door Code</label>
                        <input
                          type="text"
                          className="qty"
                          name="door_code"
                          id="door_code"
                          field="form.door_code.code"
                          placeholder="Last 4 Digits"
                          value={form.door_code.code}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Key box code */}
                  <div className="inputs-col">
                    <div className="check_radio_group check_disclaimer d-flex start">
                      <div className="check_radio_item">
                        <input
                          type="checkbox"
                          name="key_box_code"
                          id="key_box_code"
                          field="form.key_box_code.checked"
                          checked={form.key_box_code.checked}
                        />
                        <span className="checkbox_checkbox"></span>
                        <label htmlFor="key_box_code">Key Box Code</label>
                        <input
                          type="text"
                          className="qty"
                          name="key_box_code"
                          id="key_box_code"
                          field="form.key_box_code.code"
                          placeholder="Last 4 Digits"
                          value={form.key_box_code.code}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Has job appropriate footwear and pants */}
                  <div className="inputs-col">
                    <div className="check_radio_group check_disclaimer d-flex start">
                      <div className="check_radio_item">
                        <input
                          type="checkbox"
                          name="has_job_appropriate_accessories"
                          id="has_job_appropriate_accessories"
                          field="form.has_job_appropriate_accessories"
                          checked={form.has_job_appropriate_accessories}
                        />
                        <span className="checkbox_checkbox"></span>
                        <label htmlFor="has_job_appropriate_accessories">Has job appropriate footwear and pants</label>
                      </div>
                    </div>
                  </div>

                  {/* Personal Climbing Items */}
                  <div className="inputs-col">
                    <div className="check_radio_group check_disclaimer d-flex start">
                      <div className="check_radio_item">
                        <input
                          type="checkbox"
                          name="personal_climbing_items"
                          id="personal_climbing_items"
                          field="form.personal_climbing_items.checked"
                          checked={form.personal_climbing_items.checked}
                        />
                        <span className="checkbox_checkbox"></span>
                        <label htmlFor="personal_climbing_items">Personal Climbing Items</label>
                        <textarea
                          type="text"
                          className="qty"
                          name="personal_climbing_items"
                          id="personal_climbing_items"
                          field="form.personal_climbing_items.items"
                          placeholder="Last 4 Digits"
                          value={form.personal_climbing_items.items}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Total */}
                <p className="accessories-total">
                  <h3 className="inputs-head">Total</h3>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className=""
                      name="item_total"
                      id="item_total"
                      field="form.item_total"
                      placeholder="Total"
                      value={`$ ${this.computeTotal()}`}
                      disabled
                    />
                  </div>
                </p>
              </section>
            )}

            {/* Employee Signature */}
            {/* form.paid === "payroll" */}
            {true && (
              <div className="row form-row">
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">
                      Employee Signature
                      <span className="required-star">&#x2A;</span>
                    </div>
                    <div className="inputs-items">
                      <div
                        ref={(element) => {
                          this.signContainer = element;
                        }}
                        className="sign_here"
                      >
                        {this.state.sign && (
                          <Fragment>
                            <SignPad
                              height={this.state.sign.height}
                              width={this.state.sign.width}
                              handleSignature={this.handleSignature.bind(this, "form.employee_signature")}
                              signature={this.state.form.employee_signature}
                              disabled={adminMode}
                            />
                            <input
                              type="hidden"
                              value={form.employee_signature}
                              name="employee_signature"
                              field="form.employee_signature"
                              required
                            ></input>
                            {errors["form.employee_signature"] && (
                              <label htmlFor="employee_signature" className="error_label">
                                {errors["form.employee_signature"]}
                              </label>
                            )}
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="inputs-col">
                    <div className="inputs-head">
                      Date<span className="required-star">&#x2A;</span>
                    </div>
                    <div className="inputs-items">
                      <div className="inputs-items">
                        <DatePicker
                          className={`${errors["form.employee_signature_date"] ? "error" : ""} w-100`}
                          name="employee_signature_date"
                          id="employee_signature_date"
                          required
                          selected={moment(form.employee_signature_date)}
                          field="form.employee_signature_date"
                          placeholderText="mm/dd/yyyy"
                          onChange={this.handleDateChange.bind(this, "form.employee_signature_date")}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          disabled={adminMode}
                        />
                        {errors["form.employee_signature_date"] && (
                          <label htmlFor="employee_signature_date" className="error_label">
                            {errors["form.employee_signature_date"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Superintendent  */}
            {adminMode && (
              <div className="row form-row">
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">
                      Sup.Signature<span className="required-star">&#x2A;</span>
                    </div>
                    <div className="inputs-items">
                      <div
                        ref={(element) => {
                          this.signContainer = element;
                        }}
                        className="sign_here"
                      >
                        {this.state.sign && (
                          <Fragment>
                            <SignPad
                              height={this.state.sign.height}
                              width={this.state.sign.width}
                              handleSignature={this.handleSignature.bind(this, "form.superintendent_signature")}
                              signature={this.state.form.superintendent_signature}
                            />
                            <input
                              type="hidden"
                              value={form.superintendent_signature}
                              name="superintendent_signature"
                              field="form.superintendent_signature"
                              required
                            ></input>
                            {errors["form.superintendent_signature"] && (
                              <label htmlFor="superintendent_signature" className="error_label">
                                {errors["form.superintendent_signature"]}
                              </label>
                            )}
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="inputs-col">
                    <div className="inputs-head">
                      Date<span className="required-star">&#x2A;</span>
                    </div>
                    <div className="inputs-items">
                      <div className="inputs-items">
                        <DatePicker
                          className={`${errors["form.superintendent_signature_date"] ? "error" : ""} w-100`}
                          name="superintendent_signature_date"
                          id="superintendent_signature_date"
                          required
                          selected={moment(form.employee_superintendent_signature_date)}
                          field="form.superintendent_signature_date"
                          placeholderText="mm/dd/yyyy"
                          onChange={this.handleDateChange.bind(this, "form.superintendent_signature_date")}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                        />
                        {errors["form.superintendent_signature_date"] && (
                          <label htmlFor="superintendent_signature_date" className="error_label">
                            {errors["form.superintendent_signature_date"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Disclaimer check */}
            <div className="row form-row">
              <div className="col">
                <div className="disclaimers">
                  <strong className="diss">Disclaimer: </strong>You must read and carefully understand the documents
                  that you are filling out and signing. These are legally binding documents and must be accurate. By
                  filling these forms out, you are agreeing to our{" "}
                  <a href="#">Terms of Use, Privacy Policy and Employee Agreements.</a>
                </div>
              </div>
            </div>

            <div className="row form-row last">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group check_disclaimer">
                    <div className="check_radio_item">
                      <input
                        type="checkbox"
                        name="disclaimer_check"
                        id="disclaimer_check"
                        field="form.disclaimer_check"
                        checked={form.disclaimer_check}
                        required
                      />
                      <span className="checkbox_checkbox"></span>
                      <label htmlFor="disclaimer_check">I have confirmed the above details are true and valid.</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End of Disclaimer check */}

            {/* Footer */}
            <FormNav
              single_doc={this.props.single_doc}
              view_doc={this.props.view_doc}
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.disclaimer_check}
              lastPage={this.props.lastPage}
              mobileMode={this.props.mobileMode}
            />
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

let connectedGearIssueForm = compose(
  withRouter,
  connect(mapStateToProps)
)(GearIssueForm);
export { connectedGearIssueForm as GearIssueForm };
