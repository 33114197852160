/* eslint-disable eqeqeq */
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config } from "_helpers/config";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { docsActions } from "_actions/docs.actions";
import { usPhone } from "_utils/formatter";
import { toast } from "react-toastify";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";
import moment from "moment";

class CandidateInfoPage extends Component {
  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.state = {
      rehire_doc: false,
      doc: {},
      user: {
        mailing_address: {},
        emgcontact1: {},
        emgcontact2: {},
        gender: "Male",
      },
      form: {
        date: moment().format("MM/DD/YYYY"),
        dob: "",
      },
      errors: {},
      form_edited: false,
    };

    // Handlers
    this.handleFormChange = this.handleFormChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.navigate = navigate.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date){
    _.set(nextState, field, date.format("MM/DD/YYYY"));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: "",
      },
    });
  }

  handleFormChange(e) {
    // safety check for datepicker
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { value, checked, type } = e.target;

      if (type == "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handlePhoneChange(e) {
    e.target.value = usPhone(e.target.value);
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();
    if (err) {
      return;
    }

    let { doc, user, form } = this.state;

    let data = {
      id: doc?.id,
      doc_type: doc?.docs_type_id,
      form,
      user,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc ? (data.single_doc = true) : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { user, form } = res.data;
        // date needs to a moment object or Datepicker breaks.
        form.date = form.date || currentState.form.date;
        form.dob = form.dob || currentState.form.dob;
        // spread the data coming from API into state
        this.setState({
          user,
          form,
        });
      }
    });
  }

  goBack() {
    if (this.state.form_edited) {
      this.props?.toggleCloseConfirmation(true);
    } else {
      this.props?.setScreen("sidebar");
    }
  }

  componentDidMount() {
    let docSlug = this.props.slug ? this.props.slug : this.props.match.params.doc;
    let doc;
    if (this.props.giss) {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 5,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 4,
        );
      }
    } else {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
        );
      } else {
        this.props.single_doc ? (doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 3,
        )) : (doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 1,
        ));
      }
    }
    this.props.single_doc && doc && (doc.id = this.props.new_doc_id);

    this.setState({ doc });

    if(doc?.id){
      this.getFormData(doc.id);
    }
  }

  render() {
    // still linked to state after destructuring
    let { user, form, errors } = this.state;
    let { adminMode } = this.props;
    let { classifications, pantSize, shirtSize, race, crewsList, userDocs, inseamPantSize } = this.props.docs;

    return (
      <div className='doc_info-rh'>
        {/* Title */}
        <div className='doc-title'>
          <img src={`${config.assetUrl}icons/doc-title-img.svg`} className='doc-title-img' />
          <span className='close-button' onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Candidate Information</h3>
        </div>

        {/* Form */}
        <div className='doc_info-form'>
          <form
            className='info-form'
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            name='candidate_info_page'
            noValidate>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Full Name<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items group'>
                    <div className='grouped'>
                      <input
                        className={`${errors["user.first_name"] ? "error" : ""}`}
                        type='text'
                        name='first_name'
                        id='first_name'
                        value={user.first_name}
                        field='user.first_name'
                        placeholder='First Name'
                        required
                      />
                      {errors["user.first_name"] && (
                        <label htmlFor='first_name' className='error_label'>
                          {errors["user.first_name"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.middle_name"] ? "error" : ""}`}
                        name='middle_name'
                        id='middle_name'
                        value={user.middle_name}
                        field='user.middle_name'
                        placeholder='Middle Name (Optional)'
                      />
                      {errors["user.middle_name"] && (
                        <label htmlFor='middle_name' className='error_label'>
                          {errors["user.middle_name"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.last_name"] ? "error" : ""}`}
                        name='last_name'
                        id='last_name'
                        value={user.last_name}
                        field='user.last_name'
                        placeholder='Last Name'
                        required
                      />
                      {errors["user.last_name"] && (
                        <label htmlFor='last_name' className='error_label'>
                          {errors["user.last_name"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Preferred Name <span>(Optional)</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      type='text'
                      className={`${errors["form.prefered_name"] ? "error" : ""}`}
                      name='prefered_name'
                      id='prefered_name'
                      value={form.prefered_name}
                      field='form.prefered_name'
                      placeholder='- Enter here -'
                    />
                    {errors["form.prefered_name"] && (
                      <label htmlFor='prefered_name' className='error_label'>
                        {errors["form.prefered_name"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Date<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <DatePicker
                      className={`${errors["form.date"] ? "error" : ""} w-100`}
                      name='date'
                      id='date'
                      required
                      selected={moment(form.date)}
                      field='form.date'
                      placeholder='mm/dd/yyyy'
                      onChange={this.handleDateChange.bind(this, "form.date")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      autoComplete='off'
                    />
                    {/* <input type="text" className={`${errors['form.date'] ? 'error' : ''}`} name="date" id="date" value={form.date} field="form.date" placeholder="mm / dd / yyyy" /> */}
                    {errors["form.date"] && (
                      <label htmlFor='date' className='error_label'>
                        {errors["form.date"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Position<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["form.position"] ? "error" : ""}`}
                      name='position'
                      id='position'
                      value={form.position}
                      field='form.position'
                      required>
                      <option value=''>- Select -</option>
                      <option value='arborist'>Arborist</option>
                      <option value='climber'>Climber</option>
                      <option value='roundsperson'>Groundsperson</option>
                      <option value='operations'>Operations / Administrative</option>
                    </select>
                    {errors["form.position"] && (
                      <label htmlFor='position' className='error_label'>
                        {errors["form.position"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Classification<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["form.classification"] ? "error" : ""}`}
                      name='classification'
                      id='classification'
                      value={form.classification}
                      field='form.classification'
                      required>
                      <option value=''>- Select -</option>
                      {classifications.map((option) => (
                        <option value={option.id} key={option.id}>
                          {option.value}
                        </option>
                      ))}
                    </select>
                    {errors["form.classification"] && (
                      <label htmlFor='classification' className='error_label'>
                        {errors["form.classification"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>CREW</div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["user.crew_identifier_id"] ? "error" : ""}`}
                      name='crew'
                      id='crew'
                      value={user.crew_identifier_id}
                      field='user.crew_identifier_id'>
                      <option value=''>- Select -</option>
                      {crewsList.map((crew) => (
                        <option value={crew.id} key={crew.id}>
                          {crew.resource_name}
                        </option>
                      ))}
                    </select>
                    {errors["user.crew_identifier_id"] && (
                      <label htmlFor='crew' className='error_label'>
                        {errors["user.crew_identifier_id"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    SSN<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items '>
                    <input
                      type='text'
                      className={`${errors["form.ssn"] ? "error" : ""}`}
                      name='ssn'
                      id='ssn'
                      value={form.ssn}
                      field='form.ssn'
                      placeholder='- Enter here -'
                      required
                    />
                    {errors["form.ssn"] && (
                      <label htmlFor='ssn' className='error_label'>
                        {errors["form.ssn"]}
                      </label>
                    )}
                  </div>
                  <p className='notes'>*Work eligibility will be confirmed via E-Verify System</p>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Driver License or State ID
                    <span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items group two-item'>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.drivers_license"] ? "error" : ""}`}
                        name='drivers_license'
                        id='drivers_license'
                        value={user.drivers_license}
                        field='user.drivers_license'
                        placeholder='- Enter here -'
                        required
                      />
                      {errors["user.drivers_license"] && (
                        <label htmlFor='drivers_license' className='error_label'>
                          {errors["user.drivers_license"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.DLState"] ? "error" : ""}`}
                        name='DLState'
                        id='DLState'
                        value={user.DLState}
                        field='user.DLState'
                        placeholder='State'
                        required
                      />
                      {errors["user.DLState"] && (
                        <label htmlFor='DLState' className='error_label'>
                          {errors["user.DLState"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                    <DatePicker
                        className={`${errors["user.useritemdates.dl_expiry"] ? "error" : ""}`}
                        name='dl_expiry'
                        id='dl_expiry'
                        required
                        field='user.useritemdates.dl_expiry'
                        placeholder='Expiry date'
                        selected={moment(user?.useritemdates?.dl_expiry)?.isValid() ? moment(user?.useritemdates?.dl_expiry) : ""}
                        onChange={this.handleDateChange.bind(this, "user.useritemdates.dl_expiry")}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        autoComplete='off'
                      />
                      {errors["user.useritemdates.dl_expiry"] && (
                        <label htmlFor='dl_expiry' className='error_label'>
                          {errors["user.useritemdates.dl_expiry"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Sex<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='check_radio_group gender_radio'>
                    <div className='check_radio_item agreement_radio'>
                      <input
                        type='radio'
                        name='gender'
                        value='Male'
                        field='user.gender'
                        id='male'
                        checked={user.gender === "Male"}
                        required
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='male'>Male</label>
                    </div>
                    <div className='check_radio_item agreement_radio'>
                      <input
                        type='radio'
                        name='gender'
                        value='Female'
                        field='user.gender'
                        id='female'
                        checked={user.gender === "Female"}
                        required
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='female'>Female</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col half-col dress-measurements'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Height <span className='required-star'>&#x2A;</span>
                    <span>(in cm)</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      type='text'
                      className={`${errors["form.height"] ? "error" : ""}`}
                      name='height'
                      id='height'
                      value={form.height}
                      field='form.height'
                      placeholder='- Enter here -'
                      required
                    />
                    {errors["form.height"] && (
                      <label htmlFor='height' className='error_label'>
                        {errors["form.height"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Weight <span className='required-star'>&#x2A;</span>
                    <span>(in lbs)</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      type='text'
                      className={`${errors["user.weight"] ? "error" : ""}`}
                      name='weight'
                      id='weight'
                      value={user.weight}
                      field='user.weight'
                      placeholder='- Enter here -'
                      required
                    />
                    {errors["user.weight"] && (
                      <label htmlFor='weight' className='error_label'>
                        {errors["user.weight"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Pant Size<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["user.pant_size"] ? "error" : ""}`}
                      name='pant_size'
                      id='pant_size'
                      value={user.pant_size}
                      field='user.pant_size'
                      required>
                      <option value=''>- Select -</option>
                      {pantSize.map((size) => (
                        <option value={size.id} key={size.id}>
                          {size.value}
                        </option>
                      ))}
                    </select>
                    {errors["user.pant_size"] && (
                      <label htmlFor='pant_size' className='error_label'>
                        {errors["user.pant_size"]}
                      </label>
                    )}
                  </div>
                </div>
                
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Inseam Pant Size<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["user.inseam_pant_size"] ? "error" : ""}`}
                      name='inseam_pant_size'
                      id='inseam_pant_size'
                      value={user.inseam_pant_size}
                      field='user.inseam_pant_size'
                      required>
                      <option value=''>- Select -</option>
                      {inseamPantSize.map((size) => (
                        <option value={size.id} key={size.id}>
                          {size.value}
                        </option>
                      ))}
                    </select>
                    {errors["user.inseam_pant_size"] && (
                      <label htmlFor='inseam_pant_size' className='error_label'>
                        {errors["user.inseam_pant_size"]}
                      </label>
                    )}
                  </div>
                </div>

                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Shirt Size<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["user.shirt_size"] ? "error" : ""}`}
                      name='shirt_size'
                      id='shirt_size'
                      value={user.shirt_size}
                      field='user.shirt_size'
                      required>
                      <option value=''>- Select -</option>
                      {shirtSize.map((size) => (
                        <option value={size.id} key={size.id}>
                          {size.value}
                        </option>
                      ))}
                    </select>
                    {errors["user.shirt_size"] && (
                      <label htmlFor='shirt_size' className='error_label'>
                        {errors["user.shirt_size"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    DOB<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <DatePicker
                      className={`${errors["form.dob"] ? "error" : ""} w-100`}
                      name='dob'
                      id='dob'
                      required
                      selected={form.dob ? moment(form.dob) : ""}
                      field='form.dob'
                      placeholder='mm/dd/yyyy'
                      onChange={this.handleDateChange.bind(this, "form.dob")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      autoComplete='off'
                    />
                    {errors["form.dob"] && (
                      <label htmlFor='dob' className='error_label'>
                        {errors["form.dob"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Race or Ethnicity
                    <span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["form.ethnicity"] ? "error" : ""}`}
                      name='ethnicity'
                      id='ethnicity'
                      value={form.ethnicity}
                      field='form.ethnicity'
                      required>
                      <option value=''>- Select -</option>
                      {race.map((option) => (
                        <option value={option.id} key={option.id}>
                          {option.value}
                        </option>
                      ))}
                    </select>
                    {errors["form.ethnicity"] && (
                      <label htmlFor='ethnicity' className='error_label'>
                        {errors["form.ethnicity"]}
                      </label>
                    )}
                  </div>
                  <p className='notes'>*Required for Affirmative Action Program</p>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Mailing Address<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      type='text'
                      className={`${errors["user.mailing_address.address"] ? "error" : ""}`}
                      name='address'
                      id='address'
                      value={user.mailing_address.address}
                      field='user.mailing_address.address'
                      placeholder='Street or Box Number'
                      required
                    />
                    {errors["user.mailing_address.address"] && (
                      <label htmlFor='address' className='error_label'>
                        {errors["user.mailing_address.address"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col mailing-address'>
                <div className='inputs-col'>
                  <div className='inputs-items group'>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.mailing_address.city"] ? "error" : ""}`}
                        name='city'
                        id='city'
                        value={user.mailing_address.city}
                        field='user.mailing_address.city'
                        placeholder='City'
                        required
                      />
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.mailing_address.state"] ? "error" : ""}`}
                        name='state'
                        id='state'
                        value={user.mailing_address.state}
                        field='user.mailing_address.state'
                        placeholder='State'
                        required
                      />
                      {errors["user.mailing_address.state"] && (
                        <label htmlFor='state' className='error_label'>
                          {errors["user.mailing_address.state"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.mailing_address.zip"] ? "error" : ""}`}
                        name='zip'
                        id='zip'
                        value={user.mailing_address.zip}
                        field='user.mailing_address.zip'
                        placeholder='Zip'
                        required
                      />
                      {errors["user.mailing_address.zip"] && (
                        <label htmlFor='zip' className='error_label'>
                          {errors["user.mailing_address.zip"]}
                        </label>
                      )}
                    </div>
                  </div>
                  <p className='notes'>*Paychecks, W-2, memos, etc will be mailed here</p>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Contact Numbers<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items group two-item'>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["form.home_phone"] ? "error" : ""}`}
                        name='home_phone'
                        id='home_phone'
                        value={form.home_phone}
                        field='form.home_phone'
                        placeholder='Home Phone'
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors["form.home_phone"] && (
                        <label htmlFor='home_phone' className='error_label'>
                          {errors["form.home_phone"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["form.cell_phone"] ? "error" : ""}`}
                        name='cell_phone'
                        id='cell_phone'
                        value={form.cell_phone}
                        field='form.cell_phone'
                        placeholder='Cell Phone'
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors["form.cell_phone"] && (
                        <label htmlFor='cell_phone' className='error_label'>
                          {errors["form.cell_phone"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["form.message_number"] ? "error" : ""}`}
                        name='message_number'
                        id='message_number'
                        value={form.message_number}
                        field='form.message_number'
                        placeholder='Message Number'
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors["form.message_number"] && (
                        <label htmlFor='message_number' className='error_label'>
                          {errors["form.message_number"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Email Address<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items group two-item'>
                    <div className='grouped'>
                      <input
                        type='email'
                        className={`${errors["user.email"] ? "error" : ""}`}
                        name='email'
                        id='email'
                        value={user.email}
                        field='user.email'
                        placeholder='Enter here'
                        required
                      />
                      {errors["user.email"] && (
                        <label htmlFor='email' className='error_label'>
                          {errors["user.email"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Emergency Contact #1
                    <span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items group two-item'>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.emgcontact1.fname"] ? "error" : ""}`}
                        name='fname'
                        id='fname'
                        value={user.emgcontact1.fname}
                        field='user.emgcontact1.fname'
                        placeholder='Name'
                        required
                      />
                      {errors["user.emgcontact1.fname"] && (
                        <label htmlFor='fname' className='error_label'>
                          {errors["user.emgcontact1.fname"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.emgcontact1.relation"] ? "error" : ""}`}
                        name='relation'
                        id='relation'
                        value={user.emgcontact1.relation}
                        field='user.emgcontact1.relation'
                        placeholder='Relationship'
                        required
                      />
                      {errors["user.emgcontact1.relation"] && (
                        <label htmlFor='relation' className='error_label'>
                          {errors["user.emgcontact1.relation"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["user.emgcontact1.phone"] ? "error" : ""}`}
                        name='phone'
                        id='phone'
                        value={user.emgcontact1.phone}
                        field='user.emgcontact1.phone'
                        placeholder='Phone Number'
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors["user.emgcontact1.phone"] && (
                        <label htmlFor='phone' className='error_label'>
                          {errors["user.emgcontact1.phone"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["user.emgcontact1.alt_phone"] ? "error" : ""}`}
                        name='alt_phone'
                        id='alt_phone'
                        value={user.emgcontact1.alt_phone}
                        field='user.emgcontact1.alt_phone'
                        placeholder='Alternative Phone Number'
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors["user.emgcontact1.alt_phone"] && (
                        <label htmlFor='alt_phone' className='error_label'>
                          {errors["user.emgcontact1.alt_phone"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Emergency Contact #2
                    <span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items group two-item'>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.emgcontact2.fname"] ? "error" : ""}`}
                        name='fname'
                        id='fname'
                        value={user.emgcontact2.fname}
                        field='user.emgcontact2.fname'
                        placeholder='Name'
                        required
                      />
                      {errors["user.emgcontact2.fname"] && (
                        <label htmlFor='fname' className='error_label'>
                          {errors["user.emgcontact2.fname"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.emgcontact2.relation"] ? "error" : ""}`}
                        name='relation'
                        id='relation'
                        value={user.emgcontact2.relation}
                        field='user.emgcontact2.relation'
                        placeholder='Relationship'
                        required
                      />
                      {errors["user.emgcontact2.relation"] && (
                        <label htmlFor='relation' className='error_label'>
                          {errors["user.emgcontact2.relation"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["user.emgcontact2.phone"] ? "error" : ""}`}
                        name='phone'
                        id='phone'
                        value={user.emgcontact2.phone}
                        field='user.emgcontact2.phone'
                        placeholder='Phone Number'
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors["user.emgcontact2.phone"] && (
                        <label htmlFor='phone' className='error_label'>
                          {errors["user.emgcontact2.phone"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["user.emgcontact2.alt_phone"] ? "error" : ""}`}
                        name='alt_phone'
                        id='alt_phone'
                        value={user.emgcontact2.alt_phone}
                        field='user.emgcontact2.alt_phone'
                        placeholder='Alternative Phone Number'
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors["user.emgcontact2.alt_phone"] && (
                        <label htmlFor='alt_phone' className='error_label'>
                          {errors["user.emgcontact2.alt_phone"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col medical-info'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Medical Info<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <textarea
                      type='text'
                      className={`${errors["form.allergies"] ? "error" : ""}`}
                      name='allergies'
                      id='allergies'
                      value={form.allergies}
                      field='form.allergies'
                      placeholder='If any Allergies, Illnesses, etc please mention here, if not write N/A'
                      required
                    />
                    {errors["form.allergies"] && (
                      <label htmlFor='allergies' className='error_label'>
                        {errors["form.allergies"]}
                      </label>
                    )}
                  </div>
                  <div className='inputs-items'>
                    <textarea
                      type='text'
                      className={`${errors["form.prescriptions"] ? "error" : ""}`}
                      name='prescriptions'
                      id='prescriptions'
                      value={form.prescriptions}
                      field='form.prescriptions'
                      placeholder='If any Prescription Medications please mention here, if not write N/A '
                      required
                    />
                    {errors["form.prescriptions"] && (
                      <label htmlFor='prescriptions' className='error_label'>
                        {errors["form.prescriptions"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='disclaimers'>
                  <strong className='diss'>Disclaimer: </strong>You must read and carefully
                  understand the documents that you are filling out and signing. These are legally
                  binding documents and must be accurate. By filling these forms out, you are
                  agreeing to our{" "}
                  <a href=''>Terms of Use, Privacy Policy and Employee Agreements.</a>
                </div>
              </div>
            </div>
            <div className='row form-row last'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group check_disclaimer'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='disclaimer_check'
                        id='disclaimer_check'
                        field='form.disclaimer_check'
                        checked={form.disclaimer_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>

                      <label htmlFor='disclaimer_check'>
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormNav
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.disclaimer_check}
              mobileMode={this.props.mobileMode}
            />
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

let connectedCandidateInfoPage = compose(withRouter, connect(mapStateToProps))(CandidateInfoPage);
export { connectedCandidateInfoPage as CandidateInfoPage };
