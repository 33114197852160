//refer https://gist.github.com/markerikson/3df1cf5abbac57820a20059287b4be58
import Pusher from "pusher-js";
import { config } from "../_helpers";
import { chatConstants } from "../_constants/chat.constants";
import { chatActions } from "../_actions";
let socket = null;
let user = null;
let userHash = null;
let conversationChannel = null;
let countChannel = null;
let conversationListTimeout = null;
let connectionInitiated = false;

export default function socketMiddleware(store) {
  return next => action => {
    let { type, payload } = action;

    if (action.type == "USERS_LOGDETAILS_SUCCESS" && action.profile) {
      socket = null;
      countChannel = null;
      userHash = action.profile.data.hash;
    }

    try {
      user = JSON.parse(localStorage.getItem("user"));
    } catch (e) {
      console.log("error in user token data", e);
    }

    if (
      user &&
      user.success &&
      user.success.token &&
      userHash &&
      connectionInitiated == false
    ) {
      connectionInitiated = true;
      socket = new Pusher(config.pusherKey, {
        cluster: config.pusherCluster,
        encrypted: true,
        forceTLS: true,
        authEndpoint: `${config.apiUrl}/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${user.success.token}`
          }
        }
      });
      socket.connection.bind("error", error => {
        console.log(error);
      });
    }

    if (socket && socket.connection.state == "connected") {
      if (userHash && countChannel == null) {
        countChannel = socket.subscribe("private-App.User." + userHash);

        countChannel.bind("App\\Events\\NotifyUsers", data => {
          let { conversations } = store.getState().chat;
          clearTimeout(conversationListTimeout);
          conversationListTimeout = setTimeout(() => {
            store.dispatch(chatActions.getConversations());
          }, 1000);

          next({
            type: chatConstants.UNREAD_COUNT_UPDATE,
            payload: {
              chatCount: data.chat_count,
              data: data.conversations
            }
          });
        });
      }

      let processMessage = data => {
        
        let { chat, user } = store.getState();

        let { conversation, conversations } = chat;

        let { message } = data;

        if (message.conversation_id === conversation.id) {
          store.dispatch(chatActions.readAllMessages(conversation));
        }

        if (message.sender.hash != user.profile.data.hash || message.type == 'info') {

          let payload = {};

          let { message, last_message, deleted_message } = data;

          if (deleted_message) {
            message = deleted_message;
          }

          if (conversation.id == message.conversation_id) {
            let existingMessage = conversation.messages.find(
              _message => _message.id === message.id
            );
            if (existingMessage) {
              payload.conversation = {
                ...conversation,
                messages: conversation.messages.map(_message => {
                  if (_message.id === message.id) return message;
                  return _message;
                })
              };
            } else {
              payload.conversation = {
                ...conversation,
                messages: [...conversation.messages, message]
              };
            }
            if (last_message) {
              payload.conversations = {
                ...conversations,
                data: conversations.data.map(_conversation => {
                  if (_conversation.id == message.conversation_id) {
                    _conversation.last_message = last_message;
                    return _conversation;
                  }
                  return _conversation;
                })
              };
            } else {
              payload.conversations = {
                ...conversations,
                data: conversations.data.map(_conversation => {
                  if (_conversation.id == message.conversation_id) {
                    _conversation.last_message = message;
                    return _conversation;
                  }
                  return _conversation;
                })
              };
            }
          }

          if (message.type == "info") {
            let details = JSON.parse(message.body);
            switch (details.event) {
              case "remove":
                if (
                  !chat.admin.active &&
                  user.profile &&
                  conversationChannel &&
                  conversationChannel.subscribed &&
                  user.profile.data.hash === details.user.hash &&
                  message.conversation_id === conversation.id
                ) {
                  conversationChannel.unsubscribe();
                  conversationChannel = null;
                }
                payload.conversation = {
                  ...payload.conversation,
                  users: payload.conversation.users.filter(
                    _user => _user.hash != details.user.hash
                  ),
                  recipients: payload.conversation.recipients.filter(
                    _user => _user.hash != details.user.hash
                  )
                };
                payload.conversations = {
                  ...payload.conversations,
                  data: payload.conversations.data.map(_conversation => {
                    if (_conversation.id == message.conversation_id) {
                      _conversation.users = _conversation.users.filter(
                        _user => _user.hash != details.user.hash
                      );
                      _conversation.recipients = _conversation.recipients.filter(
                        _user => _user.hash != details.user.hash
                      );
                    }
                    return _conversation;
                  })
                };
                break;
              case "add":
                let users = payload.conversation.users;
                let recipients = payload.conversation.recipients;
                if (!users.some(user => user.hash == details.user.hash)) {
                  users = [...users, details.user];
                }
                if (!recipients.some(user => user.hash == details.user.hash)) {
                  recipients = [...recipients, details.user];
                }
                payload.conversation = {
                  ...payload.conversation,
                  users,
                  recipients
                };
                payload.conversations = {
                  ...payload.conversations,
                  data: payload.conversations.data.map(_conversation => {
                    if (_conversation.id == message.conversation_id) {
                      if (
                        !_conversation.users.some(
                          user => user.hash == details.user.hash
                        )
                      )
                        _conversation.users = [
                          ..._conversation.users,
                          details.user
                        ];

                      if (
                        !_conversation.recipients.some(
                          user => user.hash == details.user.hash
                        )
                      )
                        _conversation.recipients = [
                          ..._conversation.recipients,
                          details.user
                        ];
                    }
                    return _conversation;
                  })
                };
                break;
              default:
            }
          }
          next({
            type: chatConstants.NEW_MESSAGE_UPDATE,
            payload
          });
        }
      };

      let { chat, user } = store.getState();
      switch (type) {
        case "GET_MESSAGES_SUCCESS":
          let { conversation } = action.payload;

          if (conversationChannel && conversationChannel.subscribed) {
            conversationChannel.unsubscribe();
            conversationChannel = null;
          }
          if (
            user.profile &&
            conversationChannel == null &&
            (conversation.users.some(
              _user => _user.hash == user.profile.data.hash
            ) ||
              chat.admin.active)
          ) {
            conversationChannel = socket.subscribe(
              `private-mc-chat-conversation.${payload.conversation.id}`
            );

            conversationChannel.bind("message", processMessage);
          }
          break;
        case "SET_ADMIN_MODE_SUCCESS":
          let { admin } = payload;

          if (admin.active) {
            let { conversation } = store.getState().chat;
            if (conversation.id)
              store.dispatch(chatActions.getMessages(conversation));
          } else {
            store.dispatch(chatActions.clearConversation());
            if (conversationChannel && conversationChannel.subscribed) {
              conversationChannel.unsubscribe();
              conversationChannel = null;
            }
          }
          break;
        case "CREATE_CONVERSATION_SUCCESS":
          let newConversation = action.conversation;

          if (conversationChannel && conversationChannel.subscribed) {
            conversationChannel.unsubscribe();
            conversationChannel = null;
          }

          if (
            user.profile &&
            conversationChannel == null &&
            (newConversation.users.some(
              _user => _user.hash == user.profile.data.hash
            ) ||
              chat.admin.active)
          ) {
            conversationChannel = socket.subscribe(
              `private-mc-chat-conversation.${newConversation.id}`
            );

            conversationChannel.bind("message", processMessage);
          }
          break;
        default:
          return next(action);
      }
    }
    return next(action);
  };
}
