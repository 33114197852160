import moment from "moment";
import React from "react";
import { useMemo } from "react";
import "./ViewExpiryAndAssignedDates.css"

const ViewExpiryAndAssignedDates = ({ data, viewRT = false }) => {
  const userDates = data && data.useritemdates;
  const mspaExpDate = data?.mspa_exp_date

  const hasData = useMemo(() => {
    if (userDates) {
      let expiration = false;
      let assigned = false;
      let expirationKeys = [
        "finger_print_expiry",
        "physical_expiry",
        "dl_expiry",
        "first_aid_expiry",
        "rt_130_expiry",
        "sexual_harassment_expiry",
        "faller_quals",
        "commercial_dl_expiry"
      ];
      let assignedKeys = [
        "security_code_assigned",
        "credit_card_assigned",
        "fuel_pin_assigned",
        "key_fob",
        "key_fob_assigned",
      ];

      for (const key of expirationKeys) {
        if (userDates[key]) {
          expiration = true;
          break;
        }
      }
      for (const key of assignedKeys) {
        if (userDates[key]) {
          assigned = true;
          break;
        }
      }

      return { expiration, assigned };
    }
  }, [userDates]);

  const showExpiry = !!(mspaExpDate || data?.drivers_license || data?.DLState)
  
  return userDates || showExpiry ? (
    <React.Fragment>
      <div className="ViewExpiryAndAssignedDates_container">
        {userDates?.rt_130_expiry && viewRT && <div className="ViewExpiryAndAssignedDates_sectionOne">
            <div className="ViewExpiryAndAssignedDates_title">
              Date Completed
            </div>
            {userDates?.rt_130_expiry && (
              <p>RT-130 : {moment(userDates.rt_130_expiry).format("MM/DD/YY")}</p>
            )}
          </div>}
        {(hasData?.expiration || showExpiry) && (
          <div className="ViewExpiryAndAssignedDates_sectionOne">
            <div className="ViewExpiryAndAssignedDates_title">
              Expiration Dates
            </div>
            {data?.drivers_license && (
              <p>Drivers License : {data.drivers_license}</p>
            )}
            {data?.DLState && (
              <p>Drivers License State Issued : {data.DLState}</p>
            )}
            <div>
              {userDates?.dl_expiry && (
                <p>Drivers License Expiration Date : {moment(userDates.dl_expiry).format("MM/DD/YY")}</p>
              )}
            </div>
            {data?.commercial_drivers_license && (
              <p>Commercial Drivers License : {data.commercial_drivers_license}</p>
            )}
            <div>
              {userDates?.commercial_dl_expiry && (
                <p>Commercial Drivers License Expiration Date : {moment(userDates.commercial_dl_expiry).format("MM/DD/YY")}</p>
              )}
            </div>
            <ul className="unOrdered_list">
            {userDates?.physical_expiry && (
                <li>
                  DMV Physical: {moment(userDates.physical_expiry).format("MM/DD/YY")}
                </li>
              )}
            </ul>
            {userDates?.first_aid_expiry && (
              <p>
                First Aid/CPR:{" "}
                {moment(userDates.first_aid_expiry).format("MM/DD/YY")}
              </p>
            )}
            {userDates?.fallerQuals && (
              <p>Faller Qualification: {moment(userDates.fallerQuals).format("MM/DD/YY")}</p>
            )}
            {userDates?.sexual_harassment_expiry && (
              <p>
                Sexual Harassment:{" "}
                {moment(userDates.sexual_harassment_expiry).format("MM/DD/YY")}
              </p>
            )}
            {userDates?.faller_quals && (
              <p>
                Faller Qualification:{" "}
                {moment(userDates.faller_quals).format("MM/DD/YY")}
              </p>
            )}
          </div>
        )}

        {hasData?.assigned && (
          <div className="ViewExpiryAndAssignedDates_sectionTwo">
            <div className="ViewExpiryAndAssignedDates_title">
              Assigned Dates
            </div>
            {userDates?.security_code_assigned && (
              <p>
                Security Code:{" "}
                {moment(userDates.security_code_assigned).format("MM/DD/YY")}
              </p>
            )}
            {userDates?.credit_card_assigned && (
              <p>
                Credit Card:{" "}
                {moment(userDates.credit_card_assigned).format("MM/DD/YY")}
              </p>
            )}
            {userDates?.fuel_pin_assigned && (
              <p>
                Fuel Pin:{" "} {moment(userDates.fuel_pin_assigned).format("MM/DD/YY")}
              </p>
            )}
            {(userDates?.key_fob || userDates?.key_fob_assigned) && <p> Key Fob {`${userDates?.key_fob ? "#" + userDates?.key_fob : ""}${userDates?.key_fob_assigned ? `: ${moment(userDates?.key_fob_assigned).format("MM/DD/YY")}` : ""}`}</p>}
          </div>
        )}

      </div>
    </React.Fragment>
  ) : (
    <React.Fragment />
  );
};

export default ViewExpiryAndAssignedDates;
