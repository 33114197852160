export const permissionConstants = {

    GETALL_REQUEST: 'PERMISSION_GETALL_REQUEST',
    GETALL_SUCCESS: 'PERMISSION_GETALL_SUCCESS',
    GETALL_FAILURE: 'PERMISSION_GETALL_FAILURE',

    SETPERM_REQUEST: 'PERMISSION_SETPERM_REQUEST',
    SETPERM_SUCCESS: 'PERMISSION_SETPERM_SUCCESS',
    SETPERM_FAILURE: 'PERMISSION_SETPERM_FAILURE',

    GETADMIN_REQUEST: 'PERMISSION_GETADMIN_REQUEST',
    GETADMIN_SUCCESS: 'PERMISSION_GETADMIN_SUCCESS',
    GETADMIN_FAILURE: 'PERMISSION_GETADMIN_FAILURE',

    DELETE_REQUEST: 'PERMISSION_DELETE_REQUEST',
    DELETE_SUCCESS: 'PERMISSION_DELETE_SUCCESS',
    DELETE_FAILURE: 'PERMISSION_DELETE_FAILURE',

    ALLADMIN_REQUEST: 'PERMISSION_ALLADMIN_REQUEST',
    ALLADMIN_SUCCESS: 'PERMISSION_ALLADMIN_SUCCESS',
    ALLADMIN_FAILURE: 'PERMISSION_ALLADMIN_FAILURE',
 
};