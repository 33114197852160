import { chatConstants } from "../_constants";
import { chatService } from "../_services";
import { history } from "../_helpers";
import Conversation from "../Chat/Conversation";
import imageCompression from "browser-image-compression";

export const chatActions = {
  getConversations,
  getConversation,
  getUsers,
  getCrews,
  getMessages,
  getLatestMessages,
  createConversation,
  updateConversation,
  setFavorite,
  sendMessage,
  updateMessage,
  deleteMessage,
  searchConversation,
  addParticipant,
  removeParticipant,
  uploadFile,
  getConversationSettings,
  loadMessages,
  toggleAdminMode,
  readAllMessages,
  getAllCounts,
  clearConversation,
  getRecentMessages,
};

function getConversationSettings() {
  let request = () => ({ type: chatConstants.CONVERSATION_SETTINGS_REQUEST });
  let success = (res) => ({
    type: chatConstants.CONVERSATION_SETTINGS_SUCCESS,
    payload: res.data,
  });
  let failure = (error) => ({
    type: chatConstants.CONVERSATION_SETTINGS_FAIL,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    return chatService.getConversationSettings().then(
      (res) => dispatch(success(res)),
      (error) => dispatch(failure(error))
    );
  };
}

function toggleAdminMode(data) {
  let request = () => ({ type: chatConstants.SET_ADMIN_MODE_REQUEST });
  let success = (payload) => ({
    type: chatConstants.SET_ADMIN_MODE_SUCCESS,
    payload,
  });
  let failure = (error) => ({
    type: chatConstants.SET_ADMIN_MODE_FAIL,
    error,
  });
  return (dispatch, getState) => {
    dispatch(request());
    let chat = getState().chat;
    let payload = {
      admin: {
        ...chat.admin,
        active: data.adminMode,
      },
    };
    dispatch(success(payload));
  };
}

function getUsers(data) {
  let request = () => ({ type: chatConstants.USERS_REQUEST });
  let success = (res) => ({
    type: chatConstants.USERS_SUCCESS,
    users: res.data,
  });
  let failure = (error) => ({ type: chatConstants.USERS_FAIL, error });

  return (dispatch) => {
    dispatch(request());
    chatService.getUsers(data).then(
      (res) => dispatch(success(res)),
      (error) => dispatch(failure(error))
    );
  };
}

function getCrews(data) {
  let request = () => ({ type: chatConstants.CREWS_REQUEST });
  let success = (res) => {
    let data = [];
    for (let key in res.data) {
      if (res.data[key]) data = [...data, ...res.data[key]];
    }
    return { type: chatConstants.CREWS_SUCCESS, crews: data };
  };
  let failure = (error) => ({ type: chatConstants.CREWS_FAIL, error });

  return (dispatch) => {
    dispatch(request());
    chatService.getCrews(data).then(
      (res) => dispatch(success(res)),
      (error) => dispatch(failure(error))
    );
  };
}

function clearConversation() {
  // let request = () => ({ type: chatConstants.CREWS_REQUEST });
  let success = (payload) => {
    return { type: chatConstants.CLEAR_CONVERSATION_UPDATE, payload };
  };
  // let failure = error => ({ type: chatConstants.CREWS_FAIL, error });
  return (dispatch) => {
    let payload = {
      conversation: {
        data: {},
        users: [],
        recipients: [],
        messages: [],
        crews: [],
        currentPage: 1,
        mark: 0,
        loading: false,
      },
    };

    return dispatch(success(payload));
  };
}

function uploadFile(newMessage) {
  let request = (payload) => ({
    type: chatConstants.SEND_FILE_REQUEST,
    payload,
  });
  let success = (payload) => ({
    type: chatConstants.SEND_FILE_SUCCESS,
    payload,
  });
  let failure = (error) => ({ type: chatConstants.SEND_FILE_FAIL, error });

  return (dispatch, getState) => {
    let { chat } = getState();

    // setting up initial payload for file loading entry in message list

    let initialPayload = {
      conversation: {
        ...chat.conversation,
        messages: [...(chat.conversation.messages || []), newMessage],
      },
      conversations: {
        ...chat.conversations,
        data: chat.conversations.data.map((conversation) => {
          if (conversation.id == newMessage.conversation_id) {
            conversation.last_message = newMessage;
            return conversation;
          }
          return conversation;
        }),
      },
    };

    dispatch(request(initialPayload));

    // generate thumbnail and upload to DO S

    let { body } = newMessage;
    let { thumb, file } = body;

    let pipeLine = [chatService.getSignedDOUrl(body)];

    if (body.type.includes("image")) {
      pipeLine.push(
        imageCompression(body.file, {
          maxSizeMB: 0.3,
        })
      );
    }

    Promise.all(pipeLine)
      .then(([urls, compressedBlob]) => {
        if (urls.success) {
          let { ORIGINAL_URL, THUMB_URL } = urls.data;
          let uploadRequests = [
            urls.data,
            chatService.uploadFile(file, ORIGINAL_URL),
          ];
          if (thumb) {
            let compressedFile = new File([compressedBlob], file.name, {
              type: file.type,
            });
            uploadRequests.push(
              chatService.uploadFile(compressedFile, THUMB_URL)
            );
          }
          return Promise.all(uploadRequests);
        }

        throw new Error("Invalid File");
      })
      .then(([urls]) => {
        let { ORIGINAL_FILE, THUMB_FILE } = urls;
        let newBody = {
          file: ORIGINAL_FILE,
          thumb: THUMB_FILE,
          file_name: body.file_name,
          extension: body.extension,
          size: file.size,
          type: file.type,
        };

        if (body.type.includes("image")) {
          newBody.width = body.width;
          newBody.height = body.height;
        }

        newMessage.body = JSON.stringify(newBody);
        return chatService.sendMessage(newMessage);
      })
      .then((res) => {
        if (res.success) {
          let { chat } = getState();
          let { body } = res.data;
          let sentMessage = {
            ...res.data,
            temp_id: newMessage.temp_id,
            body: JSON.parse(body),
          };
          let payload = {
            conversation: {
              ...chat.conversation,
              messages: chat.conversation.messages.map((_message) => {
                if (
                  _message.conversation_id == sentMessage.conversation_id &&
                  _message.temp_id &&
                  _message.temp_id == sentMessage.temp_id
                ) {
                  return sentMessage;
                }
                return _message;
              }),
            },
            conversations: {
              ...chat.conversations,
              data: chat.conversations.data.map((conversation) => {
                if (conversation.id == sentMessage.conversation_id) {
                  conversation.last_message = sentMessage;
                  return conversation;
                }
                return conversation;
              }),
            },
          };
          return dispatch(success(payload));
        } else {
          throw new Error("Unable to upload file");
        }
      })
      .catch((err) => {
        return dispatch(failure(err));
      });
  };
}

function getConversations() {
  let request = () => ({ type: chatConstants.CONVERSATIONS_REQUEST });
  let success = (payload) => ({
    type: chatConstants.CONVERSATIONS_SUCCESS,
    payload,
  });
  let page = 1;
  let failure = (error) => ({ type: chatConstants.CONVERSATIONS_FAIL, error });
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      let { admin } = getState().chat;
      chatService
        .getConversations({ admin, page })
        .then(async (res) => {
          for (let next = 2; next <= res.data.last_page; next++) {
            page = next;
            await chatService
              .getConversations({ admin, page })
              .then((response) => {
                res.data.data.push.apply(res.data.data, response.data.data);
              });
          }
          return Promise.all([res, chatService.getAllCounts()]);
        })
        .then(
          ([res, countRes]) => {
            let allConversations = res.data;
            let { conversations, chat_count } = countRes.data;
            allConversations = {
              ...allConversations,
              data: allConversations.data.map((_conversation) => {
                if (conversations[_conversation.id]) {
                  return {
                    ..._conversation,
                    unreadCount: conversations[_conversation.id],
                  };
                }
                return {
                  ..._conversation,
                  unreadCount: 0,
                };
              }),
            };
            let payload = {
              count: chat_count,
              conversations: allConversations,
            };
            dispatch(success(payload));
            resolve(res);
          },
          (error) => {
            dispatch(failure(error));
            reject(error);
          }
        );
    });
  };
}

function getConversation(data) {
  let request = () => ({ type: chatConstants.CONVERSATION_REQUEST });
  let success = (payload) => ({
    type: chatConstants.CONVERSATION_SUCCESS,
    payload,
  });
  let failure = (error) => ({ type: chatConstants.CONVERSATION_FAIL, error });
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      let { admin } = getState().chat;
      chatService.getConversation({ ...data, admin }).then(
        (res) => {
          if (res.data) {
            let { conversations, count } = getState().chat;
            let conversation = res.data;
            let allConversations = {
              ...conversations,
              data: [conversation, ...conversations.data],
            };
            let payload = {
              count: count + 1,
              conversations: allConversations,
            };
            dispatch(success(payload));
            resolve(res);
          }
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
}

function searchConversation(data) {
  let request = () => ({ type: chatConstants.SEARCH_CONVERSATION_REQUEST });
  let success = (res) => ({
    type: chatConstants.SEARCH_CONVERSATION_SUCCESS,
    payload: res.data,
  });
  let failure = (error) => ({
    type: chatConstants.SEARCH_CONVERSATION_FAIL,
    error,
  });
  return (dispatch) => {
    dispatch(request());
    chatService.searchConversation(data).then(
      (res) => dispatch(success(res)),
      (error) => dispatch(failure(error))
    );
  };
}

function getMessages(conversation) {
  let request = () => ({ type: chatConstants.GET_MESSAGES_REQUEST });
  let success = (payload) => ({
    type: chatConstants.GET_MESSAGES_SUCCESS,
    payload,
  });
  let failure = (error) => ({ type: chatConstants.GET_MESSAGES_FAIL, error });

  return (dispatch, getState) => {
    dispatch(request());
    return chatService.getMessages(conversation).then(
      (res) => {
        let { data, current_page, total } = res.data;
        let { chat } = getState();
        let selectedCount = 0;
        let payload = {
          conversation: {
            ...chat.conversations.data.find(
              (_conversation) => _conversation.id == conversation.id
            ),
            messages: data,
            currentPage: current_page,
            totalPages: total,
          },
          conversations: {
            ...chat.conversations,
            data: chat.conversations.data.map((_conversation) => {
              if (_conversation.id == conversation.id) {
                selectedCount = _conversation.unreadCount;
                return {
                  ..._conversation,
                  unreadCount: 0,
                };
              }
              return _conversation;
            }),
          },
        };

        payload.count = chat.count - selectedCount;

        // history.push("/chat");
        return dispatch(success(payload));
      },
      (error) => dispatch(failure(error))
    );
  };
}

function loadMessages(conversation) {
  let request = () => ({ type: chatConstants.GET_MESSAGES_REQUEST });
  let success = (payload) => ({
    type: chatConstants.GET_MESSAGES_SUCCESS,
    payload,
  });
  let failure = (error) => ({ type: chatConstants.GET_MESSAGES_FAIL, error });
  return (dispatch, getState) => {
    dispatch(request());
    return chatService.getMessages(conversation).then(
      (res) => {
        let { conversation_id, data, current_page, total } = res.data;
        let { chat } = getState();

        let payload = {};

        if (chat.conversation.id == conversation_id) {
          payload = {
            conversation: {
              ...chat.conversation,
              messages: [
                ...(data || []),
                ...(chat.conversation.messages || []),
              ],
              currentPage: current_page,
              totalPages: total,
            },
          };
        }

        return dispatch(success(payload));
      },
      (error) => dispatch(failure(error))
    );
  };
}

function getLatestMessages(conversation) {
  let request = () => ({ type: chatConstants.GET_LATEST_MESSAGES_REQUEST });
  let success = (payload) => ({
    type: chatConstants.GET_LATEST_MESSAGES_SUCCESS,
    payload,
  });
  let failure = (error) => ({
    type: chatConstants.GET_LATEST_MESSAGES_FAIL,
    error,
  });

  return (dispatch, getState) => {
    dispatch(request());

    return chatService.getMessages(conversation).then(
      (res) => {
        let { data, conversation_id } = res.data;
        let { conversation } = getState().chat;

        if (conversation.id == conversation_id) {
          let newMessages = data.filter(
            (message) =>
              !conversation.messages.some(
                (_message) => _message.id == message.id
              )
          );

          let messages = conversation.messages.map((message) => {
            let newMessage = data.find(
              (newMessage) => newMessage.id == message.id
            );

            if (newMessage) return newMessage;

            return message;
          });

          let payload = {
            conversation: {
              ...conversation,
              messages: [...messages, ...newMessages],
            },
          };

          return dispatch(success(payload));
        }

        return dispatch(success({ loading: false }));
      },
      (error) => dispatch(failure(error))
    );
  };
}

function sendMessage(newMessage) {
  let request = (payload) => ({
    type: chatConstants.SEND_MESSAGE_REQUEST,
    payload,
  });
  let success = (payload) => ({
    type: chatConstants.SEND_MESSAGE_SUCCESS,
    payload,
  });
  let failure = (error) => ({ type: chatConstants.SEND_MESSAGE_FAIL, error });

  return (dispatch, getState) => {
    let { chat } = getState();

    let initialPayload = {
      conversation: {
        ...chat.conversation,
        messages: [...(chat.conversation.messages || []), newMessage],
      },
      conversations: {
        ...chat.conversations,
        data: chat.conversations.data.map((conversation) => {
          if (conversation.id == newMessage.conversation_id) {
            conversation.last_message = newMessage;
            return conversation;
          }
          return conversation;
        }),
      },
    };

    dispatch(request(initialPayload));

    return chatService.sendMessage(newMessage).then(
      (res) => {
        let { chat } = getState();

        let message = {
          ...res.data,
          temp_id: newMessage.temp_id,
        };

        let payload = {
          conversation: {
            ...chat.conversation,
            messages: chat.conversation.messages.map((_message) => {
              if (
                _message.conversation_id == message.conversation_id &&
                _message.temp_id &&
                _message.temp_id == message.temp_id
              ) {
                return message;
              }
              return _message;
            }),
          },
          conversations: {
            ...chat.conversations,
            data: chat.conversations.data.map((conversation) => {
              if (conversation.id == message.conversation_id) {
                conversation.last_message = message;
                return conversation;
              }
              return conversation;
            }),
          },
        };

        return dispatch(success(payload));
      },
      (error) => dispatch(failure(error))
    );
  };
}

function createConversation(data) {
  let request = () => ({ type: chatConstants.CREATE_CONVERSATION_REQUEST });
  let success = (conversation) => {
    history.push("/chat");
    return {
      type: chatConstants.CREATE_CONVERSATION_SUCCESS,
      conversation: conversation.data[0],
    };
  };
  let failure = (error) => ({
    type: chatConstants.CREATE_CONVERSATION_FAIL,
    error,
  });

  return (dispatch) => {
    return new Promise((resolve, reject) => {
    dispatch(request());
    return chatService.createConversation(data).then(
      (res) => {
        dispatch(success(res))
        resolve(res)
      },
      (error) => {
        dispatch(failure(error))
        reject(error);
      }
    );
    });
  };
}

function getRecentMessages() {
  let request = () => ({ type: chatConstants.RECENT_MESSAGES_REQUEST });
  let success = (payload) => {
    return {
      type: chatConstants.RECENT_MESSAGES_SUCCESS,
      payload,
    };
  };
  let failure = (error) => ({
    type: chatConstants.RECENT_MESSAGES_FAIL,
    error,
  });

  return (dispatch, getState) => {
    dispatch(request());
    return chatService.createConversation().then(
      (res) => {
        let _conversations = res.data.reduce((conversations, conversation) => {
          conversations[conversation.id] = conversation;
          return conversations;
        }, {});

        let { conversations } = getState().chat;

        let payload = {
          conversations: {
            ...conversations,
            data: conversations.data.map((item) => {
              if (_conversations[item.id]) {
                return {
                  ...item,
                  ..._conversations[item.id],
                };
              }
              return item;
            }),
          },
        };
        return dispatch(success(payload));
      },
      (error) => dispatch(failure(error))
    );
  };
}

function readAllMessages(conversation) {
  let request = () => ({ type: chatConstants.READ_ALL_MESSAGES_REQUEST });
  let success = () => ({ type: chatConstants.READ_ALL_MESSAGES_SUCCESS });
  let failure = (error) => ({
    type: chatConstants.READ_ALL_MESSAGES_FAIL,
    error,
  });
  return (dispatch) => {
    dispatch(request());
    return chatService.readAllMessages(conversation).then(
      (res) => dispatch(success(res)),
      (error) => dispatch(failure(error))
    );
  };
}

function getAllCounts() {
  let request = () => ({ type: chatConstants.ALL_COUNTS_REQUEST });
  let success = (payload) => ({
    type: chatConstants.ALL_COUNTS_SUCCESS,
    payload,
  });
  let failure = (error) => ({
    type: chatConstants.ALL_COUNTS_FAIL,
    error,
  });
  return (dispatch) => {
    dispatch(request());
    return chatService.getAllCounts().then(
      (res) => {
        let { chat_count } = res.data;
        let payload = {
          count: chat_count,
        };
        dispatch(success(payload));
      },
      (error) => dispatch(failure(error))
    );
  };
}

function updateConversation(data) {
  let request = () => ({ type: chatConstants.UPDATE_CONVERSATION_REQUEST });
  let success = (payload) => ({
    type: chatConstants.UPDATE_CONVERSATION_SUCCESS,
    payload,
  });
  let failure = (error) => ({
    type: chatConstants.UPDATE_CONVERSATION_FAIL,
    error,
  });

  return (dispatch, getState) => {
    dispatch(request());
    return chatService.updateConversation(data).then(
      (res) => {
        let { conversation, conversations } = getState().chat;
        let { id, data } = res.data;

        let payload = {
          conversation: {
            ...conversation,
            ...res.data,
            loading: false,
          },
          conversations: {
            ...conversations,
            data: conversations.data.map((item) => {
              if (item.id == id) {
                return {
                  ...item,
                  ...res.data,
                  data: {
                    ...item.data,
                    ...data,
                  },
                };
              }
              return item;
            }),
            loading: false,
          },
        };

        return dispatch(success(payload));
      },
      (error) => dispatch(failure(error))
    );
  };
}

function addParticipant(formData) {
  let request = () => ({ type: chatConstants.ADD_PARTICIPANT_REQUEST });
  let success = (payload) => ({
    type: chatConstants.ADD_PARTICIPANT_SUCCESS,
    payload,
  });
  let failure = (error) => ({
    type: chatConstants.ADD_PARTICIPANT_FAIL,
    error,
  });

  return (dispatch, getState) => {
    dispatch(request());
    return chatService.addParticipant(formData).then(
      (res) => {
        if (res.success) {
          let { conversation } = getState().chat;
          let { data } = res;
          if (conversation.id == data.id) {
            let payload = {
              conversation: {
                ...conversation,
                ...data,
              },
            };
            history.push("/chat");
            return dispatch(success(payload));
          }
        }
      },
      (error) => dispatch(failure(error))
    );
  };
}

function removeParticipant(data) {
  let request = () => ({ type: chatConstants.REMOVE_PARTICIPANT_REQUEST });

  let success = (payload) => ({
    type: chatConstants.REMOVE_PARTICIPANT_SUCCESS,
    payload,
  });

  let failure = (error) => ({
    type: chatConstants.REMOVE_PARTICIPANT_FAIL,
    error,
  });

  return (dispatch, getState) => {
    dispatch(request());
    return chatService.removeParticipant(data).then(
      (res) => {
        if (res.success) {
          let { conversation } = getState().chat;
          let result = res.data;
          if (conversation.id == result.id) {
            let payload = {
              conversation: {
                ...conversation,
                ...result,
              },
            };
            return dispatch(success(payload));
          }
        }
      },
      (error) => dispatch(failure(error))
    );
  };
}

function setFavorite(data) {
  let request = () => ({ type: chatConstants.SET_FAVORITE_REQUEST });
  let success = (payload) => ({
    type: chatConstants.SET_FAVORITE_SUCCESS,
    payload,
  });
  let failure = (error) => ({ type: chatConstants.SET_FAVORITE_FAIL, error });

  return (dispatch, getState) => {
    dispatch(request());
    return chatService.setFavorite(data).then(
      (res) => {
        let { conversations, conversation } = getState().chat;
        let payload = {
          conversations: {
            ...conversations,
            data: conversations.data.map((_conversation) => {
              if (_conversation.id == res.data.id) {
                return {
                  ..._conversation,
                  ...res.data,
                };
              }
              return _conversation;
            }),
          },
          conversation: {
            ...conversation,
            mark: res.data.mark,
          },
        };
        return dispatch(success(payload));
      },
      (error) => dispatch(failure(error))
    );
  };
}

function updateMessage(message) {
  let request = () => ({ type: chatConstants.UPDATE_MESSAGE_REQUEST });
  let success = (payload) => ({
    type: chatConstants.UPDATE_MESSAGE_SUCCESS,
    payload,
  });
  let failure = (error) => ({ type: chatConstants.UPDATE_MESSAGE_FAIL, error });

  return (dispatch, getState) => {
    dispatch(request());
    return chatService.updateMessage(message).then(
      (res) => {
        let { conversation, conversations } = getState().chat;
        if (res.success) {
          let updateMessage = res.data;

          let payload = {
            conversation: {
              ...conversation,
              messages: conversation.messages.map((_message) => {
                if (_message.id == updateMessage.id)
                  return {
                    message,
                    ...updateMessage,
                  };
                return _message;
              }),
            },
            conversations: {
              ...conversations,
              data: conversations.data.map((_conversation) => {
                if (
                  _conversation.id == updateMessage.conversation_id &&
                  _conversation.last_message &&
                  _conversation.last_message.id == updateMessage.id
                ) {
                  return {
                    ..._conversation,
                    last_message: {
                      ..._conversation.last_message,
                      ...updateMessage,
                    },
                  };
                }
                return _conversation;
              }),
            },
          };

          return dispatch(success(payload));
        }
      },
      (error) => dispatch(failure(error))
    );
  };
}

function deleteMessage(message) {
  let request = () => ({ type: chatConstants.DELETE_MESSAGE_REQUEST });
  let success = (payload) => ({
    type: chatConstants.DELETE_MESSAGE_SUCCESS,
    payload,
  });
  let failure = (error) => ({ type: chatConstants.DELETE_MESSAGE_FAIL, error });

  return (dispatch, getState) => {
    dispatch(request());
    return chatService.deleteMessage(message).then(
      (res) => {
        let { conversation, conversations } = getState().chat;
        let { last_message, deleted_message } = res.data;
        if (res.success) {
          let payload = {
            conversation: {
              ...conversation,
              messages: conversation.messages.map((_message) => {
                if (_message.id == deleted_message.id) {
                  return {
                    ..._message,
                    deleted_at: new Date(),
                    ...deleted_message,
                  };
                }
                return _message;
              }),
            },
            conversations: {
              ...conversations,
              data: conversations.data.map((_conversation) => {
                if (
                  last_message &&
                  _conversation.id == last_message.conversation_id
                ) {
                  return {
                    ..._conversation,
                    last_message: last_message,
                  };
                }
                return _conversation;
              }),
            },
          };
          return dispatch(success(payload));
        }
      },
      (error) => dispatch(failure(error))
    );
  };
}
