import React from "react";
import { connect } from "react-redux";
import { authHeader, config } from "../_helpers";
import Modal from "react-responsive-modal";
import { notificationActions, userActions } from "../_actions";
import { AlertPopup } from "../_components/AlertPopup";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import parse from 'html-react-parser';
import "./draft.css";

class BulletinBoard extends React.Component {
  constructor(props) {

    super(props);
    // this.state = {};
    this.state = {
      message: "",
      editorState: EditorState.createWithContent(
        ContentState.createFromText("")
      ),
      bulletin_files: [],
      title: "",
      filesPreview: [],
      imagesPreview: [],
      id: "",
      files: "",
      attachFileOpen: false,
      deleteBullOpen: false,
      fileError: "",
      step: "create",
      bull_content: [],
      file_list: [],
      file_options: [],
      all_file: [],
      image_options: [],
      delete_id: "",
      alertPop: false,
      bull_action: "",
      editId: "",
      editFileList: [],
      smsCheckbox: 0,
      emailCheckbox: 0,
      saveClick: false,
      successKey: false
    };
    
    this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTextSubmit = this.handleTextSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.attachFileOpenModal = this.attachFileOpenModal.bind(this);
    this.attachFileCloseModal = this.attachFileCloseModal.bind(this);
    this.deleteBullOpenModal = this.deleteBullOpenModal.bind(this);
    this.deleteBullCloseModal = this.deleteBullCloseModal.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.handlePin = this.handlePin.bind(this);
    this.deleteBulletin = this.deleteBulletin.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.updataPin = this.updataPin.bind(this);

    this.onChange = editorState => {
      this.setState({ editorState });
      // console.log( draftToHtml(convertToRaw(editorState.getCurrentContent())) );
      this.setState({
        message: draftToHtml(convertToRaw(editorState.getCurrentContent()))
      });
    };
  }
  _onEditorChange(editorState) {}
  updateEditor(data) {
    var data = (data || "").toString().trim();
    if (!data) return;
    //Below code has been added to resolve font sie issue
    const blocksFromHtml = htmlToDraft(data);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);

    this.setState({
      editorState
    });
    // this.setState({
    //   editorState: EditorState.createWithContent(
    //     ContentState.createFromBlockArray(convertFromHTML(data))
    //   )
    // });
  }
  attachFileOpenModal() {
    this.setState({ attachFileOpen: true });
  }

  attachFileCloseModal() {
    this.setState({ attachFileOpen: false, fileError:"" });
  }
  deleteBullOpenModal(id) {
    this.setState({ deleteBullOpen: true, delete_id: id });
  }

  deleteBullCloseModal() {
    this.setState({ deleteBullOpen: false });
  }

  componentDidMount() {
    this.props.dispatch(notificationActions.getBulletin());
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  closeAlert() {
    this.setState({
      alertPop: false
    });
  }

  changeTab(step) {
    this.setState({
      step,
      title: "",
      message: "",
      file_list: [],
      bull_action: ""
    });
    if (step === "list") {
      this.props.dispatch(notificationActions.getBulletin());
    } else {
      this.setState({ imagesPreview: "" });
    }
  }

  handlePin(id) {
    this.props.dispatch(notificationActions.pinnedPost(id));
  }

  deleteBulletin() {
    this.props.dispatch(
      notificationActions.deleteBulletin(this.state.delete_id)
    );
    this.setState({ deleteBullOpen: false });
  }
  updataPin(id) {
    this.setState({ step: "create", editId: id, editFileList: [] });
    this.props.dispatch(userActions.getSingleBulletin(id));
  }

  componentWillReceiveProps(nextProps) {
    const { bulletin, user } = nextProps;
    if (bulletin.list) {
      const { data } = bulletin.list.data;
      var content = [];
      bulletin.list.data.map((item, index) => {
        content.push(
          <div className="list_bull_item float" key={index}>
            <div className="title bull_cell" >{item.title}</div>
            <div className="message bull_cell has-ul-dot">
               {parse(item.message)}
            </div>
            <div className="action">
              <button
                className="editPin button bull_cell"
                onClick={this.updataPin.bind(this, item.id)}
              >
                Edit
              </button>
              <button
                className="pinPost button bull_cell"
                onClick={this.handlePin.bind(this, item.id)}
              >
                {item.pinned ? "Pinned Post" : "Pin Post"}
              </button>
              <img
                className="close_btn bull_cell"
                src={require("../assets/images/close_button.png").default}
                onClick={this.deleteBullOpenModal.bind(this, item.id)}
              />
            </div>
          </div>
        );
        var file_options = [];
        var image_options = [];
        var file_format = "";
        if (item.bulletin_files) {
          item.bulletin_files.map((file, index2) => {
            if (item.bulletin_files) {
              file_format = file.file_name.split(".").pop().toLowerCase();

              image_options.push(
                <div className="bulletin_item">
                  <p
                    key={index2}
                    className={
                      file_format === "docx"
                        ? "doc green"
                        : file_format + " green"
                    }
                  />
                  <p className="title">
                    {file.title ? file.title : file.file_name}
                    <img
                      className="close_btn"
                      onClick={this.handleDelete.bind(this, file.id)}
                      src={require("../assets/images/close_button.png").default}
                    />
                  </p>
                </div>
              );
            }
          });
        }
        this.setState({
          // message:item.message,
          id: item.id,
          bulletin_files: item.bulletin_files,
          filesPreview: file_options,
          imagesPreview: image_options
        });
      });
      this.setState({ bull_content: content });
    }
    if (user.bullDetail) {
      if (user.bullDetail.data) {
        var data = user.bullDetail.data;
        this.setState({
          title: data.title,
          message: data.message,
          file_list: data.bulletin_files,
          bull_action: "edit"
        });
        this.updateEditor(data.message || "");
      }
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleCheckbox(event) {
    var name = event.target.name;
    this.setState({
      [name]: event.target.checked ? 1 : 0
    });
  }
  //
  handleDelete(id, edit, fileId) {
    let data = new FormData();
    data.append("id", id);
    if (edit === "") {
      if (this.state.bull_action === "edit") {
        let data = new FormData();
        data.append("id", fileId);
        this.props.dispatch(userActions.deleteBulletinFile(data));
      }
      this.state.file_list.map((item, index) => {
        if (index === id) {
          this.state.file_list.splice(id, 1);
        }
      });
    } else {
      this.state.editFileList.map((item, index) => {
        if (index === id) {
          this.state.editFileList.splice(id, 1);
        }
      });
    }

    this.setState({ file_list: this.state.file_list });
    var content = [],
      file_format,
      image_options = [];

    // this.state.file_list.map((item, index) => {
    //     // file_format = item.name.split('.').pop();
    //     image_options.push(<div className="bulletin_item"><p key={'file'+index} className={file_format === "docx" ? "doc green" : file_format+" green"}></p><p className="title">{item.name}<img className="close_btn" onClick={this.handleDelete.bind(this,index)} src={require("../assets/images/close_green.png")}/></p></div>);
    // })
    // this.setState({ image_options })
    // this.props.dispatch(notificationActions.deleteFile(data));
  }
  //
  handleTextSubmit(event) {
    event.preventDefault();
    this.setState({ saveClick: true });
    let data = new FormData();
    if (this.state.bull_action === "edit") {
      this.state.editFileList.map((file, index) => {
        return data.append("files[]", file);
      });
    } else {
      this.state.file_list.map((file, index) => {
        return data.append("files[]", file);
      });
    }

    data.append("title", this.state.title);
    data.append("message", this.state.message);
    data.append("email", this.state.emailCheckbox);
    data.append("sms", this.state.smsCheckbox);
    if (this.state.bull_action === "edit") {
      data.append("id", this.state.editId);
    }
    var self = this,
      errMessage = "";
    fetch(config.apiUrl + "/api/bulletin/publish", {
      headers: authHeader(),
      method: "POST",
      body: data
    })
      .then(function(res) {
        // self.setState({ successKey: true })
        return res.json();
      })
      .then(function(data) {
        // self.setState({ bull_action: ""})
        if (data.success) {
          self.setState({
            alertPop: true,
            alertHead: "Success",
            alertMsg: JSON.parse(JSON.stringify(data.message)),
            alertFunction: self.changeTab.bind(this, "list"),
            saveClick: false
          });
          self.setState({ title: "", message: "", file_list: [] });
        } else {
          // if(Array.isArray(data.message)){
          //     for(var key in data.message){
          //         errMessage = JSON.parse(JSON.stringify( data.message[key] ))
          //     }
          // } else{
          //     errMessage = JSON.parse(JSON.stringify( data.message ))
          // }

          if (typeof data.message === "object") {
            for (var key in data.message) {
              errMessage = JSON.parse(JSON.stringify(data.message[key][0]));
              // console.log(JSON.parse(JSON.stringify( inventory.inventory.message[key][0] )))
            }
          } else {
            errMessage = JSON.parse(JSON.stringify(data.message));
          }

          self.setState({
            alertPop: true,
            alertHead: "Error",
            alertMsg: errMessage,
            alertFunction: "",
            saveClick: false
          });
        }

        return;
        // alert( JSON.stringify( data.message ) ) JSON.parse(nextProps.authentication.error).error
      });
  }

  validate(e) {
    let isError = false;
  }

  handleSubmit(event) {
    event.preventDefault();
    const err = this.validate();
    var file_list_array = [],
      file_options = [],
      file_format,
      all_file = [];
    if (this.state.bull_action === "edit") {
      this.state.editFileList.push(this.state.files);
      this.setState({
        attachFileOpen: false,
        editFileList: this.state.editFileList
      });

      // this.state.editFileList.map((item, index)=>{
      //     file_format = item.name.split('.').pop();

      //     file_options.push(<div className="bulletin_item"><p key={index}className={file_format === "docx" ? "doc green" : file_format+" green"}></p><p className="title">{item.name}<img className="close_btn" onClick={this.handleDelete.bind(this,index)} src={require("../assets/images/close_green.png")}/></p></div>);
      //     all_file.push(item)
      // })

      // this.setState({ file_options, all_file, image_options:file_options })
    } else {
      this.state.file_list.push(this.state.files);
      this.setState({ attachFileOpen: false, file_list: this.state.file_list });
      // this.state.file_list.map((item, index)=>{
      //     file_format = item.name.split('.').pop();

      //     file_options.push(<div className="bulletin_item"><p key={index}className={file_format === "docx" ? "doc green" : file_format+" green"}></p><p className="title">{item.name}<img className="close_btn" onClick={this.handleDelete.bind(this,index)} src={require("../assets/images/close_green.png")}/></p></div>);
      //     all_file.push(item)
      // })

      // this.setState({ file_options, all_file, image_options:file_options })
    }
  }

  _handleImageChange(e) {
    e.preventDefault();
    this.setState({
      fileError:""
    });
    let reader = new FileReader();
    let file = e.target.files[0];
    if(file)
    {
      let ext = file.name.split('.').pop().toLowerCase();

      if([
        'jpg','jpeg','png','gif','doc',
        'docx','pdf','zip','txt','eps',
        'mp3','mov','ppt','avi','wav','rar'].includes(ext))
      {
        this.setState({ files: file });
      }
      else
      {
        this.setState({
          fileError:'Invalid file type.'
        });
      }
    }         
    else
    {
        this.setState({
            fileError:'Invalid file.'
        });
    } 
  }
  //
  render() {
    var content = [],
      file_format,
      image_options = [],
      new_image_options = [];
    const { bulletin } = this.props;

    this.state.file_list.map((item, index) => {
      if (this.state.bull_action === "edit") {
        file_format = item.file_name.split(".").pop().toLowerCase();
      } else {
        file_format = item.name.split(".").pop().toLowerCase();
      }
      image_options.push(
        <div className="bulletin_item" key={index}>
          <p
            key={"file" + index}
            className={
              file_format === "docx" ? "doc green" : file_format + " green"
            }
          />
          <p className="title">
            {this.state.bull_action === "edit" ? item.file_name : item.name}
            <img
              className="close_btn"
              onClick={this.handleDelete.bind(this, index, "", item.id)}
              src={require("../assets/images/close_button.png").default}
            />
          </p>
        </div>
      );
    });
    this.state.editFileList.map((item, index) => {
      file_format = item.name.split(".").pop().toLowerCase();
      new_image_options.push(
        <div className="bulletin_item" key={index}>
          <p
            key={"file" + index}
            className={
              file_format === "docx" ? "doc green" : file_format + " green"
            }
          />
          <p className="title">
            {item.name}
            <img
              className="close_btn"
              onClick={this.handleDelete.bind(this, index, "edit", "")}
              src={require("../assets/images/close_button.png").default}
            />
          </p>
        </div>
      );
    });

    const { editorState } = this.state;
    return (
      <div className="content_div bulletin settingsBull">
        <div className="bulletin_outer_div">
          <div className="bulletin_tabs">
            <div
              className={this.state.step === "create" ? "active" : ""}
              onClick={this.changeTab.bind(this, "create")}
            >
              Create Bulletin
            </div>
            <div
              className={this.state.step === "list" ? "active" : ""}
              onClick={this.changeTab.bind(this, "list")}
            >
              List
            </div>
          </div>
          {this.state.step === "create" && (
            <div className="new_bulletin">
              <div className="create_bulletin">
                <div className="new_title">
                  <label>Title</label>
                  <input
                    type="text"
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
                <label>Write a message</label>

                <div className="fs-block">
                  <Editor
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "list",
                        "history"
                      ]
                    }}
                    editorState={editorState}
                    placeholder="Write a message"
                    onEditorStateChange={this.onChange}
                  />
                </div>

                {/* <textarea className="bulletin_txt" name="message" cols="80" rows="10" placeholder="Start typing here..." value={this.state.message} onChange={this.handleChange.bind(this)}></textarea> */}

                <div className="opt-msg-select bulletin">
                  <input
                    name="emailCheckbox"
                    className="opt-email opt-select"
                    type="checkbox"
                    onChange={this.handleCheckbox.bind(this)}
                  />
                  <label>Email</label>
                  <input
                    name="smsCheckbox"
                    className="opt-sms opt-select"
                    type="checkbox"
                    onChange={this.handleCheckbox.bind(this)}
                  />
                  <label>SMS</label>
                </div>
              </div>
              <div className="clearFix" />
              <div className="preview" />
              <div className="preview" />
              <div className="bulletin_link">{this.state.filesPreview}</div>
              {this.state.imagesPreview.length > 0 && (
                <h2 className="attach_head">Attach Document</h2>
              )}
              <div className="bulletin_imgs float">
                {/*this.state.file_options*/}
                {image_options}
                {new_image_options}
              </div>
              <div className="btn_group">
                {this.state.saveClick && (
                  <img
                    className="login_loader"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                  />
                )}
                <button
                  className="button grey"
                  onClick={this.attachFileOpenModal.bind(this)}
                >
                  Attach File
                </button>
                <button
                  className="button"
                  onClick={this.handleTextSubmit.bind(this)}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
          {this.state.step === "list" && (
            <div className="list_bulletin float">
              {/*<h2>List</h2>*/}
              <div className="list_bull_item float appln_list_header">
                <div className="title bull_cell">Title</div>
                <div className="message bull_cell">Message</div>
                <div className="action" />
              </div>
              {this.state.bull_content}
            </div>
          )}

          <Modal
            open={this.state.attachFileOpen}
            onClose={this.attachFileCloseModal.bind(this)}
            little
          >
            <div id="attachFilePopup">
              <div className="modal-head">
                <h4 className="modal-title">Add Document</h4>
              </div>
              <div className="modal-body">
                {/*<label>Title</label>
                                    <input type="text" name="title" value={this.state.title} onChange={this.handleChange.bind(this)}></input>*/}
                <span className="error-message">{this.state.fileError?this.state.fileError:''}</span>
                <input
                  type="file"
                  name="file"
                  errors={this.state.fileError}
                  id="file"
                  onChange={e => this._handleImageChange(e)}
                />
                {this.state.fileError === ""?<button
                  className="button"
                  onClick={this.handleSubmit.bind(this)}
                >
                  Submit
                </button>:''}
              </div>
            </div>
          </Modal>

          <Modal
            open={this.state.deleteBullOpen}
            onClose={this.deleteBullCloseModal.bind(this)}
            little
          >
            <div id="deleteBullPopup">
              <div className="modal-head">
                <h4 className="modal-title">Confirm</h4>
              </div>
              <div className="modal-body">
                {/*<label>Title</label>
                                    <input type="text" name="title" value={this.state.title} onChange={this.handleChange.bind(this)}></input>*/}
                <p>Are you sure you want to remove this bulletin?</p>
                <div className="btn_list">
                  <button
                    className="button grey"
                    onClick={this.deleteBullCloseModal.bind(this)}
                  >
                    Cancel
                  </button>
                  <button
                    className="button"
                    onClick={this.deleteBulletin.bind(this)}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {this.state.alertPop === true && (
            <AlertPopup
              head={this.state.alertHead}
              message={this.state.alertMsg}
              alertFunction={this.state.alertFunction}
              close={this.closeAlert.bind(this)}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registering } = state.registration;
  const { bulletin, dltBulletin, user } = state;
  return {
    registering,
    bulletin,
    dltBulletin,
    user
  };
}

const connectedSettingsPage = connect(mapStateToProps)(BulletinBoard);
export { connectedSettingsPage as BulletinBoard };
