import { resourceConstants } from '../_constants';

export function vehicle(state = {}, action) {
  switch (action.type) {
    case resourceConstants.GETVEHICLE_REQUEST:
      return {
        loading: true
      };
    case resourceConstants.GETVEHICLE_SUCCESS:
      return {
        items: action.vehicle
      };
    case resourceConstants.GETVEHICLE_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}