export const notificationConstants = {
  GETALL_REQUEST: "NOTIFICATION_GETALL_REQUEST",
  GETALL_SUCCESS: "NOTIFICATION_GETALL_SUCCESS",
  GETALL_FAILURE: "NOTIFICATION_GETALL_FAILURE",

  GETBULLET_REQUEST: "BULLETIN_GETALL_REQUEST",
  GETBULLET_SUCCESS: "BULLETIN_GETALL_SUCCESS",
  GETBULLET_FAILURE: "BULLETIN_GETALL_FAILURE",

  GETLASTSEEN_REQUEST: "NOTIFICATION_GETLASTSEEN_REQUEST",
  GETLASTSEEN_SUCCESS: "NOTIFICATION_GETLASTSEEN_SUCCESS",
  GETLASTSEEN_FAILURE: "NOTIFICATION_GETLASTSEEN_FAILURE",

  MARKALL_REQUEST: "NOTIFICATION_MARKALL_REQUEST",
  MARKALL_SUCCESS: "NOTIFICATION_MARKALL_SUCCESS",
  MARKALL_FAILURE: "NOTIFICATION_MARKALL_FAILURE",

  SETMARK_REQUEST: "NOTIFICATION_SETMARK_REQUEST",
  SETMARK_SUCCESS: "NOTIFICATION_SETMARK_SUCCESS",
  SETMARK_FAILURE: "NOTIFICATION_SETMARK_FAILURE",

  GETCOUNT_REQUEST: "NOTIFICATION_GETCOUNT_REQUEST",
  GETCOUNT_SUCCESS: "NOTIFICATION_GETCOUNT_SUCCESS",
  GETCOUNT_FAILURE: "NOTIFICATION_GETCOUNT_FAILURE",

  PINNED_REQUEST: "NOTIFICATION_PINNED_REQUEST",
  PINNED_SUCCESS: "NOTIFICATION_PINNED_SUCCESS",
  PINNED_FAILURE: "NOTIFICATION_PINNED_FAILURE",

  DELETEBULLET_REQUEST: "NOTIFICATION_DELETEBULLET_REQUEST",
  DELETEBULLET_SUCCESS: "NOTIFICATION_DELETEBULLET_SUCCESS",
  DELETEBULLET_FAILURE: "NOTIFICATION_DELETEBULLET_FAILURE"
};
