export const docsConstants = {
  GENERATE_DOCS_REQUEST: "GENERATE_DOCS_REQUEST",
  GENERATE_DOCS_SUCCESS: "GENERATE_DOCS_SUCCESS",
  GENERATE_DOCS_FAILURE: "GENERATE_DOCS_FAILURE",

  // get doc of particular user
  DOCS_LIST_REQUEST: "DOCS_LIST_REQUEST",
  DOCS_LIST_SUCCESS: "DOCS_LIST_SUCCESS",
  DOCS_LIST_FAILURE: "DOCS_LIST_FAILURE",

  DOCS_SELECT_REQUEST: "DOCS_SELECT_REQUEST",
  DOCS_SELECT_SUCCESS: "DOCS_SELECT_SUCCESS",
  DOCS_SELECT_FAILURE: "DOCS_SELECT_FAILURE",

  DOCS_LIST_ALL_REQUEST: "DOCS_LIST_ALL_REQUEST",
  DOCS_LIST_ALL_SUCCESS: "DOCS_LIST_ALL_SUCCESS",
  DOCS_LIST_ALL_FAILURE: "DOCS_LIST_ALL_FAILURE",

  DOCS_LIST_FILTER_REQUEST: "DOCS_LIST_FILTER_REQUEST",
  DOCS_LIST_FILTER_SUCCESS: "DOCS_LIST_FILTER_SUCCESS",
  DOCS_LIST_FILTER_FAILURE: "DOCS_LIST_FILTER_FAILURE",

  // get types of docs
  DOCS_TYPE_REQUEST: "DOCS_TYPE_REQUEST",
  DOCS_TYPE_SUCCESS: "DOCS_TYPE_SUCCESS",
  DOCS_TYPE_FAILURE: "DOCS_TYPE_FAILURE",

  // get doc category
  DOCS_CATEGORY_REQUEST: "DOCS_CATEGORY_REQUEST",
  DOCS_CATEGORY_SUCCESS: "DOCS_CATEGORY_SUCCESS",
  DOCS_CATEGORY_FAILURE: "DOCS_CATEGORY_FAILURE",

  // get docs in particular category
  CATEGORY_DOCS_REQUEST: "CATEGORY_DOCS_REQUEST",
  CATEGORY_DOCS_SUCCESS: "CATEGORY_DOCS_SUCCESS",
  CATEGORY_DOCS_FAILURE: "CATEGORY_DOCS_FAILURE",

  // get individual form contents
  DOCS_FORM_GET_REQUEST: "DOCS_FORM_GET_REQUEST",
  DOCS_FORM_GET_SUCCESS: "DOCS_FORM_GET_SUCCESS",
  DOCS_FORM_GET_FAILURE: "DOCS_FORM_GET_FAILURE",

  FORM_OPTIONS_REQUEST: "FORM_OPTIONS_REQUEST",
  FORM_OPTIONS_SUCCESS: "FORM_OPTIONS_SUCCESS",
  FORM_OPTIONS_FAILURE: "FORM_OPTIONS_FAILURE",

  // get pdf for docs
  DOCS_PDF_REQUEST: "DOCS_PDF_REQUEST",
  DOCS_PDF_SUCCESS: "DOCS_PDF_SUCCESS",
  DOCS_PDF_FAILURE: "DOCS_PDF_FAILURE",

  // get docs stats
  DOCS_STATS_REQUEST: "DOCS_STATS_REQUEST",
  DOCS_STATS_SUCCESS: "DOCS_STATS_SUCCESS",
  DOCS_STATS_FAILURE: "DOCS_STATS_FAILURE",

  // get docs stats
  DOCS_SAVE_REQUEST: "DOCS_SAVE_REQUEST",
  DOCS_SAVE_SUCCESS: "DOCS_SAVE_SUCCESS",
  DOCS_SAVE_FAILURE: "DOCS_SAVE_FAILURE",
};
