import React from "react";
import { useSelector } from "react-redux";
import { getCurrentUserPermissions } from "_helpers/reduxSelector";
import "../assets/styles/applicants.css";

export default function ApplicationHistoryAlert() {
  const currentUserPermissions = useSelector(getCurrentUserPermissions);

  return currentUserPermissions?.includes("Manage Applicant History") ? (
    <div className="application_history_alert_container">
      <p className="application_history_alert_text">
        This employee has previous employment history within NVAM companies.
        Please check the Employment History tab for more details.
      </p>
    </div>
  ) : (
    <></>
  );
}
