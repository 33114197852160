import React from "react";
import { connect } from "react-redux";
import { authHeader, config } from "../_helpers";
import { applicantsActions, permissionActions } from "../_actions";
import { Link } from "react-router-dom";
import "../assets/styles/applicants.css";
import { getApplicantStatus } from "_utils/utils";

// To format the phone number
function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
}


const getAppliedCompany={
  1:"About Trees Applicant",
  2:"NVTS Applicant"
}
class ArchivedApplicant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: true,
      applicationItem: [],
      showComponent: false,
      type: "archive",
      keyword: "",
      resultData: [],
      order: true,
      sort: "date",
      nameOrder: false,
      emailOrder: false,
      phoneOrder: false,
      dateOrder: false,
      areaOrder: false,
      certOrder: false,
      orderAll: "asc",
      keySearch: ""
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.viewDetails = this.viewDetails.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.goBack = this.goBack.bind(this);
    this.keyUpFn = this.keyUpFn.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.sorting = this.sorting.bind(this);
  }
  componentDidMount() {
    this.componentWillReceiveProps(this.props);
    this.props.dispatch(applicantsActions.getArchived());
    this.props.dispatch(permissionActions.getPermission());
  }

  componentWillReceiveProps(nextProps) {
    window.addEventListener("scroll", this.handleScroll);
    const { applicants } = nextProps;
    if (applicants.notes) {
      if (applicants.notes.success) {
        this.props.dispatch(applicantsActions.getArchived());
      }
    }
    if (applicants.items) {
      this.setState({ resultData: applicants.items.data });

      var applicationItem = [];
      if (applicants.items.data.data) {
        if (
          applicants.items.data.data &&
          applicants.items.data.data.length > 0
        ) {
          applicants.items.data.data.map((item, index) => {
            applicationItem.push(
              <div className="applicant_item" key={index}>
                <div className="basic_info">
                  <div className="appln_name">
                    {item.first_name + " " + item.last_name}
                  </div>
                  <div className="appln_company">
                    {getAppliedCompany[item.applying_company]}
                  </div>
                  <div className="appln_email">
                    {item.email_address ? (
                      <a
                        onClick={event => {
                          event.stopPropagation();
                        }}
                        className="phone_email_link"
                        href={`mailto:${item.email_address}`}
                        target="_top"
                      >
                        {item.email_address}
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="appln_phone">
                    {item.phone_number ? (
                      <a
                        onClick={event => {
                          event.stopPropagation();
                        }}
                        className="phone_email_link"
                        href={`tel:${item.phone_number}`}
                        target="_top"
                      >
                        {formatPhoneNumber(item.phone_number)}
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="appln_date">{item.date}</div>

                  <div className="appln_status">{getApplicantStatus[item.status]}</div>
                  {/* <div className="appln_area">{item.area}</div> Disabled for NVAM clone*/}
                  {/* <div className="appln_cert_status">{item.cert_status}</div> Disabled for NVAM clone */}
                </div>
                <div className="actions" style={{ display: "flex" }}>
                  {!item?.interview_form_count ? (
                    <div className="applicant_no_interview_forms_icon" style={{ position: "unset", maxWidth: 40, transform: "unset" }}>
                      <img src={`${config.assetUrl}icons/interview_form_not_filled.svg`} alt="Interview form not filled" />
                      <span className="applicant_no_interview_forms_tooltip">
                        No interview forms are available.
                      </span>
                    </div>
                  ) : null}
                  <textarea
                    className="alb-info left"
                    id="single_note"
                    contentEditable=""
                    placeholder="Notes..."
                    data-hash={item.hash}
                    autoComplete="off"
                    onBlur={this.updateNotes.bind(this, item.hash)}
                    defaultValue={item.applicant_info || ''}
                  />
                  <Link to={"/applicant-view/" + item.hash} target="_blank">
                    <img
                      alt={item.first_name + " " + item.last_name}
                      src={require("../assets/images/view.png").default}
                      className="view"
                    />
                  </Link>
                </div>
              </div>
            );
            return applicationItem;
          });
        } else {
          applicationItem.push(
            <div className="applicant_item">
              <div className="basic_info">
                <p className="noResult">No search results were found</p>
              </div>
            </div>
          );
        }
      }
      this.setState({ applicationItem: applicationItem });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleChange() {}
  goBack() {
    this.props.goBack();
  }

  sorting(sort, e) {
    this.setState({ order: !this.state.order, sort });
    if (sort === "name") {
      this.setState({
        nameOrder: !this.state.nameOrder,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: false,
        certOrder: false,
        jobStatusOrder:false,
        companyOrder:false,
        dateOrder: false
      });
    }
    if(sort==="applying_company"){
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: false,
        certOrder: false,
        dateOrder: false,
        jobStatusOrder:false,
        companyOrder:!this.state.companyOrder
      });
    }
    if (sort === "email") {
      this.setState({
        nameOrder: false,
        emailOrder: !this.state.emailOrder,
        phoneOrder: false,
        areaOrder: false,
        certOrder: false,
        jobStatusOrder:false,
        companyOrder:false,
        dateOrder: false
      });
    }
    if (sort === "phone") {
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: !this.state.phoneOrder,
        areaOrder: false,
        certOrder: false,
        jobStatusOrder:false,
        companyOrder:false,
        dateOrder: false
      });
    }
    if (sort === "area") {
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: !this.state.areaOrder,
        certOrder: false,
        jobStatusOrder:false,
        companyOrder:false,
        dateOrder: false
      });
    }
    if (sort === "cert") {
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: false,
        jobStatusOrder:false,
        companyOrder:false,
        certOrder: !this.state.certOrder,
        dateOrder: false
      });
    }
    if (sort === "date") {
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: false,
        certOrder: false,
        jobStatusOrder:false,
        companyOrder:false,
        dateOrder: !this.state.dateOrder
      });
    }
    if (sort === "job_status") {
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: false,
        certOrder: false,
        dateOrder: false,
        companyOrder:false,
        jobStatusOrder: !this.state.jobStatusOrder
      });
    }
    var order;
    if (this.state.order) {
      order = "asc";
    } else {
      order = "desc";
    }
    this.setState({ orderAll: order }, function() {});
    let data = new FormData();
    data.append("keyword", this.state.keySearch);
    data.append("sort", sort);
    data.append("order", order);
    data.append("type", this.state.type);
    this.props.dispatch(applicantsActions.searchApplicants(data));
  }

  keyUpFn(event) {
    var value = event.target.value;
    this.setState({ keySearch: value });
    clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(
      function() {
        this.handleSearch(value);
      }.bind(this),
      1000
    );
  }
  handleSearch(text) {
    this.setState({ keyword: text });
    let data = new FormData();
    data.append("keyword", text);
    data.append("type", this.state.type);
    data.append("sort", this.state.sort);
    data.append("order", this.state.orderAll);
    this.props.dispatch(applicantsActions.searchApplicants(data));
  }
  handleFilter(event) {
    var option = event.target.value;
    this.setState({ type: option });
    let data = new FormData();
    data.append("keyword", this.state.keyword);
    data.append("type", option);
    data.append("sort", this.state.sort);
    data.append("order", this.state.orderAll);
    this.props.dispatch(applicantsActions.searchApplicants(data));
  }
  viewDetails(hash) {
    this.props.viewDetails(hash, "archived");
  }
  sendMail(email) {
    this.props.sendMail(email);
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }
  updateNotes(hash, e) {
    const { permission } = this.props;
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }
    if (permList.includes("Update Applicant Note")) {
      let data = new FormData();
      data.append("hash", hash);
      data.append("note", e.target.value);
      this.props.dispatch(applicantsActions.updateNote(data));
    }
  }

  handleScroll(event) {
    var method;
    var wrap = document.getElementById("right_sidebar"),
      contentHeight = wrap.offsetHeight,
      yOffset = window.pageYOffset,
      y = yOffset + window.innerHeight,
      applicationItem = [];
    if (y >= contentHeight) {
      let data = this.state.resultData;

      if (data.current_page < data.last_page && this.state.flag !== false) {
        var requestOptions = {};
        if (data.next_page_url.indexOf("search") !== -1) {
          method = "POST";
          let bdata = new FormData();
          bdata.append("keyword", this.state.keySearch);
          bdata.append("type", this.state.type);
          bdata.append("sort", this.state.sort);
          bdata.append("order", this.state.orderAll);
          requestOptions = {
            method: method,
            headers: authHeader(),
            body: bdata
          };
        } else {
          method = "GET";
          requestOptions = {
            method: method,
            headers: authHeader()
          };
        }
        fetch(data.next_page_url, requestOptions)
          .then(res => {
            return res.json();
          })
          .then(data => {
            this.setState({ resultData: data.data });
            data.data.data.map((item, index) => {
              applicationItem.push(
                <div className="applicant_item" key={index}>
                  <div className="basic_info">
                    <div className="appln_name">
                      {item.first_name + " " + item.last_name}
                    </div>
                    <div className="appln_company">
                    {getAppliedCompany[item.applying_company]}
                  </div>
                    <div className="appln_email">
                      {item.email_address ? (
                        <a
                          onClick={event => {
                            event.stopPropagation();
                          }}
                          className="phone_email_link"
                          href={`mailto:${item.email_address}`}
                          target="_top"
                        >
                          {item.email_address}
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="appln_phone">
                      {item.phone_number ? (
                        <a
                          onClick={event => {
                            event.stopPropagation();
                          }}
                          className="phone_email_link"
                          href={`tel:${item.phone_number}`}
                          target="_top"
                        >
                          {formatPhoneNumber(item.phone_number)}
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="appln_date">{item.date}</div>
                    {/* <div className="appln_area">{item.area}</div> */}
                    <div className="appln_status">{getApplicantStatus[item.status]}</div>
                    {/* <div className="appln_cert_status">{item.cert_status}</div> Disabled for NVAM clone*/}
                  </div>
                  <div className="actions" style={{ display: "flex" }}>
                    {!item?.interview_form_count ? (
                      <div className="applicant_no_interview_forms_icon" style={{ position: "unset", maxWidth: 40, transform: "unset" }}>
                        <img src={`${config.assetUrl}icons/interview_form_not_filled.svg`} alt="Interview form not filled" />
                        <span className="applicant_no_interview_forms_tooltip">
                          No interview forms are available.
                        </span>
                      </div>
                    ) : null}
                    <textarea
                      className="alb-info left"
                      id="single_note"
                      contentEditable=""
                      placeholder="Notes..."
                      data-hash={item.hash}
                      autoComplete="off"
                      onBlur={this.updateNotes.bind(this, item.hash)}
                      defaultValue={item.applicant_info || ''}
                    />
                    <Link to={"/applicant-view/" + item.hash} target="_blank">
                      <img
                        alt={item.first_name + " " + item.last_name}
                        src={require("../assets/images/view.png").default}
                        className="view"
                        onClick={this.viewDetails.bind(this, item.hash)}
                      />
                    </Link>
                  </div>
                </div>
              );
              return applicationItem;
            });
            this.setState({
              applicationItem: [
                ...this.state.applicationItem,
                ...applicationItem
              ]
            });
          })
          .catch(err => console.log(err));
      }
    }
  }
  render() {
    const applicants = this.props.applicants;
    var nameClass, emailClass, phoneClass, dateClass, areaClass,companyClass, certClass,jobStatusClass;
    if (this.state.sort === "name") {
      if (this.state.nameOrder && this.state.sort === "name") {
        nameClass = "sort-arrow desc";
      } else {
        nameClass = "sort-arrow asc";
      }
      emailClass = phoneClass = dateClass = areaClass = certClass = jobStatusClass = companyClass =
        "sort-arrow";
    }
    if (this.state.sort === "applying_company") {
      if (this.state.companyOrder && this.state.sort === "applying_company") {
        companyClass = "sort-arrow desc";
      } else {
        companyClass = "sort-arrow asc";
      }
      emailClass = phoneClass = dateClass = areaClass = certClass = jobStatusClass = 
        "sort-arrow";
    }
    if (this.state.sort === "email") {
      if (this.state.emailOrder && this.state.sort === "email") {
        emailClass = "sort-arrow desc";
      } else {
        emailClass = "sort-arrow asc";
      }
      nameClass = phoneClass = dateClass = areaClass = certClass = companyClass = jobStatusClass = "sort-arrow";
    }
    if (this.state.sort === "phone") {
      if (this.state.phoneOrder && this.state.sort === "phone") {
        phoneClass = "sort-arrow desc";
      } else {
        phoneClass = "sort-arrow asc";
      }
      nameClass = emailClass = dateClass = areaClass = certClass = companyClass = jobStatusClass = "sort-arrow";
    }
    if (this.state.sort === "date") {
      if (this.state.dateOrder && this.state.sort === "date") {
        dateClass = "sort-arrow desc";
      } else {
        dateClass = "sort-arrow asc";
      }
      nameClass = emailClass = areaClass = phoneClass = certClass = jobStatusClass = companyClass =
        "sort-arrow";
    }
    if (this.state.sort === "job_status") {
      if (this.state.jobStatusOrder && this.state.sort === "job_status") {
        jobStatusClass = "sort-arrow desc";
      } else {
        jobStatusClass = "sort-arrow asc";
      }
      nameClass = emailClass = areaClass = phoneClass = certClass = companyClass =
        "sort-arrow";
    }
    if (this.state.sort === "area") {
      if (this.state.areaOrder && this.state.sort === "area") {
        areaClass = "sort-arrow desc";
      } else {
        areaClass = "sort-arrow asc";
      }
      nameClass = emailClass = dateClass = phoneClass = certClass = jobStatusClass = companyClass =
        "sort-arrow";
    }
    if (this.state.sort === "cert") {
      if (this.state.certOrder && this.state.sort === "cert") {
        certClass = "sort-arrow desc";
      } else {
        certClass = "sort-arrow asc";
      }
      nameClass = emailClass = dateClass = phoneClass = areaClass = jobStatusClass = companyClass =
        "sort-arrow";
    }

    if (applicants.items) {
      // if(applicants.items.data.data.length > 0){
      return (
        <div className="applicants_container">
          <div className="breadcrumbs">
            <span onClick={this.goBack.bind(this)} className="green">
              Applicants
            </span>{" "}
            / Archived Applicants
          </div>
          <div className="page_title">
            <h1>Archived Applicants</h1>
          </div>
          <div className="top_btn_group" />
          <div className="applicants_inner_head inner_head inner_head_mob">
            <select
              className="applicant_filter"
              name="filter"
              value={this.state.type ? this.state.type : "archive"}
              onChange={this.handleFilter.bind(this)}
            >
              <option value="archive">All</option>
              <option value="hire">Was Hired</option>
              <option value="remove">Not Selected</option>
              <option value="donthire">Do Not Hire</option>
            </select>
            <input
              type="text"
              name="keyword"
              defaultValue={this.state.keySearch}
              className="applicant_search"
              placeholder="Search..."
              onKeyUp={this.keyUpFn.bind(this)}
              autoComplete="off"
            />
          </div>
          <div
            className="inner_container archived_applicant col-lg-12"
            id="applicants_inner"
          >
            <div className="clearFix" />
            <div
              className="applicants_list"
              id="applicants_list"
              onScroll={this.handleScroll}
            >
              <div className="appln_list_header">
                <div
                  className="list_item_head appln_name"
                  onClick={this.sorting.bind(this, "name")}
                >
                  Name
                  <span className={nameClass} />
                </div>
                <div
                  className="list_item_head appln_company"
                  onClick={this.sorting.bind(this, "applying_company")}
                >
                  Company
                  <span className={companyClass} />
                </div>
                <div
                  className="list_item_head appln_email"
                  onClick={this.sorting.bind(this, "email")}
                >
                  Email
                  <span className={emailClass} />
                </div>
                <div
                  className="list_item_head appln_phone"
                  onClick={this.sorting.bind(this, "phone")}
                >
                  Phone#
                  <span className={phoneClass} />
                </div>
                <div
                  className="list_item_head appln_date"
                  onClick={this.sorting.bind(this, "date")}
                >
                  Date
                  <span className={dateClass} />
                </div>
                <div
                  className="list_item_head appln_status"
                  onClick={this.sorting.bind(this, "job_status")}
                >
                  Status
                  <span className={jobStatusClass} />
                </div>
                {/* <div  //disabled for NVAM clone
                  className="list_item_head appln_area"
                  onClick={this.sorting.bind(this, "area")}
                >
                  Area
                  <span className={areaClass} />
                </div> */}
                {/* <div 
                  className="list_item_head"
                  onClick={this.sorting.bind(this, "cert")}
                >
                  Cert. Status
                  <span className={certClass} />
                </div> */}
              </div>

              {this.state.applicationItem}
            </div>
          </div>
        </div>
      );
      // }
    }
    // Showing loader
    return (
      <div className="applicants_container archived_page">
        <div className="page_title">
          <h1>Archived Applicants</h1>
        </div>
        <div className="top_btn_group" />
        <div className="applicants_inner_head inner_head">
          <input
            type="text"
            name="keyword"
            defaultValue={this.state.keySearch}
            className="applicant_search"
            placeholder="Search..."
            onKeyUp={this.keyUpFn.bind(this)}
            autoComplete="off"
          />
        </div>
        <div
          className="inner_container archived_applicant col-lg-12"
          id="applicants_inner"
        >
          <center
            id="feedLoader"
            className={this.state.loader ? this.state.loader : ""}
          >
            <img alt="Loader" src={require("../assets/images/loader.gif").default} />
          </center>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { applicants, permission } = state;
  return {
    applicants,
    permission
  };
}
const connectedHomePage = connect(mapStateToProps)(ArchivedApplicant);
export { connectedHomePage as ArchivedApplicant };
