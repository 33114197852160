import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { resourceActions } from "_actions"
import { selectEmployementHistory } from "_helpers/reduxSelector"
import EmploymentHistoryList from "./EmploymentHistoryList"



export default function EmploymentHistory({ hash }) {

    const dispatch = useDispatch()

    const employmentHistory = useSelector(selectEmployementHistory)


    const getEmploymentHistory = (data) => {
        dispatch(resourceActions.getEmploymentHistory(data))
    }

    return (
        <>
            <div style={{ padding: 20, display: "flex", flexDirection: "column" }}>
                <div style={{ marginTop: 10 }}>
                    <EmploymentHistoryList hash={hash} lastPage={employmentHistory?.last_page} getEmploymentHistory={getEmploymentHistory} data={employmentHistory?.data} />
                </div>
            </div>
        </>

    )
}

