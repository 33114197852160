import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { userActions, permissionActions, inventoryActions } from "../_actions";
import { authHeader, config } from "../_helpers";
import Modal from "react-responsive-modal";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import { AlertPopup } from "../_components/AlertPopup";
import NumberFormat from "react-number-format";
import ReactModal from "react-modal";
import { getCompanyName, unAvailableReason } from '../_utils/utils'
import ChangeStatusModal from "_components/ChangeStatusModal";
import ApplicationHistoryAlert from "_components/ApplicantHistoryAlert";
import ViewExpiryAndAssignedDates from "_components/ViewExpiryAndAssignedDates"

function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
}

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hash: this.props.hash,
      statusOpen: false,
      removeOpen: false,
      mailSendOpen: false,
      email: true,
      sms: true,
      status: "",
      available: "",
      statusStep: "1",
      returnDateField: moment(),
      returnDate: "",
      message: "",
      alertPop: false,
      userName: "",
      alertOpen: false,
      isGuest: false,
      unavailableReason: 0,
      otherReason: "",
    };
    this.goBack = this.goBack.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.removeOpenModal = this.removeOpenModal.bind(this);
    this.removeCloseModal = this.removeCloseModal.bind(this);
    this.alertOpenModal = this.alertOpenModal.bind(this);
    this.alertCloseModal = this.alertCloseModal.bind(this);
    this.mailSendOpenModal = this.mailSendOpenModal.bind(this);
    this.mailSendCloseModal = this.mailSendCloseModal.bind(this);
    this.setStatusReturn = this.setStatusReturn.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }

  statusOpenModal() {
    this.setState({ statusOpen: true, statusStep: "1" });
  }

  statusCloseModal() {
    this.setState({ statusOpen: false });
  }

  removeOpenModal() {
    this.setState({ removeOpen: true });
  }

  removeCloseModal() {
    this.setState({ removeOpen: false });
  }

  alertOpenModal() {
    this.setState({ alertOpen: true });
  }

  alertCloseModal() {
    this.setState({ alertOpen: false, redirect: "/resources" });
  }

  mailSendOpenModal() {
    this.setState({ mailSendOpen: true });
  }

  mailSendCloseModal() {
    this.setState({ mailSendOpen: false });
  }

  handleChange(event) {
    // var permList = []
    if (event.target.name === "notes") {
      if (this.state.permList.includes("Employee Notes")) {
        this.setState({
          [event.target.name]: event.target.value,
          template: "custommail",
        });
      }
      return;
    }
    this.setState({
      [event.target.name]: event.target.value,
      template: "custommail",
    });
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  handleCheck(event) {
    // var value = event.target.name === 'email' ? this.state.email : this.state.sms;
    // this.setState({ [event.target.name]: !value });
  }

  updateNotes(hash) {
    let data = new FormData();
    data.append("hash", hash);
    data.append("note", this.state.notes);
    if (this.state.notes !== null) {
      this.setState({ savingNote: true })
      this.props.dispatch(userActions.updateEmployeeNote(data, "?hash=" + hash)).then(
        (res) =>{
          toast.success("Notes Saved !")
          this.setState({ savingNote: false })
        } ,
        (err) =>{
          toast.error("Notes Cannot be saved")
          this.setState({ savingNote: false })
        } ,
      );
    }
    // this.props.dispatch(userActions.getProfile("/"+hash));
  }

  componentDidMount() {
    var hash = "?hash=" + this.state.hash;
    this.props.dispatch(userActions.getProfile(hash));
    this.props.dispatch(permissionActions.getPermission());
    let data = new FormData();
    data.append("hash", this.state.hash);
    this.props.dispatch(inventoryActions.getInventoryList(data));
  }

  componentWillReceiveProps(nextProps) {
    const { user, inventory, permission } = nextProps;
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;

      this.setState({
        permList,
      });
    }
    var generalQualificationList = [],
      inventory_list = [];
    if (inventory.inventory) {
      if (inventory.inventory.data) {
        var total_cost = 0;
        inventory.inventory.data.map((item, index) => {
          total_cost += parseInt(item.cost);
          inventory_list.push(
            <div className="inv_item" key={index}>
              <div className="inv_cell">
                {index + 1 + ". " + item.unique_id + " (" + item.type + ") "}
                {/*<span className="cost">{"$"+item.cost}</span>*/}
                <NumberFormat
                  value={item.cost}
                  className="cost"
                  displayType={"text"}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </div>
              {/*<div className="inv_cell" data-header-title="Unique Id">{item.unique_id}</div>
                            <div className="inv_cell" data-header-title="Cost">{item.cost}</div>*/}
            </div>,
          );
          return inventory_list;
        });
        this.setState({ inventory_list, total_cost });
      }
    }
    if (user.items) {
      var profile = user.items.data;
      var available = profile.active === "Available" ? true : false;
      this.setState({
        status: profile.active,
        available: available,
        notes: this?.state?.notes ? this?.state?.notes : profile.notes,
        profile,
        active: user.items.data.active,
        userName: profile.first_name,
        user_type: user.items.data.user_type,
        isGuest: profile.status === 3 ? true : false,
        unavailableReason: profile.unavailable_reason,
        otherReason: profile.other_reason,
      });
      if (profile.qualification) {
        profile.qualification.map((item, index) => {
          generalQualificationList.push(
            <p className="crew-profile-detail" key={index}>
              {item}
            </p>,
          );
          return generalQualificationList;
        });
      }
    }
    this.setState({
      generalQualificationList,
    });
    if (user.removeUser) {
      // alert(user.removeUser.message);
      if (user.removeUser.success) {
        // this.setState({ redirect: "/resources" })
        this.setState({
          // alertPop: true,
          alertHead: "Success",
          alertMsg: user.removeUser.message,
          removeOpen: false,
          // alertFunction: "",
          alertOpen: true,

          // redirect: "/resources"
        });
      } else {
        var hash = "?hash=" + this.state.hash;
        this.props.dispatch(userActions.getProfile(hash));
        this.setState({ removeOpen: false });
        // alert(user.removeUser.message);
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: user.removeUser.message,
          alertFunction: "",
        });
      }
    }
    if (user.notes) {
      if (user.notes.success) {
      } else {
        // alert(user.notes.message);
        this.setState({
          alertPop: true,
          alertHead: "Success",
          alertMsg: user.notes.message,
          alertFunction: "",
        });
      }
      this.props.dispatch(userActions.getProfile("?hash=" + this.state.hash));
    }
  }

  setStatusReturn(status) {
    if (status) {
      var step = "2a";
    } else step = "2b";

    this.setState(
      {
        // statusStep:"3",
        statusStep: step,
      },
      function () {
        this.componentWillReceiveProps(this.props);
      },
    );
  }

  setUnAvailableReason = (reason, other) => {
    return unAvailableReason(reason, other);

  };

  setStep(step) {
    this.setState({ statusStep: step }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  handleDateChange(date) {
    this.setState({
      returnDateField: date,
      returnDate: date.format("MM/DD/YY"),
    });
  }

  changeStatus(status) {
    // console.log(this.state.hash)
    if (status) {
      var stat = this.state.status === "Available" ? "Unavailable" : "Available";
      // this.setState({ status:stat },function(){
      //     console.log(this.state.status)
      //     // this.componentWillReceiveProps(this.props)
      // })
      var hash = "?hash=" + this.state.hash;
      let data = new FormData();
      this.statusCloseModal();
      data.append("end_date", this.state.returnDate);
      data.append("hash", this.state.hash);
      data.append("status", stat);
      // this.props.dispatch(userActions.changeEmployeeStatus(data,hash));
      // fetch(config.apiUrl + '/api/statusupdate',
      // {
      //     headers: authHeader(),
      //     method: "POST",
      //     body: data
      // })
      // .then(function(res){ return res.json(); })
      // .then(function(data){ alert( JSON.stringify( data.message ) ) })
      this.props.dispatch(userActions.changeStatus(data, hash));
      this.setState({ status: stat });
    }
  }

  remove(hash) {
    let data = new FormData();
    this.statusCloseModal();
    data.append("hash", hash);
    this.props.dispatch(userActions.removeEmployee(data));
    // this.props.goBack(this.props.resourceDetails);
  }

  goBack() {
    this.props.goBack(this.props.resourceDetails);
  }

  editProfile(hash) {
    this.props.editProfile(hash);
  }

  print() {
    window.print();
  }

  sendMail(hash) {
    let data = new FormData();
    this.mailSendCloseModal();
    data.append("type", "mail");
    data.append("message", this.state.message);
    data.append("user_hash", hash);

    var self = this;
    fetch(config.apiUrl + "/api/employee/sendmails", {
      headers: authHeader(),
      method: "POST",
      body: data,
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        if (data.success) {
          // alert( JSON.stringify( data.message ) )
          self.setState({
            alertPop: true,
            alertHead: "Success",
            alertMsg: JSON.parse(JSON.stringify(data.message)),
            alertFunction: "",
          });
        } else {
          self.setState({
            alertPop: true,
            alertHead: "Error",
            alertMsg: JSON.parse(JSON.stringify(data.message)),
            alertFunction: "",
          });
        }
      });
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  render() {
    const { permission } = this.props;

    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }

    // if(user.items) {
    //     var profile = user.items.data;
    // }
    if (this.state.redirect === "/resources") {
      return <Redirect to={"/dashboard"} />;
    }
    if (this.state.profile) {
      return (
        // details
        <div className="resource_container profile_container">
          <div className="page_title float">
            <h1>{this.state.profile ? this.state.profile.first_name + " " + this.state.profile.last_name : ""}</h1>
            <div className="btn_list">
              {/*<button className="button remove_resource" onClick={this.goBack.bind(this)}>Back</button>*/}
              <button className="button print" onClick={this.print.bind(this)}>
                Print
              </button>
              {permList.includes("Message Employees") && (
                <button className="button edit_resource" onClick={this.mailSendOpenModal.bind(this)}>
                  Message
                </button>
              )}
              {permList.includes("Status Update") && (
                <button className="button edit_resource" onClick={this.statusOpenModal.bind(this)}>
                  Edit Status
                </button>
              )}
              {permList.includes("Edit User Profile") && (
                <button className="button edit_resource" onClick={this.editProfile.bind(this, this.state.hash)}>
                  Edit Profile
                </button>
              )}
              {permList.includes("Remove Employee") && (
                <button className="button edit_resource" onClick={this.removeOpenModal.bind(this)}>
                  Remove Employee
                </button>
              )}
            </div>
          </div>
          <div className="applicants_inner_head inner_head" />
          <div className="content_div settings">
            <Link className="goback" to={"/dashboard"}>
              Back
            </Link>
            <div className="clearFix" />
            <div className="profile1 col-sm-3">
              <p className="name_emp">
                <span className={this.state.profile && this.state.profile.colorcode ? "color_code " + this.state.profile.colorcode : "color_code"} />
                {this.state.profile && this.state.profile.first_name ? this.state.profile.first_name + " " + this.state.profile.last_name : ""}
              </p>
              <div>
                <img
                  className={(this.state.isGuest ? 'guest-code' : this.state.profile.active) + " profilePicture availability"}
                  src={
                    this.state.profile && this.state.profile.profile_photo
                      ? config.apiUrl + "/" + this.state.profile.profile_photo
                      : config.apiUrl + "/" + config.altImg
                  }
                  alt={this.state.profile && this.state.profile.first_name ? this.state.profile.first_name + " " + this.state.profile.last_name : ""}
                  onError={this.onImageError.bind(this)}
                />
              </div>
              {/*<button className={this.state.active ? "avail_btn "+this.state.active : ""}>{this.state.active ? this.state.active : ""}</button>*/}
              <div className="crew-profile-detail">
                <p>{this.state.profile && this.state.profile.user_type ? this.state.profile.user_type : ""}</p>
                {this.state.profile.crew_name && <p>{this.state.profile.crew_name}</p>}
                <p>{this.state.profile && this.state.profile.nvam_id ? this.state.profile.nvam_id : ""}</p>
                {/* <p>{this.state.profile && this.state.profile.ica_number ? this.state.profile.ica_number : ""}</p> */}
                {this.state.profile.primary_crew_name && <p>Primary Crew: {this.state.profile.primary_crew_name}</p>}
                {this.state.unavailableReason !== 0 && (
                  <p>Unavailable: {this.setUnAvailableReason(this.state.unavailableReason, this.state.otherReason)}</p>
                )}
                {this.state.profile.applying_company !== 0 && <p>Company: {getCompanyName(this.state.profile.applying_company)}</p>}

                <ViewExpiryAndAssignedDates data={this.state.profile} />
              </div>

              {this.state?.profile?.employment_history_count ? (             
                  <ApplicationHistoryAlert />
              ) : null}
            </div>
            <div className="profile2 cp-profile-info col-sm-5">
              <div className="print_float">
                <div className="cp-qual profile-sep">
                  <h4 className="cp-title">Qualifications</h4>
                  {this.state.generalQualificationList}
                </div>
                <div className="cp-cellno profile-sep">
                  <h4 className="cp-title">Cell Number</h4>
                  <p className="crew-profile-detail">
                    {this.state.profile && this.state.profile.phone ? (
                      <a
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        className="phone_email_link"
                        href={`tel:${this.state.profile.phone}`}
                        target="_top">
                        {formatPhoneNumber(this.state.profile.phone)}
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
                <div className="cp-sec profile-sep">
                  <h4 className="cp-title">Secondary Number</h4>
                  <p className="crew-profile-detail">
                    {this.state.profile && this.state.profile.second_phone !== "null" ? (
                      <a
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        className="phone_email_link"
                        href={`tel:${this.state.profile.second_phone}`}
                        target="_top">
                        {formatPhoneNumber(this.state.profile.second_phone)}
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
                <div className="cp-email profile-sep">
                  <h4 className="cp-title">Email</h4>
                  <p className="crew-profile-detail">
                    {this.state.profile && this.state.profile.email ? (
                      <a
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        className="phone_email_link"
                        href={`mailto:${this.state.profile.email}`}
                        target="_top">
                        {this.state.profile.email}
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
                <div className="cp-madr profile-sep">
                  <h4 className="cp-title">Mailing Address</h4>
                  <p className="crew-profile-detail">
                    {this.state.profile && this.state.profile.mailing_address ? this.state.profile.mailing_address.address : ""}
                  </p>
                  <p className="crew-profile-detail txtformat-capitalize">
                    {this.state.profile && this.state.profile.mailing_address ? this.state.profile.mailing_address.city : ""}
                  </p>
                  <p className="crew-profile-detail txtformat-capitalize">
                    {this.state.profile && this.state.profile.mailing_address ? this.state.profile.mailing_address.state : ""}
                  </p>
                  <p className="crew-profile-detail">
                    {this.state.profile && this.state.profile.mailing_address ? this.state.profile.mailing_address.zip : ""}
                  </p>
                </div>
              </div>
              <div className="print_float">
                <div className="cp-pa profile-sep">
                  <h4 className="cp-title">Physical Address</h4>
                  <p className="crew-profile-detail">
                    {this.state.profile && this.state.profile.physical_address ? this.state.profile.physical_address.address : ""}
                  </p>
                  <p className="crew-profile-detail txtformat-capitalize">
                    {this.state.profile && this.state.profile.physical_address ? this.state.profile.physical_address.city : ""}
                  </p>
                  <p className="crew-profile-detail txtformat-capitalize">
                    {this.state.profile && this.state.profile.physical_address ? this.state.profile.physical_address.state : ""}
                  </p>
                  <p className="crew-profile-detail">
                    {this.state.profile && this.state.profile.physical_address ? this.state.profile.physical_address.zip : ""}
                  </p>
                </div>
                <div className="cp-sex profile-sep">
                  <h4 className="cp-title">Sex</h4>
                  <p className="crew-profile-detail">{this.state.profile && this.state.profile.gender ? this.state.profile.gender : ""}</p>
                </div>
                <div className="cp-contact1 profile-sep">
                  <h4 className="cp-title">Emergency Contact #1</h4>
                  <p className="crew-profile-detail">
                    {this.state.profile && this.state.profile.emgcontact1
                      ? this.state.profile.emgcontact1.fname + " " + this.state.profile.emgcontact1.lname
                      : ""}
                  </p>
                  <p className="crew-profile-detail">
                    {this.state.profile && this.state.profile.emgcontact1 ? (
                      // formatPhoneNumber(this.state.profile.emgcontact1.phone)
                      <a
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        className="phone_email_link"
                        href={`tel:${this.state.profile.emgcontact1.phone}`}
                        target="_top">
                        {formatPhoneNumber(this.state.profile.emgcontact1.phone)}
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                  <p className="crew-profile-detail">{this.state.profile && this.state.profile.emgcontact1 ? this.state.profile.emgcontact1.relation : ""}</p>
                </div>
                <div className="cp-contact2 profile-sep">
                  <h4 className="cp-title">Emergency Contact #2</h4>
                  <p className="crew-profile-detail">
                    {this.state.profile && this.state.profile.emgcontact2
                      ? this.state.profile.emgcontact2.fname + " " + this.state.profile.emgcontact2.lname
                      : ""}
                  </p>
                  <p className="crew-profile-detail">
                    {this.state.profile && this.state.profile.emgcontact2 ? (
                      // formatPhoneNumber(this.state.profile.emgcontact2.phone)
                      <a
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        className="phone_email_link"
                        href={`tel:${this.state.profile.emgcontact2.phone}`}
                        target="_top">
                        {formatPhoneNumber(this.state.profile.emgcontact2.phone)}
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                  <p className="crew-profile-detail">{this.state.profile && this.state.profile.emgcontact2 ? this.state.profile.emgcontact2.relation : ""}</p>
                </div>
                <div className="cp-drivers-license profile-sep">
                  <h4 className="cp-title">Drivers License</h4>
                  <p className="crew-profile-detail">{this.state.profile && this.state.profile.drivers_license ? this.state.profile.drivers_license : ""}</p>
                </div>
                {/*<div className="cp-experience profile-sep">
                                    <h4 className="cp-title">Experience</h4><p className="crew-profile-detail">More than 1 season</p>
                            </div>*/}
                <div className="cp-weight profile-sep">
                  <h4 className="cp-title">Weight (lbs)</h4>
                  <p className="crew-profile-detail">{this.state.profile && this.state.profile.weight ? this.state.profile.weight : ""}</p>
                </div>
              </div>
            </div>
            <div className="profile3 col-sm-4">
              {/*<div className="single-notes-cp" placeholder="Notes..." data-hash="af0e78de0e404f0eb792126874855d56" autoComplete="off">C3. Not available until future notice Per Duane-- BS 10/1. Missed call BS 10/31.&nbsp;</div>*/}
              {permList.includes("Employee Notes") && (
                <section>
                  <textarea
                    name="notes"
                    className="single-notes-cp m-0"
                    placeholder="Notes..."
                    value={this.state.notes || ""}
                    rows="3"
                    cols="20"
                    onChange={this.handleChange}>
                    {this.state.notes}
                  </textarea>
                  <p>
                    <button disabled={this.state?.savingNote} className="btn btn-xs button" onClick={this.updateNotes.bind(this, this.props.hash)}>
                      Save Note
                    </button>
                  </p>
                </section>
              )}

              {this.state.inventory_list && (
                <div className="inventory_table">
                  {this.state.inventory_list}
                  <div className="inv_item total_cost">
                    <div className="inv_cell">
                      {/*Total<span className="cost">{"$"+this.state.total_cost}</span>*/}
                      Total
                      <NumberFormat
                        className="cost"
                        value={this.state.total_cost}
                        displayType={"text"}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </div>
                    <p className="disclaimer">
                      Employee is responsible for the condition, care, and replacement value of issued inventory. Issued Inventory not returned, lost or stolen
                      will be the reasonability of the employee. If you do not see the cost above of your current issued equipment, please contact your
                      supervisor.
                    </p>
                  </div>
                </div>
              )}
              {/*<div className="cp-issued-gear profile-sep">
                            <h4 className="cp-title">Issued Gear</h4>
                            <div>
                                <div className="gear_total">
                                    <label>Total</label>
                                    <p className="total_cost">$0</p>
                                </div>
                                <div className="legal-agreement">
                                <br/><a href="javascript:;" className="green show_poupLegalAgreement">Read full legal agreement here</a>.</div>
                                <div className="clearFix"></div>
                                <div className="btn-wrap-assg-gear"><a href="javascript:;" className="button show_popupAssignGear">Assign/Manage Gear</a></div>
                            </div>
                            
                        </div>*/}
            </div>
          </div>
          {this.state.statusOpen ? (
            <ChangeStatusModal  open={this.state.statusOpen} onClose={this.statusCloseModal} />
          ) : null}
          {/* <Modal id="status_popup" className="status_popup" open={this.state.statusOpen} onClose={this.statusCloseModal.bind(this)} little>
            <div id="statusPopup">
              <div className="modal-head">
                <h4 className="modal-title">Current Status</h4>
              </div>
              {this.state.statusStep === "1" && (
                <div className="modal-body">
                  <div className="status_step step1">
                    <label>
                      {this.state.userName + " is: "}
                      <span className="green">{this.state.status}</span>
                    </label>
                    <br />
                    <button className="button" onClick={this.setStep.bind(this, "2")}>
                      Change Current Status
                    </button>
                    <p className="step_no">1/3</p>
                  </div>
                </div>
              )}
              {this.state.statusStep === "2" && this.state.available && (
                <div className="modal-body">
                  <div className="status_step step2">
                    <label>Do you know when {this.state.userName} will return?</label>
                    <button className="button" onClick={this.setStatusReturn.bind(this, true)}>
                      Yes
                    </button>
                    <button className="button grey" onClick={this.setStatusReturn.bind(this, false)}>
                      No
                    </button>
                    <p className="step_no">2/3</p>
                  </div>
                </div>
              )}
              {this.state.statusStep === "2" && !this.state.available && (
                <div className="modal-body">
                  <div className="status_step step2">
                    <label>Make Available?</label>
                    <button className="button" onClick={this.changeStatus.bind(this, true)}>
                      Yes
                    </button>
                    <button className="button grey" onClick={this.statusCloseModal.bind(this)}>
                      No
                    </button>
                    <p className="step_no">2/3</p>
                  </div>
                </div>
              )}
              {this.state.statusStep === "2a" && (
                <div className="modal-body">
                  <div className="status_step step4">
                    <label>Choose date of return:</label>
                    <DatePicker
                      className="returnDateField"
                      name="returnDateField"
                      selected={this.state.returnDateField}
                      onChange={this.handleDateChange.bind(this)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    <button className="button" onClick={this.setStep.bind(this, "3")}>
                      Next
                    </button>
                    <p className="step_no">2/3</p>
                  </div>
                </div>
              )}
              {this.state.statusStep === "2b" && (
                <div className="modal-body">
                  <div className="status_step step2">
                    <label>Make Unavailable?</label>
                    <button className="button" onClick={this.changeStatus.bind(this, true)}>
                      Yes
                    </button>
                    <button className="button grey" onClick={this.statusCloseModal.bind(this)}>
                      No
                    </button>
                    <p className="step_no">2/3</p>
                  </div>
                </div>
              )}
              {this.state.statusStep === "3" && (
                <div className="modal-body">
                  <div className="status_step step5">
                    <label>Are you sure you want to switch {this.state.userName} to Unavailable?</label>
                    <p>(returning {this.state.returnDate === "" ? moment().format("MM/DD/YY") : this.state.returnDate})</p>
                    <button className="button" onClick={this.changeStatus.bind(this, true)}>
                      Yes
                    </button>
                    <button className="button grey" onClick={this.statusCloseModal.bind(this)}>
                      No
                    </button>
                    <p>Time off must be approved by your supervisor.</p>
                    <p className="step_no">3/3</p>
                  </div>
                </div>
              )}
            </div>
          </Modal> */}
          <Modal id="remove_popup" className="status_popup" open={this.state.removeOpen} onClose={this.removeCloseModal.bind(this)} little>
            <div id="removePopup">
              <div className="modal-head">
                <h4 className="modal-title">Remove Employee: {this.state.profile ? this.state.profile.first_name + " " + this.state.profile.last_name : ""}</h4>
              </div>
              <div className="modal-body">
                <label>Are you sure you want to archive this employee?</label>
                <br />
                <button className="button grey" onClick={this.removeCloseModal.bind(this)}>
                  Cancel
                </button>
                <button className="button" onClick={this.remove.bind(this, this.state.hash)}>
                  Archive
                </button>
              </div>
            </div>
          </Modal>
          <Modal id="email_popup" className="status_popup" open={this.state.mailSendOpen} onClose={this.mailSendCloseModal.bind(this)} little>
            <div id="emailPopup">
              <div className="modal-head">
                <h4 className="modal-title">Custom Message</h4>
              </div>
              <div className="modal-body">
                {/* <p><input type="checkbox" name="email" defaultValue="true" onChange={this.handleCheck.bind(this)}>Email</input></p> */}
                {/* <p><input type="checkbox" name="sms" defaultValue="true" onChange={this.handleCheck.bind(this)}>SMS</input></p> */}
                <textarea name="message" cols="80" rows="10" value={this.state.message || ""} onChange={this.handleChange.bind(this)} />

                <p className="step_no">Signature will automatically be included.</p>
                {/*<div className="btn_list">*/}
                <button className="button grey" onClick={this.mailSendCloseModal.bind(this)}>
                  Cancel
                </button>
                <button className="button" onClick={this.sendMail.bind(this, this.state.hash)}>
                  Send
                </button>
                {/*</div>*/}
              </div>
            </div>
          </Modal>
          <ReactModal
            isOpen={this.state.alertOpen}
            onClose={this.alertCloseModal.bind(this)}
            contentLabel="Minimal Modal Example"
            overlayClassName="alertPopup">
            <div id="statusPopup" className="popupModal">
              <div className="modal-head">
                <h4 className="modal-title">{this.state.head}</h4>
                {/*<img className="closeModal" onClick={this.viewCloseModal.bind(this)} src={require("../assets/images/close_green.png")} />*/}
              </div>
              <div className="modal-body">
                <p>{this.state.alertMsg}</p>
                <div className="btn_list">
                  <button className="button" onClick={this.alertCloseModal.bind(this)}>
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </ReactModal>
          {this.state.alertPop && (
            <AlertPopup
              head={this.state.alertHead}
              message={this.state.alertMsg}
              viewOpen="true"
              alertFunction={this.state.alertFunction}
              close={this.closeAlert.bind(this)}
            />
          )}
        </div>
      );
    }
    return (
      <div className="resource_container profile_container">
        <div className="page_title float">
          <h1>{this.state.profile ? this.state.profile.first_name + " " + this.state.profile.last_name : ""}</h1>
          <div className="btn_list">
            {/*<button className="button remove_resource" onClick={this.goBack.bind(this)}>Back</button>*/}
            <button className="button print" onClick={this.print.bind(this)}>
              Print
            </button>
            {permList.includes("Message Employees") && (
              <button className="button edit_resource" onClick={this.mailSendOpenModal.bind(this)}>
                Message
              </button>
            )}
            {permList.includes("Status Update") && (
              <button className="button edit_resource" onClick={this.statusOpenModal.bind(this)}>
                Edit Status
              </button>
            )}
            {permList.includes("Edit User Profile") && (
              <button className="button edit_resource" onClick={this.editProfile.bind(this, this.state.hash)}>
                Edit Profile
              </button>
            )}
            {permList.includes("Remove Employee") && (
              <button className="button edit_resource" onClick={this.removeOpenModal.bind(this)}>
                Remove Employee
              </button>
            )}
          </div>
        </div>
        <div className="applicants_inner_head inner_head" />
        <div className="content_div settings">
          <center id="feedLoader" className={this.state.loader ? this.state.loader : ""}>
            <img alt="Loader" src={require("../assets/images/loader.gif").default} />
          </center>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, permission, inventory } = state;
  return {
    user,
    permission,
    inventory,
  };
}

const connectedRegisterPage = connect(mapStateToProps)(Profile);
export { connectedRegisterPage as Profile };
